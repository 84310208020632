export function exportExcel(fileExcel: any) {
  let blob = new Blob([fileExcel], {
    type:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  });

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "file.xlsx");
  document.body.appendChild(link);

  // export file link Timestamp
  link.download = `export${new Date().getTime() / 1000}.xlsx`;
  link.click();
  document.body.removeChild(link);
}

export function exportZip(fileZip: any, fileType: string) {
  let blob = new Blob([fileZip], {
    type:
      "application/octet-stream",
  });

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "file.zip");
  document.body.appendChild(link);

  // export file link Timestamp
  link.download = `${fileType}-${new Date().getTime() / 1000}.zip`;
  link.click();
  document.body.removeChild(link);
}

export function exportTxt(file: any) {
  let blob = new Blob([file], {
    type: "text/plain;charset=utf-8",
  });

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "file.txt");
  document.body.appendChild(link);

  // export file link Timestamp
  link.download = `export${new Date().getTime() / 1000}.txt`;
  link.click();
  document.body.removeChild(link);
}
