const urls = {
  development: "http://127.0.0.1:5555",
  production: "https://api-prod.beniplus.ca",
} as any;
const API_URL = urls[process.env.NODE_ENV];
export default API_URL;

export const maxAge = "900";

export const homePage = "/";
export const signInURL = "/sign-in/";
export const signUpURL = "/sign-up/";
export const forgotPasswordURL = "/forgot-password/";
export const updatePasswordURL = "/update-password/";
export const verificationURL = "/verification/";
export const unsubcribeURL = "/unsubcribe/**";
export const benefitURL = "/employer/classes/";
export const enrollmentCoverageURL = "/employer/plan/";
export const settingURL = "/employer/setting/";
export const employeeURL = "/employer/employees/";
export const employeeDetailUrl = "/employer/employees/detail/";
export const documentsURL = "/employer/documents/";
export const reportsUrl = "/employer/report/";
export const companyAccountURL = "/employer/my-wallet/";
export const privacyURL = "/employer/privacy/";
export const supportURL = "/employer/support/";
export const termsOfServicesURL = "/employer/terms-of-services/";
export const claimURL = "/employer/claim/";
export const contactURL = "/contact/";
export const reAllocateURL = "/employer/re-allocate-class-benefit/";
export const terminateEmployerURL = "/terminate-employer/"

export const defaultLandingPageURL = enrollmentCoverageURL;

export const marketplaceURL = "/marketplace/";
export const marketplaceDetailURL = "/marketplace/detail/";
export const marketplaceSearchURL = "/marketplace/search/";

// default search param
export const employeeSearchParam =
  "?page=1&sortType=ASC&columnName=id&rowsPerPage=10";
export const companyAccountSearchParam =
  "?page=1&rowsPerPage=10&sortType=DESC&columnName=createdDate";
export const claimSearchParam =
  "?page=1&rowsPerPage=10&sortType=DESC&columnName=claimedDate";

// setup
export const setupCompanyURL = "/employer/setup/company/";
export const setupPlanURL = "/employer/setup/plan/";
export const setupBenefitURL = "/employer/setup/classes/";
export const setupBankingURL = "/employer/setup/funding/";
export const setupServicesAgreementURL = "/employer/setup/services-agreement/";

export const searchParamDetailEmployeeDefault = (id: number) =>
    `?id=${id}&page=1&rowsPerPage=10&sortType=DESC&columnName=submittedDate`;