export function usdToCent(money: number | null): number {
  if (money) {
    return Number((money * 100).toFixed());
  }
  return 0;
}

export function centToUsd(money?: number | null): number {
  if (money) {
    return money / 100;
  }
  return 0;
}
