import React, { useState } from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledLargerTitle,
} from "../../../../../shared/employer-styled";
import { UploadFile } from "../../../../../../cores/helpers/file-upload/file-upload";
import { Employee, Role } from "../../employee-type";
import { Class } from "../../../../request-a-quote/request-a-quote-type";
import { Provinces } from "../../../employer-setting/company-information/company-information-type";
import {
  validateForm,
  validateParsedEmployee,
} from "../../../../../../cores/utils/validation/validate-upload-in-bulk";
import { convertToRawString } from "../../../../../../cores/utils/format/string-format";
import {dateStringToDate, formatInputDateTime} from "../../../../../../cores/utils/format/date-time-format";
import {EmployerPlan} from "../../../plan/plan-preferences/plan-preferences-type";

type Props = {
  findValueByPlanPreferencesByEmployer: () => number | null;
  classes: Class[];
  roles: Role[];
  provinces: Provinces[];
  createInBulkError: String[];
  handleAddEmployeeInBulk: (employees: Employee[]) => void;
  handleResetCreateInBulkError: () => void;
  employerPlan: EmployerPlan;
};

const StyledContentBox = styled.div`
  padding: 9px 5px;
  width: 100%;
  color: ${(props) => props.theme.secondaryColor};
`;

type EmployeeManualSetupBenefitType = {
  hsaAllocated?: number;
  hsaClaimed?: number;
  wsaAllocated?: number;
  wsaClaimed?: number;
  insuranceAllocated?: number;
  insuranceClaimed?: number;
  charityAllocated?: number;
  charityClaimed?: number;
  rrspAllocated?: number;
  rrspClaimed?: number;
}

export type EmployeeManualSetupType = Employee & EmployeeManualSetupBenefitType;

export default function UploadInBulk(props: Props) {
  const [employees, setEmployees] = useState<EmployeeManualSetupType[]>([]);
  const [fieldError, setFieldError] = useState<{ row: number; error: string }[]>([]);
  const eligibleTime = props.findValueByPlanPreferencesByEmployer();
  const isValid = fieldError.length === 0 && employees.length > 0;
  let isManualBenefitSettingForm: boolean = false;

  const findIdByName = (field: string, list: any[]): number => {
    if (!field) return 0;
    const item = list.find(
      (item) => convertToRawString(item.name) === convertToRawString(field)
    );
    if (item) return Number(item.id);
    return -1;
  };

  const parseData = (rowData: string[]) => {
    const roles =
      (rowData[5] && rowData[5].split(",").map((role) => convertToRawString(role))) || [];
    const roleIds = roles.map((role) => findIdByName(role, props.roles));
    const classId = findIdByName(rowData[6], props.classes);

    const employee: Employee = {
      id: 0,
      code: rowData[0],
      firstName: rowData[1],
      lastName: rowData[2],
      gender: null,
      dateOfBirth: null,
      email: rowData[3],
      phoneNumber: null,
      street: null,
      provinceId: null,
      cityName: null,
      postCode: null,
      hiringDate: rowData[4] && dateStringToDate(formatInputDateTime(rowData[4])),
      roleIds: roleIds || [0],
      role: roles,
      classId,
      socialInsuranceNumber: null,
      position: null,
      salary: null,
      eligibilityDate: null,
      benefitCancellationDate: null,
      terminationDate: null,
      prorated: true,
      status: "WORKING",
    };

    if (eligibleTime !== null && rowData[4]) {
      let eligibilityDate = dateStringToDate(formatInputDateTime(rowData[4]));
      eligibilityDate.setDate(eligibilityDate.getDate() + eligibleTime);
      const planStartDate = props.employerPlan.planStartDate ? new Date(props.employerPlan.planStartDate) : null;
      if (planStartDate && eligibilityDate < planStartDate) {
        eligibilityDate = planStartDate;
      }
      employee.eligibilityDate = eligibilityDate;
    }

    if (isManualBenefitSettingForm)
      return createEmployeeWithManualBenefitSetting(employee, rowData)

    return employee;
  };

  function createEmployeeWithManualBenefitSetting(employee: Employee, rowData: string[]) {
    const employeeManualSetup: EmployeeManualSetupType = {...employee, ...{} as EmployeeManualSetupBenefitType};
    employeeManualSetup.hsaAllocated = rowData[7] ? Number(rowData[7]) : 0;
    employeeManualSetup.hsaClaimed = rowData[8] ? Number(rowData[8]) : 0;
    employeeManualSetup.wsaAllocated = rowData[9] ? Number(rowData[9]) : 0;
    employeeManualSetup.wsaClaimed = rowData[10] ? Number(rowData[10]) : 0;
    employeeManualSetup.insuranceAllocated = rowData[11] ? Number(rowData[11]) : 0;
    employeeManualSetup.insuranceClaimed = rowData[12] ? Number(rowData[12]) : 0;
    employeeManualSetup.charityAllocated = rowData[13] ? Number(rowData[13]) : 0;
    employeeManualSetup.charityClaimed = rowData[14] ? Number(rowData[14]) : 0;
    employeeManualSetup.rrspAllocated = rowData[15] ? Number(rowData[15]) : 0;
    employeeManualSetup.rrspClaimed = rowData[16] ? Number(rowData[16]) : 0;
    if (rowData[17]) {
      employeeManualSetup.eligibilityDate = dateStringToDate(formatInputDateTime(rowData[17]));
    }
    return employeeManualSetup;
  }

  const processEmployeeData = (dataStringLinesFiltered: string) => {
    const listEmployee: Employee[] = [];
    const errorEmployee: { row: number; error: string }[] = [];

    for (let i = 0; i < dataStringLinesFiltered.length; i++) {
      const rowData: string[] = dataStringLinesFiltered[i].split(",").map(cell => cell.replace(";", ","));
      if (rowData[0] === "Employee ID*") continue;
      if (rowData.every(value => value === "")) break;

      const employee: EmployeeManualSetupType = parseData(rowData);
      const employeeError = validateParsedEmployee(employee, isManualBenefitSettingForm);

      if (employeeError.length > 0) {
        errorEmployee.push({ row: i + 1, error: employeeError.join(", ")})
      } else {
        listEmployee.push(employee);
      }
    }

    setEmployees(listEmployee);
    setFieldError(errorEmployee);
  }

  const processData = (dataString: any) => {
    setEmployees([]);
    setFieldError([]);
    props.handleResetCreateInBulkError();
    const dataStringLines = dataString && dataString.split(/\r\n|\n/);
    const dataStringLinesFiltered = dataStringLines
      .filter((line: string) => line.split(",").length === dataStringLines[0].split(",").length &&
        line.split(",").filter((cell: string) => cell !== "").length > 1
      );

    if (dataStringLinesFiltered[0].split(",").length > 7) isManualBenefitSettingForm = true;

    const validateFormError = validateForm(dataStringLinesFiltered, eligibleTime, isManualBenefitSettingForm);
    if (validateFormError) {
      setFieldError(validateFormError);
      return;
    }
    processEmployeeData(dataStringLinesFiltered);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <StyledLargerTitle>
          Add new employee via CSV or Excel template
        </StyledLargerTitle>

        <UploadFile processData={processData} />
      </div>
      {fieldError.length > 0 &&
        fieldError.map((item, idx) => (
          <StyledContentBox key={`fieldError-${idx}`}>{`${
            item.row > 0 ? `Error as row ${item.row}` : "Error"
          }: ${item.error}`}</StyledContentBox>
        ))}
      {props.createInBulkError &&
        employees.length === 0 &&
        Object.entries(props.createInBulkError).map((item, idx) => (
          <StyledContentBox key={`createError-${idx}`}>
            Error when create user {item.join(": ")}
          </StyledContentBox>
        ))}
      {fieldError.length === 0 && (
        <div className="col-lg-12">
          <div className="col-lg-4 mt-3 p-0 float-right">
            <StyledButtonSubmit
              submit={isValid}
              disabled={!isValid}
              onClick={() => {
                props.handleAddEmployeeInBulk(employees);
                setEmployees([]);
              }}
              type="submit"
              tabIndex={20}
            >
              Add new employees
            </StyledButtonSubmit>
          </div>
        </div>
      )}
    </div>
  );
}
