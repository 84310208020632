import React, {useMemo} from "react";
import moment from "moment";
import styled from "styled-components";
import {formatterUSD} from "../../../cores/helpers/format-usd";
import {centToUsd} from "../../../cores/helpers/cent-to-usd";
import BNPProgressBar from "../progress-bar/BNPProgressBar";
import {PendingAmount} from "./WalletBenefitType"

const StyledDivAmount = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  line-height: 22px;
  width: 50%;
`;

const StyledBox = styled.div`
  background-color: #f2f2f5;
  padding: 15px 11px;
  border-radius: 5px;
  height: fit-content;
  @media (max-width: 599px) {
    padding: 10px;
  }
`;

export const StyledTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`;

const isBeforeResetBenefitDate = moment().month() === 0 && moment().date() <= 15;

export type WalletBenefitType = {
    title: string;
    total: number;
    limit: number;
    proRatedAmount?: number;
    proRated: boolean;
    pendingAmountByCategory: PendingAmount;
    isSetUpDone: boolean | null;
};


export const WalletBenefit = (props: WalletBenefitType) => {
    const {claimedAmount, availableLimit,  requestedAmount, pendingPayment,
        pendingPaymentThisYear, pendingPaymentLastYear} = useMemo(() => {
        const claimedAmount = (!props.isSetUpDone || (!props.limit && props.limit !== 0)) ? 0 : Math.max(props.total - props.limit, 0);
        const {
            pendingApproveClaimAmount,
            pendingISSClaimAmount,
            pendingISSClaimAmountThisYear
        } = !!props.pendingAmountByCategory ? props.pendingAmountByCategory : {
            pendingApproveClaimAmount: 0,
            pendingISSClaimAmount: 0,
            pendingISSClaimAmountThisYear: 0
        };

        const pendingPaymentToDisplay = isBeforeResetBenefitDate ?
            pendingISSClaimAmount :
            pendingISSClaimAmountThisYear;

        const availableLimit = (!props.limit && props.limit !== 0) ? 0 :
            Math.max(props.limit - (pendingPaymentToDisplay + pendingApproveClaimAmount), 0);

        const pendingPaymentLastYear = Math.max(pendingISSClaimAmount - pendingISSClaimAmountThisYear, 0);

        return {
            claimedAmount,
            availableLimit,
            requestedAmount: pendingApproveClaimAmount,
            pendingPayment: pendingISSClaimAmount,
            pendingPaymentThisYear: pendingISSClaimAmountThisYear,
            pendingPaymentLastYear
        }
    }, [props.total, props.limit, props.pendingAmountByCategory]);

    const {progressBarValues, progressBarColors} = useMemo(() => {
        if (isBeforeResetBenefitDate) {
            return {
                progressBarValues: [claimedAmount, pendingPayment, requestedAmount],
                progressBarColors: ["primary", "#04A77A", "#FFA400"]
            }
        }
        return {
            progressBarValues: [claimedAmount, pendingPaymentThisYear, requestedAmount],
            progressBarColors: ["primary", "#04A77A", "#FFA400"]
        }
    }, [claimedAmount, pendingPaymentThisYear, pendingPayment, requestedAmount]);

    return (
        <StyledBox className="mt-3">
            <StyledTitle>{props.title}</StyledTitle>
            <div>
                <div className="w-100 mt-2 d-flex justify-content-between">
                    Claimed
                    <div style={{color: "#003049"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(claimedAmount)
                        )}
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                    Pending Payment
                    <div style={{color: "#04A77A"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(pendingPaymentThisYear)
                        )}
                    </div>
                </div>
                {pendingPaymentLastYear > 0 && <div className="w-100 d-flex justify-content-between">
                    Pending Payment From Previous Years
                    <div style={{color: "#04A77A"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(pendingPaymentLastYear)
                        )}
                    </div>
                </div>}
                <div className="w-100 d-flex justify-content-between">
                    Requested Claim
                    <div style={{color: "#FFA400"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(requestedAmount)
                        )}
                    </div>
                </div>

            </div>
            <BNPProgressBar values={progressBarValues}
                            total={props.proRated && props.proRatedAmount !== undefined ? props.proRatedAmount : props.total}
                            colors={progressBarColors}
            />
            <div className="d-flex">
                <div className="w-50">Available</div>
                <div className="w-50 text-right">Allocated</div>
            </div>
            <div className="d-flex mt-1">
                <StyledDivAmount>
                    {
                        formatterUSD("currency", "USD").format(centToUsd(
                            (props.proRated && props.proRatedAmount !== undefined) ?
                                (props.proRatedAmount - (props.total - availableLimit) <= 0 ? 0 : Math.abs(props.proRatedAmount - (props.total - availableLimit)))
                                :
                                availableLimit
                        ))
                    }
                </StyledDivAmount>
                <StyledDivAmount className="text-right">
                    {
                        formatterUSD("currency", "USD").format(centToUsd(
                            props.proRated && props.proRatedAmount !== undefined ?
                                props.proRatedAmount :
                                props.total
                        ))
                    }
                </StyledDivAmount>
            </div>
        </StyledBox>
    );
};