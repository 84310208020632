import React from "react";
import TermServiceView from "./term-service-view";

type Props = {};
type State = {};

export default class TermsOfServices extends React.Component<Props, State> {
  render() {
    return (
      <TermServiceView/>
    );
  }
}
