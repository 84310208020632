import React, { useState, useEffect } from "react"
import { RouteChildrenProps } from "react-router"
import { addLoading, removeLoading } from "./../../../../cores/utils/loading"
import { getDataEmployerBenefits } from "../employer-classes/employer-classes-function"
import { EmployerBenefitClass } from "../employer-classes/employer-classes-type"
import {
  StyledBigTitle,
  StyledMainContent,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  LandingStyledTextValidator,
  StyledButtonSubmit,
  StyledSpanExpansionDelete,
  StyledImgDelete,
  StyledExpansionLabel
} from "../../../shared/employer-styled"
import { formatterUSD } from "../../../../cores/helpers/format-usd"
import { ExpansionPanel } from "@material-ui/core"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"
import { centToUsd } from "../../../../cores/helpers/cent-to-usd"
import { Class, ClassBenefit } from "../../request-a-quote/request-a-quote-type"
import { ValidatorForm } from "react-material-ui-form-validator"
import { getInfoByToken } from "../../../../cores/utils/helpers"
import { updateClassBenefits, postClassBenefits, deleteClassBenefits, getClasses as getClassesApi } from "../../../../services/employer-benefits-service"
import styled from "styled-components"
import {
  StyledTable,
  StyledHeaderTable,
  StyledRowBodyTable,
  StyledImgIconClose,
} from "../../../shared/table-styled"
import moment from "moment";
import { defaultLandingPageURL } from "../../../../cores/constants/url-config";
import { publishMessage } from "../../../../cores/utils/message";
import IconDelete from "../../../../cores/config/component/images/bin@2x.png";
import IconDeleteDesktop from "../../../assets/images/RAQ-Step2-fill/bin.png";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import { AddClassBenefits } from "../../../../cores/helpers/add-class-benefits/add-class-benefits";
import { usdToCent } from "../../../../cores/helpers/cent-to-usd";
import {
  ConfirmDialog,
  showConfirmModal,
  closeConfirmModal,
} from "../../../../cores/helpers/confirm-dialog/confirm-dialog";

const StyledColumn20Table = styled.div`
  width: 20%;
  overflow: hidden;
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledColumn40Table = styled.div`
  width: 40%;
  padding: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledWidthTable = styled.div`
  width: 100%;
`;

const StyledSpanIconClose = styled.span`
  cursor: pointer;
  color: #212529;
`;

export default function ReAllocateClassBenefit(props: RouteChildrenProps) {

  const [employerClassBenefits, setEmployerClassBenefits] = useState<EmployerBenefitClass[]>([])
  const [classes, setClasses] = useState<Class[]>([])
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [isOtherClass, setIsOtherClass] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [classBenefit, setClassBenefit] = useState<ClassBenefit>({
    classId: 0,
    planId: 0,
    coverageAmount: 0,
    noOfEmployees: 0,
    className: ""
  })
  const [id, setId] = React.useState<number>(0)

  useEffect(() => {
    if (Number(moment().format("MMDD")) > 115) {
      props.history.push(defaultLandingPageURL)
    }

    initData()
  }, [])

  useEffect(() => {
    setIsDisable(employerClassBenefits.findIndex(i => i.coverageAmount === undefined || i.coverageAmount === 0) !== -1)
  }, [employerClassBenefits])

  const handleChangeInputDefineClassBenefits = (event: any) => {
    let defineClassBenefit: ClassBenefit = { ...classBenefit };
    const { name, value } = event.target;
    if (name === "coverageAmount") {
      defineClassBenefit.coverageAmount = Number(value);
    } else if (name === "className") {
      defineClassBenefit.className = value;
    } else {
      defineClassBenefit.noOfEmployees = Number(value);
    }

    setClassBenefit(defineClassBenefit)
    validatorListener(defineClassBenefit);
  };

  const handleChangeSelectClassBenefits = (event: any) => {
    const { name, value } = event.target;

    let defineClassBenefit: any = { ...classBenefit };

    defineClassBenefit[name] = value;
    setClassBenefit(defineClassBenefit);
    validatorListener(defineClassBenefit);
  };

  const initData = async () => {
    addLoading();

    let result = await getDataEmployerBenefits()

    setClasses(result.classes || [])
    setEmployerClassBenefits(result.classBenefits || [])

    initCurrentClassBenefit(result.classes, result.classBenefits);

    removeLoading();
  }

  const initCurrentClassBenefit = (classesInput: Class[], employerClassBenefitsInput: EmployerBenefitClass[]) => {
    //set current class benefit
    let remainingClasses = getRemainingClasses(classesInput, employerClassBenefitsInput);
    let firstClassId = remainingClasses.length ? remainingClasses[0].id : 0;
    // set default DefineClassBenefit
    let defaultDefineClassBenefit: ClassBenefit = {
      classId: firstClassId,
      planId: 0,
      noOfEmployees: 0,
      coverageAmount: 0,
      className: "",
    };

    // set state
    setClassBenefit(defaultDefineClassBenefit)
  }

  const handleChangeCheckbox = (event: any): void => {
    const { checked } = event.target;
    let classBenefitTemp: ClassBenefit = { ...classBenefit };
    if (!checked) {
      let remainingClasses = getRemainingClasses();
      let firstClassId = remainingClasses.length ? remainingClasses[0].id : 0;
      classBenefitTemp.classId = firstClassId;
      classBenefitTemp.className = "";
    } else {
      classBenefitTemp.classId = 0;
    }
    setIsOtherClass(checked)
    setClassBenefit(classBenefitTemp)
    validatorListener(classBenefitTemp);
  };

  const validatorListener = (defineClassBenefit: ClassBenefit) => {
    let isValid = validateCurrentClassBenefit(defineClassBenefit);
    setIsValid(isValid)
  };

  const validateCurrentClassBenefit = (defineClassBenefit: ClassBenefit): boolean => {
    return !!(defineClassBenefit.coverageAmount &&
        ((isOtherClass && defineClassBenefit.className) ||
            defineClassBenefit.classId > 0));

  };

  const getRemainingClasses = (classesInput?: Class[], employerClassBenefitsInput?: EmployerBenefitClass[]) => {
    let selectClasses = (employerClassBenefitsInput ? employerClassBenefitsInput : employerClassBenefits).map(
      (classBenefit) => classBenefit.classId
    );
    let remainingClasses = (classesInput ? classesInput : classes).filter(
      (classItem) => selectClasses.indexOf(classItem.id) === -1
    );
    return remainingClasses;
  };

  const findClassById = (idClass: number): { label: string; value: number } => {
    let label = ""
    let value = 0

    let classItem = classes.find((classBenefit: Class) => {
      return classBenefit.id === idClass;
    });

    if (classItem) {
      label = classItem.name
      value = classItem.id
    }

    return { label, value };
  };

  const handleSubmitUpdateClassBenefit = async () => {
    let decoded = getInfoByToken()
    let model = {
      employerId: decoded.employerId,
      benefits: employerClassBenefits
    }
    const res = await updateClassBenefits(model)
    if (res.status === 204) {
      publishMessage({
        message: "Saved class benefit successfully.",
        variant: "success"
      })
    }
  }

  const handleChangeInputUpdateClassBenefits = (event: any, id: number) => {
    const val = Number(event.target.value) * 100;
    let benefitCoverage: EmployerBenefitClass[] = [...employerClassBenefits];

    benefitCoverage.forEach(i => {
      if (i.id === id) i.coverageAmount = val;
    });

    setEmployerClassBenefits(benefitCoverage)
  }

  const addClassBenefits = () => {
    let classBenefitTemp = Object.assign({}, classBenefit, {
      coverageAmount: usdToCent(classBenefit.coverageAmount),
      classId: isOtherClass ? 0 : classBenefit.classId,
    });

    postClassBenefits(classBenefitTemp).then(async (response) => {
      let result = await getDataEmployerBenefits()

      setClasses(result.classes || [])
      setEmployerClassBenefits(result.classBenefits || [])

      initCurrentClassBenefit(result.classes, result.classBenefits);
      
      setIsValid(false)
    });
  };

  const removeEmployerClasses = (id: number) => {
    deleteClassBenefits(id).then(() => {
      let employerClassBenefitsTemp: EmployerBenefitClass[] = [ ...employerClassBenefits ]

      let employerClassBenefitIndex = employerClassBenefitsTemp.findIndex(
        (employerClassBenefit) => {
          return employerClassBenefit.id === id;
        }
      );

      let temp = employerClassBenefitsTemp[employerClassBenefitIndex];

      employerClassBenefitsTemp.splice(employerClassBenefitIndex, 1);
      setEmployerClassBenefits(employerClassBenefitsTemp);

      getClasses(employerClassBenefitsTemp, temp.classId);
    });
  };

  const getClasses = async (employerClassBenefits: EmployerBenefitClass[], classId: number) => {
    let resultClasses = await getClassesApi();
    let classes: Class[] = resultClasses.data || [];

    setClasses(classes);
    
    initCurrentClassBenefit(classes.filter(i => i.id != classId), employerClassBenefits);
  }

  const showModalDelete = (classId: number) => {
    setId(classId);
    showConfirmModal();
  };

  const handleDelete = () => {
    removeEmployerClasses(id);
    closeConfirmModal();
  };

  return <>
    <StyledMainContent>
      <div className="row">
        <StyledBigTitle className="col-lg-10 d-flex align-items-center">Re-allocate class benefit</StyledBigTitle>
      </div>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <StyledContentBox isBorder={false}>
              <div>
                {/* Desktop view */}
                <StyledHideForMobile>
                  <ValidatorForm onSubmit={handleSubmitUpdateClassBenefit}>
                    <StyledWidthTable>
                      <StyledTable>
                        <StyledHeaderTable>
                          <StyledColumn20Table>Class</StyledColumn20Table>
                          <StyledColumn20Table>No of Employee</StyledColumn20Table>
                          <StyledColumn40Table>Coverage Amount</StyledColumn40Table>
                          <StyledColumn20Table>Total</StyledColumn20Table>
                        </StyledHeaderTable>
                        {
                          employerClassBenefits.map(i => {
                            return (<div className="d-flex flex-row justify-content-center align-items-center">
                              <StyledRowBodyTable key={i.id}>
                                <StyledColumn20Table className="d-flex align-items-center">
                                  {i.classId === 0
                                    ? i.className
                                    : findClassById(i.classId).label}
                                </StyledColumn20Table>
                                <StyledColumn20Table className="d-flex align-items-center">
                                  {`${i.noOfEmployees} person${
                                    i.noOfEmployees > 1 ? "s" : ""
                                    }`}
                                </StyledColumn20Table>
                                <StyledColumn40Table>
                                  <LandingStyledTextValidator
                                    onChange={(event: any) => handleChangeInputUpdateClassBenefits(event, i.id)}
                                    name="coverageAmount"
                                    type="number"
                                    title="Coverage Amount"
                                    placeholder="Coverage Amount"
                                    value={centToUsd(i.coverageAmount === undefined ? 0 : i.coverageAmount)}
                                    validators={["required", "matchRegexp:^[0-9]"]}
                                    errorMessages={[
                                      "Please enter the number",
                                      "Please do not enter a negative number"
                                    ]}
                                    tabIndex={-1}
                                    role="application"
                                    aria-hidden="true" />
                                </StyledColumn40Table>
                                <StyledColumn20Table className="font-weight-bold d-flex align-items-center justify-content-start">
                                  {
                                    formatterUSD("currency", "USD").format(
                                      centToUsd(i.coverageAmount) * i.noOfEmployees
                                    )
                                  }
                                </StyledColumn20Table>
                              </StyledRowBodyTable>
                              <StyledSpanIconClose
                                className="ml-2"
                                onClick={() => showModalDelete(i.id)}>
                                <StyledImgIconClose
                                  title="Delete"
                                  alt="icon-delete"
                                  src={IconDeleteDesktop} />
                              </StyledSpanIconClose>
                            </div>);
                          })
                        }
                      </StyledTable>
                    </StyledWidthTable>
                    {
                      employerClassBenefits.length !== 0 && (
                        <div className="row mt-3">
                          <StyledButtonSubmit
                            className="col-2 ml-auto"
                            submit={!isDisable}
                            disabled={isDisable}
                            type="submit">
                            Save
              			</StyledButtonSubmit>
                        </div>
                      )
                    }
                  </ValidatorForm>
                </StyledHideForMobile>

                {/* Mobile view */}
                <StyledHideForDesktop>
                  <ValidatorForm onSubmit={handleSubmitUpdateClassBenefit}>
                    {employerClassBenefits.map((i, index) => (
                      <ExpansionPanel
                        square
                        defaultExpanded={false}
                        className="mt-2"
                        key={index}>
                        <StyledExpansionPanelSummary
                          className="m-0"
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          expandIcon={<ArrowDropUp />} >
                          <div className="w-100">
                            <StyledTitleExpansion>class</StyledTitleExpansion>
                            <div className="mt-2">
                              {findClassById(i.classId).label}
                            </div>
                          </div>
                        </StyledExpansionPanelSummary>
                        <StyledExpansionPanelDetails>
                          <div className="w-100">
                            <StyledTitleExpansion>Plan</StyledTitleExpansion>
                            <div className="mt-2">Standard Plan</div>
                          </div>
                          <div className="w-100 mt-3">
                            <StyledTitleExpansion>
                              NO OF EMPLOYEE
											</StyledTitleExpansion>
                            <div className="mt-2">
                              {i.noOfEmployees}
                            </div>
                          </div>
                          <div className="w-100 mt-3">
                            <StyledTitleExpansion>
                              COVERAGE AMOUNT
											</StyledTitleExpansion>
                            <div className="mt-2">
                              <LandingStyledTextValidator
                                onChange={(event: any) => handleChangeInputUpdateClassBenefits(event, i.id)}
                                name="coverageAmount"
                                type="number"
                                title="Coverage Amount"
                                placeholder="Coverage Amount"
                                value={centToUsd(i.coverageAmount === undefined ? 0 : i.coverageAmount)}
                                validators={["required", "matchRegexp:^[0-9]"]}
                                errorMessages={[
                                  "Please enter the number",
                                  "Please do not enter a negative number"
                                ]}
                                tabIndex={-1}
                                role="application"
                                aria-hidden="true" />
                            </div>
                          </div>
                          <div className="w-100 mt-3 fw-bold">
                            <StyledTitleExpansion>TOTAL</StyledTitleExpansion>
                            <div className="mt-2">
                              {
                                formatterUSD("currency", "USD").format(
                                  centToUsd(
                                    i.coverageAmount *
                                    i.noOfEmployees
                                  )
                                )
                              }
                            </div>
                          </div>
                          <hr />
                          <div className="text-center">
                            <StyledSpanExpansionDelete
                              onClick={() => removeEmployerClasses(i.id)}>
                              <StyledImgDelete src={IconDelete} alt="delete" />
                              <StyledExpansionLabel>Delete</StyledExpansionLabel>
                            </StyledSpanExpansionDelete>
                          </div>
                        </StyledExpansionPanelDetails>
                      </ExpansionPanel>
                    ))}
                    <StyledButtonSubmit
                      className="mt-2"
                      submit={!isDisable}
                      disabled={isDisable}
                      type="submit">
                      Save
              	</StyledButtonSubmit>
                  </ValidatorForm>
                </StyledHideForDesktop>

                {/* Add Class Benefits */}
                <CheckBox
                  classLabel="mt-3"
                  title="Add a new custom class"
                  onChange={handleChangeCheckbox}
                  checked={isOtherClass}
                />
                <AddClassBenefits
                  isValid={isValid}
                  classBenefit={classBenefit}
                  getRemainingClasses={getRemainingClasses}
                  findClassById={findClassById}
                  onSubmit={addClassBenefits}
                  handleChangeInputDefineClassBenefits={handleChangeInputDefineClassBenefits}
                  handleChangeSelectClassBenefits={handleChangeSelectClassBenefits}
                  showSelectPlan={true}
                  isOtherClass={isOtherClass}
                />
              </div>
            </StyledContentBox>
          </div>
        </div>
      </div>
    </StyledMainContent>

    <ConfirmDialog
      title="Delete"
      content="Are you sure you want to delete this class?"
      handleOnOk={handleDelete}
    />
  </>;
}