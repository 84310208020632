import React, {useEffect, useMemo, useState} from 'react';
import styled, {css} from "styled-components";
import { RouteChildrenProps } from "react-router";
import moment from "moment";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import PayrollReport from "./payrolls/PayrollReport";
import {reportRef, SearchParamType} from "./ReportType";
import {
  ClaimTypes,
  defaultPagingInfo,
  defaultSearchParam,
  ReportPeriod,
  ReportType,
  GroupInsuranceClaimType,
  GroupInsuranceReportType
} from "./ReportModel";
import {StyledBigTitle, StyledLargerTitle, StyledMainContent} from "../../shared/employer-styled";
import {BNPSelect} from "../../../cores/helpers/select/select";
import {SortType} from "../../../services/employer-employees-service";
import {SortTable} from "../employer/employees/employee-model";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import {BE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";
import {PagingInfo} from "../../../cores/helpers/pagingation/pagination";
import ReportSummarizedGroup from "./component/ReportSummarizedGroup";
import {Balance} from "../employer/wallet/wallet-type";
import {SummarizeType} from "./ReportType";
import ClaimReport from "./claims/ClaimReport";
import {statusTransaction} from "../employer/wallet/wallet-model";
import {getInfoByToken} from "../../../cores/utils/helpers";
import {getAllBalances, getAllSeperatePendingAmount} from "../../../services/wallet-service";
import {formatterUSD} from "../../../cores/helpers/format-usd";
import {centToUsd} from "../../../cores/helpers/cent-to-usd";
import TransactionReport from "./transactions/TransactionReport";
import {TableFilterAndSearch} from "../../shared/BNPTable/TableFilterAndSearch";
import GroupInsuranceReport from './group-insurances/GroupInsuranceReport';
import { groupInsuranceSummaryReportColumns } from './group-insurances/GroupInsuranceReportModel';

const ColoredPanelItem = styled.div`
  background-color: #EFF2E8;
  border-radius: 4px;
  padding-top:24px;
  padding-bottom:24px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 2px 1px rgba(28, 73, 6, 0.08);
`;

const StyledColoredPanelTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 16px;
`;

const StyledColoredPanelContent = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  color: ${props => props.theme.successColor};
`;

const StyledReportNavBar = styled.div`
  width: fit-content;
  min-width: 356px;
  background: none;
  display: flex;
  justify-content: space-between;
`;

const StyledReportNavBarItem = styled.div`
  overflow: hidden;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 32px;
  :hover {
    background-color: #F7F7F8;
  }
  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: ${props => props.theme.infoColor};
      color: #fff;
      font-weight: bold;
      :hover {
        background-color: ${props => props.theme.infoColor};
        opacity: 0.8;
      }
    `}
`;

const StyledBNPSelect = styled(BNPSelect)`
  .MuiInputBase-input {
    padding: 4px 0;
  }
`;


export const StyledDivExport = styled.div`
  font-weight: bold;
  line-height: 16px;
  color: ${props => props.theme.infoColor};
  width: fit-content;
  border: 1px solid ${props => props.theme.infoColor};
  border-radius: 32px;
  padding: 15px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    padding-right: 4px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
  cursor: pointer;
`;

export function calculatePeriod(period: string, searchParam: SearchParamType, from: Date | string | null, to: Date | string | null) {
  let fromLocal: string | null | Date = moment().month(moment().month()).startOf('month').toDate();
  let toLocal: string | null | Date = moment().month(moment().month()).endOf('month').toDate();
  switch (period) {
    case ReportPeriod.lastMonth.type:
      fromLocal = moment().month(moment().month()-1).startOf('month').toDate();
      toLocal = moment().month(moment().month()-1).endOf('month').toDate();
      break;
    case ReportPeriod.quarter.type:
      fromLocal = moment().quarter(moment().quarter()).startOf('quarter').toDate();
      toLocal = moment().quarter(moment().quarter()).endOf('quarter').toDate();
      break;
    case ReportPeriod.year.type:
      fromLocal = moment().year(moment().year()).startOf('year').toDate();
      toLocal = moment().year(moment().year()).endOf('year').toDate();
      break;
    case ReportPeriod.custom.type:
      fromLocal = from && new Date(from);
      toLocal = to && new Date(to);
      break;
  }
  searchParam.from = fromLocal && moment(fromLocal).format(BE_DATE_FORMAT);
  searchParam.to = toLocal && moment(toLocal).format(BE_DATE_FORMAT);
  return searchParam;
}

function Report(props: RouteChildrenProps) {
  const [activeReportType, setActiveReportType] = useState<string>(ReportType.HR.type);
  const [searchParam, setSearchParam] = useState<SearchParamType>(defaultSearchParam);
  const [paging, setPaging] = useState<PagingInfo>(defaultPagingInfo);
  const [column, setColumn] = useState<SortTable[]>([]);
  const [period, setPeriod] = useState<string>(ReportPeriod.thisMonth.type);
  const [filterTypes, setFilterTypes] = useState<any[]>([]);
  const [summarizedList, setSummarizedList] = useState<SummarizeType[]>([]);
  const [isGroupInsuranceSummary, setIsGroupInsuranceSumary] = useState<boolean>(false);

  let childHandle: reportRef | null

  useEffect(() => {
    addLoading();

    let searchParam = {...defaultSearchParam};
    if (ReportType.HR.type === activeReportType) {
      setFilterTypes([...ClaimTypes, ...GroupInsuranceClaimType])
    } else if (ReportType.CLAIM.type === activeReportType) {
      setFilterTypes(ClaimTypes)
    } else if (ReportType.TRANSACTION.type === activeReportType) {
      setFilterTypes(statusTransaction)
    } else if (ReportType.GROUP_INSURANCE.type === activeReportType) {
      setFilterTypes(GroupInsuranceClaimType)
      searchParam = {...searchParam,
        filter: GroupInsuranceClaimType[0].id,
        [ReportType.GROUP_INSURANCE.filter]: GroupInsuranceClaimType[0].id
       }
    }

    setColumn(getSearchByUrl().columns);
    setSearchByParam(calculatePeriod(period, searchParam, searchParam.from, searchParam.to));

    removeLoading();
  }, [activeReportType]);

  useEffect(() => {
    const newSearch = getSearchByUrl();

    setSearchParam(newSearch.searchParam);
    setColumn(newSearch.columns);

  }, [window.location.search])

  const getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      from = urlParams.get("from"),
      to = urlParams.get("to"),
      filter = urlParams.get(ReportType[activeReportType].filter),
      searchName = urlParams.get("searchName"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage");

    let searchParamClone = Object.assign({}, searchParam);
    let columns: SortTable[] = ReportType[activeReportType].columns;
    setIsGroupInsuranceSumary(false);

    if (page) {
      searchParamClone.page = Number(page);
    } else {
      searchParamClone.page = 1;
    }
    if (from) {
      searchParamClone.from = moment(from).format(BE_DATE_FORMAT);
    } else {
      searchParamClone.from = null;
    }
    if (to) {
      searchParamClone.to = moment(to).format(BE_DATE_FORMAT);
    } else {
      searchParamClone.to = null;
    }
    if (filter) {
      searchParamClone.filter = filter;
      if (filter === GroupInsuranceReportType.GROUP_INSURANCE_SUMMARY) {
        columns = groupInsuranceSummaryReportColumns;
        setIsGroupInsuranceSumary(true);
      } 
    } else {
      searchParamClone.filter = null;
    }
    if (searchName) {
      searchParamClone.searchName = searchName;
    } else {
      searchParamClone.searchName = null;
    }
    if (columnName && sortType) {
      searchParamClone.columnName = columnName;
      searchParamClone.sortType = sortType;
    } else {
      searchParamClone.columnName = null;
      searchParamClone.sortType = null;
    }
    if (rowsPerPage) {
      searchParamClone.perPage = Number(rowsPerPage);
    } else {
      searchParamClone.perPage = searchParam.perPage;
    }

    // set sort table
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam: searchParamClone,
      columns,
    };
  };

  const setSearchByParam = (searchParam: SearchParamType) => {
    let url = new URL(window.location.href);

    searchParam.page
      ? url.searchParams.set("page", searchParam.page.toString())
      : url.searchParams.delete("page");

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    if (searchParam.filter) {
        url.searchParams.set(ReportType[activeReportType].filter, searchParam.filter)
    } else {
      url.searchParams.delete(ReportType.TRANSACTION.filter);
      url.searchParams.delete(ReportType.HR.filter);
      url.searchParams.delete(ReportType.CLAIM.filter);
    }

    searchParam.searchName
      ? url.searchParams.set("searchName", searchParam.searchName)
      : url.searchParams.delete("searchName");

    searchParam.from
      ? url.searchParams.set("from", moment(searchParam.from).format(BE_DATE_FORMAT))
      : url.searchParams.delete("from");

    searchParam.to
      ? url.searchParams.set("to", moment(searchParam.to).format(BE_DATE_FORMAT))
      : url.searchParams.delete("to");

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    props.history.push(url.search);
  };

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.page = 1;
    searchParamBuffer.perPage = Number(value);

    // set url search
    setSearchByParam(searchParamBuffer);
  };

  const handleChangePage = (page: number) => {
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);
    searchParamClone.page = page;

    setSearchByParam(searchParamClone);
  };

  function sortTable (columnId: string) {
    let columnsHeaderTable: SortTable[] = [...column];
    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParamBuffer.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    setSearchByParam(searchParamBuffer);
  }

  function handleChangeReportType (type: string) {
    setActiveReportType(type);
  }

  const handleChangeReportPeriod = (e: any) => {
    const periodClone = e.target.value !== -1 ? e.target.value : ReportPeriod.thisMonth.type;
    setPeriod(periodClone);

    let searchParamClone = Object.assign({}, searchParam);
    if (periodClone !== ReportPeriod.custom.type) {
      searchParamClone = calculatePeriod(periodClone, searchParamClone, "", "")
    } else {
      searchParamClone.from = null;
      searchParamClone.to = null;
    }

    setSearchByParam(searchParamClone);
  };

  function handleChangeReportDate(date: Date | null, name: string | null) {
    const searchParamClone = Object.assign({}, searchParam)
    if (period === ReportPeriod.custom.type && name === "from") {
      searchParamClone.from = date && moment(date.toString()).format(BE_DATE_FORMAT);
    } else if (period === ReportPeriod.custom.type && name === "to") {
      searchParamClone.to = date && moment(date.toString()).format(BE_DATE_FORMAT);
    }
    setSearchByParam(searchParamClone);
  }

  function handleChangeFilterType(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);

    searchParamClone.filter = Number(value) !== -1 ? value : null;
    searchParamClone.page = 1;

    setSearchByParam(searchParamClone);
  }

  const searchNameOrEmail = (searchKey: string) => {
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);

    searchParamClone.searchName = searchKey;
    searchParamClone.page = 1;

    setSearchByParam(searchParamClone);
  };

  const isCustomPeriod = useMemo(() => {
    return period === ReportPeriod.custom.type;
  }, [period]);

  return (
    <>
      <StyledMainContent>
        <ReportSummaryGroup />
        <div>
          <ReportNavbar activeReportType={activeReportType} handleChangeReportType={handleChangeReportType} />
        </div>
        <div className="my-4">
          <StyledLargerTitle>
            {ReportType[activeReportType].name}
          </StyledLargerTitle>
        </div>
        <div className="row mb-5">
          <div className="col-md-3">
            <StyledBNPSelect
              options={Object.values(ReportPeriod).map(period => ({id: period.type, name: period.name}))}
              value={period}
              name="reportPeriod"
              onChange={handleChangeReportPeriod}
              tabIndex={3}
            />
          </div>
          {isCustomPeriod ? (
            <>
              <div className="col-md-3">
                <BNPDatePicker
                  onChange={handleChangeReportDate}
                  name="from"
                  placeholder={isGroupInsuranceSummary ? 'From withdrawal date' : undefined}
                  value={searchParam.from && moment(searchParam.from).zone(new Date().getTimezoneOffset()).toDate()} />
              </div>
              <div className="col-md-3">
                <BNPDatePicker
                  onChange={handleChangeReportDate}
                  name="to"
                  placeholder={isGroupInsuranceSummary ? 'To withdrawal date' : undefined}
                  value={searchParam.to && moment(searchParam.to).zone(new Date().getTimezoneOffset()).toDate()}
                  minDate={searchParam.from} />
              </div>
            </>
          ) : <div className="col-md-6" />}
          <StyledDivExport className="col-md-3" onClick={() => childHandle && childHandle.exportReport()}>
            <SystemUpdateAltOutlinedIcon className="icon mr-2"/>
            Export via Excel
          </StyledDivExport>
        </div>
        <ReportSummarizedGroup reportType={activeReportType} summarizedList={summarizedList}/>
        <TableFilterAndSearch
          tableType={activeReportType}
          searchParam={searchParam}
          handleChangeFilter={handleChangeFilterType}
          filterTypes={filterTypes}
          handleSearch={searchNameOrEmail}
          searchPlaceholder={isGroupInsuranceSummary ? "Search by Insurance Coverage" : undefined} 
          />
        <div>
          {activeReportType === ReportType.HR.type &&
            <PayrollReport
              ref={c => childHandle = c}
              columns={column}
              sortTable={sortTable}
              changePage={handleChangePage}
              paging={paging}
              setPaging={setPaging}
              setRowsPerPage={setRowsPerPage}
              searchParams={searchParam}
              setSummarizedList={setSummarizedList}
              period={period}
              {...props}
            />}
          {activeReportType === ReportType.CLAIM.type &&
            <ClaimReport
              ref={c => childHandle = c}
              columns={column}
              sortTable={sortTable}
              changePage={handleChangePage}
              paging={paging}
              setPaging={setPaging}
              setRowsPerPage={setRowsPerPage}
              searchParams={searchParam}
              setSummarizedList={setSummarizedList}
              period={period}
              {...props}
            />}
          {activeReportType === ReportType.TRANSACTION.type &&
            <TransactionReport
              ref={c => childHandle = c}
              columns={column}
              sortTable={sortTable}
              changePage={handleChangePage}
              paging={paging}
              setPaging={setPaging}
              setRowsPerPage={setRowsPerPage}
              searchParams={searchParam}
              setSummarizedList={setSummarizedList}
              period={period}
              {...props}
            />}
          {activeReportType === ReportType.GROUP_INSURANCE.type &&
            <GroupInsuranceReport
              ref={c => childHandle = c}
              columns={column}
              sortTable={sortTable}
              changePage={handleChangePage}
              paging={paging}
              setPaging={setPaging}
              setRowsPerPage={setRowsPerPage}
              searchParams={searchParam}
              setSummarizedList={setSummarizedList}
              period={period}
              {...props}
            />}
        </div>
      </StyledMainContent>
    </>
  );
}

export default Report;

type TPendingAmount = {
    processing: number;
    pendingAmount: number;
    claimRequested: number;
  }

type TSummaryData = {
  name: string;
  balanceInAccount: number;
  topUpPending: number;
  claimPending: number;
}

const ReportSummaryGroup = React.memo(() => {
  const [summaryData, setSummaryData] = useState<TSummaryData>({
    name: "",
    balanceInAccount: 0,
    topUpPending: 0,
    claimPending: 0
  })

  useEffect(() => {
    getSummarizes().then(result => setSummaryData(result))
  }, [])

  async function getSummarizes () {
    let info = getInfoByToken()

    let resultBalances = await getAllBalances(info.employerId);

    let resultPendingAmount = await getAllSeperatePendingAmount(
      info.employerId
    );

    let balances: Balance[] = resultBalances.data.balances;
    let pendingAmount: TPendingAmount = resultPendingAmount.data;
    let availableAmount = 0;

    if (balances.length) {
      let physical = balances.find(
        (balance) => balance.walletType === "physical"
      );

      if (physical) {
        availableAmount = physical.balance;
      }
    }

    return {
      name: info.user.name,
      balanceInAccount: availableAmount,
      topUpPending: pendingAmount.pendingAmount,
      claimPending: pendingAmount.claimRequested + pendingAmount.processing
    }
  }
  return (
    <>
      <StyledBigTitle>{`Here's your report, ${summaryData.name}!`}</StyledBigTitle>
      <div className="row my-5">
        <div className="col-lg-4 mt-2">
          <ColoredPanelItem>
            <StyledColoredPanelTitle>Balance in Account</StyledColoredPanelTitle>
            <StyledColoredPanelContent>{formatterUSD("currency", "USD").format(
              centToUsd(summaryData.balanceInAccount)
            )}</StyledColoredPanelContent>
          </ColoredPanelItem>
        </div>
        <div className="col-lg-4 mt-2">
          <ColoredPanelItem>
            <StyledColoredPanelTitle>Top Up Pending</StyledColoredPanelTitle>
            <StyledColoredPanelContent>{formatterUSD("currency", "USD").format(
              centToUsd(summaryData.topUpPending)
            )}</StyledColoredPanelContent>
          </ColoredPanelItem>
        </div>
        <div className="col-lg-4 mt-2">
          <ColoredPanelItem>
            <StyledColoredPanelTitle>Claim Pending</StyledColoredPanelTitle>
            <StyledColoredPanelContent>{formatterUSD("currency", "USD").format(
              centToUsd(summaryData.claimPending)
            )}</StyledColoredPanelContent>
          </ColoredPanelItem>
        </div>
      </div>
    </>
  )
})

function ReportNavbar (props: {activeReportType: string, handleChangeReportType: (type: string) => void }) {
  return <StyledReportNavBar>
    {Object.keys(ReportType).map((type: string) => (
      <div key={`report-nav-bar-item-${type}`}>
        <StyledReportNavBarItem active={type === props.activeReportType}
                                onClick={() => props.handleChangeReportType(type)}>
          {ReportType[type].name}
        </StyledReportNavBarItem>
      </div>
    ))}
  </StyledReportNavBar>;
}