import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";

import FundingModal, {
  showFundingModal,
  closeFundingModal,
} from "./funding-modal";
import { payments } from "./funding-model";
import {PaymentModel, ChequeInfo, BankingInfo} from "./funding-type";

import EditIcon from "@material-ui/icons/Edit";
import {
  StyledContentBox,
  StyledLargerTitle,
  StyledButtonSubmit,
} from "../../../shared/employer-styled";
import styled from "styled-components";
import {
  getPaymentMethod,
  getChequeInfo,
  putPaymentMethod,
  putChequeInfo, putBankingInfo,
} from "../../../../services/employer-setting-service";
import OCRCheque from "../../../../cores/helpers/ocr-cheque/ocr-cheque";
import { publishMessage } from "../../../../cores/utils/message";
import { removeLoading, addLoading } from "../../../../cores/utils/loading";
import { Agreement } from "../setup-wizard/agreement/agreement-type";
import { getAgreementBankingInfo } from "../../../../services/employer-dashboard-services";
import { getFileUrl } from "../../../../services/file-service";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import {
  showTermsAndConditionsModal,
  TermsAndConditionsModal,
} from "../../../../cores/helpers/terms-and-conditions/terms-and-conditions";
import { PdfViewer } from "../../../shared/pdf-viewer"

const ContentBoxCustomStyled = styled.div`
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #f4f5f9;
`;
const CellStyled = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledImgReview = styled.img`
  max-height: 200px;
  max-width: 100%;
`;

type Props = {
  isContinue: boolean;
};
type State = {
  paymentMethod: number;
  cheque: ChequeInfo;
  chequeEdit: ChequeInfo;
  agreementData: Agreement[];
  isOCRCheckingCompleted: boolean;
  fileUrl: string;
  fileUrlEdit: string;
};

export default class Funding extends Component<Props, State> {
  state: State = {
    paymentMethod: -1,
    cheque: {
      voidCheque: "",
      eSignature: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      date: null,
    },
    chequeEdit: {
      voidCheque: "",
      eSignature: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      date: new Date(),
    },
    agreementData: [],
    isOCRCheckingCompleted: false,
    fileUrl: "",
    fileUrlEdit: "",
  };

  async componentDidMount() {
    //add loading
    addLoading();

    let result = await this.getData();

    if (result.chequeInfo.voidCheque) {
      this.getFileUrl(result.chequeInfo.voidCheque);
    }

    this.setState({
      paymentMethod: result.paymentMethod,
      cheque: result.chequeInfo,
      agreementData: result.agreementData
    });

    await this.initChequeEdit(result.chequeInfo)

    //remove loading
    removeLoading();
  }

  async getData() {
    let resultPaymentMethod = await getPaymentMethod();
    let resultChequeInfo = await getChequeInfo();
    let paymentMethod: number = resultPaymentMethod.data.paymentMethod;

    let chequeInfo: ChequeInfo = resultChequeInfo.data || [];

    let result = await getAgreementBankingInfo();

    let agreementData = result.data || [];

    return {
      paymentMethod,
      chequeInfo,
      agreementData,
    };
  }

  initChequeEdit = async (chequeInfo: ChequeInfo) => {

    const fileUrlRes = await getFileUrl(chequeInfo.voidCheque)

    this.setState({ chequeEdit: {...chequeInfo, agreements: []}, fileUrlEdit: fileUrlRes.data })
  }

  getFileUrl = (url: string) => {
    getFileUrl(url).then((response) => {
      this.setState({ fileUrl: response.data });
    });
  };

  handleChangeSelectPaymentMethod = (event: any) => {
    this.setState({ paymentMethod: event.target.value });
  };

  handleSubmitUpdatePaymentMethod = () => {
    if (this.state.paymentMethod > -1) {
      let model = { paymentMethod: this.state.paymentMethod };
      putPaymentMethod(model).then(() => {
        publishMessage({
          message: "Your payment information has been update successfully.",
          variant: "success",
        });
      });
    } else {
      publishMessage({
        message: "Please change one option.",
        variant: "error",
      });
    }
  };

  handleChangeInputChequeInfo = (event: any) => {
    const { name, value } = event.target;

    let cheque: ChequeInfo = Object.assign({}, this.state.chequeEdit);

    if (name !== "eSignature") {
      if (Number(value) >= 0) {
        cheque[name] = value;
      }
    } else {
      cheque[name] = value;
    }
    this.setState({ chequeEdit: cheque });
  };
  handleChangeCheckboxChequeInfo = (event: any) => {
    const { name, checked } = event.target;
    let chequeEdit = Object.assign({}, this.state.chequeEdit);

    if (checked) {
      chequeEdit.agreements.push(Number(name));
    } else {
      let index = chequeEdit.agreements.findIndex((agreement: any) => {
        return agreement === Number(name);
      });

      chequeEdit.agreements.splice(index, 1);
    }

    this.setState({ chequeEdit: chequeEdit });
  };
  handleChangeVoidCheque = (urlImage: string) => {
    let cheque: ChequeInfo = Object.assign({}, this.state.chequeEdit);
    if (urlImage) {
      cheque.voidCheque = urlImage;
    }

    getFileUrl(urlImage).then((response) => {
      this.setState({ fileUrlEdit: response.data });
    });

    this.setState({ chequeEdit: cheque });
  };

  handleOCRCheque = (urlBlob: string) => {
    this.setState({ isOCRCheckingCompleted: false });
    OCRCheque(urlBlob, "../../tessdata", (res: any) => {
      if (!res.error) {
        const newCheque: ChequeInfo = { ...this.state.chequeEdit };
        newCheque.transitNumber = res.numbers.transit;
        newCheque.bankNumber = res.numbers.institution;
        newCheque.accountNumber = res.numbers.account;

        this.setState({ chequeEdit: newCheque });
      }
      this.setState({ isOCRCheckingCompleted: true });
    });
  };
  handleChangeDate = (date: Date | null, name: string) => {
    let cheque = Object.assign(this.state.chequeEdit);

    cheque[name] = date;
    this.setState({ chequeEdit: cheque });
  };
  handleSubmitChequeInfo = async () => {

    let cheque = { ...this.state.chequeEdit }; 
    if (cheque.date)
      cheque.date = toUTCTime(new Date(cheque.date))
    if (!cheque.authorizedEmail || cheque.authorizedEmail === "") {
      cheque.authorizedEmail = null;
    }

    const bankingInformationModel: BankingInfo = { paymentMethod: this.state.paymentMethod, chequeInfo: cheque }

    await putBankingInfo(bankingInformationModel);

    closeFundingModal();
    publishMessage({
      message: "Your banking information has been update successfully.",
      variant: "success",
    });


    this.setState({ cheque: {...this.state.chequeEdit}, fileUrl: this.state.fileUrlEdit });
  };

  formatBankNumber = (bankNumber: string): string => {
    return bankNumber ? "xxxx-xxxx-x" + bankNumber.toString().slice(-3) : "";
  };

  validate = () => {
    let cheque = this.state.chequeEdit;

    let isValid = false;

    if (
      this.state.chequeEdit.agreements.length ===
      this.state.agreementData.length
    ) {
      isValid = true;
    }

    if (
      cheque.accountNumber &&
      cheque.bankNumber &&
      cheque.eSignature &&
      cheque.transitNumber &&
      cheque.voidCheque &&
      isValid
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let isSubmit = false;

    if (this.state.paymentMethod > -1) {
      isSubmit = true;
    } else {
      isSubmit = false;
    }

    const isValid = this.validate();
    const isPdfFile = this.state.fileUrl.match("/*.pdf") ? true : false;

    let $termsAndConditions = document.getElementById("termsAndConditions");

    if ($termsAndConditions) {
      $termsAndConditions.removeEventListener(
        "click",
        showTermsAndConditionsModal
      );
      $termsAndConditions.addEventListener(
        "click",
        showTermsAndConditionsModal
      );
    }

    return (
      <StyledContentBox isBorder={false}>
        <div>
          <StyledLargerTitle>Funding</StyledLargerTitle>
        </div>
        <div className="mt-3">
          <div className="row mt-4 mb-3">
            <div className="col-lg-12">
              <div>How do you want to process payment?</div>

              <Select
                className="mt-3"
                value={this.state.paymentMethod}
                onChange={this.handleChangeSelectPaymentMethod}
                name="paymentInfo"
              >
                {payments.map((payment: PaymentModel) => (
                  <MenuItem value={payment.id} key={payment.id}>
                    {payment.name}
                  </MenuItem>
                ))}
              </Select>

              {this.state.cheque.bankNumber &&
              this.state.paymentMethod === 2 ? (
                <div className="mt-3">
                  <ContentBoxCustomStyled>
                    <div className="d-flex">
                      <CellStyled className="w-40 p-2">
                        Account Number
                      </CellStyled>
                      <CellStyled className="w-60 text-right p-2 fw-bold">
                        {this.formatBankNumber(this.state.cheque.accountNumber)}
                      </CellStyled>
                    </div>
                    <div className="d-flex">
                      <CellStyled className="w-40 p-2">
                        Transit Number
                      </CellStyled>
                      <CellStyled className="w-60 text-right p-2 fw-bold">
                        {this.state.cheque.transitNumber}
                      </CellStyled>
                    </div>
                    <div className="d-flex">
                      <CellStyled className="w-40 p-2">
                        Institution Number
                      </CellStyled>
                      <CellStyled className="w-60 text-right p-2 fw-bold">
                        {this.state.cheque.bankNumber}
                      </CellStyled>
                    </div>
                    <div className="d-flex">
                      <CellStyled className="w-40 p-2">Cheque</CellStyled>
                      <CellStyled className="w-60 text-right p-2 fw-bold">
                        {this.state.cheque.voidCheque &&
                          (isPdfFile ? (
                            <PdfViewer fileUrl={this.state.fileUrl} height='100px' />
                          ) : (
                            <StyledImgReview
                              src={this.state.fileUrl}
                              alt="cheque"
                            />
                          ))}
                      </CellStyled>
                    </div>
                  </ContentBoxCustomStyled>

                  <span
                    className="float-right primary-color cur mt-3 hover fw-bold"
                    onClick={showFundingModal}
                  >
                    <EditIcon className="icon mr-1 mb-1" />
                    Edit & Update
                  </span>
                </div>
              ) : (
                <div>
                  <div className="mt-3">
                    {this.state.paymentMethod === 2 ? (
                      <div className="text-right">
                        Please{" "}
                        <span
                          className="primary-color cur op-0-8 fw-bold"
                          onClick={() => {
                            showFundingModal();
                            if (this.state.paymentMethod > -1) {
                              let model = {
                                paymentMethod: this.state.paymentMethod,
                              };
                              putPaymentMethod(model);
                            }
                          }}
                        >
                          setup a bank information
                        </span>
                      </div>
                    ) : (
                      <div className="text-right">
                        Please setup a monthly email transfer to our bank so
                        that your services will not be interrupted.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-12 p-0">
              {this.props.isContinue && <div className="col-lg-4 float-right mt-3">
                <StyledButtonSubmit
                  submit={isSubmit}
                  onClick={this.handleSubmitUpdatePaymentMethod}
                  disabled={!isSubmit}
                  type="submit"
                >
                  Save & Update
                </StyledButtonSubmit>
              </div>}

            </div>
          </div>

          <FundingModal
            handleSubmitChequeInfo={this.handleSubmitChequeInfo}
            handleChangeInputChequeInfo={this.handleChangeInputChequeInfo}
            handleChangeCheckboxChequeInfo={this.handleChangeCheckboxChequeInfo}
            handleChangeVoidCheque={this.handleChangeVoidCheque}
            handleOCRCheque={this.handleOCRCheque}
            isOCRCheckingCompleted={this.state.isOCRCheckingCompleted}
            chequeEdit={this.state.chequeEdit}
            isValid={isValid}
            folderName="Employer/Cheque"
            agreementData={this.state.agreementData}
            handleChangeDate={this.handleChangeDate}
            fileUrlEdit={this.state.fileUrlEdit}
          />
          <TermsAndConditionsModal />
        </div>
      </StyledContentBox>
    );
  }
}
