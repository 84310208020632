import React, {useEffect, useMemo, useState} from "react";
import {
    StyledButtonSubmit,
    StyledHeaderModal,
    StyledHeaderTitleModal,
    StyledSpanTitle,
} from "../../../../shared/employer-styled";
import styled from "styled-components";
import { AutoTopUpDetail } from "./setting-auto-top-up-type";
import { IOSSwitch } from "../../../../../cores/helpers/switch/ios-switch";

type Props = {
    onSwitchChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
        autoTopUpEnrollmentDetail: AutoTopUpDetail,
      ) => void;
      autoTopUpDetailData: AutoTopUpDetail[];
    handleSubmit: () => void;
    handleClose: () => void;
};

const StyledToggleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
`

const StyledDescriptionTextDiv = styled.div`
  padding: 0px 0px 25px 0px;
`

const StyledModalBodyDiv = styled.div`
  margin: 35px 35px;
`

const StyledButtonGroupDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledSubmitButtonCustom = styled(StyledButtonSubmit)`
  min-width: 150px;
  margin-left: 20px;
`;

const StyledCancelButtonCustom = styled(StyledButtonSubmit)`
  background-color: #F7F7F8;
  color: #1F3746;
`;

const StyledToggleElemnentText = styled.div`
 max-width: 450px;
`

export default function SettingAutoTopUpModal(props: Props) {

    return (
        <div className="modal" id="settingAutoTopUpModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered" 
                style={{minWidth: "300px", maxWidth: "700px", width: "auto"}} 
                role="document">
                <div className="modal-content">
                    <StyledModalBodyDiv className="modal-body">
                        <StyledHeaderModal>
                            <StyledHeaderTitleModal>Auto Top Up Settings</StyledHeaderTitleModal>
                        </StyledHeaderModal>
                        
                        <StyledDescriptionTextDiv className="row">
                            Setup your Auto Top Up preferences below.
                        </StyledDescriptionTextDiv>
        
                        {props.autoTopUpDetailData.map((e) => (
                            <StyledToggleDiv key={e.enrollmentDepositGroupId} className="row">
                                <StyledToggleElemnentText>
                                    <StyledSpanTitle>{e.enrollmentDepositGroupName}</StyledSpanTitle>
                                    <div>Only auto top up for this account.</div>
                                </StyledToggleElemnentText>
                                <div>
                                    <IOSSwitch
                                        checked={e.isAutoTopUp}
                                        onChange={(event: any, checked: boolean) => props.onSwitchChange(event, checked, e)}
                                    />
                                </div>
                            </StyledToggleDiv>
                        ))}
                        
                        <StyledButtonGroupDiv className="row mt-3">
                            <StyledCancelButtonCustom
                                className="col-2"
                                onClick={props.handleClose}
                                submit={true}
                                type="submit"
                                >
                                Cancel
                            </StyledCancelButtonCustom>
                            <StyledSubmitButtonCustom
                                className="col-2"
                                onClick={props.handleSubmit}
                                submit={true}
                                type="submit"
                            >
                                Apply Changes
                            </StyledSubmitButtonCustom>
                        </StyledButtonGroupDiv>
                    </StyledModalBodyDiv>
                </div>
            </div>
        </div>
    );
}

export function showSettingAutoTopUpModal() {
    ($( "#settingAutoTopUpModal" ) as any).modal( {
        show: true,
        keyboard: false,
        backdrop: "static",
    } );
}

export function closeSettingAutoTopUpModal() {
    ($( "#settingAutoTopUpModal" ) as any).modal( "hide" );
}
