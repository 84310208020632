import { getAsync, getFileAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { SearchParamClaim, FilterPaidClaim } from "../components/pages/claim-management/claim-management-type";
import moment from "moment";

const mapQueryString = (model: any): string => {
  let temp: any = { ...model };
  let query = '?';

  Object.keys(model).forEach((key) => {
      const val = temp[key];
      if (val) {
          if (Array.isArray(val)) {
              val.forEach(t => {
                  query += `${key}=${t}&`;
              });
          }
          else {
              if (val instanceof Date) {
                  query += `${key}=${moment(val).format("YYYY-MM-DD")}&`;
              } else {
                  query += `${key}=${val}&`;
              }
          }
      }
  });

  return query;
}

export const getReceipts = (): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/paid-claim`;

  return getAsync(url);
};

export const getAllReceipt = (
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/search/employee`;

  return getAsync(url, param);
};

export const getAllReceiptAsZip = async (criteria: FilterPaidClaim): Promise<AxiosResponse> => {

  let query = mapQueryString(criteria);

  let url = `manage-claim/api/claims/paid-claim/export${query.substring(query.length - 1, 0)}`;

  return await getFileAsync(url);
};

export const getEmployeeClaimsById = (
    id: number,
    param: SearchParamClaim
): Promise<AxiosResponse> => {
    let url = `/manage-claim/api/employees/${id}/claims`;

    return getAsync(url, param);
};
