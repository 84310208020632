import React from 'react';
import styled from "styled-components";
import { ArrowBack } from "@material-ui/icons"
import {RouteChildrenProps} from "react-router";

const StyledBackButton = styled.button`
    background: none;
    border: none;
    color: ${({theme}) => theme.primaryColor};
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 4px;
    
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

type Props = {
  path: string,
  params: any
} & RouteChildrenProps

const BackButton = ({params, history}: Props) => {
  const {path, searchParams} = params;

  const handleClick = () => {
    history.push(path, searchParams);
  };

  return (
    <StyledBackButton onClick={handleClick}>
      <ArrowBack />
      Back
    </StyledBackButton>
  );
};

export default BackButton;
