import React, {useEffect, useState} from "react";
import {columnsTransactions, statusTransaction} from "./wallet-model";
import BNPTable from "../../../shared/BNPTable/BNPTable";
import {TableSearchParamType} from "../../../shared/BNPTable/BNPTableType";
import {defaultTablePagingInfo, defaultTableSearchParam} from "../../../shared/BNPTable/BNPTableModel";
import {getExportTransactionsWithCriteria, getTransactionsWithCriteria} from "../../../../services/wallet-service";
import {setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {PagingInfo} from "../../../../../AdminPortal/src/cores/helpers/pagination/pagination";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {getInfoByToken} from "../../../../cores/utils/helpers";
import {Transactions} from "./wallet-type";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import moment from "moment";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import { exportExcel } from "../../../../cores/helpers/export-file/export-file";


export const WalletTransactionTable = React.memo(function WalletTransactionTable(props) {
    const [transactionData, setTransactionData] = useState<any[]>([]);
    const [searchParam, setSearchParam] = useState<TableSearchParamType>(Object.assign({}, defaultTableSearchParam));
    const [paging, setPaging] = useState<PagingInfo>(defaultTablePagingInfo);

    const info = getInfoByToken();

    useEffect(() => {
        getTransactions().then();
    }, [
        searchParam.filter,
        searchParam.page,
        searchParam.page,
        searchParam.columnName,
        searchParam.sortType,
        searchParam.from,
        searchParam.to,
        searchParam.searchKey,
    ])

    async function getTransactions() {
        addLoading();
        const resultTransactions = await getTransactionsWithCriteria(info.employerId, searchParam);

        const pagingInfo = setPagingInfo(
            searchParam.page,
            resultTransactions.data.pageCount,
            searchParam.perPage
        );

        setTransactionData(processTransactionData(resultTransactions.data.records))
        setPaging(pagingInfo)
        removeLoading();
    }

    const handleDownloadEmployerTransactions = () => {
        getExportTransactionsWithCriteria(searchParam).then(
        (result) => {
            exportExcel(result.data);
        }
        );
    }

    const processTransactionData = (transactions: Transactions[]) => {
        return transactions.map(t => {
            const {txnTitle, txnDescription, clearedAmount, pendingAmount, transactionType, createdDate} = t;
            return {
                txnTitle,
                txnDescription,
                clearedAmount: formatterUSD("currency", "USD").format(centToUsd(clearedAmount)),
                pendingAmount: formatterUSD("currency", "USD").format(centToUsd(pendingAmount)),
                transactionType,
                createdDate: createdDate ? moment(createdDate).format(FE_DATE_FORMAT) : ""
            }
        })
    }

    return <BNPTable tableName="Transactions"
                     searchParam={searchParam}
                     setSearchParam={setSearchParam}
                     columns={columnsTransactions}
                     tableWidth="1200px"
                     tableData={transactionData}
                     pagingInfo={paging}
                     haveFilterByDate={true}
                     handleDownload={handleDownloadEmployerTransactions}
                     haveSearch={true}
                     searchPlaceHolder="Search by Title"
                     filterType={statusTransaction}
                     filterPlaceHolder="Transaction Type"/>
})