import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {SortTable} from "../../employer/employees/employee-model";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {reportRef, SearchParamType} from "../ReportType";
import {
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion
} from "../../../shared/employer-styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledCellTable,
  StyledHeaderTable, StyledImportExportIcon, StyledRowBodyTable,
  StyledTable
} from "../../../shared/table-styled";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import {ExpansionPanel} from "@material-ui/core";
import {ArrowDropUp} from "@material-ui/icons";
import {ClaimReportType} from "./ClaimReportType";
import {
  exportClaimReport,
  getClaimReport,
  getClaimSummarize
} from "../../../../services/employer-report-service";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {SummarizeType} from "../ReportType";
import {defaultSearchParam, EmployerEnrollmentsMap, GroupInsuranceClaimType} from "../ReportModel";
import {exportExcel} from "../../../../cores/helpers/export-file/export-file";
import {calculatePeriod} from "../report";
import moment from "moment";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";

type Props = {
  columns: SortTable[];
  sortTable: (columnId: string) => void;
  changePage: (page: number) => void;
  paging: PagingInfo;
  setPaging: (value: any) => void;
  setRowsPerPage: (event: any) => void;
  searchParams: SearchParamType;
  setSummarizedList: (value: any) => void;
  period: string;
}


const ClaimReport = forwardRef((props: Props, ref: Ref<reportRef>) => {
  const [claimList, setClaimList] = useState<ClaimReportType[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  useEffect(() => {
    if (props.searchParams.from && props.searchParams.to) {
      getReportData().then();
    }
  }, [JSON.stringify(props.searchParams)])

  async function getReportData() {
    addLoading();
    let searchParam = {...defaultSearchParam, excludeFilter: GroupInsuranceClaimType[0].id}
    if (isFirstLoad) {
      const firstLoadSearchParams = calculatePeriod(props.period, searchParam, props.searchParams.from, props.searchParams.to)
      await getClaimSummarizeData(firstLoadSearchParams);
      await getClaimReportData(firstLoadSearchParams);
      setIsFirstLoad(false)
    } else {
      await getClaimSummarizeData(props.searchParams);
      await getClaimReportData({...props.searchParams, excludeFilter: GroupInsuranceClaimType[0].id});
    }
    removeLoading();
  }

  function calculateClaimSummarizeData(result: any) {
    const summarizeData: SummarizeType[] = [];
    Object.keys(result).forEach(key => {
      if(result[key] > 0)
        summarizeData.push({
          title: findClaimTypeNameByType(key),
          balance: result[key],
        });
    })
    return summarizeData.length > 0 ? summarizeData : [{title: "--", balance: "--"}]
  }

  function findClaimTypeNameByType(type: string): string {
    const claimType =  Object.values(EmployerEnrollmentsMap)
      .find(item => item.type.toLowerCase() === type.toLowerCase());
    if (claimType) return claimType.name;
    return "";
  }

  async function getClaimReportData(searchParams: SearchParamType) {
    try {
      const result = await getClaimReport(searchParams);
      setClaimList(result.data.records);
      let pagingInfo = setPagingInfo(
        searchParams.page,
        result.data.pageCount,
        searchParams.perPage,
      )
      props.setPaging(pagingInfo);
    } catch (e) {
      console.log(e)
    }
  }

  async function getClaimSummarizeData(searchParams: SearchParamType) {
    try {
      const result = (await getClaimSummarize(searchParams)).data;
      props.setSummarizedList(calculateClaimSummarizeData(result))
    } catch (e) {
      console.log(e)
    }
  }

  useImperativeHandle(ref, () => ({
    exportReport,
  }))

  function exportReport() {
    exportClaimReport(props.searchParams).then(
      (result) => {
        exportExcel(result.data);
      }
    )
  }

  return (
    <div className="p-0 mt-4">
      {claimList.length === 0 ?
        <div className="text-center text-danger">There was no record in this report</div> :
        (<div className="row">
          <div className="col-12 mt-2">
            <StyledHideForMobile>
              <StyledTable>
                <StyledHeaderTable>
                  {props.columns.map((column, index) => (
                    <StyledCellTable
                      width={column.width}
                      key={index}
                      className="cur"
                      onClick={() => props.sortTable(column.columnId)}
                    >
                      {column.columnName}
                      <span>
                            {column.sortType ? (
                              column.sortType === "ASC" ? (
                                <StyledArrowDropDownIcon/>
                              ) : (
                                <StyledArrowDropUpIcon/>
                              )
                            ) : (
                              <StyledImportExportIcon/>
                            )}
                          </span>
                    </StyledCellTable>
                  ))}
                </StyledHeaderTable>
                <div>
                  {claimList.map((claim, index) => (
                    <StyledRowBodyTable
                      key={index}
                      title={claim.employeeName}
                    >
                      <StyledCellTable width={20}>
                        {claim.employeeName}
                      </StyledCellTable>
                      <StyledCellTable
                          width={20}
                          title={moment(claim.paidDate).format(FE_DATE_FORMAT)}
                      >
                        {moment(claim.paidDate).format(FE_DATE_FORMAT)}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={claim.claimType}
                      >
                        {claim.claimType}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={claim.taxibility}
                      >
                        {claim.taxibility}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={formatterUSD("currency", "USD").format(
                          centToUsd(claim.claimAmount)
                        )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.claimAmount)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={formatterUSD("currency", "USD").format(
                          centToUsd(claim.adminFee)
                        )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.adminFee)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(claim.gst)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.gst)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(claim.hst)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.hst)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(claim.rst)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.rst)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(claim.premiumTax)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.premiumTax)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        className="text-right"
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(claim.totalAmount)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.totalAmount)
                        )}
                      </StyledCellTable>
                    </StyledRowBodyTable>
                  ))}
                </div>
              </StyledTable>
              <div className="text-center mt-4">
                <label>
                  <Pagination
                    pagerPagination={props.paging}
                    getPage={props.changePage}
                    onChangeRowsPerPage={props.setRowsPerPage}
                  />
                </label>
              </div>
            </StyledHideForMobile>
          </div>
          <div className="col-12 mt-2">
            <StyledHideForDesktop>
              {claimList.map((claim, index) => (
                <ExpansionPanel
                  square
                  defaultExpanded={false}
                  className="mt-2"
                  key={index}
                >
                  <StyledExpansionPanelSummary
                    className="m-0"
                    expandIcon={<ArrowDropUp/>}
                  >
                    <div className="w-100 ">
                      <StyledTitleExpansion>Employee Name</StyledTitleExpansion>
                      <div className="mt-2">{claim.employeeName}</div>
                    </div>
                  </StyledExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <div className="w-100">
                      <StyledTitleExpansion>
                        Paid Date
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {moment(claim.paidDate).format(FE_DATE_FORMAT)}
                      </div>
                    </div>
                    <div className="w-100">
                      <StyledTitleExpansion>
                        Claim Type
                      </StyledTitleExpansion>
                      <div className="mt-1 mt-3">
                        {claim.claimType}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Taxibility
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {claim.taxibility}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Claim Amount
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.claimAmount)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Admin Fee
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.adminFee)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        GST
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.gst)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        HST
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.hst)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        RST
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.rst)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Premium Tax
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.premiumTax)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Total Amount
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(claim.totalAmount)
                        )}
                      </div>
                    </div>
                  </StyledExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                      pagerPagination={props.paging}
                      getPage={props.changePage}
                      onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </StyledHideForDesktop>
          </div>
        </div>)
      }
    </div>
  );
})

export default ClaimReport;