import jwt from "jsonwebtoken";
import { UserInfo } from "../../models/login/login-req-model";
import moment from "moment";
import {removeCookie, setCookie} from "./cookie";
import {getCookie} from "./cookie";
import {getCheckFinishSetup, getEmployerConfiguration} from "../../services/employer-dashboard-services";
import {publishMessage} from "./message";
import {signInURL} from "../constants/url-config";
import {employeeStatus, Status} from "../../components/pages/employer/employees/employee-model";

export const authorizationHeader = (): { [index: string]: string } => {
  const token = getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }
  return {};
};

export const COOKIE_NAME = {
  TOKEN: 'token',
  IS_SETUP_DONE: 'isSetupDone'
}

export const getToken = (): string | null => getCookie(COOKIE_NAME.TOKEN);
export const setToken = (token: string, expiredAt?: Date): void => {
  if (token) {
    setCookie(COOKIE_NAME.TOKEN, token, expiredAt);
  } else {
    removeCookie(COOKIE_NAME.TOKEN);
  }
};

/**
 *
 * @param isSetupDone "true" or ""
 */

export const getIsSetupDone = async (): Promise<any> => {
  if (getIsSetupDoneCookie() === "True") return true;

  const res = await getCheckFinishSetup();
  if (res.status === 200 && res.data) {
    setIsSetupDoneCookie(res.data.isSetupDone ? "True" : "");
    return res.data.isSetupDone;
  } else {
    publishMessage({
      message: "Can not get isSetupDone. Please try again later.",
      variant: "error",
    });
    signOut();
    window.location.href = signInURL;
  }
};

export const getEmployerConfigurationInfo = async (): Promise<any> => {

  const res = await getEmployerConfiguration();
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    publishMessage({
      message: "Can not get employer configuration. Please try again later.",
      variant: "error",
    });
  }

};

export const setIsSetupDoneCookie = (isSetupDone: "True" | ""): void => {
  setCookie(COOKIE_NAME.IS_SETUP_DONE, isSetupDone);
};

export const getIsSetupDoneCookie = (): string | null => getCookie(COOKIE_NAME.IS_SETUP_DONE);

export const getInfoByToken = (): UserInfo => {
  let token = getToken() as string;

  let decoded = jwt.decode(token) as UserInfo;

  return decoded;
};

export const signOut = () => {
  setToken("");
  setIsSetupDoneCookie("");
};

export const download = (url: string) => {
  window.open(url, "_blank");
};

export const getInfoByCookies = () => {
  let cookies = document.cookie.split(";");

  let token = cookies.find((cookie) => {
    return cookie.match("token");
  });

  let isSetupDone = cookies.find((cookie) => {
    return cookie.match("isSetupDone");
  });

  return {
    token: token ? token.split("=")[1] : "",
    isSetupDone: isSetupDone ? isSetupDone.split("=")[1] : "",
  };
};

export const convertTimeZone = (timeZone: string, date: Date | string) => {
  const tz = date.toLocaleString("en", { timeZone }).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
  
  // return UTC offset in millis
  return moment(date).add(-offset, "ms");
}

export const findDuplicatesInArray = (arr: any[]) => {
  return [...new Set(arr.filter((item, index) => arr.indexOf(item) !== index))]
}

export const findEmployeeStatusNameById = (statusId: string, T4Employee = true): string => {
  if (!T4Employee) return employeeStatus[0].name

  const status: Status | undefined = employeeStatus.find((employeeStatus) => {
    return employeeStatus.id === statusId;
  });
  return status ? status.name : "";
};



export const NA_OPTION_VALUE: number = -1;
export const BENIPLUS_DEFAULT_OPTION = { id: 1, name: "Beniplus", type: "COMPANY", companyEmail: "admin@beniplus.ca" };

export const getRemakedReferralPartnerUsers = (partner: any, originalPartnerUsers: any) => {
  if (!partner) return originalPartnerUsers;

  if (partner.type == 'COMPANY' && partner.companyEmail == partner.contactEmail) {
    return [{ id: NA_OPTION_VALUE, name: partner.companyName }, ...originalPartnerUsers];
  }
  return originalPartnerUsers;
}

export const getDefaultSelectedPartnerUserId = (partner: any, partnerUserOptions: any) => {

  let defaultSelectedPartnerUserId = NA_OPTION_VALUE;

  if (!partner) return defaultSelectedPartnerUserId;

  if (partner.type != 'COMPANY' || partner.companyEmail != partner.contactEmail) {
    let defaultPartnerUserEmail = partner.type == 'COMPANY' ? partner.companyEmail : partner.contactEmail;
    // get default for referralPartnerUser
    if (partnerUserOptions && partnerUserOptions.length > 0) {
      let existDefaultPartnerUser = partnerUserOptions.filter((pu: any) => pu.email == defaultPartnerUserEmail);
      if (existDefaultPartnerUser && existDefaultPartnerUser.length > 0) {
        defaultSelectedPartnerUserId = existDefaultPartnerUser[0].id;
      } else {
        defaultSelectedPartnerUserId = partnerUserOptions[0].id;
      }
    }
  }

  return defaultSelectedPartnerUserId;

}

export const getAvgRating = (ratingTotal: number, reviewsTotal: number) => {
  return Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(ratingTotal / reviewsTotal);
}

export const getResizedImageUrl = (url: string, size: number) => {
  const urlParts = url.split('/');

  if (urlParts.length < 4) {
    return url;
  }

  // Insert the path (e.g., "300") before the last part (the key)
  urlParts.splice(3, 0, size.toString());

  return urlParts.join('/');
}