import React from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { amber, green } from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

export const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

const StyledSpan = styled.span`
  max-width: 500px;
  text-align: justify;
`;

export interface Props {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon | null;
}

export function SnackbarContentWrapper(props: Props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variant ? variantIcon[variant] : "";

  return (
    <SnackbarContent
      className={variant ? clsx(classes[variant], className) : ""}
      aria-describedby="client-snackbar"
      message={
        <StyledSpan id="client-snackbar" className={classes.message}>
          {Icon ? (
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          ) : (
            ""
          )}
          {message}
        </StyledSpan>
      }
      {...other}
    />
  );
}
