import { variantIcon } from "../../utils/snackbar-content-wrapper";
import { MessageData, publishMessage } from "../../utils/message";

export function message(message: string, type: keyof typeof variantIcon) {
  let data = {} as MessageData;

  data.message = message;
  data.variant = type;
  publishMessage(data);
}
