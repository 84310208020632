import {AxiosResponse} from "axios";
import {getAsync} from "../cores/utils/http-client";

const URL_Employer_Partner_Options = "/manage-employer/api/partner-options";

export const getPartnerOptions = (): Promise<AxiosResponse> => {
    let url = `${URL_Employer_Partner_Options}`;

    return getAsync(url);
};

export const getReferralPartnerUserOptions = (partnerId: number): Promise<AxiosResponse> => {
    let url = `${URL_Employer_Partner_Options}/users?partnerId=${partnerId}`;

    return getAsync(url);
};