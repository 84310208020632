import React from 'react';
import {download} from "../../../../cores/utils/helpers";
import styled from "styled-components";
import { LaunchOutlined, SaveAlt } from "@material-ui/icons";

const StyledAction = styled.div`
  color: ${(props) => props.theme.infoColor};
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding-left: 16px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

function FileActions(props: {title: string, url: string}) {

  async function handleOpenUrl (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (props.url) {
      window.open(props.url, props.title, "left=100,top=100,width=520,height=520")
    }
  }

  return (
    <div>
      {/*<StyledAction*/}
      {/*  onClick={handleOpenUrl}*/}
      {/*  title="View"*/}
      {/*>*/}
      {/*  <LaunchOutlined/>*/}
      {/*  View*/}
      {/*</StyledAction>*/}
      <StyledAction
        onClick={() => download(props.url)}
        title="Download"
      >
        <SaveAlt/>
        Download
      </StyledAction>
    </div>
  );
}

export default FileActions;