import React from "react";
import { EmployerBenefitClass } from "./employer-classes-type";
import {
  Class,
  ClassBenefit,
} from "../../request-a-quote/request-a-quote-type";
import EmployerBenefitsTable from "./employer-classes-table";
import {
  StyledBigTitle,
  StyledMainContent,
  StyledContentBox,
  StyledLargerTitle,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledSpanExpansionDelete,
  StyledImgDelete,
  StyledExpansionLabel,
  StyledExpansionPanelDetails
} from "../../../shared/employer-styled";
import { AddClassBenefits } from "../../../../cores/helpers/add-class-benefits/add-class-benefits";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import { ExpansionPanel } from "@material-ui/core";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import IconDelete from "../../../../cores/config/component/images/bin@2x.png";
import { MedicalAndTravel } from "../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";

type Props = {
  classBenefit: ClassBenefit;
  defineClassBenefits: EmployerBenefitClass[];
  findClassById: (idClass: number) => { label: string; value: number };
  removeEmployerClasses: (idClass: number) => void;
  getRemainingClasses: () => Class[];
  addClassBenefits: () => void;
  handleChangeInputDefineClassBenefits: (event: any) => void;
  handleChangeSelectClassBenefits: (event: any) => void;
  handleChangeCheckbox: (event: any) => void;
  isValid: boolean;
  isOtherClass: boolean;
  validatorListener: () => void;
};

export default function EmployerBenefitsView(props: Props) {
  return (
    <StyledMainContent>
      <div className="row">
        <StyledBigTitle className="col-lg-10 d-flex align-items-center">Classes</StyledBigTitle>
      </div>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <StyledContentBox isBorder={false}>
              <div className="w-100 d-flex align justify-content-between align-items-center">
                <StyledLargerTitle>
                  Setup Class Benefits
                </StyledLargerTitle>
              </div>
              <div>
                {/* table Setup Class Benefits */}
                <StyledHideForMobile>
                  <EmployerBenefitsTable
                    findClassById={props.findClassById}
                    defineClassBenefits={props.defineClassBenefits}
                    delete={props.removeEmployerClasses}
                  />
                </StyledHideForMobile>
                <StyledHideForDesktop>
                  {props.defineClassBenefits.map((classBenefit, index) => (
                    <ExpansionPanel
                      square
                      defaultExpanded={false}
                      className="mt-2"
                      key={index}
                    >
                      <StyledExpansionPanelSummary
                        className="m-0"
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowDropUp />}
                      >
                        <div className="w-100">
                          <StyledTitleExpansion>class</StyledTitleExpansion>
                          <div className="mt-2">
                            {props.findClassById(classBenefit.classId).label}
                          </div>
                        </div>
                      </StyledExpansionPanelSummary>
                      <StyledExpansionPanelDetails>
                        <div className="w-100">
                          <StyledTitleExpansion>Plan</StyledTitleExpansion>
                          <div className="mt-2">Standard Plan</div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            NO OF EMPLOYEE
                          </StyledTitleExpansion>
                          <div className="mt-2">
                            {classBenefit.noOfEmployees}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            COVERAGE AMOUNT
                          </StyledTitleExpansion>
                          <div className="mt-2">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(classBenefit.coverageAmount)
                            )}
                          </div>
                        </div>
                        <div className="w-100 mt-3 fw-bold">
                          <StyledTitleExpansion>TOTAL</StyledTitleExpansion>
                          <div className="mt-2">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(
                                classBenefit.coverageAmount *
                                classBenefit.noOfEmployees
                              )
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="text-center">
                          <StyledSpanExpansionDelete
                            onClick={() =>
                              props.removeEmployerClasses(classBenefit.id)
                            }
                          >
                            <StyledImgDelete src={IconDelete} alt="delete" />
                            <StyledExpansionLabel>Delete</StyledExpansionLabel>
                          </StyledSpanExpansionDelete>
                        </div>
                      </StyledExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </StyledHideForDesktop>
                <CheckBox
                  classLabel="mt-3"
                  title="Add a new custom class"
                  onChange={props.handleChangeCheckbox}
                  checked={props.isOtherClass}
                />
                {/* Add Class Benefits */}
                <div className="w-95">
                  <AddClassBenefits
                    isValid={props.isValid}
                    classBenefit={props.classBenefit}
                    getRemainingClasses={props.getRemainingClasses}
                    findClassById={props.findClassById}
                    onSubmit={props.addClassBenefits}
                    handleChangeInputDefineClassBenefits={
                      props.handleChangeInputDefineClassBenefits
                    }
                    handleChangeSelectClassBenefits={
                      props.handleChangeSelectClassBenefits
                    }
                    showSelectPlan={true}
                    isOtherClass={props.isOtherClass}
                  />
                </div>
              </div>
            </StyledContentBox>
          </div>
        </div>
      </div>
    </StyledMainContent>
  );
}
