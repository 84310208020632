import React, {useEffect} from "react";
import {
    StyledButtonCloseModal,
    StyledButtonSubmit,
    StyledHeaderModal,
    StyledHeaderTitleModal,
    StyledIconCloseModal,
} from "../../../../shared/employer-styled";
import styled from "styled-components";
import MedicalAndTravelCoverage from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage";
import { GroupInsuranceDefault, MedicalAndTravel } from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { MedicalTravelData } from "../../../request-a-quote/request-a-quote-type";

type Props = {
    employerId: number;
    isQuoteChecked: boolean;
    medicalTravels: MedicalAndTravel[];
    medicalTravelData: MedicalTravelData[];
    groupInsuranceDefault: GroupInsuranceDefault[];
    groupInsuranceQuoteRequest: number[];
    onSwitchChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isAllowRenewal: boolean;
    isDisableSubmit: boolean;
    handleSubmit: () => void;
    handleCloseWithoutSubmit: () => void;
};
export const StyledDivBoxCheque = styled.div`
  background-color: #f4f5f9;
  padding: 21px 17px;
  border-radius: 5px;
  margin-top: 1.5rem;
`;
export default function GroupInsuranceOptInModal(props: Props) {

    useEffect(() => {
    }, [])

    const handleSubmit = () => {
        props.handleSubmit();
    }

    return (
        <div className="modal" id="groupInsuranceOptInModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered" style={{minWidth: "700px"}} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <StyledButtonCloseModal name="btn-close" onClick={props.handleCloseWithoutSubmit}>
                            <StyledIconCloseModal>&times;</StyledIconCloseModal>
                        </StyledButtonCloseModal>
                        <StyledHeaderModal>
                            <StyledHeaderTitleModal>Group Insurance Opt In</StyledHeaderTitleModal>
                        </StyledHeaderModal>
                        <MedicalAndTravelCoverage
                            isChecked={props.isQuoteChecked}
                            medicalTravelData={props.medicalTravelData}
                            onCheckboxChange={props.onCheckboxChange}
                            onSwitchChange={props.onSwitchChange}
                            medicalTravels={props.medicalTravels}
                            groupInsuranceDefault={props.groupInsuranceDefault}
                            groupInsuranceQuoteRequest={props.groupInsuranceQuoteRequest}
                            isSetup={false}
                            isRenewal={false}
                        />
                        <div className="row mt-3">
                            <StyledButtonSubmit
                                className="col-2 ml-auto"
                                onClick={handleSubmit}
                                submit={!props.isDisableSubmit}
                                disabled={props.isDisableSubmit}
                                type="submit"
                            >
                                Save
                            </StyledButtonSubmit>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function showGroupInsuranceOptInModal() {
    ($( "#groupInsuranceOptInModal" ) as any).modal( {
        show: true,
        keyboard: false,
        backdrop: "static",
    } );
}

export function closeGroupInsuranceOptInModal() {
    ($( "#groupInsuranceOptInModal" ) as any).modal( "hide" );
}
