import React, {useState} from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import IconFile from "../../config/component/images/single-content-02.svg";
import IconResend from "../../config/component/images/icon-re-send.svg";

const CustomFileUpload = styled.div`
  width: 100%;
  height: 200px;
  border: 1px dashed #ced4da;
  display: flex;
  align-items: center;
  position: relative;
`;
const StyledInputFile = styled.input`
  color: transparent;
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  ::-webkit-file-upload-button {
    visibility: hidden;
  }
  :active {
    outline: 0;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;
const StyledForm = styled.div`
  z-index: 50;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
`;
const StyledDivDragAndDrop = styled.div`
  margin-top: 15px;
  opacity: 0.5;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;
const StyledDivYourFileHereOrBr = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 15px;
`;
const StyledPMessage = styled.p`
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  margin-top: 0.75rem;
`;

const StyledIconReSend = styled.img`
  margin-top: 5px;
`;

type Props = {
  processData: (data: string) => void
};

export const UploadFile = (props: Props) => {
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [sheetName, setSheetName] = useState<string | null>(null);

  // handle file upload
  const handleFileChosen = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target && evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataCsv = XLSX.utils.sheet_to_csv(ws);
      if (file && dataCsv) props.processData(dataCsv);
    };
    reader.readAsBinaryString(file);

    if (hasFile) e.target.value = "";
    setHasFile(true);
    setSheetName(file.name)
  };

  return (
    <div>
      {!hasFile && <CustomFileUpload>
        <StyledInputFile
          type="file"
          id="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileChosen}
        />
        <StyledForm>
          <StyledIcon src={IconFile} alt="img-file" />
          <StyledDivDragAndDrop>Drag & drop</StyledDivDragAndDrop>
          <StyledDivYourFileHereOrBr>
            your file here or Browse to upload
          </StyledDivYourFileHereOrBr>
        </StyledForm>
      </CustomFileUpload>}

      {hasFile && <CustomFileUpload>
        <StyledInputFile
          type="file"
          id="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileChosen}
        />
        <StyledForm>
          <StyledIcon src={IconFile} alt="img-file" />
          <StyledDivYourFileHereOrBr>
            {sheetName}
          </StyledDivYourFileHereOrBr>
          <StyledIconReSend src={IconResend} alt="icon-re-send" />
        </StyledForm>
      </CustomFileUpload>}

      <StyledPMessage>
        Only Excel or CSV files with max size of 2MB.
        <a className="ml-1 font-weight-bold" 
        href={require("../../../assets/files/Beniplus_add_employee_template.xlsx")} download>
          Download Template
        </a>
      </StyledPMessage>
    </div>
  );
}
