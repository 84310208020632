import React from "react";
import styled from "styled-components";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { MenuItem, Select } from "@material-ui/core";

export type PagingInfo = {
  currentPage: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  pages: number[];
  rowsPerPage: number;
};

const StyledPagination = styled.nav`
  display: flex;
  .page-item {
    padding: 0 5px;
  }
  .page-item .page-link {
    color: #2d2d2d;
    height: 30px;
    width: 30px;
    padding: 6px 0;
    text-align: center;
    cursor: pointer;
  }
  /* .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border: none;
    width: 100%;
  } */

  .page-item.active .page-link {
    color: ${(props) => props.theme.secondaryColor};
    text-align: center;
    border: 1px solid ${(props) => props.theme.secondaryColor};
    background-color: #fff;
  }
`;
const StyledPageLink = styled.span`
  width: 100% !important;
  padding: 5px 3px !important;
`;

const StyledHiddenForMobile = styled.li`
  @media (max-width: 767px) {
    display: none;
  }
`;
const StyledHiddenForDesktop = styled.li`
  @media (min-width: 767px) {
    display: none;
  }
`;

const StyledSelectPaging = styled(Select)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
    min-height: auto !important;
    padding: 5px 25px 4px 10px;
  }
  :after {
    border-bottom: none !important;
  }
`;

type Props = {
  pagerPagination: PagingInfo;
  getPage: (page: number) => void;
  onChangeRowsPerPage: (event: any) => void;
  perPageOptions?: number[];
};

const StyledDivText = styled.div`
  padding: 0.2rem;
  margin-right: 0.5rem;
  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledOption = styled.div`
  font-size: 14px;
`;

export default function Pagination(props: Props) {
  let pager = props.pagerPagination;
  const perPageOptions = props.perPageOptions || [10, 20, 40, 60];

  if (pager.totalPages < 1) {
    // don't display pager if there is only 1 page
    return null;
  }

  return (
    <StyledPagination>
      <StyledDivText>Rows per page:</StyledDivText>
      <div className="mr-2">
        <StyledSelectPaging
          value={props.pagerPagination.rowsPerPage}
          onChange={props.onChangeRowsPerPage}
          //name={props.name}
          //disabled={props.disabled}
        >
          {perPageOptions.map((option) => (
            <MenuItem value={option} key={option}>
              <StyledOption>{option}</StyledOption>
            </MenuItem>
          ))}
        </StyledSelectPaging>
      </div>
      <ul className="pagination">
        <li className="page-item">
          <StyledPageLink
            onClick={() =>
              props.pagerPagination.currentPage !== 1 ? props.getPage(1) : ""
            }
            className="page-link"
          >
            <FirstPageIcon fontSize="small" color="action" />
          </StyledPageLink>
        </li>
        <li className="page-item">
          <StyledPageLink
            onClick={() =>
              props.pagerPagination.currentPage !== 1
                ? props.getPage(pager.currentPage - 1)
                : ""
            }
            className="page-link"
          >
            <NavigateBeforeIcon fontSize="small" color="action" />
          </StyledPageLink>
        </li>

        <StyledHiddenForDesktop className="page-item active">
          <span
            className="page-link"
            onClick={() => props.getPage(pager.currentPage)}
          >
            {pager.currentPage}
          </span>
        </StyledHiddenForDesktop>

        {pager.pages.map((page, index) => (
          <StyledHiddenForMobile
            className={
              pager.currentPage === page ? "page-item active" : "page-item"
            }
            key={index}
          >
            <span className="page-link" onClick={() => props.getPage(page)}>
              {page}
            </span>
          </StyledHiddenForMobile>
        ))}

        <li className="page-item">
          <StyledPageLink
            onClick={() =>
              props.pagerPagination.currentPage !== pager.totalPages
                ? props.getPage(pager.currentPage + 1)
                : ""
            }
            className="page-link"
          >
            <NavigateNextIcon fontSize="small" color="action" />
          </StyledPageLink>
        </li>
        <li className="page-item">
          <StyledPageLink
            onClick={() =>
              props.pagerPagination.currentPage !== pager.totalPages
                ? props.getPage(pager.totalPages)
                : ""
            }
            className="page-link"
          >
            <LastPageIcon fontSize="small" color="action" />
          </StyledPageLink>
        </li>
      </ul>
    </StyledPagination>
  );
}

// set page pagination
export function setPagingInfo(
  page: number,
  pageCount: number,
  rowsPerPage: number
) {
  if (page < 1 || page > pageCount) {
    page = 1;
  }

  // get new pager object for specified page
  let pagingInfo = getPager(pageCount, page, rowsPerPage);

  return pagingInfo;
}

function getPager(
  pageCount: number,
  currentPage: number,
  rowsPerPage: number
): PagingInfo {
  // default to first page
  currentPage = currentPage || 1;
  let startPage: number = 0,
    endPage: number = 0;
  if (pageCount <= 10) {
    // if we have less than 10 pages, we will show all
    startPage = 1;
    endPage = pageCount;
  } else {
    if (pageCount <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = pageCount;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= pageCount) {
        startPage = pageCount - 9;
        endPage = pageCount;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
  }
  // create an array of pages to ng-repeat in the pager control
  let pages = [...Array(endPage + 1 - startPage).keys()].map(
    (i) => startPage + i
  );

  // return object with all pager properties required by the view
  return {
    currentPage: currentPage,
    totalPages: pageCount,
    startPage: startPage,
    endPage: endPage,
    pages: pages,
    rowsPerPage: rowsPerPage,
  };
}
