import {
  DetailType,
  ReportTypeDetail,
  SearchParamType,
  TFilterType,
  TReport,
  TReportPeriod
} from "./ReportType";
import {PagingInfo} from "../../../cores/helpers/pagingation/pagination";
import {columnsClaim} from "./claims/ClaimReportModel";
import {columnsTransaction} from "./transactions/TransactionReportModel";
import {columnsPayroll} from "./payrolls/PayrollReportModel";

export const defaultSearchParam: SearchParamType = {
  page: 1,
  from: null,
  to: null,
  filter: "",
  searchName: "",
  columnName: null,
  sortType: null,
  perPage: 10,
  excludeFilter: "",
}

export const defaultPagingInfo: PagingInfo = {
  currentPage: 0,
  totalPages: 0,
  startPage: 0,
  endPage: 0,
  pages: [],
  rowsPerPage: 10,
}

export const ReportType: TReport<ReportTypeDetail> = {
  HR: {
    type: "HR",
    name: "Report for Payroll",
    filter: "claimType",
    filterPlaceHolder: "Claim Type",
    columns: columnsPayroll,
  },
  CLAIM: {
    type: "CLAIM",
    name: "Claim History",
    filter: "claimType",
    filterPlaceHolder: "Claim Type",
    columns: columnsClaim,
  },
  TRANSACTION: {
    type: "TRANSACTION",
    name: "Transaction History",
    filter: "transactionType",
    filterPlaceHolder: "Transaction Type",
    columns: columnsTransaction,
  },
  GROUP_INSURANCE: {
    type: "GROUP_INSURANCE",
    name: "Group Insurance History",
    filter: "claimType",
    filterPlaceHolder: "Group Insurance",
    columns: columnsClaim,
  }
}

export const ReportPeriod: TReportPeriod<DetailType> = {
  thisMonth: {
    id: 0,
    type: "thisMonth",
    name: "This Month",
  },
  lastMonth: {
    id: 1,
    type: "lastMonth",
    name: "Last Month",
  },
  quarter: {
    id: 2,
    type: "quarter",
    name: "Quarter to Date",
  },
  year: {
    id: 3,
    type: "year",
    name: "Year to Date",
  },
  custom: {
    id: 4,
    type: "custom",
    name: "Custom Period",
  }
}

export const EmployerEnrollmentsMap: TFilterType<DetailType> = {
  1: {id: 1, type: "HSA", name: "Healthcare"},
  2: {id: 2, type: "WELLNESS", name: "Wellness"},
  3: {id: 3, type: "INSURANCE", name: "Personal Insurance"},
  4: {id: 4, type: "CHARITY", name: "Charity"},
  5: {id: 5, type: "RRSP", name: "RRSP"}
}

export const ClaimTypes  = [
  {
    id: "HSA",
    name: "Healthcare",
  },
  {
    id: "WELLNESS",
    name: "Wellness",
  },
  {
    id: "INSURANCE",
    name: "Personal Insurance",
  },
  {
    id: "CHARITY",
    name: "Charity",
  },
  {
    id: "RRSP",
    name: "RRSP",
  }
]
export const GroupInsuranceReportType = {
  GROUP_INSURANCE_SUMMARY: "GROUP_INSURANCE_SUMMARY",
  GROUP_INSURANCE_DETAIL: "GROUP_INSURANCE",
};

export const GroupInsuranceClaimType = [
  {
    id: GroupInsuranceReportType.GROUP_INSURANCE_SUMMARY,
    name: "Summary",
  },
  {
    id: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL,
    name: "Detail",
  },
]