import API_URL, { signInURL } from "../constants/url-config";
import axios, { AxiosResponse, AxiosInstance } from "axios";
import { authorizationHeader, signOut } from "./helpers";
import { publishMessage, MessageData } from "./message";
import { addLoading, removeLoading } from "./loading";

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: Object.assign({}, authorizationHeader()),
  });

  instance.interceptors.response.use(
    (response) => {
      removeLoading();
      return response;
    },
    (error) => {
      removeLoading();
      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAsync = async (
  url: string,
  params?: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(
    axiosInstance().get(url, {
      params: params,
    }),
    handleErrorAutomatic
  );
};

export const getFileAsync = async (
  url: string,
  params?: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(
    axiosInstance().get(url, {
      params: params,
      responseType: "arraybuffer"
    }),
    handleErrorAutomatic,
    true
  );
};

export const postAsync = async (
  url: string,
  json: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().post(url, json), handleErrorAutomatic);
};

export const putAsync = async (
  url: string,
  json: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().put(url, json), handleErrorAutomatic);
};

export const deleteAsync = async (
  url: string,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().delete(url), handleErrorAutomatic);
};

function callApi(
  promise: Promise<AxiosResponse>,
  handleErrorAutomatic: boolean = true,
  isFile: boolean = false
): AxiosResponse<any> | PromiseLike<AxiosResponse<any>> {
  addLoading();
  if (handleErrorAutomatic) {
    return promise.then(handleSuccess).catch(err => handleError(err, isFile));
  }
  return promise;
}

function handleSuccess(
  res: AxiosResponse<any>
): AxiosResponse<any> | PromiseLike<AxiosResponse<any>> {
  return Promise.resolve(res);
}

export function handleError(error: any, isFile: boolean = false) {
  let data = {} as MessageData;
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.data.status === 401) {
      signOut();
      window.location.href = signInURL;
    } else if (
      error.response.data.status === 500 ||
      error.response.data.status === 403
    ) {
      data.message = "We’ve run into a problem. Please try again later";
    } else {
      data.message = error.response.data.message;
    }
  } else {
    data.message =
      "System error. We apologize for the inconvenience. Please try again.";
    if (isFile) {
      data = JSON.parse(JSON.stringify(error));
      if(data.message.includes("401")) {
        signOut();
        window.location.href = signInURL;
      } else {
        data.message = "There are no claims has been paid.";
      }
    }
  }
  data.variant = "error";
  publishMessage(data);
  return Promise.reject(error);
}
