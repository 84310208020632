import React from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal
} from "../../../components/shared/employer-styled";

type Props = {
  title: string | JSX.Element;
  content: string | JSX.Element;
  handleOnOk: any;
};

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.primaryColor};
`;
const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.secondaryColor};
`;
const StyledModal = styled.div`
  z-index: 1100 !important;
`;

export function ConfirmDialog(props: Props) {
  return (
    <StyledModal className="modal" id="confirmModal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal className="text-left">
              {props.title}
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal
              name="btn-close"
              onClick={closeConfirmModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body text-left">{props.content}</div>
          {/* end body */}
          {/* footer */}
          <div className="modal-footer">
            <StyledWidthDiv>
              <StyledOptionRejected
                type="button"
                submit={true}
                onClick={closeConfirmModal}
              >
                Cancel
              </StyledOptionRejected>
            </StyledWidthDiv>
            <StyledWidthDiv>
              <StyledOptionApproved
                type="button"
                submit={true}
                onClick={() => {
                  closeConfirmModal();
                  props.handleOnOk();
                }}
                name="btn-ok"
              >
                Yes
              </StyledOptionApproved>
            </StyledWidthDiv>
          </div>
          {/* end footer */}
        </div>
      </div>
    </StyledModal>
  );
}

// export function showConfirmModal() {
//   ($("#confirmModal") as any).modal({
//     show: true,
//     keyboard: false,
//     backdrop: "static"
//   });
// }
// export function closeConfirmModal() {
//   ($("#confirmModal") as any).modal("hide");
// }

export function showConfirmModal() {
  $("#confirmModal").attr("style", "display: block; overflow: auto;");
  $("body").append(
    '<div id="modalBackdropConfirm" style="z-index: 1090" class="modal-backdrop show"></div>'
  );
}
export function closeConfirmModal() {
  $("#confirmModal").removeAttr("style");
  $("#modalBackdropConfirm").remove();
}
