import React, {useEffect} from "react";
import {EmployeeData, Employee, Role, TerminatingRequest} from "./employee-type";
import {
  StyledMainContent,
  StyledContentBox,
  StyledLargerTitle,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledHideForMobile,
  StyledSpanBigTitle,
} from "../../../shared/employer-styled";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledLinkBodyTable,
} from "../../../shared/table-styled";
import AddEmployeeModal from "./add-employee-modal/add-employee-modal";
import moment from "moment";
import Pagination, {
  PagingInfo,
} from "../../../../cores/helpers/pagingation/pagination";
import { Class } from "../../request-a-quote/request-a-quote-type";
import { EmployeeSearchParams } from "../../../../services/employer-employees-service";
import IconResend from "../../../../cores/config/component/images/icon-re-send.svg";
import { Status, SortTable } from "./employee-model";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  ConfirmEmployeeActionDialog,
  showConfirmModal,
  closeConfirmModal,
} from "../../../../cores/helpers/confirm-dialog/confirm-employee-action-dialog";
import { ExpansionPanel } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { capFirst } from "../../../../cores/helpers/cap-first";
import { Provinces } from "../employer-setting/company-information/company-information-type";
import { getInfoByToken } from "../../../../cores/utils/helpers";
import { ResponseLocation } from "../../../../cores/helpers/google-map-services/google-location";
import {UserInfo} from "../../../../models/login/login-req-model";
import {EmployerPlan} from "../plan/plan-preferences/plan-preferences-type";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {employeeDetailUrl, searchParamDetailEmployeeDefault} from "../../../../cores/constants/url-config";

const StyledSpanEditEmployee = styled.span`
  float: right;
  cursor: pointer;
  top: 8px;
  position: relative;
  color: ${(props) => props.theme.primaryColor};

  :hover {
    opacity: 0.7;
  }
`;

const StyledSpanEdit = styled.span`
  font-size: 14px;
  position: relative;
  top: -2px;
`;

const StyledIconReSend = styled.img`
  margin-left: 0.5rem;
  margin-top: -4px;
`;

const StyledSpanAction = styled.span`
  position: relative;
  top: 0;
  margin-right: 8px;
  font-weight: bold;
  color: ${props => props.title === "Archive" ? props.theme.errorColor : props.theme.grey[500]};
  cursor: pointer;
`;

const StyledSpanStatus = styled.span`
  font-weight: bold;
  color: ${(props: any) => {
    if (props.title === "TERMINATED") return props.theme.errorColor;
    if (props.title === "TERMINATING") return props.theme.grey[500];
    if (props.title === "SUSPEND") return props.theme.warningColor;
    if (props.title === "WORKING") return props.theme.successColor;
  }}
`;

const StyledSpanErrorColor = styled.span`
  font-weight: bold;
  color: ${props => props.theme.errorColor};
`;

const StyledSpanBold = styled.span`
  font-weight: bold;
`;

const StyledImportExportIcon = styled(ImportExportIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;
const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;
const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;

const StyledAddIcon = styled(AddIcon)`
  top: -3px;
  position: relative;
`;

type Props = {
  listEmployees: EmployeeData;
  findClassById: (classId: number) => string;
  findSignUpStatusById: (signUpStatusId: string) => string;
  findStatusesById: (statusId: string, t4Employee?: boolean) => string;
  deleteEmployee: (employeeId: number) => void;
  classes: Class[];
  statuses: Status[];
  roles: Role[];
  employeeSearchParams: EmployeeSearchParams;
  changePage: (page: number) => void;
  changeFilter: (event: any) => void;
  pagingInfo: PagingInfo;
  employee: Employee;
  handleChangeDate: (date: Date | null, name: string) => void;

  handleChangeSelect: (event: any) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSubmit: () => void;
  resendInvite: (id: number) => void;
  isValid: boolean;
  isEdit: boolean;

  showModalEditEmployee: (employee: Employee) => void;
  editEmployee: () => void;
  showModalAddEmployee: (isUploadInBulk: boolean) => void;
  columnsHeaderTable: SortTable[];
  sortTable: (columnId: string) => void;
  setEmployerBenefitClass: () => Class[];
  setRowsPerPage: (event: any) => void;
  provinces: Provinces[];
  //
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;

  handleSuspendEmployee: (id: number, fromDate: Date, toDate: Date) => void;
  handleUnSuspendEmployee: (id: number) => void;
  findValueByPlanPreferencesByEmployer: () => number | null;
  createInBulkError: String[];
  isUploadInBulk: boolean;
  handleAddEmployeeInBulk: (employees: Employee[]) => void;
  handleResetCreateInBulkError: () => void;
  handleTerminateEmployee: (req: TerminatingRequest) => void;
  getEmployeesEmployer: () => void;
  employerPlan: EmployerPlan;
  isT4Employee: boolean;
  setIsT4Employee: (val: boolean) => void;
};

export enum employeeActionEnum {
  cancel,
  cancelNow,
  suspend,
  unSuspend,
  archive,
}
export default function EmployeeView(props: Props) {
  const [id, setId] = React.useState<number>(0);
  const [name, setName] = React.useState<string>("");
  const [confirmingEmployee, setConfirmingEmployee] = React.useState<Employee | null>(null);

  const [confirmNumber, setConfirmNumber] = React.useState<employeeActionEnum>(employeeActionEnum.cancel);
  const [userInfo, setUserInfo] = React.useState<UserInfo | null>(null);

  useEffect(()=> {
    const info: UserInfo = getInfoByToken();
    if (info) setUserInfo(info);
  }, [])

  const showModalConfirm = (employee: Employee, confirm: employeeActionEnum) => {
    setId(employee.id);
    setName(`${employee.firstName} ${employee.lastName}`);
    setConfirmingEmployee(employee);
    setConfirmNumber(confirm);
    showConfirmModal();
  };

  const handleDelete = () => {
    props.deleteEmployee(id);
    closeConfirmModal();
  };
  const handleSuspend = (fromDate: Date, toDate: Date) => {
    props.handleSuspendEmployee(id, fromDate, toDate);
    closeConfirmModal();
  };
  const handleUnSuspend = () => {
    props.handleUnSuspendEmployee(id);
    closeConfirmModal();
  };

  const handleCancelEmployee = (fromDate: Date) => {
    props.handleTerminateEmployee({id, terminatedDate: fromDate, isTerminatedNow: false})
    closeConfirmModal();
  };

  const handleCancelEmployeeNow = () => {
    props.handleTerminateEmployee({id,terminatedDate: new Date(), isTerminatedNow: true})
    closeConfirmModal();
  };

  const checkEdit = (roleId: number): boolean => {
    if (userInfo)
      return userInfo.authorities[0] === "ROLE_EMP_OWNER" ||
        (roleId !== 1 && userInfo.authorities[0] === "ROLE_EMP_ADMIN");
    return false;
  };

  const checkEmployeeOfCurrentEmployerAccount = (email: string): boolean => {
    if (userInfo) return userInfo.sub === email;
    return false;
  }

  const findEmployeeById = (id: number): Employee | undefined => {
    return props.listEmployees.records.find(employee => employee.id === id);
  }

  const confirmModalData: {[key in employeeActionEnum]: {title: string; content: JSX.Element; secondButtonName?: string; action: any; secondAction?: any;}} = {
    [employeeActionEnum.cancel]: {
      title: "Benefit Cancellation Date",
      content: <span>You are about to <StyledSpanErrorColor>cancel the benefits </StyledSpanErrorColor>
        of {name}. Please fill in the <StyledSpanBold>Benefit Cancellation Date </StyledSpanBold>
        to specify the date you prefer.</span>,
      action: handleCancelEmployee,
      secondButtonName: "Deactivate Account Immediately",
      secondAction: () => {
          const employee = findEmployeeById(id);
          closeConfirmModal();
          employee && showModalConfirm(employee, employeeActionEnum.cancelNow)
      }
    },
    [employeeActionEnum.cancelNow]: {
      title: "Deactivate Confirmation",
      content: <span>The account of this employee will be deactivated immediately and the action cannot be reversed. Do you want to proceed?</span>,
      action: handleCancelEmployeeNow,
    },
    [employeeActionEnum.suspend]: {
      title: "Suspend",
      content: <span>You are about to <StyledSpanErrorColor>suspend</StyledSpanErrorColor> {name}. Please fill in the
                <StyledSpanBold> Start Date</StyledSpanBold> and <StyledSpanBold>End Date </StyledSpanBold>
                to specify the Suspension Period.</span>,
      action: handleSuspend,
    },
    [employeeActionEnum.unSuspend]: {
      title: "Un-suspend",
      content: <span>You are about to <StyledSpanErrorColor>un-suspend</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleUnSuspend,
    },
    [employeeActionEnum.archive]: {
      title: "Archive",
      content: <span>You are about to <StyledSpanErrorColor>archive</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleDelete,
    },
  }

  return (
    <StyledMainContent>
      <StyledSpanBigTitle>Employees</StyledSpanBigTitle>
      <StyledSpanEditEmployee
        className="ml-2"
        onClick={() => props.showModalAddEmployee(true)}
      >
        <StyledAddIcon className="icon" />
        <StyledSpanEdit>Add In Bulk</StyledSpanEdit>
      </StyledSpanEditEmployee>
      <StyledSpanEditEmployee onClick={() => props.showModalAddEmployee(false)}>
        <StyledAddIcon className="icon" />
        <StyledSpanEdit>Add Employee</StyledSpanEdit>
      </StyledSpanEditEmployee>

      <div className="container-fluid p-0">
        <StyledContentBox className="mt-4" isBorder={false}>
          <div className="pr-4">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <StyledLargerTitle>Employees Management</StyledLargerTitle>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-4" />
                  <div className="col-lg-4 mt-3">
                    <BNPSelect
                      options={props.classes}
                      placeholder="Filter by class"
                      name="classId"
                      onChange={props.changeFilter}
                      value={props.employeeSearchParams.classId}
                    />
                  </div>
                  <div className="col-lg-4 mt-3">
                    <BNPSelect
                      options={props.statuses}
                      placeholder="Filter by status"
                      name="status"
                      onChange={props.changeFilter}
                      value={props.employeeSearchParams.status}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <StyledHideForMobile>
                  <div>
                    <StyledTable>
                      <StyledHeaderTable>
                        {props.columnsHeaderTable.map((column) => (
                          <StyledCellTable
                            width={column.width}
                            key={column.columnId}
                            className={
                              column.columnId === "actions"
                                ? "text-left cur"
                                : "cur"
                            }
                            style={
                              column.columnId === "actions"
                                ? { minWidth: "230px" }
                                : {}
                            }
                            onClick={() =>
                              column.sortColumn
                                ? props.sortTable(column.columnId)
                                : () => {}
                            }
                          >
                            {column.columnName}
                            {column.sortColumn ? (
                              <span>
                                {column.sortType ? (
                                  column.sortType === "ASC" ? (
                                    <StyledArrowDropDownIcon />
                                  ) : (
                                    <StyledArrowDropUpIcon />
                                  )
                                ) : (
                                  <StyledImportExportIcon />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </StyledCellTable>
                        ))}
                      </StyledHeaderTable>
                      {props.listEmployees.records.map((employee, index) => (
                        <div className="d-flex" key={employee.id}>
                          <StyledLinkBodyTable
                              key={index}
                              to={
                                  employeeDetailUrl +
                                  searchParamDetailEmployeeDefault(employee.id)
                              }>
                            <StyledCellTable
                              width={8}
                              // onClick={() =>
                              //   props.showModalEditEmployee(employee)
                              // }
                            >
                              {employee.code}
                            </StyledCellTable>
                            <StyledCellTable width={12}>
                              {employee.firstName + " " + employee.lastName}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              {employee.classId
                                ? props.findClassById(employee.classId)
                                : ""}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              {employee.signUpStatus
                                ? props.findSignUpStatusById(
                                    employee.signUpStatus
                                  )
                                : "Invited"}
                              {employee.signUpStatus === "INVITED" && (
                                <span
                                  className="cur"
                                  onClick={() =>
                                    props.resendInvite(employee.id)
                                  }
                                >
                                  <StyledIconReSend
                                    src={IconResend}
                                    alt="icon-re-send"
                                    title="Resend the invite"
                                  />
                                </span>
                              )}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              {employee.role ? capFirst(employee.role[0]) : ""}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              {employee.eligibilityDate
                                ? moment(employee.eligibilityDate).format(
                                    FE_DATE_FORMAT
                                  )
                                : ""}
                            </StyledCellTable>
                            <StyledCellTable width={12}>
                              {employee.benefitCancellationDate
                                ? moment(
                                    employee.benefitCancellationDate
                                  ).format(FE_DATE_FORMAT)
                                : ""}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              {employee.t4Employee ? "TRUE" : "FALSE"}
                            </StyledCellTable>
                            <StyledCellTable width={10}>
                              <StyledSpanStatus
                                title={(!employee.t4Employee || !employee.status) ? "WORKING" : employee.status}
                              >
                                {employee.status
                                  ? props.findStatusesById(employee.status, employee.t4Employee)
                                  : ""}
                              </StyledSpanStatus>
                            </StyledCellTable>
                            <StyledCellTable
                              className="text-left"
                              width={18}
                              style={{ minWidth: "230px" }}
                            >
                              {checkEdit(employee.roleIds[0]) ? (
                                <>
                                  {/*<StyledSpanAction*/}
                                  {/*  title="Edit"*/}
                                  {/*  onClick={() =>*/}
                                  {/*    props.showModalEditEmployee(employee)*/}
                                  {/*  }*/}
                                  {/*>*/}
                                  {/*  Edit*/}
                                  {/*</StyledSpanAction>*/}
                                  {employee.t4Employee && (
                                    <>
                                      <StyledSpanAction
                                        title="Cancel"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          showModalConfirm(
                                            employee,
                                            employee.status === "SUSPEND" ||
                                              employee.status === "TERMINATING"
                                              ? employeeActionEnum.cancelNow
                                              : employeeActionEnum.cancel
                                          )
                                        }
                                        }
                                      >
                                        Cancel
                                      </StyledSpanAction>
                                      {employee.status === "SUSPEND" && (
                                        <StyledSpanAction
                                          title="Un-Suspend"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            showModalConfirm(
                                              employee,
                                              employeeActionEnum.unSuspend
                                            )
                                          }
                                          }
                                        >
                                          Un-suspend
                                        </StyledSpanAction>
                                      )}
                                      {employee.status === "WORKING" && (
                                        <StyledSpanAction
                                          title="Suspend"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            showModalConfirm(
                                              employee,
                                              employeeActionEnum.suspend
                                            )
                                          }
                                          }
                                        >
                                          Suspend
                                        </StyledSpanAction>
                                      )}
                                    </>
                                  )}
                                  {!checkEmployeeOfCurrentEmployerAccount(
                                    employee.email
                                  ) && (
                                    <StyledSpanAction
                                      title="Archive"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        showModalConfirm(
                                          employee,
                                          employeeActionEnum.archive
                                        )
                                      }
                                      }
                                    >
                                      Archive
                                    </StyledSpanAction>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </StyledCellTable>
                          </StyledLinkBodyTable>
                        </div>
                      ))}
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <label>
                            <Pagination
                              pagerPagination={props.pagingInfo}
                              getPage={props.changePage}
                              onChangeRowsPerPage={props.setRowsPerPage}
                            />
                          </label>
                        </div>
                      </div>
                    </StyledTable>
                  </div>
                </StyledHideForMobile>
              </div>
              <div className="col-12 mt-2">
                <StyledHideForDesktop>
                  {props.listEmployees.records.map((employee, index) => (
                    <ExpansionPanel
                      square
                      defaultExpanded={false}
                      className="mt-2"
                      key={index}
                    >
                      <StyledExpansionPanelSummary
                        className="m-0"
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUp />}
                      >
                        <div className="w-100 ">
                          <StyledTitleExpansion>Full name</StyledTitleExpansion>
                          <div className="mt-2">
                            {employee.firstName + " " + employee.lastName}
                          </div>
                        </div>
                      </StyledExpansionPanelSummary>
                      <StyledExpansionPanelDetails>
                        <div className="w-100">
                          <StyledTitleExpansion>Code</StyledTitleExpansion>
                          <div className="mt-1">{employee.code}</div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>Class</StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.classId
                              ? props.findClassById(employee.classId)
                              : ""}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Eligibility Date
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.eligibilityDate
                              ? moment(employee.eligibilityDate).format(
                                  FE_DATE_FORMAT
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Cancellation Date
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.benefitCancellationDate
                              ? moment(employee.benefitCancellationDate).format(
                                  FE_DATE_FORMAT
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Employee flow
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.signUpStatus
                              ? props.findSignUpStatusById(
                                  employee.signUpStatus
                                )
                              : "Invited"}
                            {employee.signUpStatus == "INVITED" && (
                              <span
                                className="cur"
                                onClick={() => props.resendInvite(employee.id)}
                              >
                                <StyledIconReSend
                                  src={IconResend}
                                  alt="icon-re-send"
                                  title="Resend the invite"
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>Role</StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.role ? capFirst(employee.role[0]) : ""}
                          </div>
                        </div>
                        {employee.t4Employee && (
                          <div className="w-100 mt-3">
                            <StyledTitleExpansion>Status</StyledTitleExpansion>
                            <div className="mt-1">
                              {employee.status
                                ? props.findStatusesById(employee.status)
                                : ""}
                            </div>
                          </div>
                        )}
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            T4 Employee
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {employee.t4Employee ? "TRUE" : "FALSE"}
                          </div>
                        </div>
                        <hr />
                        {checkEdit(employee.roleIds[0]) ? (
                          <>
                            <div className="text-center">
                              {employee.t4Employee && (
                                <>
                                  {/*<StyledSpanAction*/}
                                  {/*  onClick={() =>*/}
                                  {/*    props.showModalEditEmployee(employee)*/}
                                  {/*  }*/}
                                  {/*>*/}
                                  {/*  Edit*/}
                                  {/*</StyledSpanAction>*/}
                                  {employee.status !== "TERMINATED" && <StyledSpanAction
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      showModalConfirm(
                                        employee,
                                        employee.status === "SUSPEND" ||
                                        employee.status === "TERMINATING"
                                          ? employeeActionEnum.cancelNow
                                          : employeeActionEnum.cancel
                                      )
                                    }
                                    }
                                  >
                                    Cancel
                                  </StyledSpanAction>}
                                  {employee.status === "SUSPEND" && (
                                    <StyledSpanAction
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        showModalConfirm(
                                          employee,
                                          employeeActionEnum.unSuspend
                                        )
                                      }
                                      }
                                    >
                                      Un-suspend
                                    </StyledSpanAction>
                                  )}
                                  {employee.status === "WORKING" && (
                                    <StyledSpanAction
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        showModalConfirm(
                                          employee,
                                          employeeActionEnum.suspend
                                        )
                                      }
                                      }
                                    >
                                      Suspend
                                    </StyledSpanAction>
                                  )}
                                </>
                              )}
                              {!checkEmployeeOfCurrentEmployerAccount(
                                employee.email
                              ) && (
                                <StyledSpanAction
                                  title="Archive"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    showModalConfirm(
                                      employee,
                                      employeeActionEnum.archive
                                    )
                                  }
                                  }
                                >
                                  Archive
                                </StyledSpanAction>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </StyledExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.changePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledHideForDesktop>
              </div>
            </div>
          </div>
        </StyledContentBox>
      </div>

      <AddEmployeeModal
        employees={props.employee}
        statuses={props.statuses}
        roles={props.roles}
        handleChangeDate={props.handleChangeDate}
        handleChangeInput={props.handleChangeInput}
        handleChangeSelect={props.handleChangeSelect}
        handleChangeSubmit={props.handleChangeSubmit}
        isValid={props.isValid}
        isEdit={props.isEdit}
        editEmployee={props.editEmployee}
        setEmployerBenefitClass={props.setEmployerBenefitClass}
        provinces={props.provinces}
        //
        setAddress={props.setAddress}
        setLocation={props.setLocation}
        findValueByPlanPreferencesByEmployer={
          props.findValueByPlanPreferencesByEmployer
        }
        classes={props.classes}
        createInBulkError={props.createInBulkError}
        isUploadInBulk={props.isUploadInBulk}
        handleAddEmployeeInBulk={props.handleAddEmployeeInBulk}
        handleResetCreateInBulkError={props.handleResetCreateInBulkError}
        employerPlan={props.employerPlan}
        isT4Employee={props.isT4Employee}
        setIsT4Employee={props.setIsT4Employee}
      />
      <ConfirmEmployeeActionDialog
        employee={confirmingEmployee}
        type={confirmNumber}
        title={confirmModalData[confirmNumber].title}
        content={confirmModalData[confirmNumber].content}
        secondButtonName={confirmModalData[confirmNumber].secondButtonName}
        handleOnOk={confirmModalData[confirmNumber].action}
        handleSecondAction={confirmModalData[confirmNumber].secondAction}
        getEmployeesEmployer={props.getEmployeesEmployer}
      />
    </StyledMainContent>
  );
}
