import moment from "moment";

export function toUTCTime(date: Date | null) {
  return date
  ? moment(date).format("ddd, DD MMM YYYY[ 00:00:00 EST]")
    : moment().format("ddd, DD MMM YYYY[ 00:00:00 EST]");
}

export function toStringFormatTime(date: Date | null) {
  return date
  ? moment(date).format("YYYY-MM-DD[T00:00:00-05:00]")
    : null;
}