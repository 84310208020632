import React from "react";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";

type SelectProps = {
  value?: any;
  name?: string;
  onChange?: (event: any) => void;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  tabIndex?: number;
  className?: string;
};
export type Option = {
  id: number | string;
  name: number | string | JSX.Element;
};

export const StyledSelect = styled(Select)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
  }
  .MuiInputBase-input {
    padding: 4px 0 7px;
  }
`;
export const StyledDefaultOption = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #2d2d2d;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
`;
export const StyledOption = styled.div`
  line-height: 21px;
  font-size: 14px;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BNPSelect = (props: SelectProps) => (
  <StyledSelect
    value={props.value ? props.value : "-1"}
    onChange={props.onChange}
    name={props.name}
    disabled={props.disabled}
    inputProps={{ tabIndex: props.tabIndex ? props.tabIndex : 0 }}
    className={props.className ? props.className : ""}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 300,
          marginTop: "8px" // Adjust as needed to control distance from input
        }
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null,
      autoFocus: false
    }}
  >
    <MenuItem value="-1" key={-1}>
      <StyledDefaultOption className="p-2">
        {props.placeholder}
      </StyledDefaultOption>
    </MenuItem>

    {props.options.map((option) => (
      <MenuItem value={option.id} key={option.id}>
        <StyledOption className="p-2">{option.name}</StyledOption>
      </MenuItem>
    ))}
  </StyledSelect>
);
