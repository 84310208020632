import { AxiosResponse } from "axios";
import {
  getAsync,
  postAsync,
  deleteAsync,
  putAsync,
} from "../cores/utils/http-client";
import {
  Employee,
  TerminatingRequest,
} from "../components/pages/employer/employees/employee-type";
import {
  EmployeeManualSetupType
} from "../components/pages/employer/employees/add-employee-modal/upload-in-bulk/upload-in-bulk";

export type SortType = "ASC" | "DESC" | null;

export type EmployeeSearchParams = {
  page: number;
  classId: number | null;
  status: string | null;
  columnName: string | null;
  sortType: SortType;
  perPage: number;
};

const URL_Employer = "/manage-employer/api";

export const getEmployeesEmployer = (
  params: EmployeeSearchParams
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees`;

  return getAsync(url, params);
};

// export const getStatuses = (): Promise<AxiosResponse> => {
//   let url = `${URL_Employer}/employees/status`;

//   return getAsync(url);
// };

export const deleteEmployeesEmployer = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${id}`;

  return deleteAsync(url);
};

export const postEmployeesEmployer = (
  model: Employee
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees`;

  return postAsync(url, model);
};

export const postNotT4EmployeesEmployer = (
    model: Employee
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/not-t4-employee`;

  return postAsync(url, model);
};

export const postEmployeesEmployerInBulk = (
  model: EmployeeManualSetupType[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/bulk`;

  return postAsync(url, model, false);
};

export const putEmployeesEmployer = (
  model: Employee
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees`;

  return putAsync(url, model);
};

export const postResendInvite = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${id}/resend-invite`;

  return postAsync(url, {});
};

export const terminatingEmployeesEmployer = (
  model: TerminatingRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/terminated`;

  return putAsync(url, model);
};

export const putSuspendEmployee = (
  employeeId: number,
  fromDate: Date | string,
  toDate: Date | string
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${employeeId}/suspend`;

  return putAsync(url, {fromDate, toDate});
};

export const putUnSuspendEmployee = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${employeeId}/unsuspend`;

  return putAsync(url, {});
};

export const getSuspendHistory = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}`;

  return getAsync(url);
};

export const deleteSuspendHistory = (
  employeeId: number,
  suspendHistoryId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}/${suspendHistoryId}`;

  return deleteAsync(url);
};

export const updateSuspendHistory = (
  employeeId: number,
  suspendHistoryId: number,
  fromDate: Date | string,
  toDate: Date | string
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}`;

  return putAsync(url, {id: suspendHistoryId, fromDate, toDate});
};

export const getEmployeeInfoById = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${id}`;

  return getAsync(url);
};