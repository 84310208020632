import React from "react";
import { Route } from "react-router-dom";
import {} from "react";
import EmployerLayout from "./employer-layout";

const EmployerRouteLayout = ({
  component: Component,
  history,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <EmployerLayout>
          <Component {...matchProps} {...rest}></Component>
        </EmployerLayout>
      )}
    />
  );
};

export default EmployerRouteLayout;
