import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components";
import { Moment } from "moment";

type Props = {
  disabled?: boolean;
  value?: string | number | Date | null;
  onChange: (date: Date | null, name: string) => void;
  name: string;
  placeholder?: string;
  disableFuture?: boolean;
  tabIndex?: number;
  minDate?: Date | Moment | string | null;
  maxDate?: Date | Moment | string | null;
};

export const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    line-height: 21px;
    height: 19px;
  }
`;

export default function BNPDatePicker(props: Props) {
  return (
    <StyledDivDatePicker>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableFuture={props.disableFuture}
          autoOk
          InputAdornmentProps={{ position: "start" }}
          disabled={props.disabled}
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          placeholder={props.placeholder ? props.placeholder : ""}
          value={props.value ? props.value : null}
          onChange={(date) => props.onChange(date, props.name)}
          KeyboardButtonProps={{
            tabIndex: -1,
            "aria-label": "change date",
          }}
          tabIndex={props.tabIndex ? props.tabIndex : 0}
          role="application"
          aria-hidden="true"
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
        />
      </MuiPickersUtilsProvider>
    </StyledDivDatePicker>
  );
}
