/**
 *
 * @param string "hight hight"
 *
 * @returns string "Hight Hight"
 */
export function capFirst(string: string) {
  if (string) {
    let text = string
      .toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

    return text.trim();
  } else {
    return "";
  }
}
