import React from "react";
import Dropzone from "react-dropzone";
import { Page, Document, pdfjs } from "react-pdf";
import ImgStack from "../../config/component/images/img-stack.svg";
import styled, { css } from "styled-components";
import { postFileToS3 } from "../../../services/employer-setting-service";
import { publishMessage } from "../../utils/message";
import { removeLoading, addLoading } from "../../utils/loading";
import { PdfViewer } from "../../../components/shared/pdf-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type StyledButtonProps = {
  border: string;
};

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  height: 150px;
  margin-top: 0;
  border-radius: 3px;

  ${(props: StyledButtonProps) =>
    !props.border &&
    css`
      border: 1px dashed #ced4da;
    `}
`;

const StyledDivDefaultUpload = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const StyledIcon = styled.img`
  position: relative;
  left: 46%;
`;
const StyledDivDragAndDrop = styled.div`
  margin-top: 15px;
  opacity: 0.5;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;
const StyledDivYourFileHereOrBr = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 15px;
`;

const StyledImgReview = styled.img`
  max-height: 150px;
  max-width: 250px;
`;

const StyledAside = styled.aside`
  text-align: center;
`;

type ImageUploadProps = {
  addFile: (urlImage: string) => void;
  addBlob: (urlBlob: string) => void;
  urlImage: string;
  folderName: string;
  isOCRCheckingCompleted: boolean;
};

// for profile picture
export default function ImageUpload(props: ImageUploadProps) {
  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);

  const onDrop = (accepted: any, rejected: any) => {
    if (accepted.length !== 0) {
      if (accepted[0].size < 10485760) {
        handleChangeImage(accepted);
      } else {
        publishMessage({
          variant: "error",
          message:
            "This image cannot be uploaded, because it exceeds the maximum file size for uploading.",
        });
      }
    } else {
      publishMessage({
        variant: "error",
        message: "Please submit valid file type!",
      });
    }
  };

  React.useEffect(() => {
    if (props.urlImage.match("/*.pdf")) {
      setIsPdfFile(true);
    } else {
      setIsPdfFile(false);
    }
  }, [props.urlImage]);

  const handleChangeImage = (files: any[]) => {
    if (files.length) {
      // FormData image to save
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append(
        "folderName",
        props.folderName ? props.folderName : "Images"
      );
      addLoading();

      props.addBlob(files[0].preview);

      postFileToS3(formData)
        .then((result) => {
          props.addFile(result.data);

          if (files[0].type === "application/pdf") {
            setIsPdfFile(true);
          } else {
            setIsPdfFile(false);
          }
        })
        .finally(() => {
          removeLoading();
        });
    }
  };

  const { urlImage: voidCheque } = props;

  const thumbs = <StyledImgReview src={voidCheque} alt="profile" />;

  const render = voidCheque ? (
    isPdfFile ? (
      <PdfViewer fileUrl={voidCheque} height='150px' maxWidth='250px' />
    ) : (
      <StyledAside>{thumbs}</StyledAside>
    )
  ) : (
    <StyledDivDefaultUpload>
      <StyledIcon src={ImgStack} alt="img-stack" />
      <StyledDivDragAndDrop>Drag & drop</StyledDivDragAndDrop>
      <StyledDivYourFileHereOrBr>
        Your file here or Browse to upload.
      </StyledDivYourFileHereOrBr>
      <StyledDivYourFileHereOrBr>
        Acceptable Files Types - JPG, PNG up to 10MB.
      </StyledDivYourFileHereOrBr>
    </StyledDivDefaultUpload>
  );

  return (
    <div>
      <StyledDropZone
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        border={voidCheque}
        multiple={false}
        accept="image/jpeg, image/png, application/pdf"
        tabIndex={-1}
        role="application"
        aria-hidden="true"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
          // for drag and drop warning statement
          if (isDragReject) return "Please submit a valid file";
          return render;
        }}
      </StyledDropZone>
    </div>
  );
}
