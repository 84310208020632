import React from "react";
import styled, { css } from "styled-components";
import theme from "../../../../../cores/helpers/theme";
import { Link } from "react-router-dom";

const StyledBoxStepper = styled(Link)`
  padding-right: 5px;
  padding-left: 5px;

  :hover {
    text-decoration: none;
  }

  ${(props: { width: string }) =>
          props.width &&
          css`
            flex: 0 0 ${props.width};
            max-width: ${props.width};
          `}
`;

const StyledBoxStepperNonLink = styled.div`
  padding-right: 5px;
  padding-left: 5px;

  :hover {
    text-decoration: none;
  }

  ${(props: { width: string }) =>
    props.width &&
    css`
      flex: 0 0 ${props.width};
      max-width: ${props.width};
    `}
`;

const StyledStepper = styled.div`
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  padding: 4px;
  width: 100%;
  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: #00ce66;
    `}
`;
const StyledText = styled.div`
  text-align: center;
  color: ${theme.primaryColor};
  margin-top: 1rem;
  font-weight: 600;
  @media (max-width: 768px) {
    display: none;
  }
`;

type Step = {
  id: string;
  name: string;
};

type Props = {
  activeStep: number;
  stepper: Step[];
  handleChangeStep?: (step: string) => void;
};

export default function Stepper(props: Props) {
  return (
    <div className="row">
      {props.stepper.map((step, index) =>
        (props.handleChangeStep ? (
            <StyledBoxStepper
              width={100 / props.stepper.length + "%"}
              key={index}
              onClick={() => {
                props.handleChangeStep && props.handleChangeStep(step.id);
              }}
              to={`/employer/setup/${step.id}`}
            >
              <StyledStepper
                active={index <= props.activeStep}
              ></StyledStepper>
              <StyledText>{step.name}</StyledText>
            </StyledBoxStepper>
          ) : (
            <StyledBoxStepperNonLink
              width={100 / props.stepper.length + "%"}
              key={index}
            >
              <StyledStepper
                active={index <= props.activeStep}
              ></StyledStepper>
              <StyledText>{step.name}</StyledText>
            </StyledBoxStepperNonLink>
          )))}
    </div>
  );
}
