import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
} from "../../shared/employer-styled";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { getPaymentMethod } from "../../../services/employer-setting-service";
import { FilterPaidClaim } from "../claim-management/claim-management-type";
import moment from "moment";
import {PdfThumbnailComponent} from "./component/PdfThumbnail";
import ClaimReceiptTable from "./component/ClaimReceiptTable";
import DepositReceiptTable from "./component/DepositReceiptTable";
import {getInfoByToken} from "../../../cores/utils/helpers";
import { getBriefPremiumPaymentSubscriptions } from "../../../services/employer-benefits-service";
import { PremiumInsuranceType, PremiumPaymentStatus } from "../employer/plan/group-insurance-coverage/group-insurance-coverage-type";

type State = {
  commonDocumentList: {title: string, url: string}[];
  filter: FilterPaidClaim;
};

function getFirstDayOfMonth(y?: number, m?: number) {
  if (!y || !m) {
    let date = new Date();
    y = date.getFullYear();
    m = date.getMonth();
  }

  let firstDay = new Date(y, m, 1);

  return moment(firstDay).toDate();
}

function getLastDayOfMonth(y?: number, m?: number) {
  if (!y || !m) {
    let date = new Date();
    y = date.getFullYear();
    m = date.getMonth();
  }
  let lastDay = new Date(y, m + 1, 0);

  return moment(lastDay).toDate();
}

export default class Documents extends React.Component<any, State> {
  state: State = {
    commonDocumentList: [],
    filter: {
      from: getFirstDayOfMonth(),
      to: getLastDayOfMonth()
    }
  };

  async componentDidMount() {
    addLoading();

    let resultPaymentMethod = await getPaymentMethod();

    const commonDocumentList = [
      {
        title: "Service Agreement",
        url: resultPaymentMethod.data.pdfServiceAgreement || "",
      },
      {
        title: "Employer Handbook",
        url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Employer_Benefits_Guide.pdf",
      },
      {
        title: "BeniPlus Eligible Expenses - HSA",
        url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus_Eligible_Expenses_-_HSA.pdf",
      },
    ]

    const employerId = getInfoByToken().employerId;

    let result = await getBriefPremiumPaymentSubscriptions(employerId);
    let premiumPaymentData: PremiumInsuranceType[] = result.data ? result.data : {};


    this.isHavePremiumPayment(premiumPaymentData, "Major Medical") && commonDocumentList.push(documents.medical);
    this.isHavePremiumPayment(premiumPaymentData, "Travel") && commonDocumentList.push(documents.travel);
    this.isHaveMobSquadGroupInsurance(employerId) && commonDocumentList.push(documents.groupInsuranceMobSquad);

    if (this.isHaveCompuSolveWsa(employerId)) {
      commonDocumentList.push(documents.wsaCompuSolve);
    } else if (this.isHaveVianetWsa(employerId)) {
      commonDocumentList.push(documents.wsaVianet);
    } else {
      commonDocumentList.push(documents.wsa);
    }

    this.setState({ commonDocumentList })

    removeLoading();
  }

  isHavePremiumPayment = (premiumPaymentData: PremiumInsuranceType[], permiumPaymentName: string):boolean => { 
    const activePremiumPayments = 
      premiumPaymentData.filter(pp => pp.status == PremiumPaymentStatus.ACTIVE);
    const foundPremiumnPayment = activePremiumPayments.filter(pp => pp.productName.includes(permiumPaymentName));
    
    return foundPremiumnPayment && foundPremiumnPayment.length > 0;
  }

  //TODO: Get by employer benefit settings, currently, hardcode for apply for MobSquad and CompuSolve
  mobSquadEmployerId = 302;
  compuSolveEmployerId = 360;
  vianetEmployerId = 543;
  isHaveMedicalBenefit = (employerId: number):boolean => { return employerId === this.mobSquadEmployerId }
  isHaveTravelBenefit = (employerId: number):boolean => { return employerId === this.mobSquadEmployerId }
  isHaveMobSquadGroupInsurance = (employerId: number):boolean => { return employerId === this.mobSquadEmployerId }
  isHaveCompuSolveWsa = (employerId: number):boolean => { return employerId === this.compuSolveEmployerId }
  isHaveVianetWsa = (employerId: number):boolean => { return employerId === this.vianetEmployerId }

  render() {
    return (<>
      <StyledMainContent>
        <StyledBigTitle className="mb-3">Documents</StyledBigTitle>

        <div className="container-fluid p-0">
          <div className="row">
            {this.state.commonDocumentList.map((doc, index) => (
              <PdfThumbnailComponent key={`${doc.title}_${index}`} title={doc.title} url={doc.url} />
            ))}
          </div>
          <DepositReceiptTable />
          <ClaimReceiptTable />
        </div>
      </StyledMainContent>
    </>);
  }
}

const documents = {
  medical: {
    title: "Major Medical",
    url:
        "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Major_Medical_Policy_Document_.pdf",
  },
  travel: {
    title: "Travel Policy",
    url:
        "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Travel_insurance_explainer.pdf",
  },
  wsa: {
    title: "BeniPlus Eligible Expenses - WSA",
    url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus_Eligible_Expenses_-_WSA.pdf",
  },
  groupInsuranceMobSquad: {
    title: "Group Insurance Benefits - MobSquad",
    url:
        "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Policy+Booklet+for+Mobsquad+-+FGBL000L001A.pdf",
  },
  wsaCompuSolve: {
    title: "Compu SOLVE Eligible Expenses - WSA",
    url:
    "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus+Wellness+Spending+Account+(WSA)+Eligible+Expenses.pdf"
  },
  wsaVianet: {
    title: "Vianet - WSA Eligible Benefits",
    url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Vianet+WSA+Eligible+Expenses+List.pdf"
  }
}
