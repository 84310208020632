import React from "react";
import ImageSuccess from "../../../components/assets/images/mb-EmployeeS-Finish/Group@3x.png";
import styled from "styled-components";
import { StyledBtnLink } from "../../../components/shared/employer-styled";
import theme from "../theme";

type SuccessQuestProps = {
  title: string | JSX.Element;
  description: string | JSX.Element;
  link?: string;
  descriptionLink?: string;
};

const StyledSuccessPage = styled.div`
  height: calc(100vh - 75px);
`;
const StyledDivGroupSuccess = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: 320px) {
    top: 50px;
  }
  @media (min-width: 767px) {
    top: 25%;
  }
`;
const StyledImageSuccess = styled.img`
  position: relative;
  height: 132px;
  width: 150px;
`;

const StyledRequestMessage = styled.div`
  margin-top: 1rem;
`;

const StyledTitleMessage = styled.div`
  color: ${props => props.theme.primaryColor};
  font-family: ${props => props.theme.textFont};
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledDescriptionMessage = styled.div`
  color: #2d2d2d;
  margin-top: 18px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export default function SuccessQuest(props: SuccessQuestProps) {
  return (
    <StyledSuccessPage>
      <StyledDivGroupSuccess>
        <StyledImageSuccess src={ImageSuccess} alt="success" />
        <StyledRequestMessage>
          <StyledTitleMessage>{props.title}</StyledTitleMessage>
          <StyledDescriptionMessage>
            {props.description}
          </StyledDescriptionMessage>
          {props.link ? (
            <div className="mt-5">
              <StyledBtnLink to={props.link} background={theme.secondaryColor}>
                {props.descriptionLink}
              </StyledBtnLink>
            </div>
          ) : (
            ""
          )}
        </StyledRequestMessage>
      </StyledDivGroupSuccess>
    </StyledSuccessPage>
  );
}
