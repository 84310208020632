import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import {getFileUrl} from "../../../../services/file-service";
import {StyledHideForDesktop, StyledHideForMobile} from "../../../shared/employer-styled";

const ReviewContainer = styled.div`
    background-color: #F8FBFF;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 4px;
`;

const UserRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled(Avatar)`
    width: 50px;
    height: 50px;
    margin-right: 16px;
`;

const Username = styled.div`
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 8px;
`;

export const Stars = styled.div`
    display: flex;
    align-items: center;
`;

export const StarIcon = styled(Star)`
    color: #fbc02d;
`;

export const StarBorderIcon = styled(StarBorder)`
  color: #fbc02d;
`;

interface ReviewContentProps {
  expanded: boolean;
}

const ReviewContent = styled.pre<ReviewContentProps>`
    font-family: ${(props) => props.theme.textFont};
    font-size: 14px;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: ${props => (props.expanded ? 'none' : '2')};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
`;

const ReadMore = styled.span`
    color: #007bff;
    cursor: pointer;
    font-size: 0.9em;
`;

const TimeAgo = styled.div`
  font-size: 0.9em;
  color: #757575;
`;

interface ReviewComponentProps {
  index: string;
  avatarUrl: string;
  username: string;
  rating: number;
  content: string;
  date: Date;
}

const ReviewComponent: React.FC<ReviewComponentProps> = ({ avatarUrl, username, rating, content, date, index }) => {
  const [expanded, setExpanded] = useState(false);
  const [validAvatarUrl, setValidAvatarUrl] = useState(avatarUrl);
  const commentRef = React.createRef<HTMLPreElement>();

  useEffect(() => {
    if (!!avatarUrl && avatarUrl.startsWith('https://beniplus') && !/[?&]X-Amz-Algorithm=/.test(avatarUrl)) {
      getFileUrl(avatarUrl).then((res) => {
        setValidAvatarUrl(res.data);
      });
    }
  }, [avatarUrl]);

  useEffect(() => {
    if (commentRef && commentRef.current) {
      if (commentRef.current.scrollHeight > commentRef.current.clientHeight) {
        setExpanded(false);
      }
    }
  }, []);

  const handleReadMore = () => {
    setExpanded(!expanded);
  };

  const timeAgo = formatDistanceToNow(date, { addSuffix: true });

  return (
    <ReviewContainer key={index}>
      <UserRow>
        <UserInfo>
          <StyledAvatar src={validAvatarUrl} alt={username} />
          <Username>{username}</Username>
        </UserInfo>
        <StyledHideForMobile>
          <Stars>
            {[...Array(5)].map((_, index) => (
              index < rating ? <StarIcon key={index} /> : <StarBorderIcon key={index} />
            ))}
          </Stars>
        </StyledHideForMobile>
        <StyledHideForDesktop>
          <Stars>
            <StarIcon/> {rating}
          </Stars>
        </StyledHideForDesktop>
      </UserRow>
      <ReviewContent key={`${index}-comment`} ref={commentRef} expanded={expanded}>
        {content}
      </ReviewContent>
      {content.length > 100 && (
        <ReadMore onClick={handleReadMore}>
          {expanded ? 'Read Less' : 'Read More'}
        </ReadMore>
      )}
      <TimeAgo>{timeAgo}</TimeAgo>
    </ReviewContainer>
  );
};

export default ReviewComponent;
