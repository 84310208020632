import React from "react";
import { RouteChildrenProps } from "react-router";
import {
  StyledBigTitle,
  StyledBtnLink,
  StyledButtonSubmit,
} from "../../../../shared/employer-styled";
import theme from "../../../../../cores/helpers/theme";
import styled, { css } from "styled-components";
import SetUpWizardView from "../setup";
import {putEmployerPlan, putEmployersPlanPreferences} from "../../../../../services/employer-setting-service";
import {
  postRequestGroupInsuranceQuote,
  putEmployerEnrollments,
  putMedicalAndTravelCoverage,
} from "../../../../../services/employer-benefits-service";

import { removeLoading, addLoading } from "../../../../../cores/utils/loading";
import {
  Enrollments,
  EmployerEnrollment,
} from "../../employer-classes/employer-classes-type";
import {
  RequestPlanPreferences,
  PlanPreferencesModel,
  PlanPreferences,
  EmployerPlan,
} from "../../plan/plan-preferences/plan-preferences-type";
import { getDataPlan, getGroupInsuranceData } from "../../plan/plan-function";
import EnrollmentCoverage from "../../plan/enrollment-coverage/enrollment-coverage";
import EmployerPlanPreferences from "../../plan/plan-preferences/plan-preferences";
import {
  setupCompanyURL,
  setupBenefitURL,
} from "../../../../../cores/constants/url-config";
import PlanInformation from "./PlanInformation";
import {
  BE_DATE_FORMAT,
} from "../../../../../cores/utils/format/date-time-format";
import moment from "moment";
import { EmployerConfiguration } from "../../../../../models/employer-configuration/employer-configuration-model"
import MedicalAndTravelCoverage from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage";
import { GroupInsuranceDefault, MedicalAndTravel } from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { MedicalTravelData } from "../../../request-a-quote/request-a-quote-type";
import { isBeniplusUI } from "../../../../../cores/config/component/ui-config";

export const StyledDiv = styled.div`
  width: 50%;
  padding: 0 5px;
`;
const StyledDivHiddenForMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledPlanSubmitButton = styled(StyledButtonSubmit)`
  ${(props: { disabled: boolean }) =>
      props.disabled &&
      css`
      background-color: ${theme.primaryColor};
      color: #ffffff;
      opacity: 0.8;
    `}
`;

type State = {
  planPreferencesByEmployer: RequestPlanPreferences[];
  planPreferences: PlanPreferencesModel[];
  enrollments: Enrollments[];
  employerEnrollments: EmployerEnrollment[];
  employerPlan: EmployerPlan;
  updatedEmployerPlan: EmployerPlan;
  updatingEmployerEnrollments: EmployerEnrollment[];
  updatingPlanPreferences: RequestPlanPreferences[];
  isQuoteChecked: boolean;
  medicalTravels: MedicalAndTravel[];
  medicalTravelData: MedicalTravelData[];
  isValidMedical: boolean;
  groupInsuranceDefault: GroupInsuranceDefault[];
  groupInsuranceQuoteRequest: number[];
};

type Props = RouteChildrenProps & EmployerConfiguration;

export default class PlanSetup extends React.Component<Props, State> {
  state: State = {
    planPreferences: [],
    planPreferencesByEmployer: [],
    enrollments: [],
    employerEnrollments: [],
    employerPlan: {} as EmployerPlan,
    updatedEmployerPlan: {} as EmployerPlan,
    updatingEmployerEnrollments: [],
    updatingPlanPreferences: [],
    isQuoteChecked: false,
    medicalTravels: [],
    medicalTravelData: [],
    isValidMedical: false,
    groupInsuranceDefault: [],
    groupInsuranceQuoteRequest: [],
  };

  async componentDidMount() {
    addLoading();
    let result = await getDataPlan();

    if (isBeniplusUI) {
      let groupInsuranceResult = await getGroupInsuranceData();

      const checkedGroupInsurances = this.initCheckedGroupInsurance(groupInsuranceResult.groupInsuranceDefault || [])
  
      let isValid = this.validateMedicalTravel(
        groupInsuranceResult.medicalAndTravel ? groupInsuranceResult.medicalAndTravel : []
      );

      this.setState({
        medicalTravelData: groupInsuranceResult.medicalAndTravel || [],
        medicalTravels: groupInsuranceResult.medicalAndTravelDefault || [],
        groupInsuranceDefault: groupInsuranceResult.groupInsuranceDefault || [],
        groupInsuranceQuoteRequest: checkedGroupInsurances,
        isQuoteChecked: this.getIsCheckedManualApproveGroupInsurance(groupInsuranceResult.groupInsuranceDefault),
        isValidMedical: isValid,
      });
    }
    
    removeLoading();
    this.setState({
      planPreferences: result.planPreferences,
      planPreferencesByEmployer: result.planPreferencesByEmployer,
      enrollments: result.enrollments,
      employerEnrollments: result.employerEnrollments,
      employerPlan: result.employerPlan,
      updatingEmployerEnrollments: result.employerEnrollments,
      updatingPlanPreferences: result.planPreferencesByEmployer
    });
  }

  initCheckedGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]) => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.checked)
        .map((item: GroupInsuranceDefault) => item.productId)
  }

  getIsCheckedManualApproveGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]): boolean => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.checked && item.approveType.toLowerCase() === "manual")
        .length > 0
  }

  ///////////////////// EnrollmentCoverage
  handleChangeEmployerEnrollment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = event.target;

    const itemIndex = this.state.updatingEmployerEnrollments.findIndex(
      item => item.enrollmentId === Number(value)
    )
    const updateEmployerEnrollmentClone = [...this.state.updatingEmployerEnrollments]
    if(checked) {
      if (itemIndex === -1) {
        updateEmployerEnrollmentClone.push({ enrollmentId: Number(value), id: 0 })
      }
    } else {
      if (itemIndex !== -1) {
        updateEmployerEnrollmentClone.splice(itemIndex, 1)
      }
    }
    this.setState({ updatingEmployerEnrollments: updateEmployerEnrollmentClone })
  }

  checkedCheckbox = (enrollmentId: number): boolean => {
    return this.state.updatingEmployerEnrollments.some(
      (employerEnrollment) => employerEnrollment.enrollmentId === enrollmentId
    );
  };
  ////////////////////////// EmployerPlanPreferences
  handleChangePlanPreference = (event: any, planPreferences: PlanPreferences[]) => {
    const currentIdChecked = this.findIdCheckedPlanPreferences(planPreferences, this.state.updatingPlanPreferences)

    const updatingPlanPreferenceClone = [...this.state.updatingPlanPreferences]

    const index = updatingPlanPreferenceClone
      .findIndex((planPreferences) => planPreferences.planPreferenceId === currentIdChecked);

    if (index !== -1) updatingPlanPreferenceClone.splice(index, 1);

    updatingPlanPreferenceClone.push({
      planPreferenceId: Number(event.target.value),
      otherValue: "",
      allow: true
    } as RequestPlanPreferences)

    this.setState({ updatingPlanPreferences: updatingPlanPreferenceClone })
  }

  handleChangeInput = (event: any, id: number) => {
    let { value } = event.target;

    const updatingPlanPreferencesClone: RequestPlanPreferences[] = [...this.state.updatingPlanPreferences]

    let index = updatingPlanPreferencesClone.findIndex((planPreferences) => {
      return planPreferences.planPreferenceId === id;
    });

    if (isNaN(value) || value < 0 || value % 1 !== 0) value = 0;

    updatingPlanPreferencesClone[index].otherValue = value;

    this.setState({ updatingPlanPreferences: updatingPlanPreferencesClone });
  };

  findIdCheckedPlanPreferences = (planPreferences: PlanPreferences[],
                                  checkedPlanPreferences: RequestPlanPreferences[] = this.state.updatingPlanPreferences) => {
    if (checkedPlanPreferences.length) {
      let planPreference = planPreferences.find((planPreference) =>
        checkedPlanPreferences.find((plan) => {
          return plan.planPreferenceId === planPreference.id;
        })
      );

      if (planPreference) {
        return planPreference.id;
      } else {
        return -1;
      }
    }
    return -1;
  };
  handleChangePage = async () => {
    addLoading();

    if (this.state.updatedEmployerPlan !== this.state.employerPlan && this.state.updatedEmployerPlan.planStartDate) {
      await putEmployerPlan({...this.state.updatedEmployerPlan,
        planStartDate: moment(this.state.updatedEmployerPlan.planStartDate).format(BE_DATE_FORMAT)})
    }
    await this.handleSubmitEmployerEnrollments()
    await this.handleSubmitPlanPreferences()

    if (isBeniplusUI) {
      await this.requestGroupInsuranceQuote();
      await this.setMedicalAndTravelCoverage();
    }

    removeLoading();

    this.props.history.push(setupBenefitURL);
  };

  handleSubmitEmployerEnrollments = async () => {
    const enrollmentIds = this.state.updatingEmployerEnrollments
      .map((item) => item.enrollmentId);
    await putEmployerEnrollments(enrollmentIds);
  }

  handleSubmitPlanPreferences = async () => {
    await putEmployersPlanPreferences(this.state.updatingPlanPreferences)
  }

  requestGroupInsuranceQuote = async () => {
    const existedGroupInsuranceQuote = this.initCheckedGroupInsurance(this.state.groupInsuranceDefault);
    const groupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
        .filter(item => !existedGroupInsuranceQuote.includes(item));
    if (groupInsuranceQuoteRequestTemp.length > 0) {
      await postRequestGroupInsuranceQuote({productIds: groupInsuranceQuoteRequestTemp, isOptIn: false});
    }
  }
  setMedicalAndTravelCoverage = async () => {
    let medicalAndTravel: MedicalTravelData[] = Object.assign(
      this.state.medicalTravelData
    );
    if (medicalAndTravel.length > 0) {
      await putMedicalAndTravelCoverage(medicalAndTravel);
    }
  };

  handleChangeEmployerPlan = async (employerPlan: EmployerPlan) => {
    this.setState({updatedEmployerPlan: employerPlan});
  }

  isValid = () => {
    if (isBeniplusUI) {
      return this.state.employerEnrollments.length > 0 && this.state.isValidMedical;
    }
    return this.state.employerEnrollments.length > 0;
  };

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name.startsWith("groupInsurance")) {
      const groupInsuranceQuoteRequestTemp = [...this.state.groupInsuranceQuoteRequest]
      const groupInsuranceId = Number(name.split("-")[1]);
      const idx = groupInsuranceQuoteRequestTemp.findIndex(i => i === groupInsuranceId);
      if (checked && idx === -1) {
        groupInsuranceQuoteRequestTemp.push(groupInsuranceId)
      } else {
        groupInsuranceQuoteRequestTemp.splice(idx, 1)
      }
      this.setState({groupInsuranceQuoteRequest: groupInsuranceQuoteRequestTemp})
    } else {
      let medicalTravelData: MedicalTravelData[] = Object.assign(
          this.state.medicalTravelData
      );

      if (checked) {
        // set default medical travel
        let medicalTravel = {
          category: name,
          noOfEmployees: 0,
          amount: 0,
        };
        // push
        medicalTravelData.push(medicalTravel);
      } else {
        // find index medical travel
        let index = medicalTravelData.findIndex((medicalTravel) => {
          return medicalTravel.category === name;
        });

        // if have medical travel then remove
        if (index > -1) {
          medicalTravelData.splice(index, 1);
        }
      }
      let isValid = this.validateMedicalTravel(medicalTravelData);
      this.setState({
        medicalTravelData: medicalTravelData,
        isValidMedical: isValid,
      });
    }
  };

  //#region add Medical Travels
  //change switch medical and travel
  onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let medicalTravelData: MedicalTravelData[] = Object.assign(
      this.state.medicalTravelData
    );

    // if checked= false then remove all medicalTravel
    if (!checked) {
      medicalTravelData = [];
    }
    let isValid = this.validateMedicalTravel(medicalTravelData);
    this.setState({
      isQuoteChecked: checked,
      medicalTravelData: medicalTravelData,
      isValidMedical: isValid,
    });
  };

  validateMedicalTravel = (medicalTravelData: MedicalTravelData[]) => {
    let isValid = true;

    medicalTravelData.forEach((medicalTravel) => {
      if (!medicalTravel.noOfEmployees) {
        isValid = false;
      }
    });

    return isValid;
  };

  render() {
    return (
      <SetUpWizardView
        enablePresetBankingInformation={this.props.enablePresetBankingInformation}
        children={
          <div>
            <StyledBigTitle>Standard Plan</StyledBigTitle>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <PlanInformation
                      employerPlan={this.state.employerPlan}
                      updatedEmployerPlan={this.state.updatedEmployerPlan}
                      handleChangeEmployerPlan={this.handleChangeEmployerPlan}
                  />
                  <EnrollmentCoverage
                    enrollments={this.state.enrollments}
                    employerEnrollments={this.state.employerEnrollments}
                    handleChangeEmployerEnrollment={this.handleChangeEmployerEnrollment}
                    checkedCheckbox={this.checkedCheckbox}
                    isSetup={true}
                  />
                  {isBeniplusUI &&
                    <MedicalAndTravelCoverage
                      isChecked={this.state.isQuoteChecked}
                      medicalTravelData={this.state.medicalTravelData}
                      onCheckboxChange={this.onCheckboxChange}
                      onSwitchChange={this.onSwitchChange}
                      medicalTravels={this.state.medicalTravels}
                      groupInsuranceDefault={this.state.groupInsuranceDefault}
                      groupInsuranceQuoteRequest={this.state.groupInsuranceQuoteRequest}
                      isSetup={true}
                    />
                  }
                </div>
                <div className="col-lg-6 mt-4">
                  <EmployerPlanPreferences
                    planPreferences={this.state.planPreferences}
                    handleChangeInput={this.handleChangeInput}
                    planPreferencesByEmployer={
                      this.state.planPreferencesByEmployer
                    }
                    findIdCheckedPlanPreferences={
                      this.findIdCheckedPlanPreferences
                    }
                    handleChangePlanPreference={this.handleChangePlanPreference}
                    isSetup={true}
                  />
                </div>
              </div>
              <div className="row mt-5">
                <StyledDivHiddenForMobile className="col-lg-2">
                  <StyledBtnLink
                    to={setupCompanyURL}
                    background={theme.secondaryColor}
                  >
                    Back
                  </StyledBtnLink>
                </StyledDivHiddenForMobile>

                <div className="col-lg-7"></div>
                <div className="col-lg-3">
                  <StyledPlanSubmitButton
                    submit={true}
                    disabled={!this.isValid()}
                    onClick={this.handleChangePage}
                    type="submit"
                  >
                    Save & Continue
                  </StyledPlanSubmitButton>
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}
