import React, { Component } from "react";
import {
  EmployeeData,
  Employee,
  Role,
  TerminatingRequest,
} from "./employee-type";
import { Class } from "../../request-a-quote/request-a-quote-type";
import EmployeeView from "./employee-view";
import {
  employeeStatus,
  Status,
  signUpStatus,
  planPreferences,
  SortTable,
  columnsHeaderTable,
  Default_Employees_Employer,
} from "./employee-model";
import {
  EmployeeSearchParams,
  getEmployeesEmployer,
  postEmployeesEmployer,
  postResendInvite,
  deleteEmployeesEmployer,
  putEmployeesEmployer,
  SortType,
  terminatingEmployeesEmployer,
  putSuspendEmployee,
  postEmployeesEmployerInBulk,
  putUnSuspendEmployee,
  postNotT4EmployeesEmployer,
} from "../../../../services/employer-employees-service";

import {
  closeAddEmployeeModal,
  showAddEmployeeModal,
} from "./add-employee-modal/add-employee-modal";
import { formatValueInputMask } from "../../../../cores/helpers/format-phone-number";
import { publishMessage } from "../../../../cores/utils/message";
import {
  getClasses,
  getClassBenefits,
} from "../../../../services/employer-benefits-service";
import {
  getPlan,
  getPlanPreferencesByEmployerId,
  getProvinces,
} from "../../../../services/employer-setting-service";
import { EmployerBenefitClass } from "../employer-classes/employer-classes-type";
import {
  setPagingInfo,
  PagingInfo,
} from "../../../../cores/helpers/pagingation/pagination";
import { usdToCent, centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { RouteChildrenProps } from "react-router";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { getAllRole } from "../../../../services/auth-service";
import { Provinces } from "../employer-setting/company-information/company-information-type";
import {  toUTCTime } from "../../../../cores/helpers/to-utc-time";
import { ResponseLocation } from "../../../../cores/helpers/google-map-services/google-location";
import { findProvinceIdByName } from "../employer-setting/company-information/company-information";
import { getWalletEmployee } from "../../../../services/wallet-service";
import { setToken } from "../../../../cores/utils/helpers";
import { validateEmail } from "../../../../cores/config/config";
import {dateStringToDate} from "../../../../cores/utils/format/date-time-format";
import {EmployerPlan} from "../plan/plan-preferences/plan-preferences-type";
import {validFullNameLength} from "../../../../cores/utils/validation/validate-employee";
import {EmployeeManualSetupType} from "./add-employee-modal/upload-in-bulk/upload-in-bulk";
import {handleError} from "../../../../cores/utils/http-client";

type Props = RouteChildrenProps;
type State = {
  employeeData: EmployeeData;
  classes: Class[];
  classBenefits: EmployerBenefitClass[];
  statuses: Status[];
  roles: Role[];
  signUpStatuses: Status[];
  searchParam: EmployeeSearchParams;
  planPreferencesByEmployers: number[];
  pagingInfo: PagingInfo;
  //add employees 11
  employees: Employee;
  isEdit: boolean;
  isValid: boolean;
  columnsHeaderTable: SortTable[];
  provinces: Provinces[];
  terminatingRequest: TerminatingRequest;
  isUploadInBulk: boolean;
  createInBulkError: String[];
  employerPlan: EmployerPlan;

  isT4Employee: boolean;
};

export default class EmployeesEmployer extends Component<Props, State> {
  state: State = {
    employeeData: {
      records: [],
      pageCount: 0,
    } as EmployeeData,
    classes: [],
    roles: [],
    classBenefits: [],
    statuses: employeeStatus,
    signUpStatuses: signUpStatus,
    searchParam: {
      classId: null,
      status: null,
      columnName: null,
      sortType: null,
      page: 1,
      perPage: 10,
    },
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    // add employees
    employees: {
      id: 0,
      firstName: "",
      lastName: "",
      code: "",
      status: "WORKING",
      gender: null,
      dateOfBirth: "",
      email: "",
      phoneNumber: null,
      street: null,
      classId: null,
      socialInsuranceNumber: null,
      position: null,
      salary: null,
      eligibilityDate: null,
      hiringDate: null,
      benefitCancellationDate: null,
      prorated: true,
      roleIds: [],
      cityName: "",
      postCode: "",
      provinceId: null,
      terminationDate: null,
    },
    planPreferencesByEmployers: [],
    isEdit: false,
    isValid: false,
    columnsHeaderTable: [],
    provinces: [],
    terminatingRequest: {
      id: 0,
      name: "",
      terminatedDate: null,
    },
    isUploadInBulk: false,
    createInBulkError:[],
    employerPlan: {} as EmployerPlan,
    isT4Employee: true
  };

  //#region

  // default page load data
  async componentDidMount() {
    //add loading
    addLoading();

    // get search param by url
    let paramSearch = this.getSearchByUrl();

    await this.getEmployeesEmployer(paramSearch.searchParam);

    // get data
    await this.getData();

    // remove loading
    this.setState({
      columnsHeaderTable: paramSearch.columns,
      searchParam: paramSearch.searchParam,
    });
    removeLoading();
  }

  async getData() {
    const [classesResponse, resultAllPlanPreferencesByEmployer, resultClassBenefits, resultRole, resultProvinces, resultEmployerPlan] = await Promise.all([
      getClasses(),
      getPlanPreferencesByEmployerId(),
      getClassBenefits(),
      getAllRole(),
      getProvinces(),
      getPlan()
    ])

    let planPreferencesByEmployer: number[] =
      resultAllPlanPreferencesByEmployer.data.map(
        (f: any) => f.planPreferenceId
      ) || [];

    let classes: Class[] = classesResponse.data || [];
    let classBenefits: EmployerBenefitClass[] = resultClassBenefits.data || [];
    let roles: Role[] = resultRole.data || [];

    let provinces: Provinces[] = resultProvinces.data || [];
    const employerPlan: EmployerPlan = resultEmployerPlan.data || {} as EmployerPlan;

    this.setState({
      classes: classes,
      classBenefits: classBenefits,
      planPreferencesByEmployers: planPreferencesByEmployer,
      roles: roles,
      provinces: provinces,
      employerPlan: employerPlan
    });
  }

  // get employees if url change
  async UNSAFE_componentWillReceiveProps() {
    let paramSearch = this.getSearchByUrl();

    await this.getEmployeesEmployer(paramSearch.searchParam);

    this.setState({
      columnsHeaderTable: paramSearch.columns,
      searchParam: paramSearch.searchParam,
    });
  }
  // set current classes
  setEmployerBenefitClass = (): Class[] => {
    let currentClasses: Class[] = [];

    this.state.classBenefits.forEach((classBenefit) => {
      this.state.classes.forEach((classItem) => {
        if (classBenefit.classId === classItem.id) {
          currentClasses.push(classItem);
        }
      });
    });
    return currentClasses;
  };

  // find  class name by id class
  findClassById = (classId: number): string => {
    let classItem = this.state.classes.find((classBenefit: Class) => {
      return classBenefit.id === classId;
    });
    return classItem ? classItem.name : "";
  };
  // find sign up status name by id
  findSignUpStatusById = (signUpStatusId: string): string => {
    let signUpStatusItem = this.state.signUpStatuses.find(
      (signUpStatus: Status) => {
        return signUpStatus.id === signUpStatusId;
      }
    );
    return signUpStatusItem ? signUpStatusItem.name : "";
  };
  // find  status name by id
  findStatusesById = (statusId: string, T4Employee: boolean = true): string => {
    if (!T4Employee) return this.state.statuses[0].name

    let statusItem = this.state.statuses.find((status: Status) => {
      return status.id === statusId;
    });

    return statusItem ? statusItem.name : "";
  };

  // find role name by id
  findRoleById = (roleId: number): string => {
    let role = this.state.roles.find((role: Role) => {
      return role.id === roleId;
    });

    return role ? role.name : "";
  };

  // delete employee
  deleteEmployee = (employeeId: number) => {
    deleteEmployeesEmployer(employeeId).then(() => {
      let listEmployees = Object.assign({}, this.state.employeeData);

      let indexEmployees = listEmployees.records.findIndex((employee) => {
        return employee.id === employeeId;
      });
      // remove employee delete out of list
      listEmployees.records.splice(indexEmployees, 1);

      this.setState({ employeeData: listEmployees });
    });
  };

  // resent invite mail set up employee
  resendInvite = (id: number) => {
    postResendInvite(id).then(() => {
      publishMessage({
        variant: "success",
        message: "Invitation re-sent successfully!",
      });
    });
  };

  changeFilter = (event: any) => {
    let { name, value } = event.target,
      searchParam = Object.assign(this.state.searchParam);

    if (value === -1) value = null;

    searchParam[name] = value;

    searchParam.page = 1;

    // set url search
    this.setSearchByParam(searchParam);

    this.setState({ searchParam: searchParam });
  };

  changePage = (page: number) => {
    let searchParam: EmployeeSearchParams = Object.assign(
      this.state.searchParam
    );
    searchParam.page = page;

    this.setSearchByParam(searchParam);
    this.setState({ searchParam: searchParam });
  };

   getEmployeesEmployer = async (params: EmployeeSearchParams) => {
    let listEmployeesResponse = await getEmployeesEmployer(params);

    let listEmployees: EmployeeData =
      listEmployeesResponse.data ||
      ({ pageCount: 0, records: [] } as EmployeeData);

    let pagingInfo = setPagingInfo(
      params.page,
      listEmployees.pageCount,
      params.perPage
    );

    this.setState({ employeeData: listEmployees, pagingInfo: pagingInfo });
  }

  refreshEmployeesEmployer = async () => {
    const paramSearch = this.getSearchByUrl();
    await this.getEmployeesEmployer(paramSearch.searchParam);

    this.setState({
      employees: Default_Employees_Employer()
    })
  }
  //#endRegion

  //#region
  handleChangeDate = (date: Date | null, name: string) => {
    let employees = Object.assign(this.state.employees);

    if (name === "hiringDate") {
      let value = this.findValueByPlanPreferencesByEmployer();

      if (date && value !== null) {
        if (value > -1) {
          let eligibilityDate = new Date(date);
          eligibilityDate.setDate(date.getDate() + value);
          const planStartDate = this.state.employerPlan.planStartDate ? new Date(this.state.employerPlan.planStartDate) : null;
          if (planStartDate && planStartDate > eligibilityDate) {
            eligibilityDate = planStartDate;
          }
          employees.eligibilityDate = eligibilityDate;
        }
      }
    }
    employees[name] = date && toUTCTime(new Date(date));

    let isValid = this.validateForm(this.state.isT4Employee);
    this.setState({ employees: employees, isValid: isValid });
  };

  findValueByPlanPreferencesByEmployer = (): number | null => {
    let value = null;

    this.state.planPreferencesByEmployers.forEach(
      (planPreferencesByEmployer) => {
        let planPreference = planPreferences.find((planPreference) => {
          return planPreference.id === planPreferencesByEmployer;
        });
        if (planPreference) {
          value = planPreference.value;
        }
      }
    );

    return value;
  };

  handleChangeSelect = (event: any) => {
    let { name, value } = event.target,
      employees = Object.assign(this.state.employees);

    if (name === "roleIds") {
      employees.roleIds = [value];
    } else {
      if (value === -1) value = null;

      employees[name] = value;
    }

    let isValid = this.validateForm(this.state.isT4Employee);
    this.setState({ employees: employees, isValid: isValid });
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let employees = Object.assign(this.state.employees);
    if (this.state.isEdit && event.target.name === "phoneNumber") {
      employees.phoneNumber = formatValueInputMask(event.target.value);
    } else {
      employees[event.target.name] = event.target.value;
    }

    let isValid = this.validateForm(this.state.isT4Employee);
    this.setState({ employees: employees, isValid: isValid });
  };

  setAddress = (address: string) => {
    let employees: Employee = Object.assign(this.state.employees);
    employees.street = address;

    let isValid = this.validateForm(this.state.isT4Employee);
    this.setState({ employees: employees, isValid: isValid });
  };

  setLocation = (location: ResponseLocation) => {
    let employees: Employee = Object.assign(this.state.employees);

    employees.cityName = location.city;
    employees.postCode = location.postcode ? location.postcode : "";
    employees.provinceId = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validateForm(this.state.isT4Employee);
    this.setState({ employees: employees, isValid: isValid });
  };

  handleAddEmployeeInBulk = (employees: EmployeeManualSetupType[]) => {
    const employeeListToSubmit = employees.map(employee => {
      let employeeData = {...employee, salary: usdToCent(employee.salary)};
      if (employee.hiringDate)
        employeeData.hiringDate = toUTCTime(dateStringToDate(employee.hiringDate));
      if (employee.eligibilityDate)
        employeeData.eligibilityDate = toUTCTime(dateStringToDate(employee.eligibilityDate));
      if (employee.benefitCancellationDate)
        employeeData.benefitCancellationDate = toUTCTime(dateStringToDate(employee.benefitCancellationDate));
      if (employee.hsaAllocated)
        employeeData.hsaAllocated = usdToCent(employee.hsaAllocated);
      if (employee.hsaClaimed)
        employeeData.hsaClaimed = usdToCent(employee.hsaClaimed);
      if (employee.wsaAllocated)
        employeeData.wsaAllocated = usdToCent(employee.wsaAllocated);
      if (employee.wsaClaimed)
        employeeData.wsaClaimed = usdToCent(employee.wsaClaimed);
      if (employee.insuranceAllocated)
        employeeData.insuranceAllocated = usdToCent(employee.insuranceAllocated);
      if (employee.insuranceClaimed)
        employeeData.insuranceClaimed = usdToCent(employee.insuranceClaimed);
      if (employee.charityAllocated)
        employeeData.charityAllocated = usdToCent(employee.charityAllocated);
      if (employee.charityClaimed)
        employeeData.charityClaimed = usdToCent(employee.charityClaimed);
      if (employee.rrspAllocated)
        employeeData.rrspAllocated = usdToCent(employee.rrspAllocated);
      if (employee.rrspClaimed)
        employeeData.rrspClaimed = usdToCent(employee.rrspClaimed);

      employeeData.email = employee.email.toLowerCase().trim();
      return employeeData;
    })

    postEmployeesEmployerInBulk(employeeListToSubmit)
    .then(() => {
      publishMessage({
        message: "All employees in the list has been created",
        variant: "success",
      });
      closeAddEmployeeModal();
      const searchParam: EmployeeSearchParams = {
        classId: null,
        status: null,
        columnName: "id",
        sortType: "ASC",
        page: 1,
        perPage: 10,
      };
      this.setSearchByParam(searchParam);
    })
    .catch((error) => {
      if (!!error.response && !!error.response.status && error.response.status === 400) {
        this.setState({ createInBulkError: error.response.data })
      } else {
        handleError(error).then()
      }
    })
  }

  handleResetCreateInBulkError = () => {
    this.setState({ createInBulkError: [] })
  }

  handleChangeSubmit = async () => {
    addLoading();
    let employees: Employee = Object.assign(this.state.employees, {
      salary: usdToCent(this.state.employees.salary),
      homeAddress: this.state.employees.street
    });
    if (employees.dateOfBirth)
      employees.dateOfBirth = toUTCTime(new Date(employees.dateOfBirth))
    if (employees.hiringDate)
      employees.hiringDate = toUTCTime(new Date(employees.hiringDate))
    if (employees.eligibilityDate)
      employees.eligibilityDate = toUTCTime(new Date(employees.eligibilityDate))
    if (employees.benefitCancellationDate)
      employees.benefitCancellationDate = toUTCTime(new Date(employees.benefitCancellationDate))

    employees.email = employees.email.toLowerCase().trim();

    if (!this.state.isEdit && !this.state.isT4Employee) {
      await postNotT4EmployeesEmployer(employees);
    } else {
      const result = await postEmployeesEmployer(employees);
      await getWalletEmployee(result.data.id);
    }

    await this.refreshEmployeesEmployer();

    Object.assign(this.state.employees, {
      salary: centToUsd(this.state.employees.salary),
    });

    publishMessage({
      message: "An employee has been created",
      variant: "success",
    });

    this.setState({
      isT4Employee: true
    })
    closeAddEmployeeModal();
    removeLoading();
  };

  addEmployeeToEmployeeData = (id: number) => {
    let employees: Employee = Object.assign(this.state.employees),
      employeeData: EmployeeData = Object.assign(this.state.employeeData),
      defaultEmployeesEmployer: Employee = Default_Employees_Employer();

    employees.id = id;

    let nameRole = this.findRoleById(employees.roleIds[0]);

    employees.role = [nameRole];
    employeeData.records.push(employees);

    this.setState({
      employees: defaultEmployeesEmployer,
      employeeData: employeeData,
      isValid: false,
    });
    // close modal
    closeAddEmployeeModal();
  };

  validateForm = (isT4Employee: boolean = true): boolean => {
    const { firstName, lastName } = this.state.employees;
    if (!isT4Employee) {
      return !!(this.state.employees.email.match(validateEmail) &&
          firstName && lastName &&
          validFullNameLength(firstName, lastName) &&
          this.state.employees.roleIds.length > 0 &&
          this.state.employees.roleIds[0] > 0
      )}
    return !!(this.state.employees.email.match(validateEmail) &&
      this.state.employees.firstName &&
      this.state.employees.lastName &&
      this.state.employees.roleIds.length > 0 &&
      this.state.employees.roleIds[0] > 0 &&
      this.state.employees.code &&
      this.state.employees.classId &&
      this.state.employees.eligibilityDate &&
      this.state.employees.hiringDate &&
      (this.state.employees.provinceId ? this.state.employees.provinceId > 0 : true) &&
      validFullNameLength(firstName, lastName)
    );
  };

  showModalEditEmployee = (employee: Employee) => {
    this.setState({ isEdit: true });
    if (employee.id !== this.state.employees.id) {
      this.setState({
        employees: Object.assign({}, employee, {
          salary: centToUsd(employee.salary),
        }),
        isUploadInBulk: false,
      });
    }

    showAddEmployeeModal();
  };

  editEmployee = () => {
    let employee = Object.assign(this.state.employees, {
      salary: usdToCent(this.state.employees.salary),
      homeAddress: this.state.employees.street
    });

    // if (!this.checkEditEmployee(employee)) {
    //   publishMessage({
    //     message:
    //       "Company must has at least one person with role Owner, please set other user to Owner before do this.",
    //     variant: "error"
    //   });

    //   return;
    // }

    putEmployeesEmployer(employee).then((result) => {
      // If the owner edits himself
      if (result.data) {
        setToken(result.data.access_token);
      }

      this.getEmployeesEmployer(this.state.searchParam);
      Object.assign(this.state.employees, {
        salary: centToUsd(this.state.employees.salary),
      });

      publishMessage({
        message: "Update employee info successfully",
        variant: "success",
      });
    });
    closeAddEmployeeModal();
  };

  // checkEditEmployee = (employee: Employee) => {
  //   // filter list employee has role owner;
  //   const employeeRoleOwner = this.state.employeeData.records.filter(
  //     i => i.roleIds[0] === 1
  //   );

  //   // if length > 1
  //   if (employeeRoleOwner.length > 1) {
  //     return true;
  //   }
  //   // else = 1
  //   else {
  //     if (
  //       employee.id === employeeRoleOwner[0].id &&
  //       employee.roleIds[0] !== 1
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // };

  showModalAddEmployee = (isUploadInBulk: boolean) => {
    if (this.state.isEdit) {
      this.setState({ isEdit: false, employees: Default_Employees_Employer() });
    }
    this.setState({ isUploadInBulk })

    showAddEmployeeModal();
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(
      this.state.columnsHeaderTable
    );
    let param = Object.assign({}, this.state.searchParam);
    param.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        param.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    // set url search
    this.setSearchByParam(param);
  };

  setIsT4Employee = (val: boolean) => {
    const isValid = this.validateForm(val);
    this.setState({
      isT4Employee: val,
      isValid,
    })
  }

  //#endRegion

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      classId = urlParams.get("classId"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      status = urlParams.get("status"),
      rowsPerPage = urlParams.get("rowsPerPage");

    let searchParam = Object.assign({}, this.state.searchParam);

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);

    classId
      ? (searchParam.classId = Number(classId))
      : (searchParam.classId = null);

    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }
    status ? (searchParam.status = status) : (searchParam.status = null);
    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    let columns: SortTable[] = columnsHeaderTable();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: EmployeeSearchParams) => {
    let url = new URL(window.location.href);

    searchParam.page
      ? url.searchParams.set("page", searchParam.page.toString())
      : url.searchParams.delete("page");

    searchParam.classId
      ? url.searchParams.set("classId", searchParam.classId.toString())
      : url.searchParams.delete("classId");

    searchParam.status
      ? url.searchParams.set("status", searchParam.status)
      : url.searchParams.delete("status");

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  handleSubmitTerminatedEmployee = async (model: TerminatingRequest) => {

    const employee = Object.assign({}, this.state.employees);

    terminatingEmployeesEmployer(model).then(() => {
      this.getEmployeesEmployer(this.state.searchParam);

      employee.benefitCancellationDate = model.terminatedDate;
      this.setState({ employees: employee })

      publishMessage({
        variant: "success",
        message: "Employee deactivation successfully.",
      });
    });
  };

  handleSuspendEmployee = async (id: number, fromDate: Date | string, toDate: Date | string) => {
    putSuspendEmployee(id, fromDate, toDate).then(() => {
      this.getEmployeesEmployer(this.state.searchParam);

      publishMessage({
        variant: "success",
        message: "Suspend employee successfully.",
      });
    });
  };
  handleUnSuspendEmployee = (id: number) => {
    putUnSuspendEmployee(id).then(() => {
      this.getEmployeesEmployer(this.state.searchParam);

      publishMessage({
        variant: "success",
        message: "Un-suspend employee successfully.",
      });
    });
  };

  render() {
    return (
      <>
        <EmployeeView
          listEmployees={this.state.employeeData}
          findClassById={this.findClassById}
          findSignUpStatusById={this.findSignUpStatusById}
          findStatusesById={this.findStatusesById}
          deleteEmployee={this.deleteEmployee}
          classes={this.state.classes}
          statuses={this.state.statuses}
          roles={this.state.roles}
          employeeSearchParams={this.state.searchParam}
          changePage={this.changePage}
          changeFilter={this.changeFilter}
          pagingInfo={this.state.pagingInfo}
          employee={this.state.employees}
          handleChangeDate={this.handleChangeDate}
          handleChangeInput={this.handleChangeInput}
          handleChangeSelect={this.handleChangeSelect}
          handleChangeSubmit={this.handleChangeSubmit}
          isValid={this.state.isValid}
          isEdit={this.state.isEdit}
          resendInvite={this.resendInvite}
          showModalEditEmployee={this.showModalEditEmployee}
          editEmployee={this.editEmployee}
          showModalAddEmployee={this.showModalAddEmployee}
          columnsHeaderTable={this.state.columnsHeaderTable}
          sortTable={this.sortTable}
          setEmployerBenefitClass={this.setEmployerBenefitClass}
          setRowsPerPage={this.setRowsPerPage}
          provinces={this.state.provinces}
          //
          setAddress={this.setAddress}
          setLocation={this.setLocation}
          handleSuspendEmployee={this.handleSuspendEmployee}
          handleUnSuspendEmployee={this.handleUnSuspendEmployee}
          findValueByPlanPreferencesByEmployer={this.findValueByPlanPreferencesByEmployer}
          createInBulkError={this.state.createInBulkError}
          isUploadInBulk={this.state.isUploadInBulk}
          handleAddEmployeeInBulk={this.handleAddEmployeeInBulk}
          handleResetCreateInBulkError={this.handleResetCreateInBulkError}
          handleTerminateEmployee={this.handleSubmitTerminatedEmployee}
          getEmployeesEmployer={this.refreshEmployeesEmployer}
          employerPlan={this.state.employerPlan}
          isT4Employee={this.state.isT4Employee}
          setIsT4Employee={this.setIsT4Employee}
        />
      </>
    );
  }
}
