import React, {useEffect, useState} from 'react';
import {
  StyledBigTitle,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion
} from "../../../shared/employer-styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledCellTable,
  StyledHeaderTable, StyledImportExportIcon,
  StyledRowBodyTable,
  StyledTable
} from "../../../shared/table-styled";
import moment from "moment";
import {FE_DATE_FORMAT, BE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {ExpansionPanel} from "@material-ui/core";
import {ArrowDropUp} from "@material-ui/icons";
import {columnsDepositReceipt} from "../DocumentModel";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {Deposit} from "../document-type";
import FileActions from "./FileActions";
import {getAllDepositReceiptAsZip, getDepositReceipt} from "../../../../services/employer-document-service";
import {defaultPagingInfo, defaultSearchParam, ReportPeriod} from "../../report/ReportModel";
import {SearchParamType} from "../../report/ReportType";
import {SortTable} from "../../employer/employees/employee-model";
import {exportZip} from "../../../../cores/helpers/export-file/export-file";
import {calculatePeriod, StyledDivExport} from "../../report/report";
import {TableFilterAndSearch} from "../../../shared/BNPTable/TableFilterAndSearch";

function DepositReceiptTable() {
  const [deposits, setDeposits] = useState<Deposit[]>([]);
  const [searchParam, setSearchParam] = useState<SearchParamType>(calculatePeriod(ReportPeriod.custom.type, defaultSearchParam, null, null));
  const [paging, setPaging] = useState<PagingInfo>(defaultPagingInfo);
  const [column, setColumn] = useState<SortTable[]>(columnsDepositReceipt);

  useEffect(() => {
    getReceiptsData().then();
  }, [JSON.stringify(searchParam)])

  const getReceiptsData = async () => {
    addLoading();
    let resultReceipts = await getDepositReceipt(searchParam);
    setDeposits(resultReceipts.data ? resultReceipts.data.records : []);
    let pagingInfo = setPagingInfo(
        searchParam.page,
        resultReceipts.data.pageCount,
        searchParam.perPage,
    )
    setPaging(pagingInfo);
    removeLoading()
  }

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.page = 1;
    searchParamBuffer.perPage = Number(value);

    setSearchParam(searchParamBuffer);
  };

  const handleChangePage = (page: number) => {
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);
    searchParamClone.page = page;

    setSearchParam(searchParamClone);
  };

  function handleChangeDate(date: Date | null, name: string | null) {
    const searchParamClone = Object.assign({}, searchParam)
    if (name === "from") {
      searchParamClone.from = date && moment(date.toString()).format(BE_DATE_FORMAT);
    } else if (name === "to") {
      searchParamClone.to = date && moment(date.toString()).format(BE_DATE_FORMAT);
    }
    setSearchParam(searchParamClone);
  }

  function sortTable (columnId: string) {
    let columnsHeaderTable: SortTable[] = [...column];
    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParamBuffer.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    setColumn(columnsHeaderTable);
    setSearchParam(searchParamBuffer);
  }

  async function handleExportReceipt() {
    addLoading();
    const res = await getAllDepositReceiptAsZip(searchParam);
    if (res.status === 200) {
      exportZip(res.data, 'deposit');
    }
    removeLoading();
  }

  return (
    <>
      <div className="row d-flex flex-row mt-5 mb-5">
        <StyledBigTitle className="col-10">Deposit Receipts</StyledBigTitle>
        {deposits.length > 0 && <div className="col-2 d-flex flex-row justify-content-end align-items-center">
          <StyledDivExport className={"w-100"} onClick={handleExportReceipt}>
            Export
          </StyledDivExport>
        </div>}
      </div>
      <div className="p-0 mt-4">
        <TableFilterAndSearch tableType="transaction"
                              searchParam={searchParam}
                              handleChangeDate={handleChangeDate} />
        {deposits.length === 0 ?
          <div className="text-center text-danger mt-5">There was no receipt!</div> :
          (<div className="row">
            <div className="col-12 mt-2">
              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {columnsDepositReceipt.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className={"cur"}
                        onClick={() => column.sortColumn && sortTable(column.columnId)}
                      >
                        {column.columnName}
                        {column.sortColumn && <span>
                          {column.sortType ? (
                              column.sortType === "ASC" ? (
                                  <StyledArrowDropDownIcon/>
                              ) : (
                                  <StyledArrowDropUpIcon/>
                              )
                          ) : (
                              <StyledImportExportIcon/>
                          )}
                        </span>}
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {deposits.map((deposit, index) => (
                      <StyledRowBodyTable
                        key={index}
                        title="Claim Receipt"
                      >
                        <StyledCellTable
                          width={20}
                          title={deposit.companyName}
                        >
                          {deposit.companyName}
                        </StyledCellTable>
                        <StyledCellTable
                          width={25}
                          title={deposit.companyEmail}
                        >
                          {deposit.companyEmail}
                        </StyledCellTable>
                        <StyledCellTable
                          width={20}
                          title={formatterUSD("currency", "USD").format(
                              centToUsd(deposit.depositAmount)
                          )}
                        >
                          {formatterUSD("currency", "USD").format(
                              centToUsd(deposit.depositAmount)
                          )}
                        </StyledCellTable>
                        <StyledCellTable
                          width={20}
                          title={moment(deposit.depositDate).format(FE_DATE_FORMAT)}
                        >
                          {moment(deposit.depositDate).format(FE_DATE_FORMAT)}
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          className="text-right"
                          title="Actions"
                        >
                          <FileActions title="Claim Receipt" url={deposit.url} />
                        </StyledCellTable>
                      </StyledRowBodyTable>
                    ))}
                  </div>
                </StyledTable>
                <div className="text-center mt-4">
                  <label>
                    <Pagination
                      pagerPagination={paging}
                      getPage={handleChangePage}
                      onChangeRowsPerPage={setRowsPerPage}
                    />
                  </label>
                </div>
              </StyledHideForMobile>
            </div>
            <div className="col-12 mt-2">
              <StyledHideForDesktop>
                {deposits.map((deposit, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Claimed By</StyledTitleExpansion>
                        <div className="mt-2">{deposit.companyName}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Deposited By
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {deposit.companyName}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Email
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {deposit.companyEmail}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Amount
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency", "USD").format(
                              centToUsd(deposit.depositAmount)
                          )}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Deposited Date
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {moment(deposit.depositDate).format(FE_DATE_FORMAT)}
                        </div>
                      </div>
                      <div className="w-100">
                        <FileActions title="Claim Receipt" url={deposit.url} />
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={paging}
                        getPage={handleChangePage}
                        onChangeRowsPerPage={setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>)
        }
      </div>
    </>
  );
}

export default DepositReceiptTable;