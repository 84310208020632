import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion
} from "../../../shared/employer-styled";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledCellTable,
  StyledHeaderTable,
  StyledImportExportIcon,
  StyledRowBodyTable,
  StyledTable
} from "../../../shared/table-styled";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {ExpansionPanel} from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {SortTable} from "../../employer/employees/employee-model";
import {
  exportTransactionReport,
  getTransactionReport,
  getTransactionSummarize
} from "../../../../services/employer-report-service";
import {reportRef, SearchParamType} from "../ReportType";
import {TTransactionReport, TTransactionReportSummary} from "./TransactionReportType";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {exportExcel} from "../../../../cores/helpers/export-file/export-file";
import {reportDisplayAmount} from "../../../../cores/helpers/currency/report-display-amount";
import moment from "moment";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {calculatePeriod} from "../report";
import {defaultSearchParam} from "../ReportModel";

type Props = {
  columns: SortTable[];
  sortTable: (columnId: string) => void;
  changePage: (page: number) => void;
  paging: PagingInfo;
  setPaging: (value: any) => void;
  setRowsPerPage: (event: any) => void;
  searchParams: SearchParamType;
  setSummarizedList: (value: any) => void;
  period: string;
}

const TransactionReport = forwardRef( (props: Props, ref: Ref<reportRef>) => {
  const [transactionList, setTransactionList] = useState<TTransactionReport[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  useEffect(() => {
    if (props.searchParams.from && props.searchParams.to)
      getReportData().then();
  }, [JSON.stringify(props.searchParams)])

  async function getReportData() {
    addLoading();
    if (isFirstLoad) {
      const firstLoadSearchParams = calculatePeriod(props.period, defaultSearchParam, props.searchParams.from, props.searchParams.to)
      await getTransactionSummarizeData(firstLoadSearchParams);
      await getTransactionReportData(firstLoadSearchParams);
      setIsFirstLoad(false)
    } else {
      await getTransactionSummarizeData(props.searchParams);
      await getTransactionReportData(props.searchParams);
    }
    removeLoading();
  }

  async function getTransactionReportData(searchParams: SearchParamType) {
    try {
      const result = await getTransactionReport(searchParams);
      setTransactionList(result.data.records);
      let pagingInfo = setPagingInfo(
        searchParams.page,
        result.data.pageCount,
        searchParams.perPage,
      )
      props.setPaging(pagingInfo);
    } catch (e) {
      console.log(e)
    }
  }

  async function getTransactionSummarizeData(searchParams: SearchParamType) {
    try {
      const result: TTransactionReportSummary = (await getTransactionSummarize(searchParams)).data;
      props.setSummarizedList([
        {
          title: 'Opening Balance',
          balance: result ? reportDisplayAmount(result.openingBalance) : "--"
        },
        {
          title: 'Total Transferred Claim',
          balance: result ? reportDisplayAmount(Math.abs(result.transferredClaim)) : "--"
        },
        {
          title: 'Total Deposit',
          balance: result ? reportDisplayAmount(Math.abs(result.totalDeposit)) : "--"
        },
        {
          title: 'Total Withdraw',
          balance: result ? reportDisplayAmount(Math.abs(result.totalWithdraw)) : "--"
        },
        {
          title: 'Ending Balance',
          balance: result ? reportDisplayAmount(result.endingBalance) : "--"
        },
      ])
    } catch (e) {
      console.log(e)
    }
  }

  useImperativeHandle(ref, () => ({
    exportReport,
  }))

  function exportReport() {
    exportTransactionReport(props.searchParams).then(
      (result) => {
        exportExcel(result.data);
      }
    )
  }

  return (
    <div className="p-0 mt-4">
      {transactionList.length === 0 ?
        <div className="text-center text-danger">There was no record in this report</div> :
        (<div className="row">
          <div className="col-12 mt-2">
            <StyledHideForMobile>
              <StyledTable>
                <StyledHeaderTable>
                  {props.columns.map((column, index) => (
                    <StyledCellTable
                      width={column.width}
                      key={index}
                      className="cur"
                      onClick={() => props.sortTable(column.columnId)}
                    >
                      {column.columnName}
                      <span>
                            {column.sortType ? (
                              column.sortType === "ASC" ? (
                                <StyledArrowDropDownIcon/>
                              ) : (
                                <StyledArrowDropUpIcon/>
                              )
                            ) : (
                              <StyledImportExportIcon/>
                            )}
                          </span>
                    </StyledCellTable>
                  ))}
                </StyledHeaderTable>
                <div>
                  {transactionList.map((transaction, index) => (
                    <StyledRowBodyTable
                      key={index}
                      title={transaction.title}
                    >
                      <StyledCellTable width={20}>
                        {transaction.title}
                      </StyledCellTable>
                      <StyledCellTable
                        width={25}
                        title={transaction.description}
                      >
                        {transaction.description}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={formatterUSD("currency", "USD").format(
                          centToUsd(transaction.totalAmount)
                        )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(transaction.totalAmount)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={20}
                        title={transaction.transactionType}
                      >
                        {transaction.transactionType}
                      </StyledCellTable>
                      <StyledCellTable
                        width={20}
                        title={moment(transaction.createdDate).format(FE_DATE_FORMAT)}
                      >
                        {moment(transaction.createdDate).format(FE_DATE_FORMAT)}
                      </StyledCellTable>
                    </StyledRowBodyTable>
                  ))}
                </div>
              </StyledTable>
              <div className="text-center mt-4">
                <label>
                  <Pagination
                    pagerPagination={props.paging}
                    getPage={props.changePage}
                    onChangeRowsPerPage={props.setRowsPerPage}
                  />
                </label>
              </div>
            </StyledHideForMobile>
          </div>
          <div className="col-12 mt-2">
            <StyledHideForDesktop>
              {transactionList.map((transaction, index) => (
                <ExpansionPanel
                  square
                  defaultExpanded={false}
                  className="mt-2"
                  key={index}
                >
                  <StyledExpansionPanelSummary
                    className="m-0"
                    expandIcon={<ArrowDropUp/>}
                  >
                    <div className="w-100 ">
                      <StyledTitleExpansion>Title</StyledTitleExpansion>
                      <div className="mt-2">{transaction.title}</div>
                    </div>
                  </StyledExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <div className="w-100">
                      <StyledTitleExpansion>
                        Description
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {transaction.description}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Total Amount
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(transaction.totalAmount)
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Transaction Type
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {transaction.transactionType}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Paid Date
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {moment(transaction.createdDate).format(FE_DATE_FORMAT)}
                      </div>
                    </div>
                  </StyledExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                      pagerPagination={props.paging}
                      getPage={props.changePage}
                      onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </StyledHideForDesktop>
          </div>
        </div>)
      }
    </div>
  );
})

export default TransactionReport;