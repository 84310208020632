import {SortTable} from "../../employer/employees/employee-model";

export const columnsPayroll: SortTable[] = [
  {
    columnId: "reportMonth",
    columnName: "Approved Period",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "employeeName",
    columnName: "Employee",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "type",
    columnName: "Type",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "paidTotal",
    columnName: "Total Claim",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "numberOfClaim",
    columnName: "Number Of Claim",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
];