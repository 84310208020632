import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import RequestAQuote from "./components/pages/request-a-quote/request-a-quote";
import EmployerRouteLayout from "./components/shared/employer-layout/employer-route-layout";
import EmployeeRouteLayout from "./components/shared/employee-layout/employee-route-layout";
import SignUp from "./components/pages/sign-up/sign-up";
import SignIn from "./components/pages/sign-in/sign-in";
import EmployerSetting from "./components/pages/employer/employer-setting/employer-setting";
import EmployeesEmployer from "./components/pages/employer/employees/employees";
import Verification from "./components/pages/verify-account/verify-account";
import { Message } from "./cores/utils/message";
import { ThemeProvider } from "styled-components";
import Theme from "../src/cores/helpers/theme";
import PlanSetup from "./components/pages/employer/setup-wizard/plan/plan";
import EmployerBenefitsSetup from "./components/pages/employer/setup-wizard/employer-classes/employer-classes";
import FundingSetup from "./components/pages/employer/setup-wizard/funding/funding";
import EmployerAgreementSetup from "./components/pages/employer/setup-wizard/agreement/agreement";
import CompanyInfoSetup from "./components/pages/employer/setup-wizard/company-information/company-information";
import EmployerBenefits from "./components/pages/employer/employer-classes/employer-classes";
import PlanSetting from "./components/pages/employer/plan/plan";
import { StyledApp } from "./components/shared/employer-styled";
import { Loading } from "./cores/utils/loading";
import ForgotPassword from "./components/pages/forgot-password/forgot-password";
import UpdatePassword from "./components/pages/forgot-password/update-password";
import Documents from "./components/pages/documents/documents";
import Report from "./components/pages/report/report";
import MyWallet from "./components/pages/employer/wallet/wallet";
import Support from "./components/pages/support/support";
import Privacy from "./components/pages/privacy/privacy";
import TermsOfServices from "./components/pages/terms-of-services/terms-of-services";
import Unsubcribe from "./components/pages/unsubcribe/unsubcribe";
import TerminateEmployer from "./components/pages/terminate-employer/terminate-employer";
import { googleAPIKey, hubSpotChatboxSrc } from "./cores/config/config";
import {
  homePage,
  signInURL,
  signUpURL,
  forgotPasswordURL,
  updatePasswordURL,
  verificationURL,
  unsubcribeURL,
  benefitURL,
  enrollmentCoverageURL,
  settingURL,
  employeeURL,
  documentsURL,
  reportsUrl,
  companyAccountURL,
  privacyURL,
  supportURL,
  termsOfServicesURL,
  claimURL,
  setupCompanyURL,
  setupPlanURL,
  setupBenefitURL,
  setupBankingURL,
  setupServicesAgreementURL,
  contactURL,
  reAllocateURL,
  terminateEmployerURL,
  employeeDetailUrl,
  marketplaceURL,
  marketplaceSearchURL,
  marketplaceDetailURL
} from "./cores/constants/url-config";
import Contact from "./components/pages/contact/contact";
import ReAllocateClassBenefit from "./components/pages/employer/re-allocate-class-benefit/re-allocate-class-benefit";
import {
  getEmployerConfigurationInfo
} from "./cores/utils/helpers";
import { EmployerConfiguration } from "./models/employer-configuration/employer-configuration-model"
import DetailEmployee from "./components/pages/employer/employees/detail-employee/detail-employee";
import Marketplace from "./components/pages/marketplace/marketplace";
import MarketplaceSearch from "./components/pages/marketplace/marketplace-search";
import VendorDetail from "./components/pages/marketplace/detail/vendor-detail";

const App: React.FC = () => {

  const [employerConfiguration, setEmployerConfiguration] = useState<EmployerConfiguration>({
    enablePresetBankingInformation: false
  });

  useEffect(() => {
    // Start of  google api script
    const googleApi = document.createElement("script");

    googleApi.type = "text/javascript";
    googleApi.src = `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`;
    googleApi.async = true;
    document.body.appendChild(googleApi);
    // End of  google api script

    // Start of  Zendesk Widget script
    // const zenDesk = document.createElement("script");

    // zenDesk.type = "text/javascript";
    // zenDesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskAPIKey}`;
    // zenDesk.async = true;
    // zenDesk.id = "ze-snippet";
    // document.body.appendChild(zenDesk);
    // End of  Zendesk Widget script

    const hotJar = document.createElement("script");
    if (process.env.NODE_ENV === "production") {
      hotJar.append("(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3482614,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');");
      document.head.appendChild(hotJar);
    }

    // <!-- Start of HubSpot Embed Code -->
    const hubSpot = document.createElement("script");

    hubSpot.type = "text/javascript";
    hubSpot.id = "hs-script-loader";
    hubSpot.src = `${hubSpotChatboxSrc}`;
    hubSpot.async = true;
    hubSpot.defer = true;
    document.body.appendChild(hubSpot);
    // <!-- End of HubSpot Embed Code -->

    getEmployerConfigurationInfo()
    .then((result => {
      setEmployerConfiguration({
        enablePresetBankingInformation: result.enablePresetBankingInformation
      })
    }))

    return () => {
      document.body.removeChild(googleApi);
      //document.body.removeChild(zenDesk);
      document.head.removeChild(hotJar)
      document.body.removeChild(hubSpot);
    };
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <StyledApp className="App">
        <Loading />
        <Message />
        <BrowserRouter>
          <Switch>
            <EmployeeRouteLayout
              exact
              path={homePage}
              component={SignUp}
            />

            <EmployeeRouteLayout path={signInURL} component={SignIn} />
            <EmployeeRouteLayout path={signUpURL} component={SignUp} />
            <EmployeeRouteLayout
              path={forgotPasswordURL}
              component={ForgotPassword}
            />
            <EmployeeRouteLayout
              path={updatePasswordURL}
              component={UpdatePassword}
            />

            <EmployeeRouteLayout
              path={verificationURL}
              component={Verification}
            />
            <EmployeeRouteLayout path={unsubcribeURL} component={Unsubcribe} />
            <EmployeeRouteLayout path={terminateEmployerURL} component={TerminateEmployer} />
            <EmployerRouteLayout
              path={benefitURL}
              component={EmployerBenefits}
            />
            <EmployerRouteLayout
              path={enrollmentCoverageURL}
              component={PlanSetting}
            />

            <EmployerRouteLayout
              path={settingURL}
              component={EmployerSetting}
              {...employerConfiguration}
            />
            <EmployerRouteLayout
                path={employeeDetailUrl}
                component={DetailEmployee}
            />
            <EmployerRouteLayout
              path={employeeURL}
              component={EmployeesEmployer}
            />
            <EmployerRouteLayout path={documentsURL} component={Documents} />
            <EmployerRouteLayout path={reportsUrl} component={Report} />
            <EmployerRouteLayout
              path={companyAccountURL}
              component={MyWallet}
              {...employerConfiguration}
            />

            <EmployerRouteLayout
              exact
              path={marketplaceURL}
              component={Marketplace}
            />

            <EmployerRouteLayout
              path={marketplaceSearchURL}
              component={MarketplaceSearch}
            />

            <EmployerRouteLayout
              path={marketplaceDetailURL}
              component={VendorDetail}
            />
            <EmployerRouteLayout path={privacyURL} component={Privacy} />
            <EmployerRouteLayout path={supportURL} component={Support} />
            <EmployerRouteLayout
              path={termsOfServicesURL}
              component={TermsOfServices}
            />
            <EmployerRouteLayout path={contactURL} component={Contact} />
            <EmployerRouteLayout path={reAllocateURL} component={ReAllocateClassBenefit} />
            <EmployerRouteLayout
              path={setupCompanyURL}
              component={CompanyInfoSetup}
              {...employerConfiguration}
            />
            <EmployerRouteLayout 
              path={setupPlanURL} 
              component={PlanSetup} 
              {...employerConfiguration}
            />
            <EmployerRouteLayout
              path={setupBenefitURL}
              component={EmployerBenefitsSetup}
              {...employerConfiguration}
            />
            <EmployerRouteLayout
              path={setupBankingURL}
              component={FundingSetup}
              {...employerConfiguration}
            />
            <EmployerRouteLayout
              path={setupServicesAgreementURL}
              component={EmployerAgreementSetup}
              {...employerConfiguration}
            />
            {/* end setup */}

            <Redirect to={homePage} />
          </Switch>
        </BrowserRouter>
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
