import React, {useEffect, useMemo} from "react";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import {Option, StyledOption} from "../select/select";
import styled from "styled-components";
import {BNPInput} from "./input";
import useDebounce from "../../hook/useDebounce";
import SearchIcon from "@material-ui/icons/Search";

const StyledMultiSelect = styled(Select)`
    font-family: ${(props) => props.theme.textFont} !important;
    .MuiSelect-select {
        border-radius: 5px;
    }
    .MuiInputBase-input {
        padding: 5px 0 6px;
    }
    .MuiSelect-nativeInput {
        padding: 5px 0 6px;
    }
    .MuiSelect-select.Mui-disabled {
        background: #f3f6f8;
    }
    & .MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
        background-color: transparent;
    }
`;

const StyledSelectCheckBox = styled(Checkbox)`
    &.MuiCheckbox-colorSecondary.Mui-checked {
        color: ${(props) => props.theme.primaryColor};
    }

    line-height: 21px;
`;

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 21px;
  right: 24px;
  opacity: 0.4;
`;

type Props = {
  value: any[];
  name: string;
  onChange: (event: any) => void;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  tabIndex?: number;
  width?: string;
  allowAll: boolean;
  clearAll?: boolean;
};

export const MultiSelectGeneralOptions = {
  ALL: {id: 'all', name: 'All'},
  ALL_SELECTED: {id: 'all_selected', name: 'All Selected'},
}

export const BNPMultiSelect = (props: Props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const debounceSearchTerm = useDebounce(searchTerm, 300);

  const allOptionIds = useMemo(() => props.options.map((option) => option.id), [props.options]);
  const filteredOption = useMemo(() => props.options.filter(
    (option) => option.id !== MultiSelectGeneralOptions.ALL.id &&
      option.name.toString().toLowerCase().includes(debounceSearchTerm.toLowerCase())
  ), [props.options, debounceSearchTerm]);

  useEffect(() => {
    setSearchTerm("");
  }, [props.options]);

  const handleChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchTerm(event.target.value);
  }

  const renderValue = (selected: any) => {
    if (selected.includes(MultiSelectGeneralOptions.ALL.id)) {
      const defaultOption = props.options.find((option) => option.id === MultiSelectGeneralOptions.ALL.id);
      return <StyledOption>
        {defaultOption ? defaultOption.name : props.placeholder}
      </StyledOption>;
    }
    const renderValue = props.options
      .filter((option) => selected.includes(option.id))
      .map((option) => option.name)
      .join(", ");

    return <StyledOption>
      {renderValue || props.placeholder}
    </StyledOption>;
  }

  const handleOnChange = (event: any) => {
    let {value} = event.target;
    value = value.filter((val: any) => val !== -1 && val !== MultiSelectGeneralOptions.ALL_SELECTED.id);

    if (value.includes(MultiSelectGeneralOptions.ALL.id)) {
      setSearchTerm("");
      props.onChange({target: {value: allOptionIds}});
    } else {
      props.onChange({target: {value: value}});
    }
  }

  return (
    <FormControl>
      <StyledMultiSelect
        labelId={`${props.name}-multi-select-label`}
        name={props.name}
        multiple
        value={props.value}
        onChange={handleOnChange}
        renderValue={renderValue}
        displayEmpty
        disabled={props.disabled}
        hidden={props.hidden}
        className={props.className ? props.className : ""}
        inputProps={{tabIndex: props.tabIndex ? props.tabIndex : 0}}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: props.width ? props.width : "350px",
              marginTop: "8px"
            }
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          autoFocus: false
        }}
      >
        {props.allowAll && (
          <MenuItem value={MultiSelectGeneralOptions.ALL.id} key={`${props.name}-multi-select-all`}>
            <StyledSelectCheckBox
              checked={props.value.includes(MultiSelectGeneralOptions.ALL.id)}
            />
            <StyledOption>All</StyledOption>
          </MenuItem>
        )}
        {props.value.length > 0 && props.clearAll && (
          <MenuItem value="clear" key={`${props.name}-multi-select-clear`}>
            <StyledOption>Clear All</StyledOption>
          </MenuItem>
        )}
        <MenuItem value={-1}
                  key={`${props.name}-multi-select-search-input`}
                  selected={false}
                  onKeyDown={(e) => e.stopPropagation()}
        >
          <BNPInput
            name={"multi-select-search-" + props.name}
            placeholder={"Search"}
            onChange={handleChangeSearchInput}
            value={searchTerm}
            onClick={(e: any) => e.stopPropagation()}
          />
          <StyledIconSearch/>
        </MenuItem>
        {filteredOption.map((option) => (
          <MenuItem value={option.id} key={`${props.name}-menu-item-${option.id}`}>
            <StyledSelectCheckBox
              checked={props.value.includes(option.id)}
            />
            <StyledOption>{option.name}</StyledOption>
          </MenuItem>
        ))}
      </StyledMultiSelect>
    </FormControl>
  )
};
