import { SortTable } from "../employees/employee-model";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";

export const statusTransaction = [
  {
    id: "DEBIT",
    name: "Debit",
  },
  {
    id: "CREDIT",
    name: "Credit",
  },
];

export const claimType = [
  {
    id: "HSA",
    name: "HSA",
  },
  {
    id: "CHARITY",
    name: "Charity",
  },
  {
    id: "RRSP",
    name: "RRSP",
  },
  {
    id: "WELLNESS",
    name: "Wellness",
  },
  {
    id: "INSURANCE",
    name: "Insurance",
  },
  {
    id: "GROUP_INSURANCE",
    name: "Group Insurance",
  },
];

export const columnsTransactions: TableSortType[] =[
  {
    columnId: "txnTitle",
    columnName: "Title",
    sortType: null,
    width: 16,
    sortColumn: true,
  },
  {
    columnId: "txnDescription",
    columnName: "Description",
    sortType: null,
    width: 28,
    sortColumn: true,
  },
  {
    columnId: "clearedAmount",
    columnName: "cleared Amount",
    sortType: null,
    width: 14,
    sortColumn: true,
  },
  {
    columnId: "pendingAmount",
    columnName: "pending Amount",
    sortType: null,
    width: 14,
    sortColumn: true,
  },
  {
    columnId: "transactionType",
    columnName: "transaction Type",
    sortType: null,
    width: 14,
    sortColumn: true,
  },
  {
    columnId: "createdDate",
    columnName: "created Date",
    sortType: null,
    width: 14,
    sortColumn: true,
  },
];

export const columnsClaims: TableSortType[] =[
  {
    columnId: "claimId",
    columnName: "Claim ID",
    sortType: null,
    width: 10,
    sortColumn: true,
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 10,
    sortColumn: false,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 12,
    sortColumn: true,
  },
  {
    columnId: "employeeName",
    columnName: "Employee Name",
    sortType: null,
    width: 12,
    sortColumn: true,
  },
  {
    columnId: "claimTotal",
    columnName: "Claim Total",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "adminFee",
    columnName: "Admin Fee",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "gst",
    columnName: "GST",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "pst",
    columnName: "HST",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "rst",
    columnName: "RST",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "premiumTax",
    columnName: "Premium Tax",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "total",
    columnName: "Total",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
  {
    columnId: "submittedDate",
    columnName: "Submitted Date",
    sortType: null,
    width: 13,
    sortColumn: true,
  },
];
