import { Employee } from "../../../components/pages/employer/employees/employee-type";
import { validateEmail } from "../../config/config";
import { validateDate } from "./validate-date-time";
import { FE_DATE_FORMAT, shortDateFormat } from "../format/date-time-format";
import { findDuplicatesInArray } from "../helpers";
import { validFullNameLength } from "./validate-employee";
import {
  EmployeeManualSetupType
} from "../../../components/pages/employer/employees/add-employee-modal/upload-in-bulk/upload-in-bulk";

const genderList = ["MALE", "FEMALE"];

export const validateForm = (
  dataStringLines: string[],
  eligibleTime: number | null,
  isManualBenefitSetting: boolean
) => {
  const headers = dataStringLines[0].split(",");
  if (!validateTemplateHeader(headers, isManualBenefitSetting)) {
    return [{ row: 1, error: "Wrong template. Please check again" }];
  }

  if (dataStringLines.length < 2) {
    return [{ row: 1, error: "No employee added" }];
  }

  const duplicatedEmailList = findDuplicatesInArray(
    dataStringLines.map((item: any) => item.split(",")[3])
  );
  if (duplicatedEmailList.length > 0) {
    return [
      { row: 0, error: `Email duplicate: ${duplicatedEmailList.join(", ")}` },
    ];
  }

  if (eligibleTime === null) {
    return [{ row: 0, error: "Please set up eligible time before continue" }];
  }
};

export const validateParsedEmployee = (employee: EmployeeManualSetupType, isManualBenefitSetting: boolean): string[] => {
  const error: string[] = [];
  const {
    email,
    firstName,
    lastName,
    code,
    roleIds,
    classId,
    eligibilityDate,
    hiringDate,
  } = employee;

  const isMissingRoleIds = roleIds.length === 0;
  //Validate email
  if (!email) error.push("Missing Email Address");
  if (email && !email.match(validateEmail)) error.push("Invalid Email Address");
  //Validate missing required field
  if (!firstName) error.push("Missing First Name");
  if (!lastName) error.push("Missing Last Name");
  if (!code) error.push("Missing Employee ID");
  if (!hiringDate) error.push("Missing Hiring Date");
  if (isMissingRoleIds) error.push("Missing Role");
  if (!classId) error.push("Missing Class");
  if (!eligibilityDate) error.push("Missing Eligibility Date");
  if (!validFullNameLength(firstName, lastName)) error.push("Full name length could not be larger than 30 letters")
  //Validate invalid date
  if (
    hiringDate &&
    !validateDate(hiringDate, shortDateFormat) &&
    !validateDate(hiringDate, FE_DATE_FORMAT)
  )
    error.push("Invalid Hiring Date");
  if (eligibilityDate && hiringDate && new Date(eligibilityDate) < new Date(hiringDate))
    error.push(`Eligibility Date (${new Date(eligibilityDate)}) must be after Hiring Date (${new Date(hiringDate)}})`);
  //Validate invalid fields
  if (!isMissingRoleIds && roleIds.some((item) => item < 1))
    error.push("Invalid Roles");
  if (classId && classId <= 0) error.push("Invalid Class name");

  if (isManualBenefitSetting) {
    const {
      hsaAllocated,
      hsaClaimed,
      wsaAllocated,
      wsaClaimed,
      insuranceAllocated,
      insuranceClaimed,
      charityAllocated,
      charityClaimed,
      rrspAllocated,
      rrspClaimed,
    } = employee;

    if (typeof hsaAllocated !== "undefined" && isNaN(hsaAllocated))
      error.push("Invalid HSA Allocated");
    if (typeof hsaClaimed !== "undefined" && isNaN(hsaClaimed))
      error.push("Invalid HSA Claimed");
    if (typeof wsaAllocated !== "undefined" && isNaN(wsaAllocated))
      error.push("Invalid WSA Allocated");
    if (typeof wsaClaimed !== "undefined" && isNaN(wsaClaimed))
      error.push("Invalid WSA Claimed");
    if (typeof insuranceAllocated !== "undefined" && isNaN(insuranceAllocated))
      error.push("Invalid Insurance Allocated");
    if (typeof insuranceClaimed !== "undefined" && isNaN(insuranceClaimed))
      error.push("Invalid Insurance Claimed");
    if (typeof charityAllocated !== "undefined" && isNaN(charityAllocated))
      error.push("Invalid Charity Allocated");
    if (typeof charityClaimed !== "undefined" && isNaN(charityClaimed))
      error.push("Invalid Charity Claimed");
    if (typeof rrspAllocated !== "undefined" && isNaN(rrspAllocated))
      error.push("Invalid RRSP Allocated");
    if (typeof rrspClaimed !== "undefined" && isNaN(rrspClaimed))
      error.push("Invalid RRSP Claimed");
  }
  return error;
};

export const validateTemplateHeader = (header: string[], isManualBenefitSetting: boolean): boolean => {
  return (
    header.length > 6 &&
    header[0].includes("Employee ID") &&
    header[1].includes("First Name") &&
    header[2].includes("Last Name") &&
    header[3].includes("Email Address") &&
    header[4].includes("Hiring Date") &&
    header[5].includes("Role") &&
    header[6].includes("Class") &&
    (isManualBenefitSetting
      ? header[7].includes("HSA Allocated") ||
        header[8].includes("HSA Claimed") ||
        header[9].includes("WSA Allocated") ||
        header[10].includes("WSA Claimed") ||
        header[11].includes("Insurance Allocated") ||
        header[12].includes("Insurance Claimed") ||
        header[13].includes("Charity Allocated") ||
        header[14].includes("Charity Claimed") ||
        header[15].includes("RRSP Allocated") ||
        header[16].includes("RRSP Claimed") ||
        header[17].includes("Eligibility Date")
      : true)
  );
};
