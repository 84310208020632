import React, {useEffect, useState} from 'react';
import {
  StyledBigTitle,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion
} from "../../../shared/employer-styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledCellTable,
  StyledHeaderTable, StyledImportExportIcon,
  StyledRowBodyTable,
  StyledTable
} from "../../../shared/table-styled";
import moment from "moment";
import {shortDateFormat, BE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {ExpansionPanel} from "@material-ui/core";
import {ArrowDropUp} from "@material-ui/icons";
import {columnsClaimReceipt} from "../DocumentModel";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {Receipt} from "../document-type";
import FileActions from "./FileActions";
import {SearchParamType} from "../../report/ReportType";
import {ClaimTypes, defaultPagingInfo, defaultSearchParam, ReportPeriod, ReportType} from "../../report/ReportModel";
import {SortTable} from "../../employer/employees/employee-model";
import {getAllClaimReceiptAsZip, getClaimReceipt} from "../../../../services/employer-document-service";
import {exportZip} from "../../../../cores/helpers/export-file/export-file";
import {calculatePeriod, StyledDivExport} from "../../report/report";
import {TableFilterAndSearch} from "../../../shared/BNPTable/TableFilterAndSearch";

function ClaimReceiptTable() {
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [searchParam, setSearchParam] = useState<SearchParamType>(calculatePeriod(ReportPeriod.custom.type, defaultSearchParam, null, null));
  const [paging, setPaging] = useState<PagingInfo>(defaultPagingInfo);
  const [column, setColumn] = useState<SortTable[]>(columnsClaimReceipt);

  useEffect(() => {
    getReceiptsData().then();
  }, [JSON.stringify(searchParam)])

  const getReceiptsData = async () => {
    addLoading();
    let resultReceipts = await getClaimReceipt(searchParam);
    setReceipts(resultReceipts.data ? resultReceipts.data.records : []);
    let pagingInfo = setPagingInfo(
        searchParam.page,
        resultReceipts.data.pageCount,
        searchParam.perPage,
    )
    setPaging(pagingInfo);
    removeLoading()
  }

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.page = 1;
    searchParamBuffer.perPage = Number(value);

    setSearchParam(searchParamBuffer);
  };

  const handleChangePage = (page: number) => {
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);
    searchParamClone.page = page;

    setSearchParam(searchParamClone);
  };

  function handleChangeDate(date: Date | null, name: string | null) {
    const searchParamClone = Object.assign({}, searchParam)
    if (name === "from") {
      searchParamClone.from = date && moment(date.toString()).format(BE_DATE_FORMAT);
    } else if (name === "to") {
      searchParamClone.to = date && moment(date.toString()).format(BE_DATE_FORMAT);
    }
    setSearchParam(searchParamClone);
  }

  function sortTable (columnId: string) {
    let columnsHeaderTable: SortTable[] = [...column];

    let searchParamBuffer: SearchParamType = Object.assign({}, searchParam);
    searchParamBuffer.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParamBuffer.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    setColumn(columnsHeaderTable);
    setSearchParam(searchParamBuffer);
  }

  async function handleExportReceipt() {
    addLoading();
    const res = await getAllClaimReceiptAsZip(searchParam);
    if (res.status === 200) {
      exportZip(res.data, 'claim');
    }
    removeLoading();
  }

  function handleChangeFilterType(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);

    searchParamClone.filter = Number(value) !== -1 ? value : null;
    searchParamClone.page = 1;

    setSearchParam(searchParamClone);
  }

  const searchNameOrEmail = (searchKey: string) => {
    let searchParamClone: SearchParamType = Object.assign({}, searchParam);

    searchParamClone.searchName = searchKey;
    searchParamClone.page = 1;

    setSearchParam(searchParamClone);
  };

  return (
    <>
      <div className="row d-flex flex-row mt-5 mb-5">
        <StyledBigTitle className="col-10">Claim Receipts</StyledBigTitle>
        {receipts.length > 0 && <div className="col-2 d-flex flex-row justify-content-end align-items-center">
          <StyledDivExport className={"w-100"} onClick={handleExportReceipt}>
            Export
          </StyledDivExport>
        </div>}
      </div>
      <div className="p-0 mt-4">
        <TableFilterAndSearch tableType={ReportType.CLAIM.type}
                              searchParam={searchParam}
                              filterTypes={ClaimTypes}
                              handleChangeFilter={handleChangeFilterType}
                              handleChangeDate={handleChangeDate}
                              handleSearch={searchNameOrEmail} />
        {receipts.length === 0 ?
          <div className="text-center text-danger mt-5">There was no receipt!</div> :
          (<div className="row">
            <div className="col-12 mt-2">

              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {columnsClaimReceipt.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className={"cur"}
                        onClick={() => column.sortColumn && sortTable(column.columnId)}
                      >
                        {column.columnName}
                        {column.sortColumn && <span>
                          {column.sortType ? (
                              column.sortType === "ASC" ? (
                                  <StyledArrowDropDownIcon/>
                              ) : (
                                  <StyledArrowDropUpIcon/>
                              )
                          ) : (
                              <StyledImportExportIcon/>
                          )}
                        </span>}
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {receipts.map((receipt, index) => (
                      <StyledRowBodyTable
                        key={index}
                        title="Claim Receipt"
                      >
                        <StyledCellTable
                          width={20}
                          title={receipt.fullName}
                        >
                          {receipt.fullName}
                        </StyledCellTable>
                        <StyledCellTable
                          width={25}
                          title={receipt.email}
                        >
                          {receipt.email}
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          title={receipt.claimType}
                        >
                          {receipt.claimType}
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          title={formatterUSD("currency", "USD").format(
                            centToUsd(receipt.claimAmount)
                          )}
                        >
                          {formatterUSD("currency", "USD").format(
                            centToUsd(receipt.claimAmount)
                          )}
                        </StyledCellTable>
                        <StyledCellTable
                          width={12}
                          title={moment(receipt.submittedDate).format(shortDateFormat)}
                        >
                          {moment(receipt.submittedDate).format(shortDateFormat)}
                        </StyledCellTable>
                        <StyledCellTable
                          width={13}
                          className="text-right"
                          title="Actions"
                        >
                          <FileActions title="Claim Receipt" url={receipt.url} />
                        </StyledCellTable>
                      </StyledRowBodyTable>
                    ))}
                  </div>
                </StyledTable>
                <div className="text-center mt-4">
                  <label>
                    <Pagination
                      pagerPagination={paging}
                      getPage={handleChangePage}
                      onChangeRowsPerPage={setRowsPerPage}
                    />
                  </label>
                </div>
              </StyledHideForMobile>
            </div>
            <div className="col-12 mt-2">
              <StyledHideForDesktop>
                {receipts.map((receipt, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Claimed By</StyledTitleExpansion>
                        <div className="mt-2">{receipt.fullName}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Claimed By
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {receipt.fullName}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Email
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {receipt.email}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Claim Type
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {receipt.claimType}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Claim Amount
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency", "USD").format(
                            centToUsd(receipt.claimAmount)
                          )}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Claimed Date
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {moment(receipt.submittedDate).format(shortDateFormat)}
                        </div>
                      </div>
                      <div className="w-100">
                        <FileActions title="Claim Receipt" url={receipt.url} />
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={paging}
                        getPage={handleChangePage}
                        onChangeRowsPerPage={setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>)
        }
      </div>
    </>
  );
}

export default ClaimReceiptTable;