import React, {Fragment} from "react";
import {Modal} from "@material-ui/core";
import {
  StyledButtonCloseModal,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal
} from "../employer-styled";
import styled from "styled-components";

type ModalType = {
  modalsize?: "sm" | "md" | "lg";
}

const StyledModal = styled(Modal)<ModalType>`
  max-width: ${props => !!props.modalsize ?
          (props.modalsize === "sm" ? "400px" :
                  props.modalsize === "md" ? "600px" :
                          "800px") :
          "1000px"};;
  margin: 1.75rem auto;
  position: relative;
`;

const StyledModalBody = styled.div`
  min-height: 300px;
  max-height: 80vh;
  overflow-y: auto;
`;

type BNPModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  body: React.ReactElement;
  footer?: React.ReactElement;
  modalSize?: "sm" | "md" | "lg";
};

export function BNPModal(props: BNPModalProps) {
  return (
    <Fragment>
      <StyledModal open={props.open} onClose={props.handleClose}>
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>{props.title}</StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={props.handleClose}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <StyledModalBody className="modal-body">
            {props.body}
          </StyledModalBody>
          {!!props.footer && <div className="modal-footer">
            {props.footer}
          </div>}
        </div>
      </StyledModal>
    </Fragment>
  );
}
