import React from "react";
import { CheckBox } from "../checkbox/checkbox";
import styled from "styled-components";
import { IOSSwitch } from "../switch/ios-switch";
import { MedicalTravelData } from "../../../components/pages/request-a-quote/request-a-quote-type";
import {GroupInsuranceDefault, MedicalAndTravel} from "./medical-and-travel-coverage-type";
import { StyledContentBox } from "../../../components/shared/employer-styled";

const StyledDivTitle = styled.div`
  font-size: 14px;
  font-weight: bolder;
  line-height: 26px;
`;

const StyledNote = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  font-style: italic;
  margin-top: 10px;
`;
const StyledDivSwitch = styled.div`

`;

const StyledDivCheckbox = styled.div`
  width: max-content;
  span {
    top: 0
  }
`;

const StyledDivSelectQuoteGroup = styled.div`
  justify-content: space-between;
`

const StyledLiCalculationTypeName = styled.li`
  margin: 5px;
`

const StyledAutoProductTitle = styled.li`
  display: flex;
  align-items: baseline;
`

const StyledPolicyDetailsLink = styled.a`
  padding-left: 15px;
  color: red !important;
  :hover {
    text-decoration: none;
    opacity: 0.5;
  }
`

const POLICY_DETAILS_LINKS = {
  majorMedical: {
    name: "Major Medical",
    link: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Employer+-+Major+Medical.pdf"
  },
  travel: { 
    name: "Travel",
    link: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Employer+-+Travel+Insurance.pdf"
  }
}

type Props = {
  isChecked: boolean;
  medicalTravels: MedicalAndTravel[];
  medicalTravelData: MedicalTravelData[];
  onSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  groupInsuranceDefault: GroupInsuranceDefault[];
  groupInsuranceQuoteRequest: number[];
  isSetup?: boolean;
  isRenewal?: boolean;
};

export default function MedicalAndTravelCoverage(props: Props) {

  return (
    <StyledContentBox isBorder={props.isSetup ? true : false} style={{height: "auto"}}>
      <div className="d-flex mb-3">
        <StyledDivTitle>
          Subscribe to Major Medical And Travel insurance
        </StyledDivTitle>
      </div>
      {groupInsuranceItems(props, "Auto")}
      <StyledDivSelectQuoteGroup className="d-flex mb-3 mt-3">
        <StyledDivTitle>
          Request a quote for Life (Life, AD&D, Dependent Life), Long Term Disability and Critical Illness Insurance
        </StyledDivTitle>
        <StyledDivSwitch>
          <IOSSwitch
              checked={props.isChecked}
              onChange={props.onSwitchChange}
          />
        </StyledDivSwitch>
      </StyledDivSelectQuoteGroup>
      {props.isChecked && (
        <>
          {groupInsuranceItems(props, "Manual")}
          <StyledNote>
            If you've opted for quotes on Life, Long Term Disability, or Critical Illness insurance, we'll contact you via email.
          </StyledNote>
        </>
      )}
    </StyledContentBox>
  );
}

function groupInsuranceItems(props: Props, requestType: "Auto" | "Manual") {
  return props.groupInsuranceDefault
      .filter(item => item.approveType.toLowerCase() === requestType.toLowerCase())
      .map((groupInsurance, idx) => {
    const checked = props.groupInsuranceQuoteRequest.some(v => groupInsurance.productId === v);
    return (
        <div className="mt-2" key={`key-${groupInsurance.productName}-${idx}`}>
          <StyledAutoProductTitle>
            <StyledDivCheckbox>
              <CheckBox
                  title={groupInsurance.productNameForDisplayQuote}
                  checked={checked}
                  name={`groupInsurance-${groupInsurance.productId}`}
                  onChange={props.onCheckboxChange}
                  disabled={!props.isRenewal && groupInsurance.status === "Disabled"}
                  classLabel={(!props.isRenewal && groupInsurance.status === "Disabled") ? "checkbox-disable" : ""}
              />
            </StyledDivCheckbox>
            {"Auto" == requestType && 
              <StyledPolicyDetailsLink href={getPolicyDetailsLink(groupInsurance.productName)} target="_blank">
                <i>Click here for policy details</i>
              </StyledPolicyDetailsLink>
            }
          </StyledAutoProductTitle>  
          {"Auto" == requestType && 
            <ul>
              {groupInsurance.autoProductCalculationTypeNames.map((item, i) => {
                return (
                <StyledLiCalculationTypeName key={i}>
                  {item}
                </StyledLiCalculationTypeName>
                )
              })}
            </ul>
          }
        </div>
    )
  });
}

function getPolicyDetailsLink(productName: string) {
  
  if (!productName || !productName.trim()) return '';
  
  for (const [key, value] of Object.entries(POLICY_DETAILS_LINKS)) {
    if (productName.toLowerCase().includes(value.name.toLowerCase())) {
      return value.link;
    }
  }

  return '';

}
