import { TableSortDataType } from "../../../shared/BNPTable/BNPTableModel";
import { SortTable } from "../../employer/employees/employee-model";



export const groupInsuranceDetailReportColumns: SortTable[] = [
  {
    columnId: "employeeName",
    columnName: "Employee",
    sortType: null,
    width: 20,
    sortColumn: true
  },
  {
    columnId: "paidDate",
    columnName: "Paid Date",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "taxibility",
    columnName: "Taxibility",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "claimAmount",
    columnName: "Claim Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "adminFee",
    columnName: "Admin Fee",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "gst",
    columnName: "GST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "hst",
    columnName: "HST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "rst",
    columnName: "RST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "premiumTax",
    columnName: "Premium Tax",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "totalAmount",
    columnName: "Total Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
];

export const groupInsuranceSummaryReportColumns: SortTable[] = [
  {
    columnId: "companyName",
    columnName: "Company",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "insuranceCoverageType",
    columnName: "Insurance Coverage",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "premiumCoveragePeriod",
    columnName: "Premium Coverage Period",
    sortType: null,
    width: 20,
    sortColumn: true,
    type: TableSortDataType.MONTH,
  },
  {
    columnId: "withdrawalDate",
    columnName: "Withdrawal Date",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.DATE,
  },
  {
    columnId: "premiumAmount",
    columnName: "Premium Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.CURRENCY,
  },
  {
    columnId: "premiumTax",
    columnName: "Premium Tax",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.CURRENCY,
  },
  {
    columnId: "totalPremiumAmount",
    columnName: "Total Premium Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.CURRENCY,
  },
];