import React from "react";
import { Checkbox } from "../all-type";
import styled from "styled-components";

const StyledSpanTitle = styled.span`
  position: relative;
  top: -4px;
`;

const StyledCheckBox = styled.label`
  input:checked ~ .check-mark {
    background-color: ${(props) => props.theme.primaryColor};
  }

  margin-bottom: 0px !important;
  margin-top: 10px !important;
  line-height: 21px;
`;

export const CheckBox = (props: Checkbox) => (
  <StyledCheckBox
    className={
      "container-checkbox " + (props.classLabel ? props.classLabel : "")
    }
  >
    <StyledSpanTitle className={props.classSpan ? props.classSpan : ""}>
      {props.title}
    </StyledSpanTitle>
    <input
      disabled={props.disabled}
      type="checkbox"
      className={props.classInput}
      checked={props.checked}
      name={props.name}
      onChange={props.onChange}
      defaultChecked={props.defaultChecked}
      value={props.value}
      tabIndex={-1}
      role="application"
      aria-hidden="true"
    />
    <span className="check-mark"></span>
  </StyledCheckBox>
);
