import styled, { css } from "styled-components";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Link } from "react-router-dom";

export const StyledTable = styled.div`
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  color: #212529;
`;
export const StyledHeaderTable = styled.div`
  display: flex;
  border-bottom: none;
  opacity: 0.4;
  color: ${(props) => props.theme.primaryColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 11px;
  text-transform: uppercase;
`;

export const StyledRowBodyTable = styled.div`
  display: flex;
  width: 100%;
  border-radius: 3px;
  background-color: #f3f6f8;
  border: 1px solid #fff;

  :hover {
    background-color: #e8edf2;
  }
`;
export const StyledSpanIconClose = styled.span`
  position: relative;
  top: 12px;
  left: 1%;
  cursor: pointer;
  width: 0;
`;
export const StyledImgIconClose = styled.img`
  margin-left: 0.25rem;
  width: 16px;
  height: 16px;
`;

export const StyledCellTable = styled.div`
  padding: 1rem 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  :last-child {
    text-align: right;
  }

  ${(props: { width: number }) =>
    props.width &&
    css`
      width: ${props.width + "%"};
    `}
`;

export const StyledImportExportIcon = styled(ImportExportIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;
export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;
export const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
`;
export const StyledLinkBodyTable = styled(Link)`
  display: flex;
  width: 100%;
  border-radius: 3px;
  background-color: #f3f6f8;
  border: 1px solid #fff;
  color: #2d2d2d;

  :hover {
    background-color: #e8edf2;
    text-decoration: none;
    color: #2d2d2d;
  }
`;

export const StyledBodyTable = styled.div`
  display: flex;
  width: 100%;
  border-radius: 3px;
  background-color: #f3f6f8;
  border: 1px solid #fff;
  line-height: 21px;
  :hover {
    background-color: #e8edf2;
    text-decoration: none;
  }
`;
