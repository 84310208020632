import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../../../cores/helpers/theme";

const StyledSpanTitle = styled.span`
  padding-left: 0.5em;
  padding-top: 1em;
`;

const StyledDiv = styled.div`
  width: 3%;
  height: 50px;
  ${(props: { bgColor: string }) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

const StyledNavItem = styled("li")`
  .nav-link {
    color: ${theme => theme.theme.sideBarTextColor};
    opacity: 0.5;
    font-size: 14px;
    line-height: 22px;
    height: 50px;
    padding: 1em 0 1em 1em;
  }
  
  .nav-link:hover {
    color: ${theme => theme.theme.sideBarTextColor};
    background-color: ${theme => theme.theme.sideBarColor[300]};
    opacity: 1;
  }
  
  &.active .nav-link {
    color: ${theme => theme.theme.sideBarTextColor};
    background-color: ${theme => theme.theme.sideBarColor[300]};
    opacity: 1;
  }
`

const StyledLink = styled(Link)`
  width: 97%;
`;

const StyledDivLink = styled.div`
  width: max-content;

  :hover:not(.active) {
    transform: translateX(0.5rem);
  }
`;

const StyledATag = styled.a`
  width: 97%;
`;

const EmployerNavItem = ({
  isActive,
  to,
  label,
  iconClass,
  handleDrawerToggle,
}: any) => {
  let classes = ["nav-item", "d-flex"];
  if (isActive) classes.push("active");

  return (
    <StyledNavItem className={classes.join(" ")} onClick={handleDrawerToggle}>
      <StyledDiv
        bgColor={isActive ? theme.secondaryColor : "transparent"}
      ></StyledDiv>
      <StyledLink
        onClick={(e) => {
          isActive && e.preventDefault();
        }}
        className="nav-link"
        to={to}
      >
        <StyledDivLink>
          {iconClass}
          <StyledSpanTitle>{label}</StyledSpanTitle>
        </StyledDivLink>
      </StyledLink>
    </StyledNavItem>
  );
};

const EmployerNavItemToAnotherWebsite = ({
  to,
  label,
  iconClass,
  handleDrawerToggle,
}: any) => {
  let classes = ["nav-item", "d-flex"];

  return (
    <StyledNavItem className={classes.join(" ")} onClick={handleDrawerToggle}>
      <StyledDiv bgColor={"transparent"} />
      <StyledATag href={to} target="_blank" className="nav-link">
        <StyledDivLink>
          {iconClass}
        <StyledSpanTitle>{label}</StyledSpanTitle>
        </StyledDivLink>
      </StyledATag>
    </StyledNavItem>
  );
};

export default withRouter(({ location, goToAnotherWebsite, ...props }: any) => {

  if (goToAnotherWebsite) {
    return <EmployerNavItemToAnotherWebsite {...props} />;
   }

  const isActive = location.pathname === props.to.split("?")[0];

  return <EmployerNavItem {...props} isActive={isActive} />;
});
