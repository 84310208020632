import React, {useEffect, useState} from "react";
import {claimType, columnsClaims} from "./wallet-model";
import BNPTable from "../../../shared/BNPTable/BNPTable";
import {TableSearchParamType} from "../../../shared/BNPTable/BNPTableType";
import {defaultTablePagingInfo, defaultTableSearchParam} from "../../../shared/BNPTable/BNPTableModel";
import {setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {PagingInfo} from "../../../../../AdminPortal/src/cores/helpers/pagination/pagination";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import moment from "moment";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {getExportISSPendingClaimsWithTaxes, getISSPendingClaimsWithTaxes} from "../../../../services/employer-report-service";
import { exportExcel } from "../../../../cores/helpers/export-file/export-file";


export const WalletClaimPendingTable = React.memo(function WalletTransactionTable(props) {
    const [issPendingClaims, setIssPendingClaims] = useState<any[]>([]);
    const [searchParam, setSearchParam] = useState<TableSearchParamType>(Object.assign({}, defaultTableSearchParam));
    const [paging, setPaging] = useState<PagingInfo>(defaultTablePagingInfo);

    useEffect(() => {
        getISSPendingClaims().then();
    }, [
        searchParam.page,
        searchParam.page,
        searchParam.columnName,
        searchParam.sortType,
        searchParam.from,
        searchParam.to,
        searchParam.filter,
        searchParam.searchKey
    ])

    async function getISSPendingClaims() {
        addLoading();
        const resultISSClaims = await getISSPendingClaimsWithTaxes(searchParam);

        const pagingInfo = setPagingInfo(
            searchParam.page,
            resultISSClaims.data.pageCount,
            searchParam.perPage
        );

        setIssPendingClaims(processClaimData(resultISSClaims.data.records))
        setPaging(pagingInfo)
        removeLoading();
    }

    const handleDownloadEmployerPendingClaims = () => {
        getExportISSPendingClaimsWithTaxes(searchParam).then(
        (result) => {
            exportExcel(result.data);
        }
        );
    }

    const processClaimData = (claim: any[]) => {
        return claim.map(c => {
            const {adminFee, claimTotal, gst, pst, rst, premiumTax, total, submittedDate} = c;
            return {
                ...c,
                adminFee: formatterUSD("currency", "USD").format(centToUsd(adminFee)),
                claimTotal: formatterUSD("currency", "USD").format(centToUsd(claimTotal)),
                gst: formatterUSD("currency", "USD").format(centToUsd(gst)),
                pst: formatterUSD("currency", "USD").format(centToUsd(pst)),
                rst: formatterUSD("currency", "USD").format(centToUsd(rst)),
                premiumTax: formatterUSD("currency", "USD").format(centToUsd(premiumTax)),
                total: formatterUSD("currency", "USD").format(centToUsd(total)),
                submittedDate: submittedDate ? moment(submittedDate).format(FE_DATE_FORMAT) : "",
            }
        })
    }

    return <BNPTable tableName="Pending Claims"
                     searchParam={searchParam}
                     setSearchParam={setSearchParam}
                     columns={columnsClaims}
                     tableWidth="1550px"
                     tableData={issPendingClaims}
                     pagingInfo={paging}
                     haveFilterByDate={true}
                     filterType={claimType}
                     filterPlaceHolder="Claim Type"
                     handleDownload={handleDownloadEmployerPendingClaims}
                     haveSearch={true}/>
})