import React, { Component } from "react";
import styled, { css } from "styled-components";
import {
  StyledBtnLink,
  StyledButtonSubmit,
  StyledLargerTitle,
  StyledPTitle,
} from "../../../../shared/employer-styled";
import {
  getChequeInfo,
  getPaymentMethod,
  getUserRole,
  postFileToS3,
  putBankingInfo,
} from "../../../../../services/employer-setting-service";
import theme from "../../../../../cores/helpers/theme";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import SetUpWizardView from "../setup";
import { RouteChildrenProps } from "react-router";
import OCRCheque from "../../../../../cores/helpers/ocr-cheque/ocr-cheque";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import { Agreement } from "../agreement/agreement-type";
import { getAgreementBankingInfo } from "../../../../../services/employer-dashboard-services";
import { ChequeInfo } from "../../funding/funding-type";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getFileUrl } from "../../../../../services/file-service";
import { toUTCTime } from "../../../../../cores/helpers/to-utc-time";
import Dropzone from "react-dropzone";
import ImgStack from "../../../../../cores/config/component/images/img-stack.svg";
import { publishMessage } from "../../../../../cores/utils/message";
import Cheque from "../../../../../cores/config/component/images/cheque.jpg";
import {
  showTermsAndConditionsModal,
  TermsAndConditionsModal,
} from "../../../../../cores/helpers/terms-and-conditions/terms-and-conditions";
import {
  setupBenefitURL,
  setupServicesAgreementURL,
  setupCompanyURL
} from "../../../../../cores/constants/url-config";
import { convertTimeZone } from "../../../../../cores/utils/helpers";
import { PdfViewer } from "../../../../shared/pdf-viewer";
import { EmployerConfiguration } from "../../../../../models/employer-configuration/employer-configuration-model";

const StyledMenuItem = styled.div`
  border: 1px solid #f2f2f2 !important;
  border-radius: 5px !important;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);
  }
  padding: 4px 16px;
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-weight: 400;
  line-height: 1.75;
  white-space: nowrap;
  letter-spacing: 0.00938em;

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      border-color: ${theme.primaryColor + "!important"};
    `}
`;

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #fff;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
    background-color: #fff;
  }
`;

const StyledDivBoxCheque = styled.div`
  background-color: #f4f5f9;
  padding: 21px 17px;
  border-radius: 5px;
  margin-top: 1.5rem;
`;

const StyledImgCheque = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const StyledDivHiddenForMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px !important;
`;

const StyledDivBox = styled.div``;

type Props = RouteChildrenProps & EmployerConfiguration;
type State = {
  paymentMethod: number;
  chequeInfo: ChequeInfo;
  isOCRCheckingCompleted: boolean;
  agreementData: Agreement[];
  fileUrl: string;
  employerSetupUserRole: string;
};

export default class FundingSetup extends Component<Props, State> {
  state: State = {
    paymentMethod: -1,
    agreementData: [],
    chequeInfo: {
      voidCheque: "",
      eSignature: "",
      authorizedEmail: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      date: toUTCTime(new Date()),
    },
    isOCRCheckingCompleted: false,
    fileUrl: "",
    employerSetupUserRole: "",
  };

  componentDidUpdate() {
    if (this.props.enablePresetBankingInformation) {
      this.props.history.push(setupCompanyURL);
    }
  }

  async componentDidMount() {
    //add loading
    addLoading();

    let result = await this.getData();
    if (result.chequeInfo.voidCheque) {
      this.getFileUrl(result.chequeInfo.voidCheque);
    }
    await this.getEmployerSetupUserRole();
    //Auto set payment method = 2
    this.setState({ paymentMethod: result.paymentMethod === -1 ? 2 : result.paymentMethod })

    //remove loading
    removeLoading();

    this.setState({
      chequeInfo: result.chequeInfo,
      agreementData: result.agreementData,
    });
  }

  async getEmployerSetupUserRole() {
    const employerSetupUserRole = await getUserRole();

    this.setState({
      employerSetupUserRole: employerSetupUserRole.data,
    });
  }

  getFileUrl = (url: string) => {
    getFileUrl(url).then((response) => {
      this.setState({ fileUrl: response.data });
    });
  };

  async getData() {
    let resultPaymentMethod = await getPaymentMethod();
    let resultChequeInfo = await getChequeInfo();
    let paymentMethod: number = resultPaymentMethod.data
      ? resultPaymentMethod.data.paymentMethod
      : -1;
    let chequeInfo: ChequeInfo = resultChequeInfo.data || [];
    chequeInfo.date = chequeInfo.date ? chequeInfo.date : new Date();

    let result = await getAgreementBankingInfo();

    let agreement = result.data || [];

    let agreementData = agreement.map((agreement: any) => {
      return {
        id: agreement.id,
        title: agreement.title,
        description: agreement.description,
        checked: chequeInfo.voidCheque ? true : false,
      };
    }) as Agreement[];

    return { paymentMethod, chequeInfo, agreementData };
  }

  handleChangeDate = (date: Date | null, name: string) => {
    let cheque = Object.assign(this.state.chequeInfo);

    cheque[name] = toUTCTime(date);
    this.setState({ chequeInfo: cheque });
  };

  handleChangePaymentMethod = (id: number) => {
    this.setState({ paymentMethod: id });
  };

  handleChangeInputChequeInfo = (event: any) => {
    const { name, value } = event.target;

    let cheque = Object.assign(this.state.chequeInfo);

    if (name !== "eSignature" && name !== "authorizedEmail") {
      if (Number(value) >= 0) {
        cheque[name] = value;
      }
    } else {
      cheque[name] = value;
    }

    this.setState({ chequeInfo: cheque });
  };
  handleChangeCheckboxChequeInfo = (event: any) => {
    const { name, checked } = event.target;
    let agreementData = Object.assign(this.state.agreementData);

    let index = agreementData.findIndex((agreement: any) => {
      return agreement.id === Number(name);
    });
    agreementData[index].checked = checked;
    this.setState({ agreementData: agreementData });
  };

  handleChangeVoidCheque = (urlImage: string) => {
    let cheque: ChequeInfo = Object.assign(this.state.chequeInfo);
    if (urlImage) {
      cheque.voidCheque = urlImage;
    }

    this.getFileUrl(urlImage);

    this.setState({ chequeInfo: cheque });
  };

  handleOCRCheque = (urlBlob: string) => {
    OCRCheque(urlBlob, "../../tessdata", (res: any) => {
      if (!res.error) {
        const newCheque: ChequeInfo = { ...this.state.chequeInfo };
        newCheque.transitNumber = res.numbers.transit;
        newCheque.bankNumber = res.numbers.institution;
        newCheque.accountNumber = res.numbers.account;

        this.setState({ chequeInfo: newCheque });
      }
    });
  };

  handleSubmitChequeInfo = () => {
    if (this.state.paymentMethod === 2) {
      let chequeInfo: ChequeInfo = Object.assign({}, this.state.chequeInfo);

      chequeInfo.agreements = this.state.agreementData.map((agreement) => {
        return agreement.id;
      });
      if (chequeInfo.date)
        chequeInfo.date = toUTCTime(new Date(chequeInfo.date));
      if (chequeInfo.authorizedEmail === "" || this.state.employerSetupUserRole === "owner") {
        chequeInfo.authorizedEmail = null;
      }

      const model = {
        paymentMethod: this.state.paymentMethod,
        chequeInfo: chequeInfo
      }

      putBankingInfo(model).then(() => {
        this.props.history.push(setupServicesAgreementURL);
      });
    } else {
      this.props.history.push(setupServicesAgreementURL);
    }
  };

  validate = () => {
    let cheque = this.state.chequeInfo;

    let isValid = true;

    this.state.agreementData.forEach((agreement) => {
      if (agreement.checked === false) {
        isValid = false;
      }
    });

    if (
      cheque.accountNumber &&
      cheque.bankNumber &&
      cheque.eSignature &&
      cheque.transitNumber &&
      cheque.voidCheque &&
      isValid
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let isSubmit = false;

    if (this.state.paymentMethod > -1 && this.state.paymentMethod < 2) {
      isSubmit = true;
    } else if (this.state.paymentMethod === 2) {
      let isValid = this.validate();
      isSubmit = isValid;
    }

    let $termsAndConditions = document.getElementById("termsAndConditions");

    if ($termsAndConditions) {
      $termsAndConditions.removeEventListener(
        "click",
        showTermsAndConditionsModal
      );
      $termsAndConditions.addEventListener(
        "click",
        showTermsAndConditionsModal
      );
    }

    return (
      <SetUpWizardView
        children={
          <StyledDivBox>
            <div>
              <StyledLargerTitle>Funding</StyledLargerTitle>
            </div>
            <hr />
            <div className="mt-3">
              <div className="row mt-4 mb-3">
                <div className="col-lg-12">
                  {/*<div>How do you want to process payment?</div>*/}

                  {/*<div className="row">*/}
                  {/*  <div className="col-lg-4 mt-4">*/}
                  {/*    <StyledMenuItem*/}
                  {/*      active={false}*/}
                  {/*      //onClick={() => this.handleChangePaymentMethod(0)}*/}
                  {/*    >*/}
                  {/*      <div className="p-2  op-0-5">*/}
                  {/*        <EmailIcon className="mr-2" /> Email Transfer*/}
                  {/*      </div>*/}
                  {/*    </StyledMenuItem>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-lg-4 mt-4">*/}
                  {/*    <StyledMenuItem*/}
                  {/*      active={false}*/}
                  {/*      //onClick={() => this.handleChangePaymentMethod(1)}*/}
                  {/*    >*/}
                  {/*      <div className="p-2 op-0-5">*/}
                  {/*        <PublicIcon className="mr-2" /> Online Bill Pay*/}
                  {/*      </div>*/}
                  {/*    </StyledMenuItem>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-lg-4 mt-4">*/}
                  {/*    <StyledMenuItem*/}
                  {/*      active={this.state.paymentMethod === 2 ? true : false}*/}
                  {/*      onClick={() => this.handleChangePaymentMethod(2)}*/}
                  {/*    >*/}
                  {/*      <div className="p-2">*/}
                  {/*        <AccountBalanceIcon className="mr-2" />*/}
                  {/*        Cheque (PAD) agreement*/}
                  {/*      </div>*/}
                  {/*    </StyledMenuItem>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {this.state.paymentMethod === 2 && (
                    <StyledDivBoxCheque>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="col-lg-12 mt-4">
                            <StyledPTitle className="mb-2">
                              Void Cheque
                            </StyledPTitle>
                            <div className="mt-2">
                              <ImageUpload
                                addFile={this.handleChangeVoidCheque}
                                addBlob={this.handleOCRCheque}
                                urlImage={this.state.fileUrl}
                                folderName="Employer/Cheque"
                                isOCRCheckingCompleted={
                                  this.state.isOCRCheckingCompleted
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="row">
                            <div className="col-lg-6 mt-4">
                              <StyledPTitle className="mb-2">
                                Institution Number
                              </StyledPTitle>

                              <BNPInput
                                maxLength={3}
                                className="mt-2"
                                value={this.state.chequeInfo.bankNumber}
                                onChange={this.handleChangeInputChequeInfo}
                                name="bankNumber"
                                tabIndex={1}
                              />
                            </div>
                            <div className="col-lg-6 mt-4">
                              <StyledPTitle className="mb-2">
                                Transit Number
                              </StyledPTitle>
                              <BNPInput
                                maxLength={5}
                                className="mt-2"
                                value={this.state.chequeInfo.transitNumber}
                                onChange={this.handleChangeInputChequeInfo}
                                name="transitNumber"
                                tabIndex={2}
                              />
                            </div>
                            <div className="col-lg-6 mt-4">
                              <StyledPTitle className="mb-2">
                                Account Number
                              </StyledPTitle>
                              <BNPInput
                                maxLength={12}
                                className="mt-2"
                                value={this.state.chequeInfo.accountNumber}
                                onChange={this.handleChangeInputChequeInfo}
                                name="accountNumber"
                                tabIndex={3}
                              />
                            </div>
                            {this.state.employerSetupUserRole != "owner" && (
                              <div className="col-lg-6 mt-4">
                                <StyledPTitle className="mb-2">
                                  Authorized Signature Email
                                </StyledPTitle>
                                <BNPInput
                                  className="mt-2"
                                  value={
                                    this.state.chequeInfo.authorizedEmail || ""
                                  }
                                  onChange={this.handleChangeInputChequeInfo}
                                  name="authorizedEmail"
                                  tabIndex={3}
                                />
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-lg-6 mt-4">
                              <StyledPTitle className="mb-2">
                                {this.state.employerSetupUserRole != "owner"
                                  ? "Authorized Signature"
                                  : "E-signature"}
                                <StyledSpan>
                                  {" "}
                                  (Please type name to accept)
                                </StyledSpan>
                              </StyledPTitle>
                              <BNPInput
                                name="eSignature"
                                className="mt-2"
                                value={this.state.chequeInfo.eSignature}
                                onChange={this.handleChangeInputChequeInfo}
                                tabIndex={4}
                              />
                            </div>
                            <div className="col-lg-6 mt-4">
                              <StyledPTitle className="mb-2">Date</StyledPTitle>
                              <StyledDivDatePicker>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    autoOk
                                    disableFuture
                                    InputAdornmentProps={{ position: "start" }}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline1"
                                    placeholder="Date"
                                    name="date"
                                    value={
                                      this.state.chequeInfo.date
                                        ? convertTimeZone(
                                            "Canada/Eastern",
                                            this.state.chequeInfo.date
                                          )
                                        : ""
                                    }
                                    onChange={(date) =>
                                      this.handleChangeDate(date, "date")
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    tabIndex={5}
                                    role="application"
                                    aria-hidden="true"
                                  />
                                </MuiPickersUtilsProvider>
                              </StyledDivDatePicker>
                            </div>

                            <div className="col-12 mt-3 text-right">
                              <label>
                                <StyledImgCheque src={Cheque} alt="cheque" />
                              </label>
                            </div>
                          </div>
                        </div>

                        {this.state.agreementData.map((agreement, index) => (
                          <div className="col-lg-12 mt-3">
                            <CheckBox
                              key={index}
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: agreement.title,
                                  }}
                                />
                              }
                              checked={
                                agreement.checked ? agreement.checked : false
                              }
                              name={agreement.id.toString()}
                              onChange={this.handleChangeCheckboxChequeInfo}
                            />
                          </div>
                        ))}
                      </div>
                    </StyledDivBoxCheque>
                  )}

                  <div className="row mt-5">
                    <StyledDivHiddenForMobile className="col-lg-2">
                      <StyledBtnLink
                        to={setupBenefitURL}
                        background={theme.secondaryColor}
                      >
                        Back
                      </StyledBtnLink>
                    </StyledDivHiddenForMobile>

                    <div className="col-lg-7"></div>
                    <div className="col-lg-3">
                      <StyledButtonSubmit
                        submit={isSubmit}
                        disabled={!isSubmit}
                        onClick={this.handleSubmitChequeInfo}
                        type="submit"
                        tabIndex={6}
                      >
                        Save & Continue
                      </StyledButtonSubmit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TermsAndConditionsModal />
          </StyledDivBox>
        }
      />
    );
  }
}

type StyledButtonProps = {
  border: string;
};

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  height: 254px;
  margin-top: 0;
  border-radius: 3px;

  ${(props: StyledButtonProps) =>
    !props.border &&
    css`
      border: 1px dashed #ced4da;
    `}
`;

const StyledDivDefaultUpload = styled.div`
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
`;
const StyledIcon = styled.img`
  position: relative;
  left: 46%;
`;
const StyledDivDragAndDrop = styled.div`
  margin-top: 15px;
  opacity: 0.5;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;
const StyledDivYourFileHereOrBr = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 15px;
`;

const StyledImgReview = styled.img`
  max-height: 150px;
  max-width: 300px;
`;

const StyledAside = styled.aside`
  text-align: center;
  position: relative;
  top: 50px;
`;

type ImageUploadProps = {
  addFile: (urlImage: string) => void;
  addBlob: (urlBlob: string) => void;
  urlImage: string;
  folderName: string;
  isOCRCheckingCompleted: boolean;
};

// for profile picture
function ImageUpload(props: ImageUploadProps) {
  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);

  const onDrop = (accepted: any, rejected: any) => {
    if (accepted.length !== 0) {
      if (accepted[0].size < 10485760) {
        handleChangeImage(accepted);
      } else {
        publishMessage({
          variant: "error",
          message:
            "This image cannot be uploaded, because it exceeds the maximum file size for uploading.",
        });
      }
    } else {
      publishMessage({
        variant: "error",
        message: "Please submit valid file type!",
      });
    }
  };

  React.useEffect(() => {
    if (props.urlImage.match("/*.pdf")) {
      setIsPdfFile(true);
    } else {
      setIsPdfFile(false);
    }
  }, [props.urlImage]);

  const handleChangeImage = (files: any[]) => {
    if (files.length) {
      // FormData image to save
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append(
        "folderName",
        props.folderName ? props.folderName : "Images"
      );
      addLoading();

      props.addBlob(files[0].preview);

      postFileToS3(formData)
        .then((result) => {
          props.addFile(result.data);
        })
        .finally(() => {
          removeLoading();
        });
    }
  };

  const { urlImage: voidCheque } = props;

  const thumbs = <StyledImgReview src={voidCheque} alt="profile" />;

  const render = voidCheque ? (
    isPdfFile ? (
      <div className="text-center">
        <PdfViewer fileUrl={voidCheque} height="200px" />
      </div>
    ) : (
      <StyledAside>{thumbs}</StyledAside>
    )
  ) : (
    <StyledDivDefaultUpload>
      <StyledIcon src={ImgStack} alt="img-stack" />
      <StyledDivDragAndDrop>Drag & drop</StyledDivDragAndDrop>
      <StyledDivYourFileHereOrBr>
        Your file here or Browse to upload.
      </StyledDivYourFileHereOrBr>
      <StyledDivYourFileHereOrBr>
        Acceptable Files Types - JPG, PNG up to 10MB.
      </StyledDivYourFileHereOrBr>
    </StyledDivDefaultUpload>
  );

  return (
    <div>
      <StyledDropZone
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        border={voidCheque}
        multiple={false}
        accept="image/jpeg, image/png, application/pdf"
        tabIndex={-1}
        role="application"
        aria-hidden="true"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
          // for drag and drop warning statement
          if (isDragReject) return "Please submit a valid file";
          return render;
        }}
      </StyledDropZone>
    </div>
  );
}
