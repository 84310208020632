export const COOKIE_PATH = "/";

export function setCookie(
    cookieName: string,
    cookieValue: string,
    expriedAt: Date = new Date(new Date().setHours(new Date().getHours() + 8)),
    cookiePath = COOKIE_PATH
) {
    document.cookie = `${cookieName}=${cookieValue}; expires=${expriedAt}; path=${cookiePath}`;
}

export function removeCookie(cookieName: string, cookiePath = COOKIE_PATH) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${cookiePath}`;
}

export function getCookie(cookieName: string): string | null {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}