import React, {useEffect, useMemo, useState} from "react";
import CreateIcon from "@material-ui/icons/Create";
import styled, { css } from "styled-components";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledCell,
  StyledSpanLargerTitle,
  StyledSpanStatus,
} from "../../../../shared/employer-styled";
import theme from "../../../../../cores/helpers/theme";
import BNPDatePicker from "../../../../../cores/helpers/date-picker/date-picker";
import { formatterUSD } from "../../../../../cores/helpers/format-usd";
import Pagination, {
  PagingInfo,
} from "../../../../../cores/helpers/pagingation/pagination" 
import { BNPSelect } from "../../../../../cores/helpers/select/select";
import {
  EmployeeInfo,
  Balance,
  Transactions,
  WalletSearchParams,
  PendingAmountByCategory,
  PendingAmount
} from "./detail-employee-type";
import {Class} from "./detail-employee-model";
import { capFirst } from "../../../../../cores/helpers/cap-first";
import { formatPhoneNumber } from "../../../../../cores/helpers/format-phone-number";
import { centToUsd } from "../../../../../cores/helpers/cent-to-usd";
import { getLimitBalance } from "./detail-employee";
import { convertTimeZone, findEmployeeStatusNameById } from "../../../../../cores/utils/helpers";
import {FE_DATE_FORMAT, FE_DETAIL_DATE_FORMAT} from "../../../../../cores/utils/format/date-time-format";
import {WalletBenefit} from "../../../../shared/wallet-benefit/WalletBenefit";
import {statusTransaction} from "../../wallet/wallet-model";
import {ClaimData, SearchParamClaim, StatusClaim} from "../../../claim-management/claim-management-type";
import {employeeStatus, Status} from "../employee-model";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";

const StyledSpanEditInfo = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: right;
  cursor: pointer;
  font-family: ${(props) => props.theme.textFont};
  :hover {
    opacity: 0.7;
  }
  position: relative;
  top: 6px;
`;

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
`;
const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  ${(props: { color: string }) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;
const StyledLine = styled.div`
  padding: 5px;
  display: flex;
`;

type Props = {
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimsData: ClaimData[];
  claimsColumn: TableSortType[];
  transactionsColumn: TableSortType[];

  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  findStatusNameById: (statusId: string) => string;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;

  // employee info
  employee: EmployeeInfo;
  classes: Class[];
  statuses: Status[];
  roles: Status[];
  signUpStatuses: Status[];
  onboardingDate: Date | string;

  findClassById: (classId: number) => string;
  findSignUpStatusById: (signUpStatusId: string) => string;
  findStatusesById: (statusId: string) => string;
  findRoleById: (roleId: string) => string;
  findEnrollmentNameById: (id: number) => string;
  setRowsPerPage: (event: any) => void;

  // wallet
  availableAmount: number;
  balance: Balance[];
  pendingClaimAmountByCategory: PendingAmountByCategory;
  pendingAmount: number;

  pagingInfoWallet: PagingInfo;
  changePageWallet: (page: number) => void;
  setRowsPerPageWallet: (event: any) => void;

  transactions: Transactions[];
  searchParamWallet: WalletSearchParams;
  changeFilterWallet: (event: any) => void;
  modelFactory: () => void;

  handleOpenEditEmployeeModal: () => void;
};

export default function CompanyManagementEmployeeDetailView(props: Props) {
  const [walletUtilization, setWalletUtilization] = useState(0);

  useEffect(() => {
    const utilization = calculateWalletUtilization();
    setWalletUtilization(utilization);
  }, [props.employee.benefitCoverages]);

  const widthTitle = "40%";
  const widthParam = "60%";

  const profileMemo = useMemo(() => ([
    {
      title: "Employee ID",
      value: props.employee.code || "---",
    },
    {
      title: "First name",
      value: props.employee.firstName || "---",
    },
    {
      title: "Last name",
      value: props.employee.lastName || "---",
    },
    {
      title: "Status",
      value: <StyledSpanStatus title={!props.employee.t4Employee ? employeeStatus[0].id : props.employee.status}>
        {findEmployeeStatusNameById(props.employee.status, props.employee.t4Employee) || "---"}
      </StyledSpanStatus>,
    },
    {
      title: "Gender at Birth",
      value: capFirst(props.employee.gender) || "---",
    },
    {
      title: "Day of birth",
      value: props.employee.dateOfBirth
          ? convertTimeZone("Canada/Eastern", props.employee.dateOfBirth).format(FE_DETAIL_DATE_FORMAT)
          : "---",
    },
    {
      title: "Email",
      value: props.employee.email || "---",
    },
    {
      title: "Phone number",
      value: formatPhoneNumber(props.employee.phoneNumber) || "---",
    },
    {
      title: "Home address",
      value: (
              props.employee.homeAddress +
              (props.employee.cityName ? `, ${props.employee.cityName}` : '') +
              (props.employee.provinceName ? `, ${props.employee.provinceName}` : '') +
              (props.employee.postCode ? `, ${props.employee.postCode}` : '')
          )
          || "---"
    },
    {
      title: "Class",
      value: props.findClassById(props.employee.classId) || "---",
    },
    {
      title: "Position",
      value: props.employee.position || "---",
    },
    {
      title: "Salary",
      value: formatterUSD("currency", "USD").format(
          props.employee.salary / 100
      ) || "---",
    },
    {
      title: "Social insurance number",
      value: props.employee.socialInsuranceNumber || "---",
    },
    {
      title: "Eligibility date",
      value: props.employee.eligibilityDate
          ? convertTimeZone("Canada/Eastern", props.employee.eligibilityDate).format(FE_DETAIL_DATE_FORMAT)
          : "---",
    },
    {
      title: "Hiring date",
      value: props.employee.hiringDate
          ? convertTimeZone("Canada/Eastern", props.employee.hiringDate).format(FE_DETAIL_DATE_FORMAT)
          : "---",
    },
    {
      title: "Role",
      value: props.employee.role
          ? capFirst(props.employee.role[0])
          : "---",
    },
    {
      title: "Benefit cancellation date",
      value: props.employee.benefitCancellationDate
          ? convertTimeZone("Canada/Eastern", props.employee.benefitCancellationDate).format(FE_DETAIL_DATE_FORMAT)
          : "---",
    },
    {
      title: "External base ID",
      value: props.employee.externalBaseId || "---",
    },
    {
      title: "Onboarding date",
      value: props.onboardingDate
          ? convertTimeZone("Canada/Eastern", props.onboardingDate).format(FE_DETAIL_DATE_FORMAT)
          : "---",
    },
    ]), [ props.employee ]);

  const claimsMemo = useMemo(() => {
    return props.claimsData.map((claimData, i) => ({
      employeeName: claimData.employeeName,
      companyName: claimData.companyName,
      totalAmount: claimData.totalAmount ? formatterUSD("currency", "USD").format(claimData.totalAmount/100) : "0",
      submittedDate: claimData.submittedDate ? convertTimeZone("Canada/Eastern", claimData.submittedDate).format(FE_DATE_FORMAT) : "",
      reviewDate: claimData.reviewDate ? convertTimeZone("Canada/Eastern", claimData.reviewDate).format(FE_DATE_FORMAT) : "",
      claimType: claimData.claimType || "",
      claimStatus: props.findStatusNameById(claimData.claimStatus)
    }))
  }, [ props.claimsData ]);

  const transactionsMemo = useMemo(() => (props.transactions.map(txn => ({
    txnTitle: txn.txnTitle,
        txnDescription: txn.txnDescription,
        clearedAmount: formatterUSD("currency", "USD").format(centToUsd(txn.clearedAmount)),
        pendingAmount: formatterUSD("currency", "USD").format(centToUsd(txn.pendingAmount)),
        transactionType: txn.transactionType,
        createdDate: txn.createdDate ? convertTimeZone("Canada/Eastern", txn.createdDate).format(FE_DATE_FORMAT) : "",
  }))), [ props.transactions ])

  const getBalance = () => {
    return {
      available: getTotalAvailableAmount(),
    }
  };

  // Get availble amount of each wallets
  const getAvailableAmount = (proRated: boolean, proRatedAmount: number|undefined, total: number, limit: number) => {
      let availableAmount = 0;
      if (proRated && proRatedAmount !== undefined) {
        const difference = proRatedAmount - (total - limit);
        availableAmount = difference <= 0 ? 0 : Math.abs(difference);
      } else {
        availableAmount = limit <= 0 ? 0 : limit;
      }
      return availableAmount;
  }

  const getTotalAvailableAmount = () => {
    let sum = 0;
    if (!! props.employee.benefitCoverages) {
      props.employee.benefitCoverages.forEach((employeeBenefit) => {
        let limitBalance = getLimitBalance(employeeBenefit.enrollmentId, props.balance)
        const existingPendingAmount = props.pendingClaimAmountByCategory[employeeBenefit.enrollmentId];
        limitBalance = limitBalance - (
          existingPendingAmount ?  (
            existingPendingAmount.pendingApproveClaimAmount + existingPendingAmount.pendingISSClaimAmount
          ) : 0);
        const totalAmount = employeeBenefit.amount + employeeBenefit.rolloverAmount;
        const proratedAmount = employeeBenefit.proRatedAmount + employeeBenefit.rolloverAmount;
        let availableAmount = getAvailableAmount(
          props.employee.prorated,
          proratedAmount,
          totalAmount,
          limitBalance,
        );
        const availableAmountInUSD = centToUsd(availableAmount);
        sum += availableAmountInUSD;
      });
    }
    return sum;
  }

  // Calculate wallet utilization for all wallets
  const calculateWalletUtilization = () => {
    let totalClaimed = 0;
    let totalAllocatedAmount = 0;

    if (!! props.employee.benefitCoverages && !!props.employee.isSetUpDone) {
      props.employee.benefitCoverages.forEach((employeeBenefit) => {
        let limitBalance = getLimitBalance(employeeBenefit.enrollmentId, props.balance)
        const totalAmount = employeeBenefit.amount + employeeBenefit.rolloverAmount;
        const proRatedAmount = employeeBenefit.proRatedAmount + employeeBenefit.rolloverAmount;
        const claimedAmount = calculateClaimedAmount(
          limitBalance,
          totalAmount,
          props.pendingClaimAmountByCategory[employeeBenefit.id]
        );
        let claimedAmountInUsd = centToUsd(claimedAmount);
        let allocatedAmountInUsd = centToUsd(
          props.employee.prorated && proRatedAmount !== undefined ?
            proRatedAmount : totalAmount
        )

        totalClaimed += claimedAmountInUsd;
        totalAllocatedAmount += allocatedAmountInUsd;
      })
    }

    const walletUtilization = totalAllocatedAmount > 0 ? (totalClaimed / totalAllocatedAmount) * 100 : 0;
    return walletUtilization;
  }

  // Calculate claimed amount for a wallet
  const calculateClaimedAmount = (limit: number, total: number, pendingAmountByCategory: PendingAmount): number => {
    // Check if pendingAmountByCategory exists and then access its properties, defaulting to 0 if undefined
    const pendingISSClaimAmount = pendingAmountByCategory && pendingAmountByCategory.pendingISSClaimAmount ? pendingAmountByCategory.pendingISSClaimAmount : 0;
    const pendingApproveClaimAmount = pendingAmountByCategory && pendingAmountByCategory.pendingApproveClaimAmount ? pendingAmountByCategory.pendingApproveClaimAmount : 0;

    return (!limit && limit !== 0) ? 0 : 
      Math.max(total - limit - (pendingISSClaimAmount + pendingApproveClaimAmount), 0);
  }

  let balance = getBalance();
  return (
    <StyledMainContent>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-3 mt-3">
            {props.employee.id && <>
              <StyledBigTitle>
                {props.employee.firstName + " " + props.employee.lastName || ""}
              </StyledBigTitle>
            </>}
          </div>
          <div className="col-lg-3 mt-3">
            <StyledTitleBalance>Wallet Balance</StyledTitleBalance>
            <StyledBalance color={theme.primaryColor}>
              {formatterUSD("currency", "USD").format(
                centToUsd(props.availableAmount)
              )}
            </StyledBalance>
          </div>
          <div className="col-lg-3 mt-3">
            <StyledTitleBalance>Pending Payment</StyledTitleBalance>
            <StyledBalance color={theme.secondaryColor}>
              {formatterUSD("currency", "USD").format(
                centToUsd(Object.values(props.pendingClaimAmountByCategory).map(amount => amount.pendingISSClaimAmount).reduce((total, amount) => total + amount, 0))
              )}
            </StyledBalance>
          </div>
          <div className="col-lg-3 mt-3">
            <StyledTitleBalance>Requested Claims</StyledTitleBalance>
            <StyledBalance color={theme.secondaryColor}>
              {formatterUSD("currency", "USD").format(
                  centToUsd(Object.values(props.pendingClaimAmountByCategory).map(amount => amount.pendingApproveClaimAmount).reduce((total, amount) => total + amount, 0))
              )}
            </StyledBalance>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-6 mt-4">
                <StyledContentBox isBorder={false}>
                  <StyledSpanLargerTitle>
                    Profile Information
                    <StyledSpanEditInfo onClick={() => {
                      props.modelFactory()
                      props.handleOpenEditEmployeeModal()
                    }}>
                      <CreateIcon fontSize="small" />Edit
                    </StyledSpanEditInfo>
                  </StyledSpanLargerTitle>
                  {profileMemo.map((item, index) => (
                    <StyledLine key={index} className={`${index % 2 === 0 ? "striped" : ""} ${index === 0 ? "mt-3" : ""}`}>
                      <StyledCell width={widthTitle} className="fw-bold">
                        {item.title}
                      </StyledCell>
                      <StyledCell width={widthParam}>{item.value}</StyledCell>
                    </StyledLine>
                  ))}
                </StyledContentBox>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="row">
                  <div className="col-12">
                    <StyledContentBox isBorder={false}>
                      <StyledSpanLargerTitle>
                        Benefits Coverage
                        <div className="mt-2">
                          Available amount{": "}
                          {formatterUSD("currency", "USD").format(
                            balance.available
                          )}
                        </div>
                      </StyledSpanLargerTitle>
                      <div className="mt-2">
                        Pending amount:{" "}
                        {formatterUSD("currency", "USD").format(
                          centToUsd(props.pendingAmount)
                        )}
                      </div>
                      <div className="mt-2">
                        Wallet utilization:{" "}
                        {walletUtilization.toFixed(2)} %
                      </div>
                      {!!props.employee.benefitCoverages
                        ? props.employee.benefitCoverages.map((benefitCoverage, index) => {
                          const title = props.findEnrollmentNameById(benefitCoverage.enrollmentId);
                          const limitBalance = getLimitBalance(benefitCoverage.enrollmentId, props.balance);
                          const totalAmount = benefitCoverage.amount + benefitCoverage.rolloverAmount;
                          const proratedAmount = benefitCoverage.proRatedAmount + benefitCoverage.rolloverAmount;
                          return (
                            <WalletBenefit
                                isSetUpDone={props.employee ? props.employee.isSetUpDone : false}
                                key={`wallet-benefits-${index}`}
                                title={title}
                                total={totalAmount}
                                proRatedAmount={proratedAmount}
                                limit={limitBalance}
                                proRated={props.employee.prorated}
                                pendingAmountByCategory={props.pendingClaimAmountByCategory[benefitCoverage.enrollmentId]} />
                          );
                        }
                        ) : ""}
                    </StyledContentBox>
                  </div>

                  {/*<div className="col-12 mt-3">*/}
                  {/*  <StyledContentBox>*/}
                  {/*    <StyledSpanLargerTitle>Dependents</StyledSpanLargerTitle>*/}

                  {/*    {props.employee.dependents*/}
                  {/*      ? props.employee.dependents.map((dependent, index) => (*/}
                  {/*        <div className="mt-3 row" key={index}>*/}
                  {/*          <StyledContent className="col-6">*/}
                  {/*            {dependent.firstName + " " + dependent.lastName}*/}
                  {/*          </StyledContent>*/}
                  {/*          <StyledContent className="col-3">*/}
                  {/*            {dependent.dateOfBirth*/}
                  {/*            ? `${moment().diff(dependent.dateOfBirth, 'years')} YO`*/}
                  {/*            : ""}*/}
                  {/*      </StyledContent>*/}
                  {/*          <StyledContent  className="col-3 text-right">*/}
                  {/*            {findDependentNameById(dependent.relationship)}*/}
                  {/*          </StyledContent>*/}
                  {/*          <hr />*/}
                  {/*        </div>*/}
                  {/*      ))*/}
                  {/*      : ""}*/}
                  {/*      <div className="row">*/}
                  {/*        <div className="col-8"></div>*/}
                  {/*        <div className="col-4">*/}
                  {/*          <StyledAddDependent submit={true}*/}
                  {/*          onClick={showDependentModal}>*/}
                  {/*          Manage Dependent*/}
                  {/*          </StyledAddDependent>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*  </StyledContentBox>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <StyledContentBox isBorder={false}>
              <StyledSpanLargerTitle>Claims</StyledSpanLargerTitle>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-2 mt-4">
                      <BNPDatePicker
                        onChange={props.handleChangeDate}
                        value={props.searchParam.from ?
                          convertTimeZone("Canada/Eastern", props.searchParam.from).format(FE_DETAIL_DATE_FORMAT)
                          :
                          ""
                        }
                        placeholder="From date"
                        name="from"
                      />
                    </div>
                    <div className="col-lg-2 mt-4">
                      <BNPDatePicker
                        value={props.searchParam.to ?
                          convertTimeZone("Canada/Eastern", props.searchParam.to).format(FE_DETAIL_DATE_FORMAT)
                          :
                          ""
                        }
                        onChange={props.handleChangeDate}
                        placeholder="To date"
                        name="to"
                      />
                    </div>
                    <div className="col-lg-2 mt-4">
                      <BNPSelect
                        options={props.statusClaim}
                        value={props.searchParam.status}
                        name="claimStatus"
                        placeholder="Filter by status"
                        onChange={props.handleChangSelect}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <BNPRawTable columns={props.claimsColumn}
                               handleChangeSortColumn={props.sortTable}
                               tableData={claimsMemo}
                               tableWidth='100%' />
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                            pagerPagination={props.pagingInfo}
                            getPage={props.handleChangePage}
                            onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </StyledContentBox>
          </div>

          <div className="col-lg-12 mt-3">
            <StyledContentBox isBorder={false}>
              <StyledSpanLargerTitle>Transaction</StyledSpanLargerTitle>

              <div className="row">
                <div className="col-lg-10 mt-2"></div>
                <div className="col-lg-2 mt-2">
                  <BNPSelect
                      options={statusTransaction}
                      placeholder="Filter by transaction type"
                      name="transactionType"
                      onChange={props.changeFilterWallet}
                      value={props.searchParamWallet.transactionType || -1}
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <BNPRawTable columns={props.transactionsColumn}
                             tableData={transactionsMemo}
                             tableWidth='100%'/>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                          pagerPagination={props.pagingInfoWallet}
                          getPage={props.changePageWallet}
                          onChangeRowsPerPage={props.setRowsPerPageWallet}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </StyledContentBox>
          </div>

        </div>
      </div>
    </StyledMainContent>
  );
}
