import { StatusClaim, Relationship, RejectType } from "./claim-management-type";
import { SortTable } from "../employer/employees/employee-model";

export const statusClaim: StatusClaim[] = [
  {
    id: "OPEN",
    name: "Open",
  },
  {
    id: "REQUESTED",
    name: "Requested",
  },
  {
    id: "APPROVED",
    name: "Approved",
  },
  {
    id: "REJECTED",
    name: "Declined",
  },
];

export const columnsClaims = (): SortTable[] => {
  return [
    {
      columnId: "employeeName",
      columnName: "Employee Name",
      sortType: null,
      width: 25,
      sortColumn: true,
    },
    {
      columnId: "type",
      columnName: "Claim type",
      sortType: null,
      width: 25,
      sortColumn: true,
    },
    {
      columnId: "totalAmount",
      columnName: "Total amount",
      sortType: null,
      width: 16,
      sortColumn: true,
    },
    {
      columnId: "claimedDate",
      columnName: "Submitted Date",
      sortType: null,
      width: 17,
      sortColumn: true,
    },
    {
      columnId: "claimStatus",
      columnName: "Status",
      sortType: null,
      width: 17,
      sortColumn: true,
    },
  ];
};

export const defaultSearchParam = () => {
  return {
    page: 1,
    from: null,
    to: null,
    status: null,
    searchName: null,
    columnName: null,
    sortType: null,
  };
};

export const relationships: Relationship[] = [
  { id: "WIFE", name: "Wife" },
  { id: "HUSBAND", name: "Husband" },
  { id: "PARTNER", name: "Partner" },
  { id: "CHILDREN", name: "Child" },
  { id: "OTHER", name: "Other" },
];

export const rejectOption: RejectType[] = [
  {
    id: "IMAGE_TOO_BLURRY",
    name: "Re-submit Image",
  },
  {
    id: "OVER_BUDGET",
    name: "HSA Limit Reached",
  },
  {
    id: "OTHER",
    name: "Other (Please include comments box)",
  },
];
