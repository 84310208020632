import { Employee } from "./employee-type";

export interface Status {
  id: string;
  name: string;
}

export const employeeStatus: Status[] = [
  {
    id: "WORKING",
    name: "Working",
  },
  {
    id: "TERMINATING",
    name: "Deactivating",
  },
  {
    id: "TERMINATED",
    name: "Inactive",
  },
  {
    id: "SUSPEND",
    name: "Suspended",
  },
];
export const signUpStatus: Status[] = [
  {
    id: "INVITED",
    name: "Invited",
  },
  {
    id: "ACTIVED",
    name: "Active",
  },
  {
    id: "CREATED",
    name: "Created",
  },
];

export type PlanPreferencesFill = {
  id: number;
  value: number;
};

export const planPreferences: PlanPreferencesFill[] = [
  {
    id: 3,
    value: 0,
  },
  {
    id: 4,
    value: 30,
  },
  {
    id: 5,
    value: 60,
  },
  {
    id: 6,
    value: 90,
  },
];

export type SortTable = {
  columnId: string;
  columnName: string;
  width: number;
  sortColumn: boolean;
  sortType: "ASC" | "DESC" | null;
  type?: string | undefined;
};

export const columnsHeaderTable = (): SortTable[] => {
  return [
    {
      columnId: "code",
      columnName: "Code",
      sortType: null,
      width: 8,
      sortColumn: true,
    },
    {
      columnId: "fullName",
      columnName: "Full name",
      sortType: null,
      width: 12,
      sortColumn: true,
    },
    {
      columnId: "class",
      columnName: "Class",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "employeeFlow",
      columnName: "Employee flow",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "roleIds",
      columnName: "Role",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "eligibilityDate",
      columnName: "Eligibility Date",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "benefitCancellationDate",
      columnName: "Cancellation Date",
      sortType: null,
      width: 12,
      sortColumn: true,
    },
    {
      columnId: "t4employee",
      columnName: "T4 Employee",
      sortType: null,
      width: 10,
      sortColumn: false,
    },
    {
      columnId: "status",
      columnName: "Status",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "actions",
      columnName: "Actions",
      sortType: null,
      width: 18,
      sortColumn: false,
    },
  ];
};

export const Default_Employees_Employer = (): Employee => {
  return {
    id: 0,
    firstName: "",
    lastName: "",
    code: "",
    status: "WORKING",
    gender: null,
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    street: null,
    classId: null,
    socialInsuranceNumber: null,
    position: null,
    salary: null,
    eligibilityDate: null,
    hiringDate: null,
    benefitCancellationDate: null,
    prorated: true,
    roleIds: [],
    cityName: "",
    postCode: "",
    provinceId: null,
    terminationDate: null,
  };
};

export const roles: Status[] = [
  {
    id: "OWNER",
    name: "Owner",
  },
  {
    id: "ADMIN",
    name: "Admin",
  },
  {
    id: "EMPLOYEE",
    name: "Employee",
  },
];
