import React, {Component} from "react";
import Logo from "../../../cores/config/component/images/white-logo.png";
import SystemUpdate from "@material-ui/icons/SystemUpdate";
import People from "@material-ui/icons/People";
import Settings from "@material-ui/icons/Settings";
import NavItem from "./employer-nav-item";
import styled from "styled-components";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FolderIcon from "@material-ui/icons/Folder";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { Link } from "react-router-dom";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import IconReports from "@material-ui/icons/Assessment"
import MarketplaceIcon from "@material-ui/icons/FiberManualRecord";
import {getIsSetupDone} from "../../../cores/utils/helpers";
import {
  enrollmentCoverageURL,
  benefitURL,
  employeeURL,
  employeeSearchParam,
  companyAccountURL,
  companyAccountSearchParam,
  documentsURL,
  settingURL,
  setupCompanyURL,
  homePage,
  reAllocateURL,
  reportsUrl,
  privacyURL,
  termsOfServicesURL,
  marketplaceURL,
} from "../../../cores/constants/url-config";
import moment from "moment";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";

type EmployerSidebarProps = {
  handleDrawerToggle?: () => void;
};

const StyledImgSvg = styled.img`
  margin-top: -5px;
  width: 20px;
  height: 20px;
  margin: 2px;
`;

const StyledDivLogo = styled.div`
  height: 60px;
  padding: 15px 21px;
`;

const StyledImgLogo = styled.img`
  height: 50px;
  position: absolute;
  left: 28px;
  top: 8px;
  @media (max-width: 600px) {
    height: 40px;
  }
`;

const navItemList = [
  {
    to: enrollmentCoverageURL,
    label: "Plan",
    icon: <InsertChartIcon />,
  },
  {
    to: benefitURL,
    label: "Classes",
    icon: <SystemUpdate />
  },
  {
    to: employeeURL + employeeSearchParam,
    label: "Employee",
    icon: <People />,
  },
  {
    to: companyAccountURL + companyAccountSearchParam,
    label: "Company Account",
    icon: <MonetizationOnIcon />,
  },
  {
    to: documentsURL,
    label: "Documents",
    icon: <FolderIcon />,
  },
  {
    to: reportsUrl,
    label: "Reports",
    icon: <IconReports />,
  },
  {
    to: settingURL,
    label: "Settings",
    icon: <Settings />,
  },
  // {
  //   to: marketplaceURL,
  //   label: "Marketplace",
  //   icon: <MarketplaceIcon />
  // }
]

type State = {
  isSetupDone: boolean
  sideBarItem: any[]
}

export default class EmployerSideBar extends Component<EmployerSidebarProps> {
  state: State = {
    isSetupDone: false,
    sideBarItem: navItemList,
  };

  componentDidMount() {
    getIsSetupDone().then((isSetupDone: boolean) => {
      this.setState({isSetupDone})
    });

    if (Number(moment().format("MMDD")) <= 115) {
      const navItemListTemp = [...navItemList];
      navItemListTemp.splice(2,0,
          {
            to: reAllocateURL,
            label: "Re-allocate class benefit",
            icon: <SystemUpdateAltIcon />,
          })
      this.setState({sideBarItem: navItemListTemp})
    }
  }

  render() {
    return (
      <div>
        {/* logo */}
        <StyledDivLogo>
          <Link to={homePage}>
            <StyledImgLogo src={Logo} alt="logo" />
          </Link>
        </StyledDivLogo>

        {/* top nav */}
        {this.state.isSetupDone ? (
          <ul className="nav flex-column top-nav">
            {this.state.sideBarItem.map ((item, index) => (<NavItem
                key={`nav_item_${index}`}
                to={item.to}
                label={item.label}
                handleDrawerToggle={this.props.handleDrawerToggle}
                iconClass={item.icon}
            />))}
          </ul>
        ) : (
            <ul className="nav flex-column top-nav">
              <NavItem
                to={setupCompanyURL}
                handleDrawerToggle={this.props.handleDrawerToggle}
                label="Setup Wizard"
                iconClass={<HomeWorkIcon />}
              />
            </ul>
          )}

        {/* bottom nav */}
        <ul className="nav flex-column bottom-nav">
          {/* <NavItem to={contactURL} label="Contact US" /> */}
          {/* <NavItem to={supportURL} label="Support" /> */}
          {!isBeniplusUI
            &&
            <NavItem to={'https://gotodoctor.ca/'} label="GoToDoctor Website" goToAnotherWebsite={true}/>
          }
          <NavItem to={privacyURL} label="Privacy" />
          <NavItem to={termsOfServicesURL} label="Terms of services" />
        </ul>
      </div>
    );
  }
}
