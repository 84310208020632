import React from "react";
import { withStyles, createStyles } from "@material-ui/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

/**
 *
 * @param checkbox
 */
export const IOSSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 50,
      height: 28,
      padding: 0,
      margin: "10px"
    },
    switchBase: {
      padding: 2.5,
      "&$checked": {
        transform: "translateX(23px)",
        color: "#1FCE5F",
        "& + $track": {
          backgroundColor: "#1FCE5F",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "#ffffff",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 22,
      height: 22,
      backgroundColor: "#fff"
    },
    track: {
      borderRadius: 18,
      opacity: 1
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
      tabIndex={-1}
      role="application"
      aria-hidden="true"
    />
  );
});
