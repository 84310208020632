import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {SortTable} from "../../employer/employees/employee-model";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {reportRef, SearchParamType} from "../ReportType";
import {ClaimReportType} from "../claims/ClaimReportType";
import {
  exportClaimReport,
  exportGroupInsuranceSummaryReport,
  getClaimReport,
  getClaimSummarize,
  getGroupInsuranceSummaryReport
} from "../../../../services/employer-report-service";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {SummarizeType} from "../ReportType";
import {defaultSearchParam, EmployerEnrollmentsMap, GroupInsuranceClaimType, GroupInsuranceReportType} from "../ReportModel";
import {exportExcel} from "../../../../cores/helpers/export-file/export-file";
import {calculatePeriod} from "../report";
import BNPRawTable from '../../../shared/BNPTable/BNPRawTable';
import { GroupInsuranceSummaryReportType } from './GroupInsuranceReportType';

type Props = {
  columns: SortTable[];
  sortTable: (columnId: string) => void;
  changePage: (page: number) => void;
  paging: PagingInfo;
  setPaging: (value: any) => void;
  setRowsPerPage: (event: any) => void;
  searchParams: SearchParamType;
  setSummarizedList: (value: any) => void;
  period: string;
}


const GroupInsuranceReport = forwardRef((props: Props, ref: Ref<reportRef>) => {
  const [claimList, setClaimList] = useState<ClaimReportType[]>([])
  const [groupInsuranceSummaryReportData, setGroupInsuranceSummaryReportData] = 
  useState<GroupInsuranceSummaryReportType[]>([]);

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isSummaryReport, setIsSummaryReport] = useState<boolean>(true);

  useEffect(() => {
    if (props.searchParams.from && props.searchParams.to && props.searchParams.filter) {
      if (props.searchParams.filter === GroupInsuranceReportType.GROUP_INSURANCE_DETAIL) {
        setIsSummaryReport(false);
        getReportData().then();
      } else {
        setIsSummaryReport(true);
        getGroupInsuranceSummaryReportData().then();
      }
    }
  }, [JSON.stringify(props.searchParams)])

  async function getGroupInsuranceSummaryReportData() {
    addLoading();
    if (isFirstLoad) {
      const firstLoadSearchParams = calculatePeriod(props.period, defaultSearchParam, props.searchParams.from, props.searchParams.to)
      await getClaimSummarizeData({...firstLoadSearchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
      await getGroupInsuranceSummaryReportResult({...firstLoadSearchParams});
      setIsFirstLoad(false)
    } else {
      await getClaimSummarizeData({...props.searchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
      await getGroupInsuranceSummaryReportResult({...props.searchParams});
    }
    removeLoading();
  }

  async function getReportData() {
    addLoading();
    if (isFirstLoad) {
      const firstLoadSearchParams = calculatePeriod(props.period, defaultSearchParam, props.searchParams.from, props.searchParams.to)
      await getClaimSummarizeData({...firstLoadSearchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
      await getClaimReportData({...firstLoadSearchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
      setIsFirstLoad(false)
    } else {
      await getClaimSummarizeData({...props.searchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
      await getClaimReportData({...props.searchParams, filter: GroupInsuranceReportType.GROUP_INSURANCE_DETAIL});
    }
    removeLoading();
  }

  function calculateClaimSummarizeData(result: any) {
    const summarizeData: SummarizeType[] = [];
    Object.keys(result).forEach(key => {
      if(result[key] > 0)
        summarizeData.push({
          title: findClaimTypeNameByType(key),
          balance: result[key],
        });
    })
    return summarizeData.length > 0 ? summarizeData : [{title: "--", balance: "--"}]
  }

  function findClaimTypeNameByType(type: string): string {
    const claimType =  [...Object.values(EmployerEnrollmentsMap), {id: 6, type: "GROUP_INSURANCE", name: "Group Insurance"}]
      .find(item => item.type.toLowerCase() === type.toLowerCase());
    if (claimType) return claimType.name;
    return "";
  }

  async function getGroupInsuranceSummaryReportResult(searchParams: SearchParamType) {
    try {
      const result = await getGroupInsuranceSummaryReport(searchParams);
      setGroupInsuranceSummaryReportData(result.data.records);
      let pagingInfo = setPagingInfo(
        searchParams.page,
        result.data.pageCount,
        searchParams.perPage,
      )
      props.setPaging(pagingInfo);
    } catch (e) {
      console.log(e);
    }
  }

  async function getClaimReportData(searchParams: SearchParamType) {
    try {
      const result = await getClaimReport(searchParams);
      setClaimList(result.data.records);
      let pagingInfo = setPagingInfo(
        searchParams.page,
        result.data.pageCount,
        searchParams.perPage,
      )
      props.setPaging(pagingInfo);
    } catch (e) {
      console.log(e)
    }
  }

  async function getClaimSummarizeData(searchParams: SearchParamType) {
    try {
      const result = (await getClaimSummarize(searchParams)).data;
      props.setSummarizedList(calculateClaimSummarizeData(result))
    } catch (e) {
      console.log(e)
    }
  }

  useImperativeHandle(ref, () => ({
    exportReport,
  }))

  function exportReport() {
    if (isSummaryReport) {
      exportGroupInsuranceSummaryReport(props.searchParams)
      .then((result) => {
        exportExcel(result.data);
      })
    } else {
      exportClaimReport(props.searchParams).then(
        (result) => {
          exportExcel(result.data);
        }
      )
    }
  }

  return (<>
    <BNPRawTable columns={props.columns} tableData={isSummaryReport ? groupInsuranceSummaryReportData : claimList} tableWidth='100%' />
    <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                      pagerPagination={props.paging}
                      getPage={props.changePage}
                      onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
  </>)
})

export default GroupInsuranceReport;