import React from "react";

import {
  Balance,
  Transactions,
  WalletSearchParams,
  Credit,
} from "./wallet-type";
import {
  getAllBalances,
  postCashCreditPartner,
  getAllSeperatePendingAmount, getTransactionsWithCriteria,
} from "../../../../services/wallet-service";
import { getInfoByToken } from "../../../../cores/utils/helpers";
import MyWalletView from "./wallet-view";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../../cores/helpers/pagingation/pagination";
import { RouteChildrenProps } from "react-router";
import CreditModel, { closeCreditModal, showCreditModal } from "./credit/credit-modal";
import {
  showConfirmModal,
  ConfirmDialog,
} from "../../../../cores/helpers/confirm-dialog/confirm-dialog";
import {centToUsd, usdToCent} from "../../../../cores/helpers/cent-to-usd";
import { publishMessage } from "../../../../cores/utils/message";
import { removeLoading, addLoading } from "../../../../cores/utils/loading";
import { filterData, Filter } from "../../../../cores/helpers/filter/filter";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { generateUUID } from "../../../../cores/helpers/uuid";
import { SortTable } from "../employees/employee-model";
import {TableSearchParamType} from "../../../shared/BNPTable/BNPTableType";
import {defaultTableSearchParam} from "../../../shared/BNPTable/BNPTableModel";
import {defaultTablePagingInfo} from "../../../shared/BNPTable/BNPTableModel";
import { EmployerConfiguration } from "../../../../models/employer-configuration/employer-configuration-model"
import SettingAutoTopUpModal, { closeSettingAutoTopUpModal, showSettingAutoTopUpModal } from "./auto-top-up/setting-auto-top-up-modal";
import { AutoTopUpDetail } from "./auto-top-up/setting-auto-top-up-type";
import { getAutoTopUpDetails, putAutoTopUpDetails } from "../../../../services/employer-benefits-service";
import { calculateWalletAmount } from "./wallet-util";


type Props = RouteChildrenProps & EmployerConfiguration;
type State = {
  balances: Balance[];
  availableAmount: number;
  showPopupTime: string,
  pendingAmount: {
    processing: number;
    pendingAmount: number;
    processingMonthlyContribution: number;
    claimRequested: number;
  };
  transactions: Transactions[];
  transactionsFilter: any[];
  searchParam: TableSearchParamType;
  pagingInfo: PagingInfo;
  credit: Credit;
  columns: SortTable[];
  autoTopUpDetailData: AutoTopUpDetail[];
  updateAutoTopUpDetailData: AutoTopUpDetail[];
};
const confirmTypes = [
  {
    id: 'EFT',
    name: 'EFT'
  },
  {
    id: 'ETRANSFER',
    name: 'E-Transfer or Bill Pay'
  }
]
export default class MyWallet extends React.Component<Props, State> {
  state: State = {
    balances: [],
    transactions: [],
    availableAmount: 0,
    showPopupTime: '',
    pendingAmount: {
      claimRequested: 0,
      pendingAmount: 0,
      processingMonthlyContribution: 0,
      processing: 0,
    },
    searchParam: Object.assign({}, defaultTableSearchParam),
    pagingInfo: Object.assign({}, defaultTablePagingInfo),
    credit: {
      partnerId: 0,
      employerId: 0,
      depositAmount: 0,
      referenceId: "",
    },
    transactionsFilter: [],
    columns: [],
    autoTopUpDetailData: [],
    updateAutoTopUpDetailData: []
  };

  info = getInfoByToken();

  async componentDidMount() {
    addLoading();

    await this.getAllBalances();

    removeLoading();
  }

  async getAllBalances() {
    const [resultBalances, resultPendingAmount, autoTopUpDetailDataResult] = await Promise.all([
      getAllBalances(this.info.employerId),
      getAllSeperatePendingAmount(this.info.employerId),
      getAutoTopUpDetails(),
    ]);


    let balances: Balance[] = resultBalances.data.balances,
      autoTopUpDetailData: AutoTopUpDetail[] = autoTopUpDetailDataResult.data.enrollmentDepositGroupAutoTopUpDetails,
      pendingAmount = resultPendingAmount.data,
      availableAmount = 0;

    if (balances.length) {
      let physical = balances.find(
        (balance) => balance.walletType === "physical"
      );

      if (physical) {
        availableAmount = physical.balance;
      }
    }

    this.setState({
      balances,
      pendingAmount,
      availableAmount,
      autoTopUpDetailData,
      updateAutoTopUpDetailData: autoTopUpDetailData,
    });
  }

  // deposit
  handleChangeInputDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let credit = Object.assign(this.state.credit);
    let { name, value } = event.target;
    credit[name] = value;

    this.setState({
      credit: credit,
    });
  };

  handleSubmitDeposit = (data: any) => {
    this.setState({
      credit: {...this.state.credit, ...data}
    })
    showConfirmModal();
  };

  checkSubmit = () => {
    let credit = Object.assign({}, this.state.credit, {
      depositAmount: usdToCent(this.state.credit.depositAmount),
    });
    const info = getInfoByToken();
    credit.employerId = info.employerId;
    credit.partnerId = info.partnerId;
    credit.referenceId = generateUUID();
    postCashCreditPartner(credit).then(() => {
      this.getAllBalances();
      publishMessage({
        message: "Request deposit has been created.",
        variant: "success",
      });

      let adjustment = {
        partnerId: 0,
        employerId: 0,
        depositAmount: 0,
        referenceId: "",
      };

      closeCreditModal();
      removeLoading();
      this.setState({ credit: adjustment });
    });
  };
  //end deposit

  onSwitchChange = (e: any, checked: boolean, selectedAutoTopUpDetail: AutoTopUpDetail) => {
    let updateRequest = 
      this.state.updateAutoTopUpDetailData ? this.state.updateAutoTopUpDetailData: [];

    let existedValIndex: number = 
      updateRequest.findIndex(e => e.enrollmentDepositGroupId === selectedAutoTopUpDetail.enrollmentDepositGroupId);

    if ((existedValIndex || existedValIndex === 0) && existedValIndex != -1) {
      updateRequest[existedValIndex].isAutoTopUp = checked;
    }

    this.setState({updateAutoTopUpDetailData: updateRequest});
  }

  handleSubmitSettingAutoTopUp = () => {
    let cloneUpdateRequest = 
      this.state.updateAutoTopUpDetailData ? this.state.updateAutoTopUpDetailData : [];
  
    putAutoTopUpDetails({updateEnrollmentDepositGroupAutoTopUpDetails: cloneUpdateRequest})
    .then(() => {
      this.getAllBalances();
      publishMessage({
        message: "Updated Setting Auto Top Up",
        variant: "success",
      });
      closeSettingAutoTopUpModal();
      removeLoading();
    });
  };

  handleCloseSettingAutoTopUp = () => {
    this.setState({updateAutoTopUpDetailData: this.state.autoTopUpDetailData})
    closeSettingAutoTopUpModal();
  };

  openModalCredit = () => {
    showCreditModal();
    let credit = {...this.state.credit};
    const {claimPending, requiredTopUp} = calculateWalletAmount(this.state.pendingAmount, this.state.availableAmount);
    let depositAmount = requiredTopUp || 0;
    let depositAmountUSD = depositAmount / 100
    credit["depositAmount"] = depositAmountUSD < 0 ? 0 : depositAmountUSD;
    this.setState({ showPopupTime: (new Date()).toString(), credit: credit});
  }

  openSettingAutoTopUpModal = () => {
    showSettingAutoTopUpModal();
    this.setState({ showPopupTime: (new Date()).toString()});
  }

  render() {
    return (
      <>
        <MyWalletView
          availableAmount={this.state.availableAmount}
          pendingAmount={this.state.pendingAmount}
          openModalCredit={this.openModalCredit}
          openSettingAutoTopUpModal={this.openSettingAutoTopUpModal}
          enablePresetBankingInformation={this.props.enablePresetBankingInformation}
        />
        <CreditModel
          handleChangeInput={this.handleChangeInputDeposit}
          credit={this.state.credit}
          confirmTypes={confirmTypes}
          handleSubmit={this.handleSubmitDeposit}
          showPopupTime={this.state.showPopupTime}
        />
        <SettingAutoTopUpModal
          onSwitchChange={this.onSwitchChange}
          autoTopUpDetailData={this.state.updateAutoTopUpDetailData}
          handleSubmit={this.handleSubmitSettingAutoTopUp}
          handleClose={this.handleCloseSettingAutoTopUp}
        />
        <ConfirmDialog
          content={`Are you sure to make a deposit of ${formatterUSD(
            "currency"
          ).format(this.state.credit.depositAmount)}?`}
          title={"Deposit"}
          handleOnOk={this.checkSubmit}
        />
      </>
    );
  }
}
