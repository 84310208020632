import { DefaultTheme } from "styled-components";

export const themeConfig: DefaultTheme = {
    primaryColor: "#003049",
    secondaryColor: "#d71f27",
    tertiaryColor: "#f57f20",
    sideBarColor: {
        300: "#002c5d",
        500: "#003049"
    },
    sideBarTextColor: "#fff",
    infoColor: "#2572C6",
    successColor: "#58AD2F",
    warningColor: "#fbbe4a",
    errorColor: "#d71f27",
    grey: {
        200: "#d5d5d5",
        300: "#9b9fa3",
        500: "#646464",
    },
    textColor: "#000000",

    headlineFont: "Graphik Semibold",
    subheadFont: "Graphik Light",
    textFont: "Graphik Light"
};

export const isBeniplusUI: boolean = true;