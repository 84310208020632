import React from "react";
import styled, { css } from "styled-components";
import { FormControl, Radio, RadioGroup } from "@material-ui/core";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import {
  StyledButtonSubmit,
  StyledInputMask,
  StyledSpanBigTitle,
  StyledPTitle,
  StyledSpanTitle,
  StyledSpanLargerTitle,
  StyledFormControlLabel,
} from "../../../../shared/employer-styled";
import { Provinces, CompanyInfo } from "./company-information-type";
import { BNPSelect, Option } from "../../../../../cores/helpers/select/select";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../../cores/helpers/google-map-services/google-location";
import { BNPAutoComplete } from "../../../../../cores/helpers/input/BNPAutoComplete";

const StyledContentBox = styled.div`
  background: #fff;
  border-radius: 5px;
  height: 100%;

  ${(props: { padding: boolean }) =>
    props.padding &&
    css`
      padding: 25px 21px;
    `}
`;

const StyledDescription = styled(RadioGroup)`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

type Props = {
  provinces: Provinces[];
  companyInformation: CompanyInfo;
  isValid: boolean;
  isAdditionalStreet: boolean;
  handleSubmit: () => void;
  handleChangInput: (event: any) => void;
  handleChangSelect: (event: any) => void;
  handleAdditionalStreet: (event: any) => void;
  isSetup: boolean;
  isPadding: boolean;

  //
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  setAddress2: (address: string) => void;
  setLocation2: (location: ResponseLocation) => void;
  employerRoles?: any;
  handleChangeEmployerRole?: (event: any) => void;
  employerSetupUserRole?: string;
};

export default function CompanyInfoView(props: Props) {
  return (
    <StyledContentBox padding={props.isPadding}>
      {props.isSetup ? (
        <>
          <StyledSpanBigTitle>Company Information</StyledSpanBigTitle>
          <hr />
          <div className="mt-4"></div>
        </>
      ) : (
        <div className="row">
          <StyledSpanLargerTitle className="col-lg-6">
            Company Information
          </StyledSpanLargerTitle>
        </div>
      )}

      <div className="row">
        <div className="col-lg-4 mb-4">
          <StyledPTitle className="mb-2">Company name *</StyledPTitle>
          <BNPInput
            value={props.companyInformation.companyName || ""}
            name="companyName"
            onChange={props.handleChangInput}
            placeholder="Company name"
            tabIndex={1}
          />
        </div>

        <div className="col-lg-4 mb-4">
          <StyledPTitle className="mb-2">No of employees *</StyledPTitle>
          <BNPInput
            value={props.companyInformation.noOfEmployees || ""}
            name="noOfEmployees"
            onChange={props.handleChangInput}
            maxLength={9}
            placeholder="No of employees"
            tabIndex={2}
          />
        </div>
        <div className="col-lg-4 mb-4">
          <StyledPTitle className="mb-2">Sole Proprietor *</StyledPTitle>
          <BNPSelect
            options={[
              { id: 1, name: "Yes" },
              { id: 0, name: "No" },
            ]}
            value={
              props.companyInformation.soleProprietor < 0
                ? -1
                : props.companyInformation.soleProprietor
                ? 1
                : 0
            }
            name="soleProprietor"
            placeholder="Sole proprietor"
            onChange={props.handleChangSelect}
            tabIndex={3}
          />
        </div>
        <div className="col-12 mb-4">
          <StyledPTitle className="mb-2">Company Notification Emails</StyledPTitle>
          <BNPInput
            name="companyNotificationEmail"
            value={props.companyInformation.companyNotificationEmail || ""}
            onChange={props.handleChangInput}
            placeholder="Multiple emails separated by semicolon"
            tabIndex={4}
          />
        </div>
      </div>

      {props.employerRoles && <div className="row">
        <div className="col-lg-12 mb-4">
          <StyledPTitle className="mb-2">Are you the owner of the company?</StyledPTitle>
          <FormControl component="fieldset">
            <StyledDescription
              name="Are you the owner of the company?"
              value={props.employerSetupUserRole || props.employerRoles.owner.name}
              onChange={event => {
                props.handleChangeEmployerRole &&
                  props.handleChangeEmployerRole(event)
              }}
            >
              {Object.values(props.employerRoles).map((role: any) => (
                <span key={role.name}>
                  <StyledFormControlLabel
                    value={role.name}
                    control={<Radio color="primary" />}
                    label={role.description}
                  />
                </span>
              ))}
            </StyledDescription>
          </FormControl>
        </div>
      </div>}

      <div className="row">
        <div className="col-lg-12">
          <StyledSpanTitle>Billing address *</StyledSpanTitle>
        </div>
        <div className="col-lg-12 mb-4">
          <StyledPTitle className="mb-2">Street address</StyledPTitle>
          <GoogleLocationInput
            id="streetAddress"
            address={props.companyInformation.streetAddress || ""}
            setAddress={props.setAddress}
            setLocation={props.setLocation}
            placeholder="Street address"
            tabIndex={4}
          />
        </div>
        <div className="col-lg-3 mb-4">
          <StyledPTitle className="mb-2">Province</StyledPTitle>
          <BNPSelect
            options={props.provinces.map((province: Provinces) => ({
              id: province.id,
              name: province.name,
            }))}
            placeholder="Province"
            name="provinceId"
            value={props.companyInformation.provinceId || "-1"}
            onChange={props.handleChangSelect}
            tabIndex={5}
          />
        </div>
        <div className="col-lg-3 mb-4">
          <StyledPTitle className="mb-2">City</StyledPTitle>
          <BNPInput
            value={props.companyInformation.cityName || ""}
            onChange={props.handleChangSelect}
            name="cityName"
            placeholder="City"
            tabIndex={6}
          />
        </div>
        <div className="col-lg-3 mb-4">
          <StyledPTitle className="mb-2">Postcode</StyledPTitle>
          <StyledInputMask
            mask="*** ***"
            className="text-up"
            value={props.companyInformation.postcode || ""}
            onChange={props.handleChangInput}
            name="postcode"
            placeholder="Postcode"
            tabIndex={7}
            role="application"
            aria-hidden="true"
          />
        </div>
        <div className="col-lg-3 mb-4">
          <StyledPTitle className="mb-2">Phone number</StyledPTitle>
          <StyledInputMask
            mask="999-999-9999"
            value={props.companyInformation.companyPhoneNumber || ""}
            onChange={props.handleChangInput}
            name="companyPhoneNumber"
            placeholder="Phone number"
            tabIndex={8}
            role="application"
            aria-hidden="true"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <StyledSpanTitle>Mailing address *</StyledSpanTitle>
        </div>
        <div className="col-lg-12 mb-3">
          <CheckBox
            title="Same as the billing address"
            checked={props.isAdditionalStreet}
            onChange={props.handleAdditionalStreet}
          />
        </div>
      </div>

      {!props.isAdditionalStreet && (
        <div className="row">
          <div className="col-lg-12 mb-4">
            <StyledPTitle className="mb-2">Street address</StyledPTitle>
            <GoogleLocationInput
              id="streetAddress2"
              address={props.companyInformation.streetAddress2 || ""}
              setAddress={props.setAddress2}
              setLocation={props.setLocation2}
              placeholder="Street address"
              tabIndex={9}
            />
          </div>
          <div className="col-lg-3 mb-4">
            <StyledPTitle className="mb-2">Province</StyledPTitle>
            <BNPSelect
              options={props.provinces.map((province: Provinces) => ({
                id: province.id,
                name: province.name,
              }))}
              placeholder="Province"
              name="provinceId2"
              value={props.companyInformation.provinceId2 || "-1"}
              onChange={props.handleChangSelect}
              tabIndex={10}
            />
          </div>
          <div className="col-lg-3 mb-4">
            <StyledPTitle className="mb-2">City</StyledPTitle>
            <BNPInput
              value={props.companyInformation.cityName2 || ""}
              onChange={props.handleChangSelect}
              name="cityName2"
              placeholder="City"
              tabIndex={11}
            />
          </div>
          <div className="col-lg-3 mb-4">
            <StyledPTitle className="mb-2">Postcode</StyledPTitle>
            <StyledInputMask
              mask="*** ***"
              className="text-up"
              value={props.companyInformation.postcode2 || ""}
              onChange={props.handleChangInput}
              name="postcode2"
              placeholder="Postcode"
              tabIndex={12}
              role="application"
              aria-hidden="true"
            />
          </div>
          <div className="col-lg-3 mb-4">
            <StyledPTitle className="mb-2">Phone number</StyledPTitle>
            <StyledInputMask
              mask="999-999-9999"
              value={props.companyInformation.companyPhoneNumber2 || ""}
              onChange={props.handleChangInput}
              name="companyPhoneNumber2"
              placeholder="Phone number"
              tabIndex={13}
              role="application"
              aria-hidden="true"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-lg-12">
          <StyledSpanTitle>Other information</StyledSpanTitle>
        </div>

        <div className="col-lg-12">
          <StyledPTitle className="mb-2">
            Please let us know if someone referred you to us
          </StyledPTitle>
        </div>
        {/* employer does not have permission to update the referral partner, so just show it as a input*/}
        <div className="col-md-6 mb-4">
          <StyledPTitle className="mb-2">Referral Partner</StyledPTitle>
          <BNPInput value={props.companyInformation.referralPartnerName} disabled />
        </div>
        <div className="col-md-6 mb-4">
          <StyledPTitle className="mb-2">Referral Partner Team Member</StyledPTitle>
          {/* if the value of the referralPartnerUser is null we can understand that it is directly referred to the partner */}
          <BNPInput value={(props.companyInformation.referralPartnerUserId && props.companyInformation.referralPartnerUserId != -1) ? props.companyInformation.referralPartnerUserName : props.companyInformation.referralPartnerName} disabled />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mt-4">
          <div className="col-lg-3 float-right p-0">
            <StyledButtonSubmit
              disabled={!props.isValid}
              onClick={props.handleSubmit}
              submit={props.isValid}
              type="submit"
              tabIndex={15}
            >
              {props.isSetup ? "Save & Continue" : "Save & Update"}
            </StyledButtonSubmit>
          </div>
        </div>
      </div>
    </StyledContentBox>
  );
}
