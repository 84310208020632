import React, { Component } from "react";

import { CompanyInfo, Provinces } from "./company-information-type";
import {
  putCompanyInfo,
  getProvinces,
  getCompanyInfo,
} from "../../../../../services/employer-setting-service";
import { formatValueInputMask } from "../../../../../cores/helpers/format-phone-number";
import { publishMessage } from "../../../../../cores/utils/message";
import {
  addLoading,
  removeLoading,
} from "./../../../../../cores/utils/loading";
import { RouteChildrenProps } from "react-router";
import CompanyInfoView from "./company-information-view";
import { ResponseLocation } from "../../../../../cores/helpers/google-map-services/google-location";
import {
  ConfirmOptionDialog,
  showConfirmOptionModal,
} from "../../../../../cores/helpers/confirm-dialog/confirm-option-dialog";
import { signOut } from "../../../../../cores/utils/helpers";
import ErrorDialog, {
  showErrorModal,
} from "../../../../../cores/helpers/error-dialog/error-dialog";
import {
  signInURL,
} from "../../../../../cores/constants/url-config";

type Props = RouteChildrenProps;
type State = {
  companyInfo: CompanyInfo;
  provinces: Provinces[];
  isValid: boolean;
  isAdditionalStreet: boolean;
};

export default class CompanyInfoEmployerSetting extends Component<
  Props,
  State
> {
  state: State = {
    provinces: [],
    companyInfo: {
      referralName: "",
      referralPartnerId: null,
      referralPartnerName: "",
      referralPartnerUserId: null,
      referralPartnerUserName: "",
      companyName: "",
      companyNotificationEmail: "",
      noOfEmployees: 0,
      streetAddress: "",
      cityName: "",
      provinceId: -1,
      postcode: "",
      streetAddress2: "",
      cityName2: "",
      provinceId2: -1,
      postcode2: "",
      companyPhoneNumber: "",
      companyPhoneNumber2: "",
      soleProprietor: false,
      isHave2EmployeeIncluding1ArmLength: false,
      isCreatedByManager: false,
    },
    isValid: false,
    isAdditionalStreet: false,
  };

  async componentDidMount() {
    //add loading
    addLoading();
    let result = await getDataCompanyInfo();

    let isAdditionalStreet = false;
    if (
      result.companyInfo.postcode &&
      result.companyInfo.postcode2 &&
      result.companyInfo.postcode === result.companyInfo.postcode2
    ) {
      isAdditionalStreet = true;
    }
    let isValid = this.validate(result.companyInfo, isAdditionalStreet);

    this.setState({
      companyInfo: result.companyInfo,
      provinces: result.provinces,
      isValid: isValid,
      isAdditionalStreet: isAdditionalStreet,
    });

    removeLoading();
  }

  handleChangInput = (event: any) => {
    const {name, value} = event.target;
    const companyInformation: CompanyInfo = Object.assign({}, this.state.companyInfo);
    if (
      name === "companyPhoneNumber" ||
      name === "companyPhoneNumber2"
    ) {
      companyInformation[name] = formatValueInputMask(value);
    } else if (name === "noOfEmployees") {
      companyInformation.noOfEmployees = Number(value);
    } else {
      companyInformation[name] = value;
    }

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setAddress = (address: string) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);
    companyInformation.streetAddress = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setLocation = (location: ResponseLocation) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);

    companyInformation.cityName = location.city;
    companyInformation.postcode = location.postcode ? location.postcode : "";
    companyInformation.provinceId = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setAddress2 = (address: string) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);
    companyInformation.streetAddress2 = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setLocation2 = (location: ResponseLocation) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);

    companyInformation.cityName2 = location.city;
    companyInformation.postcode2 = location.postcode ? location.postcode : "";
    companyInformation.provinceId2 = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  handleChangSelect = (event: any) => {
    const { name, value } = event.target;

    let companyInformation = Object.assign(this.state.companyInfo);

    companyInformation[name] = value;
    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );
    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  handleSubmit = () => {
    if (this.state.companyInfo.soleProprietor) {
      showConfirmOptionModal();
    } else {
      let companyInfo = this.checkIsSameTheBillingAddress();

      this.updateCompanyInfo(companyInfo, false);
    }
  };

  // check the same as the billing address
  checkIsSameTheBillingAddress = () => {
    return !this.state.isAdditionalStreet
      ? Object.assign({}, this.state.companyInfo)
      : Object.assign({}, this.state.companyInfo, {
          streetAddress2: this.state.companyInfo.streetAddress,
          cityName2: this.state.companyInfo.cityName,
          provinceId2: this.state.companyInfo.provinceId,
          postcode2: this.state.companyInfo.postcode,
          companyPhoneNumber2: this.state.companyInfo.companyPhoneNumber,
        });
  };

  // if choose yes
  handleYes = () => {
    let companyInfo = this.checkIsSameTheBillingAddress();

    companyInfo.isHave2EmployeeIncluding1ArmLength = true;

    this.updateCompanyInfo(companyInfo, false);
  };
  // if choose no
  handleNo = () => {
    let companyInfo = this.checkIsSameTheBillingAddress();

    companyInfo.isHave2EmployeeIncluding1ArmLength = false;

    this.updateCompanyInfo(companyInfo, true);
  };

  updateCompanyInfo = (companyInfo: CompanyInfo, isDisabled: boolean) => {
    putCompanyInfo(companyInfo).then(() => {
      if (isDisabled) {
        showErrorModal();
      } else {
        this.setState({ companyInfo });
        publishMessage({
          message: "Your company information has been update successfully.",
          variant: "success",
        });
      }
    });
  };

  signOut = () => {
    signOut();
    this.props.history.push(signInURL);
  };

  validate = (
    companyInfo: CompanyInfo,
    isAdditionalStreet: boolean
  ): boolean => {
    if (
      companyInfo.companyName &&
      companyInfo.noOfEmployees &&
      companyInfo.provinceId > -1 &&
      companyInfo.cityName &&
      companyInfo.postcode &&
      companyInfo.companyPhoneNumber &&
      companyInfo.soleProprietor !== -1 &&
      (isAdditionalStreet ||
        (!isAdditionalStreet &&
          companyInfo.provinceId2 > -1 &&
          companyInfo.cityName2 &&
          companyInfo.postcode2 &&
          companyInfo.companyPhoneNumber2))
    ) {
      return true;
    }

    return false;
  };

  handleAdditionalStreet = (event: any): void => {
    let isValid = this.validate(this.state.companyInfo, event.target.checked);
    this.setState({
      isAdditionalStreet: event.target.checked,
      isValid: isValid,
    });
  };

  render() {
    return (
      <>
        <CompanyInfoView
          provinces={this.state.provinces}
          companyInformation={this.state.companyInfo}
          handleSubmit={this.handleSubmit}
          handleChangInput={this.handleChangInput}
          handleChangSelect={this.handleChangSelect}
          isValid={this.state.isValid}
          isAdditionalStreet={this.state.isAdditionalStreet}
          handleAdditionalStreet={this.handleAdditionalStreet}
          isSetup={false}
          isPadding={true}
          //
          setAddress={this.setAddress}
          setLocation={this.setLocation}
          setAddress2={this.setAddress2}
          setLocation2={this.setLocation2}
        />
        <ConfirmOptionDialog
          title="Warning"
          content="Do you have at least 2 employees including 1 arm's length employee?"
          handleNo={this.handleNo}
          handleYes={this.handleYes}
        />
        <ErrorDialog
          title="Sorry!"
          description="Sorry! Our products are not offered for sole ownership company at the moment."
          handleClose={this.signOut}
        />
      </>
    );
  }
}

export async function getDataCompanyInfo() {
  let resultProvinces = await getProvinces();
  let resultCompanyInfo = await getCompanyInfo();

  let provinces: Provinces[] = resultProvinces.data || [];
  let companyInfo: CompanyInfo = resultCompanyInfo.data || [];
  if (companyInfo.soleProprietor === null) companyInfo.soleProprietor = -1;
  return { provinces, companyInfo };
}

export function findProvinceIdByName(
  provinceName: string,
  provinces: Provinces[]
) {
  let province = provinces.find((f) => {
    return f.name === provinceName;
  });

  return province ? province.id : -1;
}
