export const validateEmail = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,8}$";
export const googleAPIKey = "AIzaSyDXH1XrfgFVq0Cez4BHV-MK3DREGHiFmFI";
export const zendeskAPIKey = "6f197e73-dafd-47d8-83cc-5b09c0ba8fa1";

// list country is one from ISO 3166-1 Alpha-2 country code.
// For example, 'us', 'ca', or 'uy'. You can provide a single one, or an array of up to five country code strings.
export const countrySearchAPI = ["ca"];

export const localStorageRedirectKey = "REDIRECT_TO";

export const hubSpotChatboxSrc = "//js.hs-scripts.com/7217425.js";