import React from "react";
import {
  StyledLargerTitle,
  StyledContentBox,
  StyledSpanExpansionDelete
} from "../../../../shared/employer-styled";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import moment from "moment"
import {
  Enrollments,
  EmployerEnrollment
} from "../../employer-classes/employer-classes-type";

type Props = {
  enrollments: Enrollments[];
  employerEnrollments: EmployerEnrollment[];
  handleChangeEmployerEnrollment: (event: React.ChangeEvent<HTMLInputElement>) => void
  checkedCheckbox: (enrollmentId: number) => boolean;
  isSetup: boolean;
};

export default function EnrollmentCoverage(props: Props) {

  return (
    <StyledContentBox isBorder={props.isSetup} style={{height: "auto", marginBottom: "10px"}}>
      <StyledLargerTitle>Wallet Coverage</StyledLargerTitle>
      <div>
        What categories of benefits would you like to make available to your
        employees?
      </div>
      <div className="mt-3">
        {props.enrollments.map(enrollment => (
          <div
            className={
              "mt-3 " +
              (!props.isSetup && (Number(moment().format("MMDD")) > 115)
                ? "op-0-7" : "")
            }
            key={enrollment.id} >
            <CheckBox
              title={enrollment.name}
              value={enrollment.id}
              checked={props.checkedCheckbox(enrollment.id)}
              onChange={props.handleChangeEmployerEnrollment}
              disabled={
                !props.isSetup &&
                (Number(moment().format("MMDD")) > 115)
              }
            />
          </div>
        ))}
      </div>
      {props.employerEnrollments.length < 1 &&
        <div className="mt-2">
          <StyledSpanExpansionDelete>Please select as least one enrollment coverage.</StyledSpanExpansionDelete>
        </div>
      }
    </StyledContentBox>
  );
}
