import React from "react";
import {
  ClassBenefit,
  Class,
} from "../../../request-a-quote/request-a-quote-type";
import {
  StyledBigTitle,
  StyledBtnLink,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionDelete,
  StyledImgDelete,
  StyledExpansionLabel,
  StyledButtonSubmit,
} from "../../../../shared/employer-styled";
import { AddClassBenefits } from "../../../../../cores/helpers/add-class-benefits/add-class-benefits";
import theme from "../../../../../cores/helpers/theme";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import IconDelete from "../../../../../cores/config/component/images/bin@2x.png";
import { ExpansionPanel } from "@material-ui/core";
import { formatterUSD } from "../../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../../cores/helpers/cent-to-usd";
import EmployerBenefitsTable from "../../employer-classes/employer-classes-table";
import { EmployerBenefitClass } from "../../employer-classes/employer-classes-type";
import { setupPlanURL } from "../../../../../cores/constants/url-config";

type Props = {
  classBenefit: ClassBenefit;
  defineClassBenefits: EmployerBenefitClass[];
  findClassById: (idClass: number) => { label: string; value: number };
  removeEmployerClasses: (idClass: number) => void;
  getRemainingClasses: () => Class[];
  addClassBenefits: () => void;
  handleChangeInputDefineClassBenefits: (event: any) => void;
  handleChangeSelectClassBenefits: (event: any) => void;
  handleChangeCheckbox: (event: any) => void;
  isValid: boolean;
  isOtherClass: boolean;

  onSubmitToNextPage: () => void;
  isSubmitValid: boolean;
};

export default function EmployerBenefitsView(props: Props) {
  return (
    <div>
      <StyledBigTitle>Classes</StyledBigTitle>
      <hr />
      <div>
        {/* table Setup Class Benefits */}
        <StyledHideForMobile>
          <EmployerBenefitsTable
            findClassById={props.findClassById}
            defineClassBenefits={props.defineClassBenefits}
            delete={props.removeEmployerClasses}
          />
        </StyledHideForMobile>
        <StyledHideForDesktop>
          {props.defineClassBenefits.map((classBenefit, index) => (
            <ExpansionPanel
              square
              defaultExpanded={false}
              className="mt-2"
              key={index}
            >
              <StyledExpansionPanelSummary
                className="m-0"
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ArrowDropUp />}
              >
                <div className="w-100">
                  <StyledTitleExpansion>class</StyledTitleExpansion>
                  <div className="mt-2">
                    {props.findClassById(classBenefit.classId).label}
                  </div>
                </div>
              </StyledExpansionPanelSummary>
              <StyledExpansionPanelDetails>
                <div className="w-100">
                  <StyledTitleExpansion>Plan</StyledTitleExpansion>
                  <div className="mt-2">Standard Plan</div>
                </div>
                <div className="w-100 mt-3">
                  <StyledTitleExpansion>NO OF EMPLOYEE</StyledTitleExpansion>
                  <div className="mt-2">{classBenefit.noOfEmployees}</div>
                </div>
                <div className="w-100 mt-3">
                  <StyledTitleExpansion>COVERAGE AMOUNT</StyledTitleExpansion>
                  <div className="mt-2">
                    {formatterUSD("currency", "USD").format(
                      centToUsd(classBenefit.coverageAmount)
                    )}
                  </div>
                </div>
                <div className="w-100 mt-3 fw-bold">
                  <StyledTitleExpansion>TOTAL</StyledTitleExpansion>
                  <div className="mt-2">
                    {formatterUSD("currency", "USD").format(
                      centToUsd(
                        classBenefit.coverageAmount * classBenefit.noOfEmployees
                      )
                    )}
                  </div>
                </div>
                <hr />
                <div className="text-center">
                  <StyledSpanExpansionDelete
                    onClick={() => props.removeEmployerClasses(classBenefit.id)}
                  >
                    <StyledImgDelete src={IconDelete} alt="delete" />
                    <StyledExpansionLabel>Delete</StyledExpansionLabel>
                  </StyledSpanExpansionDelete>
                </div>
              </StyledExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </StyledHideForDesktop>
        <CheckBox
          classLabel="mt-3"
          title="Add a new custom class"
          onChange={props.handleChangeCheckbox}
          checked={props.isOtherClass}
        />
        {/* Add Class Benefits */}
        <div className="w-100">
          <AddClassBenefits
            isValid={props.isValid}
            classBenefit={props.classBenefit}
            getRemainingClasses={props.getRemainingClasses}
            findClassById={props.findClassById}
            onSubmit={props.addClassBenefits}
            handleChangeInputDefineClassBenefits={
              props.handleChangeInputDefineClassBenefits
            }
            handleChangeSelectClassBenefits={
              props.handleChangeSelectClassBenefits
            }
            showSelectPlan={true}
            isOtherClass={props.isOtherClass}
          />
        </div>
      </div>

      <div className="row mt-5">
        <StyledHideForMobile className="col-lg-2">
          <StyledBtnLink to={setupPlanURL} background={theme.secondaryColor}>
            Back
          </StyledBtnLink>
        </StyledHideForMobile>

        <div className="col-lg-7"></div>
        <div className="col-lg-3">
          {/* <StyledBtnLink
            to="/employer/setup/funding"
            background={theme.primaryColor}
            className="float-right"
          >
            Save & Continue
          </StyledBtnLink> */}
          <StyledButtonSubmit
            submit={props.isSubmitValid}
            disabled={!props.isSubmitValid}
            type="submit"
            onClick={props.onSubmitToNextPage}
          >
            Save & Continue
          </StyledButtonSubmit>
        </div>
      </div>
    </div>
  );
}
