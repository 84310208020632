import { AxiosResponse } from "axios";
import {
  getAsync,
  postAsync,
  deleteAsync,
  putAsync,
} from "../cores/utils/http-client";
import {
  ClassBenefit,
  MedicalTravelData,
} from "../components/pages/request-a-quote/request-a-quote-type";
import { UpdateAutoTopUpRequest } from "../components/pages/employer/wallet/auto-top-up/setting-auto-top-up-type";

const URL_Employer = "/manage-employer/api";

export const getEnrollments = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/enrollments`;

  return getAsync(url);
};

export const getEmployerEnrollments = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments`;

  return getAsync(url);
};

export const putEmployerEnrollments = (enrollmentIds: number[]): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments`;

  return putAsync(url, enrollmentIds);
};

export const getClasses = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/classes`;

  return getAsync(url);
};

///////////////////////////////////////////////////////
export const deleteClassBenefits = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits/${id}`;

  return deleteAsync(url);
};

export const getClassBenefits = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits`;

  return getAsync(url);
};

export const postClassBenefits = (
  model: ClassBenefit
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits`;

  return postAsync(url, model);
};

export const updateClassBenefits = (model: any): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits`;

  return putAsync(url, model);
};

/////////////////////////////////////////////////////////
export const getMedicalAndTravelCoverage = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/medical-and-travels`;

  return getAsync(url);
};

export const putMedicalAndTravelCoverage = (
  model: MedicalTravelData[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/medical-and-travels`;

  return putAsync(url, model);
};

export const getMedicalAndTravelCoverageDefault = (): Promise<
  AxiosResponse
> => {
  let url = `${URL_Employer}/employers/medical-and-travels/default`;

  return getAsync(url);
};

export const getGroupInsuranceDefault = (): Promise<
    AxiosResponse
> => {
  let url = `${URL_Employer}/premium-payment/products/providers`;

  return getAsync(url);
};

export const postRequestGroupInsuranceQuote = (model: {productIds: number[], isOptIn: boolean}): Promise<
    AxiosResponse
> => {
  let url = `${URL_Employer}/premium-payment/employer/quote`;

  return postAsync(url, model);
};

export const putRequestGroupInsuranceQuote = (model: {productIds: number[], isUnsubscribeAll: boolean}): Promise<
    AxiosResponse
> => {
  let url = `${URL_Employer}/premium-payment/employer/quote`;

  return putAsync(url, model);
};

export const getBriefPremiumPaymentSubscriptions = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/premium-payment/employer/brief-subscriptions?employerId=${employerId}`;

  return getAsync(url);
};

export const getAutoTopUpDetails = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/enrollment-deposit-group-auto-top-up`;

  return getAsync(url, {});
};

export const putAutoTopUpDetails = (request: UpdateAutoTopUpRequest): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/enrollment-deposit-group-auto-top-up`;

  return putAsync(url, request);
};
