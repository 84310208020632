import React, { Component } from "react";
import {
  Class,
  ClassBenefit,
} from "../../request-a-quote/request-a-quote-type";
import { EmployerBenefitClass } from "./employer-classes-type";
import EmployerBenefitsView from "./employer-classes-view";
import { getDataEmployerBenefits } from "./employer-classes-function";

import ErrorDialog, {
  ErrorDialogModel,
} from "../../../../cores/helpers/error-dialog/error-dialog";

import {
  postClassBenefits,
  deleteClassBenefits,
  getClasses,
} from "../../../../services/employer-benefits-service";
import { usdToCent } from "../../../../cores/helpers/cent-to-usd";
import { addLoading, removeLoading } from "./../../../../cores/utils/loading";

type State = {
  classes: Class[];
  classBenefit: ClassBenefit;
  employerClassBenefits: EmployerBenefitClass[];
  errorMassage: ErrorDialogModel;
  isValid: boolean;
  isOtherClass: boolean;
};

type Props = {};

export default class EmployerBenefits extends Component<Props, State> {
  state: State = {
    classes: [],
    classBenefit: {
      classId: 0,
      planId: 0,
      coverageAmount: 0,
      noOfEmployees: 0,
    } as ClassBenefit,
    employerClassBenefits: [],
    errorMassage: {
      title: "",
      description: "",
    },
    isValid: false,
    isOtherClass: false,
  };

  //componentDidMount
  componentDidMount() {
    this.getData();
  }

  async getData() {
    //add loading
    addLoading();

    let result = await getDataEmployerBenefits();

    this.setState({
      classes: result.classes || [],
      employerClassBenefits: result.classBenefits || [],
    });
  
    // remove loading
    removeLoading();

    this.initCurrentClassBenefit();
  }

  initCurrentClassBenefit = () => {
    //set current class benefit
    let remainingClasses = this.getRemainingClasses();
    let firstClassId = remainingClasses.length ? remainingClasses[0].id : 0;
    // set default DefineClassBenefit
    let defaultDefineClassBenefit: ClassBenefit = {
      classId: firstClassId,
      planId: 0,
      noOfEmployees: 0,
      coverageAmount: 0,
      className: "",
    };

    // set state
    this.setState({
      classBenefit: defaultDefineClassBenefit,
    });
  };

  //region Setup Class Benefits
  // change input
  handleChangeInputDefineClassBenefits = (event: any) => {
    let defineClassBenefit: ClassBenefit = Object.assign(
      this.state.classBenefit
    );
    const { name, value } = event.target;
    if (name === "coverageAmount") {
      defineClassBenefit.coverageAmount = Number(value);
    } else if (name === "className") {
      defineClassBenefit.className = value;
    } else {
      defineClassBenefit.noOfEmployees = Number(value);
    }

    this.setState({ classBenefit: defineClassBenefit });
    this.validatorListener();
  };

  // change select
  handleChangeSelectClassBenefits = (event: any) => {
    const { name, value } = event.target;

    let defineClassBenefit = Object.assign(this.state.classBenefit);

    defineClassBenefit[name] = value;
    this.setState({ classBenefit: defineClassBenefit });
  };

  addClassBenefits = () => {
    let employerClassBenefits: EmployerBenefitClass[] = Object.assign(
      this.state.employerClassBenefits
    );
    let classBenefit = Object.assign({}, this.state.classBenefit, {
      coverageAmount: usdToCent(this.state.classBenefit.coverageAmount),
      classId: this.state.isOtherClass ? 0 : this.state.classBenefit.classId,
    });

    postClassBenefits(classBenefit).then((response) => {
      let employerClassBenefit: EmployerBenefitClass = {
        id: response.data.id,
        classId: classBenefit.classId,
        coverageAmount: usdToCent(this.state.classBenefit.coverageAmount),
        noOfEmployees: this.state.classBenefit.noOfEmployees,
        className: classBenefit.className,
      };
      employerClassBenefits.push(employerClassBenefit);

      this.setState({
        employerClassBenefits: employerClassBenefits,
        isOtherClass: false,
      });
      this.initCurrentClassBenefit();
    });
  };

  // delete class
  removeEmployerClasses = (id: number) => {
    deleteClassBenefits(id).then(() => {
      let employerClassBenefits: EmployerBenefitClass[] = Object.assign(
        this.state.employerClassBenefits
      );
      let employerClassBenefitIndex = employerClassBenefits.findIndex(
        (employerClassBenefit) => {
          return employerClassBenefit.id === id;
        }
      );

      employerClassBenefits.splice(employerClassBenefitIndex, 1);
      this.setState({ employerClassBenefits: employerClassBenefits });

      this.getClasses();
    });
  };

  async getClasses() {
    let resultClasses = (await getClasses()) as any;
    let classes: Class[] = resultClasses.data || [];

    this.setState({ classes: classes });

    this.initCurrentClassBenefit();
  }
  getRemainingClasses = () => {
    let selectClasses = this.state.employerClassBenefits.map(
      (classBenefit) => classBenefit.classId
    );
    let remainingClasses = this.state.classes.filter(
      (classItem) => selectClasses.indexOf(classItem.id) === -1
    );
    return remainingClasses;
  };

  findClassById = (idClass: number): { label: string; value: number } => {
    let valueSelect = { label: "", value: 0 };

    let classItem = this.state.classes.find((classBenefit: Class) => {
      return classBenefit.id === idClass;
    });
    if (!classItem) {
      return {
        label: "",
        value: 0,
      };
    }
    valueSelect.label = classItem.name;
    valueSelect.value = classItem.id;

    return valueSelect;
  };
  //endRegion Setup Class Benefits

  validatorListener = () => {
    let isValid = this.validateCurrentClassBenefit();
    this.setState({ isValid: isValid });
  };

  validateCurrentClassBenefit = (): boolean => {
    if (
      this.state.classBenefit.coverageAmount &&
      ((this.state.isOtherClass && this.state.classBenefit.className) ||
        this.state.classBenefit.classId > 0)
    ) {
      return true;
    }
    return false;
  };

  handleChangeCheckbox = (event: any): void => {
    const { checked } = event.target;
    let classBenefit: ClassBenefit = Object.assign(this.state.classBenefit);
    if (!checked) {
      let remainingClasses = this.getRemainingClasses();
      let firstClassId = remainingClasses.length ? remainingClasses[0].id : 0;
      classBenefit.classId = firstClassId;
      classBenefit.className = "";
    } else {
      classBenefit.classId = 0;
    }
    this.setState({ isOtherClass: checked, classBenefit: classBenefit });
    this.validatorListener();
  };

  render() {
    const { errorMassage } = this.state;
    return (
      <div>
        <EmployerBenefitsView
          classBenefit={this.state.classBenefit}
          defineClassBenefits={this.state.employerClassBenefits}
          findClassById={this.findClassById}
          removeEmployerClasses={this.removeEmployerClasses}
          getRemainingClasses={this.getRemainingClasses}
          addClassBenefits={this.addClassBenefits}
          handleChangeInputDefineClassBenefits={
            this.handleChangeInputDefineClassBenefits
          }
          handleChangeSelectClassBenefits={this.handleChangeSelectClassBenefits}
          isValid={this.state.isValid}
          validatorListener={this.validatorListener}
          handleChangeCheckbox={this.handleChangeCheckbox}
          isOtherClass={this.state.isOtherClass}
        />
        <ErrorDialog
          title={errorMassage.title}
          description={errorMassage.description}
        />
      </div>
    );
  }
}
