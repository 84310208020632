import React from "react";
import styled from "styled-components";
import FileActions from "./FileActions";
import PdfIcon from "../../../../cores/config/component/images/icon-pdf.png"

export const StyledDocumentBox = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #ecedf0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPictureAsPdfIcon = styled.img`
  margin-bottom: 8px;
`;

const StyledTextBox = styled.div`
  position: relative;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :after {
    position: absolute;
    content: "";
    height: 2px;
    background-color: ${props => props.theme.grey[300]};
    width: 60%;
    top: 100%;
    left: 20%;
  }
`;

export const PdfThumbnailComponent = (props: { title: string, url: string }) => {
  return (props.url ? (
      <div className="col-md-6 mt-2">
        <StyledDocumentBox>
          <StyledPictureAsPdfIcon alt="pdf-icon" src={PdfIcon}/>
          <StyledTextBox>
            <div className="fw-bold">{props.title}</div>
            <div className="mt-2 mb-3">
              {` `}
            </div>
          </StyledTextBox>
          <FileActions {...props} />
        </StyledDocumentBox>
      </div>
    ) : null
  )
}