// ImageModal.tsx
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {BNPModal} from "../../../shared/BNPModal/BNPModal";
import { Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const ModalContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
`;

const MainImageWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
`;

const ThumbnailList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Thumbnail = styled.img<{ selected: boolean }>`
  width: 100px;
  height: auto;
  margin: 0 10px;
  border: ${(props) => (props.selected ? '2px solid blue' : '2px solid transparent')};
  cursor: pointer;

  &:hover {
    border-color: gray;
  }
`;

interface ImageModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  images: string[];
  selected: number;
  setSelected: (index: number) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
                                                 open,
                                                 onClose,
                                                 title = "View Images",
                                                 images,
                                                 selected,
                                                 setSelected,
}) => {

  return (
    <BNPModal
      open={open}
      title={title}
      handleClose={onClose}
      body={
        <ModalContainer>
          <MainImageWrapper>
            <MainImage src={images[selected]} alt="Main View" />
          </MainImageWrapper>
          <CarouselNavigation
            totalImages={images.length}
            currentIndex={selected}
            setCurrentIndex={setSelected}
          />
          <ThumbnailList>
            {images.map((image, index) => (
              <Thumbnail
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                selected={index === selected}
                onClick={() => setSelected(index)}
              />
            ))}
          </ThumbnailList>
        </ModalContainer>
      }
      footer={<> </>}
    />
  );
};

export default ImageModal;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavButton = styled(Button)`
  aspect-ratio: 1/1;

  &.MuiButton-containedPrimary {
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.grey[200]};
    color: ${(props) => props.theme.grey[300]};
    width: 40px;
    box-shadow: none;
      
    :hover {
      background-color: ${(props) => props.theme.grey[200]};
      border: 1px solid ${(props) => props.theme.grey[200]};
      opacity: 0.8;
    }
  }
    
  &.MuiButton-root {
    min-width: 40px;
  }
`;

const IndexDisplay = styled.div`
  margin: 0 20px;
`;

type CarouselNavigationProps = {
  totalImages: number;
  currentIndex: number;
  setCurrentIndex: any;
}

const CarouselNavigation = ({ totalImages, currentIndex, setCurrentIndex }: CarouselNavigationProps) => {

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex: number) => {
      return prevIndex > 0 ? prevIndex - 1 : totalImages - 1
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex < totalImages - 1 ? prevIndex + 1 : 0));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowLeft') {
      handlePrev();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    }
  };

  return (
    <CarouselContainer>
      <NavButton onClick={handlePrev} variant="contained" color="primary">
        <ChevronLeft />
      </NavButton>
      <IndexDisplay>{`${currentIndex + 1} / ${totalImages}`}</IndexDisplay>
      <NavButton onClick={handleNext} variant="contained" color="primary">
        <ChevronRight />
      </NavButton>
    </CarouselContainer>
  );
};
