import React from 'react';
import styled from "styled-components";

const SummarizedItem = styled.div`
  height: 100%;
  background-color: #F7F7F8;
  border-radius: 4px;
  padding-top:24px;
  padding-bottom:24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 2px 1px rgba(28, 73, 6, 0.08);
`;

const SummarizedItemTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 16px;
  color: ${props => props.theme.grey[500]}
  white-space: nowrap;
`;

const SummarizedItemContent = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 31px;
`;

function ReportSummarizedItem(props: any) {
  return (
    <div className="col-md mt-2">
      <SummarizedItem>
        <SummarizedItemTitle>{props.title}</SummarizedItemTitle>
        <SummarizedItemContent>{props.content}</SummarizedItemContent>
      </SummarizedItem>
    </div>
  );
}

export default ReportSummarizedItem;