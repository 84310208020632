export function formatPhoneNumber(phoneNumber: string): string {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let match = cleaned.match(/^([1-9]|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+" + match[1] + " " : "";

    return [intlCode, "", match[2], "-", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}

export function formatValueInputMask(phoneNumber: string): string {
  let regex = /( |-|_)/g;
  return phoneNumber.replace(regex, "");
}
