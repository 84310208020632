import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import InfoIcon from "@material-ui/icons/Info";
import {
  StyledButtonCloseModal,
  StyledButtonSubmit,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal
} from "../../../components/shared/employer-styled";
import BNPDatePicker from "../date-picker/date-picker";
import {employeeActionEnum} from "../../../components/pages/employer/employees/employee-view";
import {Employee} from "../../../components/pages/employer/employees/employee-type";
import {toUTCTime} from "../to-utc-time";
import SuspendHistory from "../../../components/pages/employer/employees/suspend-history/SuspendHistory";

type Props = {
  employee: Employee | null;
  type?: employeeActionEnum;
  title: string | JSX.Element;
  content: string | JSX.Element;
  handleOnOk: any;
  secondButtonName?: string;
  handleSecondAction?: any;
  getEmployeesEmployer: () => void;
};

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  ${(props: { disabled: boolean }) =>
          props.disabled &&
          css`
      opacity: 0.8;
    `}
  
`;
const StyledOptionRejected = styled(StyledButtonSubmit)`
  color: ${props => props.theme.secondaryColor};
  background-color: transparent;
  border: none;
  :focus {
    outline: 0 !important;
  }
`;
const StyledSpanDateTitle = styled.span`
  font-weight: bold;
  color: ${props => props.theme.grey[500]};
  margin-bottom: 8px;
`;
const StyledSpanLegend = styled.span`
  color: ${props => props.theme.grey[500]};
  margin-top: 8px;
  font-size: 12px;
  .MuiSvgIcon-fontSizeSmall {
    font-size: 16px;
    margin-right: 4px;
  }
`;
const StyledModal = styled.div`
  z-index: 1100 !important;
  .modal-dialog {
    max-width: 650px;
  }
`;

export function ConfirmEmployeeActionDialog(props: Props) {
  const [fromDate, setFromDate] = useState<Date | string | null>(null);
  const [toDate, setToDate] = useState<Date | string | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  useEffect(() => {
    setToDate(null);
    setValid(false);
    initFromDate();
    if (props.type === employeeActionEnum.cancelNow ||
        props.type === employeeActionEnum.archive ||
        props.type === employeeActionEnum.unSuspend) setValid(true);
  }, [props.type, props.employee])

  useEffect(() => {
    if (props.employee  && fromDate && fromDate !== props.employee.benefitCancellationDate)
      setValid(checkIsValid())
  }, [fromDate, toDate])

  function initFromDate() {
    if (props.type === employeeActionEnum.cancel && props.employee) {
      setFromDate(props.employee.benefitCancellationDate || toUTCTime(new Date()));
      setValid(true);
    } else {
      setFromDate(null);
    }
  }

  function handleChangeDate(date: Date |  null, name: string) {
    if (name === "fromDate" && date) setFromDate(toUTCTime(new Date(date)));
    if (name === "toDate" && date) setToDate(toUTCTime(new Date(date)));
  }

  function onSubmit() {
    props.handleOnOk(fromDate && fromDate, toDate && toDate);
    setFromDate(null);
    setToDate(null);
    closeConfirmModal();
  }

  function checkIsValid() {
    if (props.type === employeeActionEnum.cancel && fromDate) return true;
    if (props.type === employeeActionEnum.suspend && fromDate && toDate) return true;
    return false;
  }

  return (
    <StyledModal className="modal" id="confirmModal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal className="text-left">
              {props.title}
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal
              name="btn-close"
              onClick={closeConfirmModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body text-left">
            {props.content}
            {props.type === employeeActionEnum.suspend &&
                <InputSuspendDate handleChangeDate={handleChangeDate}
                                  fromDate={fromDate}
                                  toDate={toDate}
                                  employee={props.employee}
                />}
            {props.type === employeeActionEnum.cancel &&
                <InputBenefitCancellationDate handleChangeDate={handleChangeDate}
                                              fromDate={fromDate}
                                              employee={props.employee}
                />}
          </div>
          {/* end body */}
          {/* footer */}
          <div className="modal-footer justify-content-between border-0">
            <div className="w-50">
              {props.secondButtonName && <StyledOptionRejected
                  className="text-left"
                  type="button"
                  submit={true}
                  onClick={props.handleSecondAction}
              >
                {props.secondButtonName}
              </StyledOptionRejected>}
            </div>
            <StyledWidthDiv>
              <StyledOptionApproved
                className={isValid ? "cur" : ""}
                type="button"
                submit={true}
                onClick={onSubmit}
                name="btn-ok"
                disabled={!isValid}
              >
                Confirm
              </StyledOptionApproved>
            </StyledWidthDiv>
          </div>
          {/* end footer */}
          {props.type === employeeActionEnum.suspend &&
            <div className="modal-body text-left">
                  <SuspendHistory
                      employee={props.employee}
                      getEmployeesEmployer={props.getEmployeesEmployer} />
            </div>
          }
        </div>
      </div>
    </StyledModal>
  );
}

export function showConfirmModal() {
  $("#confirmModal").attr("style", "display: block; overflow: auto;");
  $("body").append(
    '<div id="modalBackdropConfirm" style="z-index: 1090" class="modal-backdrop show"></div>'
  );
}
export function closeConfirmModal() {
  $("#confirmModal").removeAttr("style");
  $("#modalBackdropConfirm").remove();
}

function InputSuspendDate (props: any) {
  return (
    <div className="row pt-3">
      <div className="col-6">
        <StyledSpanDateTitle>Start date</StyledSpanDateTitle>
        <BNPDatePicker
          onChange={props.handleChangeDate}
          value={props.fromDate}
          placeholder="Start Date"
          name="fromDate"
          tabIndex={1}
          maxDate={props.employee && props.employee.benefitCancellationDate} />
      </div>
      <div className="col-6">
        <StyledSpanDateTitle>End date</StyledSpanDateTitle>
        <BNPDatePicker
          disabled={!props.fromDate}
          onChange={props.handleChangeDate}
          value={props.toDate}
          placeholder="End Date"
          name="toDate"
          tabIndex={1}
          minDate={props.fromDate}
          maxDate={props.employee && props.employee.benefitCancellationDate}
        />
      </div>
    </div>
  )
}

function InputBenefitCancellationDate (props: any) {

  return (
    <div className="row pt-3">
      <div className="col-12">
        <StyledSpanDateTitle>Benefit Cancellation Date</StyledSpanDateTitle>
        <BNPDatePicker
          onChange={props.handleChangeDate}
          value={props.fromDate}
          placeholder="Benefit Cancellation Date"
          name="fromDate"
          tabIndex={1}
          minDate={new Date()}
          disabled={!!props.employee && props.employee.status === 'TERMINATED'}
        />
        <div className="mt-1">
          <StyledSpanLegend>
            <InfoIcon fontSize="small" color="primary"/>
            Employees will have 14 days after the <b>Benefit Cancellation Date</b> to submit their eligible claims.
          </StyledSpanLegend>
        </div>
      </div>
    </div>
  )
}