import styled, { css } from "styled-components";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import theme from "../../cores/helpers/theme";
import { TextValidator } from "react-material-ui-form-validator";
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Breadcrumbs,
} from "@material-ui/core";

export const StyledApp = styled.div`
  color: ${(props) => props.theme.primaryColor};
  /* background-color: #fff; */
  font-family: ${(props) => props.theme.textFont};
  color: #2d2d2d;
  font-size: 14px;
  letter-spacing: 0.00003px;

  input,
  textarea,
  ::placeholder,
  text,
  a {
    font-family: ${(props) => props.theme.textFont} !important;
  }
`;

export const LandingStyledTextValidator = styled(TextValidator)`
  .MuiFormControl-root {
    max-width: 100% !important;
    width: 100%;
  }
  .MuiInputBase-input.MuiInput-input {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #2d2d2d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .MuiInputBase-input.MuiInput-input:focus {
    color: #2d2d2d;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .MuiInputBase-input.MuiInput-input::placeholder {
    color: #2d2d2d;
    font-size: 14px;
  }
`;

export const StyledMainContent = styled.div`
  text-align: left;
  @media (min-width: 320px) {
    padding: 0;
  }
  @media (min-width: 768px) {
    padding: 2em 1em;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 320px) {
    width: 100%;
  }
  @media (min-width: 1920px) {
    width: 80%;
  }
`;

export const StyledBigTitle = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  text-align: left;
  font-family: ${(props) => props.theme.headlineFont};
`;
export const StyledSpanBigTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  text-align: left;
  font-family: ${(props) => props.theme.headlineFont};
`;

export const StyledContentBox = styled.div`
  background: #fff;
  padding: 25px 21px;
  border-radius: 5px;
  height: 100%;

  ${(props: { isBorder: boolean }) =>
    props.isBorder &&
    css`
      border: 1px solid #ecedf0;
    `}
`;

export const StyledLargerTitle = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  text-align: left;
  font-family: ${(props) => props.theme.subheadFont};
`;

export const StyledSpanLargerTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  font-family: ${(props) => props.theme.subheadFont};
`;

export const StyledSpanRightTitle = styled.span`
  margin-top: 6px;
  text-align: right;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};

  :hover {
    opacity: 0.7;
  }
`;

export const StyledButtonSubmit = styled.button`
  width: 100%;
  position: relative;
  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  color: #96aac9;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;

  ${(props: { submit: boolean }) =>
    props.submit &&
    css`
      background-color: ${theme.primaryColor};
      color: #ffffff;

      :hover {
        opacity: 0.8;
      }
      a {
        color: #ffffff;
      }
    `}
`;

export const StyledTextSubmit = styled.button`
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: transparent !important;
  border: none;
  outline: none !important;
  font-weight: bold;
  color: #2572C6 !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;

  background-color: ${theme.primaryColor};
  color: #ffffff;

  :hover {
    opacity: 0.8;
  }
  a {
    color: #ffffff;
  }
`;

export const StyledHeaderTitleModal = styled.span`
  position: absolute;
  top: 18px;
  color: ${(props) => props.theme.primaryColor};
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  font-family: ${(props) => props.theme.headlineFont};
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const StyledHeaderModal = styled.div`
  height: 70px;
  display: block !important;
  text-align: left;
`;

export const StyledPTitle = styled.p`
  color: #2d2d2d;
  font-weight: bold;
  height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledButtonCloseModal = styled.button`
  border: none;
  padding: 0;
  height: 100%;
  float: right;
  width: 50px;
  background-color: transparent;
`;
export const StyledIconCloseModal = styled.span`
  font-size: 24px;
`;
export const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #2d2d2d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props: { styleDisabled: boolean }) =>
    props.styleDisabled &&
    css`
      opacity: 0.7;
      background-color: #f3f6f8;
    `
  }
`;

export const StyledInputMask = styled(InputMask)`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2d2d2d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  ::placeholder {
    text-transform: none;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPError = styled.p`
  color: ${(props) => props.theme.tertiaryColor};
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
`;

export const StyledLink = styled(Link)`
  height: 60px;
  padding: 15px 21px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.secondaryColor};

  :hover {
    color: ${(props) => props.theme.secondaryColor};
  }
`;

export const StyledBtnLink = styled(Link)`
  width: 100%;
  position: relative;
  color: #ffffff;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.9rem 2rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;
  :hover {
    opacity: 0.8;
    color: #ffffff;
    text-decoration: none;
  }

  ${(props: { background: string }) =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
`;
export const StyledSpanTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`;

export const StyledHideForMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyledHideForDesktop = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const StyledTitleExpansion = styled.div`
  opacity: 0.3;
  color: ${(props) => props.theme.primaryColor};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 13px;
`;

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: block !important;
`;
export const StyledImgDelete = styled.img`
  width: 14px;
  margin-right: 0.5rem;
`;
export const StyledSpanExpansionDelete = styled.label`
  color: ${(props) => props.theme.secondaryColor};
`;
export const StyledSpanExpansionEdit = styled.label`
  color: ${(props) => props.theme.primaryColor};
`;
export const StyledExpansionLabel = styled.label`
  position: relative;
  top: 2px;
`;
export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 !important;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  span {
    font-family: ${(props) => props.theme.textFont};
  }
`;

export const StyledChangeDetail = styled(Link)`
  width: 100%;
  color: ${(props) => props.theme.primaryColor};

  :hover {
    background-color: #e8edf2;
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-family: ${(props) => props.theme.textFont};
  p,
  li {
    font-family: ${(props) => props.theme.textFont};
  }
`;

export const StyledCell = styled.div`
  padding: 0.5em;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  ${(props: { width: string }) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

export const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #2d2d2d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledErrorMessage = styled.div`
  font-size: 12px;
  margin-top: 8px;
  color: ${props => props.theme.errorColor};
`;

export const StyledSpanStatus = styled.span`
  font-weight: bold;
  color: ${(props: any) => {
    switch (props.title) {
      case "TERMINATED":
        return props.theme.errorColor;
      case "TERMINATING":
        return props.theme.grey[500];
      case "SUSPEND":
        return props.theme.warningColor;
      case "WORKING":
        return props.theme.successColor;
      case "QUOTED":
        return props.theme.grey[500];
      case "ACTIVE":
        return props.theme.successColor;
      case "CANCELLED":
        return props.theme.warningColor;
      default:
        return props.theme.grey[500];
    }
}}
`;
