import {SortTable} from "../../employer/employees/employee-model";

export const columnsClaim: SortTable[] = [
  {
    columnId: "employeeName",
    columnName: "Employee",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "paidDate",
    columnName: "Paid Date",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "taxibility",
    columnName: "Taxibility",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "claimAmount",
    columnName: "Claim Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "adminFee",
    columnName: "Admin Fee",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "gst",
    columnName: "GST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "hst",
    columnName: "HST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "rst",
    columnName: "RST",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "premiumTax",
    columnName: "Premium Tax",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "totalAmount",
    columnName: "Total Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
];