import {AxiosResponse} from "axios";
import {getAsync, getFileAsync} from "../cores/utils/http-client";
import {SearchParamType} from "../components/pages/report/ReportType";
import {TableSearchParamType} from "../components/shared/BNPTable/BNPTableType";

const URL_Wallet = "/manage-wallet-partner/employer/wallet";

export const getPayrollReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/payroll/report`;

  return getAsync(url, params);
};

export const exportPayrollReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/payroll/report/export`;

  return getFileAsync(url, params);
};

export const getPayrollSummarize = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/payroll/summarize`;

  return getAsync(url, params);
};

export const getClaimReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/claim/report`;

  return getAsync(url, params);
};

export const exportClaimReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/claim/report/export`;

  return getFileAsync(url, params);
};

export const getClaimSummarize = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/claim/summarize`;

  return getAsync(url, params);
};

export const getTransactionReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/transaction/report`;

  return getAsync(url, params);
};

export const exportTransactionReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/transaction/report/export`;

  return getFileAsync(url, params);
};

export const getTransactionSummarize = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/transaction/summarize`;

  return getAsync(url, params);
};

export const getISSPendingClaimsWithTaxes = ( params: TableSearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/pending-claim/report`;

  let returnParams = {
    page: params.page,
    perPage: params.perPage,
    from: params.from,
    to: params.to,
    filter: params.filter,
    searchName: params.searchKey,
    columnName: params.columnName,
    sortType: params.sortType,
  };
  return getAsync(url, returnParams);
};

export const getExportISSPendingClaimsWithTaxes = ( params: TableSearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/pending-claim/report/export`;

  let returnParams = {
    page: 0,
    perPage: params.perPage,
    from: params.from,
    to: params.to,
    filter: params.filter,
    searchName: params.searchKey,
    columnName: params.columnName,
    sortType: params.sortType,
  };
  return getFileAsync(url, returnParams);
};

export const getGroupInsuranceSummaryReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/group-insurance/summarize/report`;

  return getAsync(url, params);
};

export const exportGroupInsuranceSummaryReport = ( params: SearchParamType): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/group-insurance/summarize/report/export`;

  return getFileAsync(url, params);
};