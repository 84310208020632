import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import {
  PlanPreferencesModel,
  PlanPreferences,
  RequestPlanPreferences
} from "./plan-preferences-type";

import {
  StyledSpanLargerTitle,
  StyledFormControlLabel,
  StyledContentBox
} from "../../../../shared/employer-styled";
import styled from "styled-components";
import { Input } from "@material-ui/core";

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
`;
const StyledDescription = styled(RadioGroup)`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 0;
  }
`;
const StyledInputTextField = styled(Input)`
  font-size: 14px;
  border-bottom: 1px solid;
`;

type Props = {
  planPreferencesByEmployer: RequestPlanPreferences[];
  planPreferences: PlanPreferencesModel[];
  handleChangePlanPreference: (event: any, planPreferences: PlanPreferences[]) => void;
  findIdCheckedPlanPreferences: (planPreferences: PlanPreferences[]) => number;
  handleChangeInput: (event: any, id: number) => void;
  isSetup: boolean;
};
export default function EmployerPlanPreferences(props: Props) {
  const findName = (id: number, planPreference: PlanPreferencesModel) => {
    let current = planPreference.planPreferences.find(plan => {
      return id === plan.id;
    });
    return current ? current.name : "";
  };

  const checkDisable = (planPreference: PlanPreferencesModel): boolean => {
    if (props.isSetup) return true;

    let ids: number[] = planPreference.planPreferences.map(i => i.id);
    let find = props.planPreferencesByEmployer.filter(t => ids.includes(t.planPreferenceId));
    if (find === null || find.length === 0) {
      return true;
    }
    return find[0].allow;
  }

  return (
    <StyledContentBox isBorder={props.isSetup}>
      <div>
        <StyledSpanLargerTitle>Plan Preferences</StyledSpanLargerTitle>
      </div>
      <div className="mt-3">
        {props.planPreferences.map((planPreference: PlanPreferencesModel) => {
          return (
            <div key={planPreference.id}>
              <StyledTitle>
                {planPreference.name}
                {planPreference.description && (
                  <Tooltip
                    title={planPreference.description}
                    placement="right"
                    className="ml-2 mb-1"
                  >
                    <InfoIcon className="icon" />
                  </Tooltip>
                )}
              </StyledTitle>
              <FormControl component="fieldset">
                <StyledDescription
                  aria-label={planPreference.id.toString()}
                  name={planPreference.id.toString()}
                  value={
                    planPreference.planPreferences
                      ? props.findIdCheckedPlanPreferences(
                        planPreference.planPreferences
                      )
                      : ""
                  }
                  onChange={event =>
                    props.handleChangePlanPreference(
                      event,
                      planPreference.planPreferences
                    )
                  }
                >
                  {planPreference.planPreferences.map(planPreferenceItem => (
                    <span key={planPreferenceItem.id}>
                      <StyledFormControlLabel
                        value={planPreferenceItem.id}
                        control={<Radio color="primary" />}
                        label={planPreferenceItem.name}
                        disabled={
                          !props.isSetup &&
                          (!checkDisable(planPreference) 
                          || Number(moment().format("MMDD")) > 115)
                        }
                      />
                      {planPreferenceItem.description && (
                        <Tooltip
                          title={planPreferenceItem.description}
                          placement="right"
                        >
                          <InfoIcon className="icon ml-2" />
                        </Tooltip>
                      )}
                    </span>
                  ))}
                </StyledDescription>
                {props.planPreferencesByEmployer.map(planPreferenceItem =>
                  findName(
                    planPreferenceItem.planPreferenceId,
                    planPreference
                  ) === "Other" ? (
                      <StyledInputTextField
                        key={planPreferenceItem.planPreferenceId}
                        value={planPreferenceItem.otherValue}
                        onChange={event =>
                          props.handleChangeInput(
                            event,
                            planPreferenceItem.planPreferenceId
                          )
                        }
                        placeholder={"Other"}
                        name="otherValue"
                        type="number"
                        className="otherValue"
                        tabIndex={-1}
                        role="application"
                        aria-hidden="true"
                      />
                    ) : null
                )}
              </FormControl>
            </div>
          );
        })}
      </div>
    </StyledContentBox>
  );
}
