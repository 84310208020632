import React, { useState } from "react";
import styled from "styled-components";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { StyledButtonSubmit } from "../../shared/employer-styled";
import { terminateEmployer } from "../../../services/employer-setting-service";

const StyledVerifyPage = styled.div`
  height: calc(100vh - 75px);
`;
const StyledVerifiedUserIcon = styled(RemoveCircleOutlineIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${(props) => props.theme.primaryColor};
`;
const StyledDivGroupVerify = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: 320px) {
    top: 50px;
  }
  @media (min-width: 767px) {
    top: 30%;
  }
`;

const StyledRequestMessage = styled.div`
  margin-top: 1rem;
`;

const StyledNoticeMessage = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.secondaryColor};
`;

const StyledTitleMessage = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledDescriptionMessage = styled.div`
  color: #2d2d2d;
  margin-top: 18px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

type Props = any;

export default function TerminateEmployer(props: Props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const token = window.location.search.split("=")[1];

  async function onSubmitTerminateEmployer(e: any) {
    e.preventDefault();
    try {
      await terminateEmployer(token);
      setIsSubmitted(true);
      setIsSubmitSuccess(true);
    } catch (error) {
      setIsSubmitSuccess(false);
    }
  }

  return (
    <StyledVerifyPage>
      <StyledDivGroupVerify>
        <>
          <StyledVerifiedUserIcon />
          {!isSubmitted ? (
            <StyledRequestMessage>
              <StyledTitleMessage>
                Terminate your company account at Beniplus
              </StyledTitleMessage>
              <StyledDescriptionMessage>
                <StyledNoticeMessage className="mb-3">
                  If you click the button, your company account will be
                  immediately terminated.
                </StyledNoticeMessage>
                <StyledButtonSubmit
                  style={{ width: "250px" }}
                  type="button"
                  submit
                  onClick={onSubmitTerminateEmployer}
                >
                  Terminate Account
                </StyledButtonSubmit>
              </StyledDescriptionMessage>
            </StyledRequestMessage>
          ) : (
            <StyledRequestMessage>
              <StyledNoticeMessage>
                {isSubmitSuccess
                  ? "You have already terminated your company account at Beniplus."
                  : "Some error occur when terminate your company account. Please try again later."}
              </StyledNoticeMessage>
            </StyledRequestMessage>
          )}
        </>
      </StyledDivGroupVerify>
    </StyledVerifyPage>
  );
}
