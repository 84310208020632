import React from "react";
import styled from "styled-components";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ListAltIcon from "@material-ui/icons/ListAlt";
import RoomIcon from "@material-ui/icons/Room";
import { Link } from "react-router-dom";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";

const StyledDivFooter = styled.div`
  position: relative;
  background-color: #e8edf2;

  @media (min-width: 600px) {
    width: calc(100% - 240px);
    margin-left: 240px;
    padding-left: 32px;
  }
`;

const StyledDivTitleFooter = styled.div`
  color: ${(props) => props.theme.primaryColor};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const StyledULFooter = styled.ul`
  padding: 0;
`;
const StyledLIFooter = styled.li`
  list-style-type: none;
  margin-top: 10px;
`;
const StyledAFooter = styled.a`
  color: #2d2d2d;
  :hover {
    text-decoration: none;
    color: #2d2d2d;
  }
`;
const StyledLinkFooter = styled(Link)`
  color: #2d2d2d;
  :hover {
    text-decoration: none;
    color: #2d2d2d;
  }
`;

export function Footer(props: any) {
  function getBeniplusFooter() {
    return (
      <StyledDivFooter id="footer">
        <div className="row m-0">
          <div className="col-lg-3 mt-3 p-0">
            {/* <StyledFooterLogo>
              <img alt="footer-logo" src={FooterLogo} />
            </StyledFooterLogo> */}
          </div>
          <div className="col-lg-3 mt-3 p-0">
            <StyledDivTitleFooter>Address</StyledDivTitleFooter>
            <StyledULFooter>
              <StyledLIFooter>
                <RoomIcon className="mr-2" />
                <span>1101-67 Yonge St, Toronto, ON M5E 1J8</span>
              </StyledLIFooter>
            </StyledULFooter>
          </div>
          <div className="col-lg-3 mt-3 p-0">
            <StyledDivTitleFooter>CONTACT INFORMATION</StyledDivTitleFooter>
            <StyledULFooter>
              <StyledLIFooter>
                <StyledAFooter href="tel:1-888-859-3579">
                  <PhoneIphoneIcon className="mr-2" />
                  1-888-859-3579
                </StyledAFooter>
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledAFooter href="mailto:info@beniplus.ca?subject=BeniPlus Inquiry">
                  <EmailIcon className="mr-2" />
                  info@beniplus.ca
                </StyledAFooter>
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledLinkFooter to="/contact">
                  <ListAltIcon className="mr-2" />
                  Contact Us
                </StyledLinkFooter>
              </StyledLIFooter>
            </StyledULFooter>
          </div>
          <div className="col-lg-3 mt-3 p-0"></div>
        </div>
      </StyledDivFooter>
    )
  }


  function getGTDFooter() {
    return (
      <StyledDivFooter id="footer">
        <div className="row m-0">
          <div className="col-lg-3 mt-3 p-0">
            {/* <StyledFooterLogo>
              <img alt="footer-logo" src={FooterLogo} />
            </StyledFooterLogo> */}
          </div>
          <div className="col-lg-3 mt-3 p-0">
            <StyledDivTitleFooter>For General Inquiries</StyledDivTitleFooter>
            <StyledULFooter>
              <StyledLIFooter>
                <StyledLIFooter>
                  <ListAltIcon className="mr-2" />
                  Your Gotohealthwallet Team
                </StyledLIFooter>
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledAFooter href="mailto:gotohealthwallet@gotodoctor.ca?subject=General Inquiry">
                  <EmailIcon className="mr-2" />
                  gotohealthwallet@gotodoctor.ca
                </StyledAFooter>
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledAFooter href="tel:1-833-820-8800">
                  <PhoneIphoneIcon className="mr-2" />
                  1-833-820-8800
                </StyledAFooter>
              </StyledLIFooter>
            </StyledULFooter>
          </div>
          <div className="col-lg-3 mt-3 p-0">
            <StyledDivTitleFooter>For Technical Support</StyledDivTitleFooter>
            <StyledULFooter>
              <StyledLIFooter>
                  <ListAltIcon className="mr-2" />
                  BeniPlus
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledAFooter href="mailto:info@beniplus.ca?subject=Technical Support">
                  <EmailIcon className="mr-2" />
                  info@beniplus.ca
                </StyledAFooter>
              </StyledLIFooter>
              <StyledLIFooter>
                <StyledAFooter href="tel:888-859-3579">
                  <PhoneIphoneIcon className="mr-2" />
                  888-859-3579
                </StyledAFooter>
              </StyledLIFooter>
            </StyledULFooter>
          </div>
          <div className="col-lg-3 mt-3 p-0"></div>
        </div>
      </StyledDivFooter>
    )
  }

  if (!isBeniplusUI) {
    return getGTDFooter(); 
  }
  return getBeniplusFooter();
}
