import React from "react";
import { Route } from "react-router-dom";
import {} from "react";
import LayoutEmployer from "./employee-layout";

const EmployeeRouteLayout = ({
  component: Component,
  history,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <LayoutEmployer>
          <Component {...matchProps}></Component>
        </LayoutEmployer>
      )}
    />
  );
};

export default EmployeeRouteLayout;
