import {SortTable} from "../../employer/employees/employee-model";

export const columnsTransaction: SortTable[] = [
  {
    columnId: "title",
    columnName: "Title",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "description",
    columnName: "Description",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
  {
    columnId: "totalAmount",
    columnName: "Total Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "transactionType",
    columnName: "Transaction Type",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "createdDate",
    columnName: "Paid Date",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
];