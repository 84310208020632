import React, {useEffect, useMemo, useState} from "react";
import { StyledContentBox } from "../../../../shared/employer-styled";
import styled from "styled-components";


const StyledMainWrapper = styled(StyledContentBox)`
  height: auto;
  margin-bottom: 10px;
`

const StyledContentWrapper = styled.div`
  background-color: #EAF1FB;
  border-radius: 4px;
  padding: 12px;
` 

const StyledOptInButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0px;
  color: #2572C6;
  font-family: Graphik Semibold;
  outline: none !important;
  :hover {
    opacity: 0.5;
  }
`

type GroupInsuranceOptInProps = {
  onOptInClick: () => void;
};

export const GroupInsuranceOptIn = React.memo(function GroupInsuranceOptIn(props: GroupInsuranceOptInProps) {

  return (
    <StyledMainWrapper isBorder={false}>
      <StyledContentWrapper>
        <span>Want to apply for Group Insurance? </span>
        <span><StyledOptInButton onClick={props.onOptInClick}>Opt in here</StyledOptInButton></span>
      </StyledContentWrapper>
    </StyledMainWrapper>
  )

});

export default GroupInsuranceOptIn;


