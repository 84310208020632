import React from "react";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit
} from "../../../components/shared/employer-styled";
import styled from "styled-components";

export type ErrorDialogModel = {
  title: string | JSX.Element;
  description: string | JSX.Element;
  handleClose?: any;
};

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.secondaryColor};
  color: #fff;
`;

/**
 * @param title : string | JSX.Element
 * @param description : string | JSX.Element
 * @param handleClose : ()=> void;
 */
export default function ErrorDialog(props: ErrorDialogModel) {
  return (
    <div className="modal text-left" id="errorDialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>{props.title}</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={() => {
                closeErrorModal();
                props.handleClose();
              }}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">{props.description}</div>
          <div className="modal-footer">
            <StyledWidthDiv>
              <StyledOptionRejected
                type="button"
                onClick={() => {
                  closeErrorModal();
                  props.handleClose();
                }}
                submit={false}
              >
                Close
              </StyledOptionRejected>
            </StyledWidthDiv>
          </div>
        </div>
      </div>
    </div>
  );
}
export function showErrorModal() {
  ($("#errorDialog") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static"
  });
}
export function closeErrorModal() {
  ($("#errorDialog") as any).modal("hide");
}
