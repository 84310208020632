import { AxiosResponse } from "axios";
import { getAsync, putAsync, postAsync } from "../cores/utils/http-client";
import { CompanyInfo } from "../components/pages/employer/employer-setting/company-information/company-information-type";
import {BankingInfo, ChequeInfo} from "../components/pages/employer/funding/funding-type";
import {
  EmployerPlan,
  RequestPlanPreferences
} from "../components/pages/employer/plan/plan-preferences/plan-preferences-type";

const URL_Employer = "/manage-employer/api";

export const getProvinces = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces`;

  return getAsync(url);
};

export const getCitiesByProvinceId = (
  provinceId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces/${provinceId}/cities`;

  return getAsync(url);
};

export const getCompanyInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/company-info`;

  return getAsync(url);
};

export const putCompanyInfo = (model: CompanyInfo): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/company-info`;

  return putAsync(url, model);
};

export const getPlan = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/plan`;

  return getAsync(url);
};

export const getPlanPreferences = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/plan-preferences`;

  return getAsync(url);
};
export const getPlanPreferencesByEmployerId = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/plan-preferences`;

  return getAsync(url);
};

export const putEmployersPlanPreferences = (
  model: RequestPlanPreferences[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/plan-preferences`;

  return putAsync(url, model);
};

export const putEmployerPlan = (model: EmployerPlan) => {
  let url = `${URL_Employer}/plan/setup`;
  return putAsync(url, model);
}

export const getPaymentMethod = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/payment-method`;

  return getAsync(url);
};

export const getChequeInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/cheque-info`;

  return getAsync(url);
};

export const putPaymentMethod = (model: {
  paymentMethod: number;
}): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/payment-method`;

  return putAsync(url, model);
};

export const putChequeInfo = (model: ChequeInfo): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/cheque-info`;

  return putAsync(url, model);
};

export const putBankingInfo = (model: BankingInfo): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/banking-info`;
  return putAsync(url, model)
}

export const postFileToS3 = (model: FormData): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/files`;

  return postAsync(url, model);
};

export const getUserRole = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/role`;

  return getAsync(url);
};

export const terminatedEmployeeOfAdmin = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/terminated-admin`;

  return putAsync(url, {});
};

export const terminatedEmployeeOfAdminWithEmployeeId = (employeeId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/terminated-admin/${employeeId}`;

  return putAsync(url, {});
};

export const updateUserRole = (role: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/update-role/${role}`;

  return putAsync(url, {});
};

export const terminateEmployer = (token: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/reject-account/${token}`;

  return putAsync(url, {}, false);
};