import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import { SortTable } from "../../employer/employees/employee-model";
import Pagination, {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagingation/pagination";
import {PayrollType, TPayrollSummarize} from "./PayrollReportType";
import { reportDisplayAmount } from "../../../../cores/helpers/currency/report-display-amount";
import {
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion
} from "../../../shared/employer-styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledCellTable,
  StyledHeaderTable, StyledImportExportIcon, StyledRowBodyTable,
  StyledTable
} from "../../../shared/table-styled";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {reportRef, SearchParamType} from "../ReportType";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { ArrowDropUp } from "@material-ui/icons";
import { ExpansionPanel } from "@material-ui/core";
import { getPayrollReport, getPayrollSummarize, exportPayrollReport } from "../../../../services/employer-report-service";
import {exportExcel} from "../../../../cores/helpers/export-file/export-file";
import moment from "moment";
import {FE_MONTH_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {calculatePeriod} from "../report";
import {defaultSearchParam} from "../ReportModel";

type Props = {
  columns: SortTable[];
  sortTable: (columnId: string) => void;
  changePage: (page: number) => void;
  paging: PagingInfo;
  setPaging: (value: any) => void;
  setRowsPerPage: (event: any) => void;
  searchParams: SearchParamType;
  setSummarizedList: (value: any) => void;
  period: string;
};

const PayrollReport = forwardRef((props: Props, ref: Ref<reportRef> ) => {
  const [payrollList, setPayrollList] = useState<PayrollType[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  useEffect(() => {
    if (props.searchParams.from && props.searchParams.to)
      getReportData().then();

  }, [JSON.stringify(props.searchParams)])

  async function getReportData() {
    addLoading();
    if (isFirstLoad) {
      const firstLoadSearchParams = calculatePeriod(props.period, defaultSearchParam, props.searchParams.from, props.searchParams.to)
      await getPayrollSummarizeData(firstLoadSearchParams);
      await getPayrollReportData(firstLoadSearchParams);
      setIsFirstLoad(false)
    } else {
      await getPayrollSummarizeData(props.searchParams);
      await getPayrollReportData(props.searchParams);
    }

    removeLoading();
  }

  async function getPayrollReportData(searchParams: SearchParamType) {
    try {
      const result = await getPayrollReport(searchParams);
      setPayrollList(result.data.records);
      let pagingInfo = setPagingInfo(
        searchParams.page,
        result.data.pageCount,
        searchParams.perPage,
      )
      props.setPaging(pagingInfo);
    } catch (e) {
      console.log(e)
    }
  }

  async function getPayrollSummarizeData(searchParams: SearchParamType) {
    try {
      const result: TPayrollSummarize = (await getPayrollSummarize(searchParams)).data;
      props.setSummarizedList(
        [
          {
            title: 'Total Claim Amount',
            balance: result ? reportDisplayAmount(result.totalAmount) : "--"
          },
          {
            title: 'Taxable Amount',
            balance: result ? reportDisplayAmount(result.taxableAmount) : "--"
          },
          {
            title: 'Non-taxable Amount',
            balance: result ? reportDisplayAmount(result.nonTaxableAmount) : "--"
          },
          {
            title: 'Number of Claims',
            balance: result ? result.numberOfClaim.toString() : "--"
          },
        ])
    } catch (e) {
      console.log(e)
    }
  }

  useImperativeHandle(ref, () => ({
    exportReport,
  }))

  function exportReport() {
    exportPayrollReport(props.searchParams).then(
      (result) => {
        exportExcel(result.data);
      }
    )
  }

  return (
    <div className="p-0 mt-4">
      {payrollList.length === 0 ?
        <div className="text-center text-danger">There was no record in this report</div> :
        (<div className="row">
          <div className="col-12 mt-2">
            <StyledHideForMobile>
              <StyledTable>
                <StyledHeaderTable>
                  {props.columns.map((column, index) => (
                    <StyledCellTable
                      width={column.width}
                      key={index}
                      className="cur"
                      onClick={() => props.sortTable(column.columnId)}
                    >
                      {column.columnName}
                      <span>
                            {column.sortType ? (
                              column.sortType === "ASC" ? (
                                <StyledArrowDropDownIcon />
                              ) : (
                                <StyledArrowDropUpIcon />
                              )
                            ) : (
                              <StyledImportExportIcon />
                            )}
                          </span>
                    </StyledCellTable>
                  ))}
                </StyledHeaderTable>
                <div>
                  {payrollList.map((payroll, index) => (
                    <StyledRowBodyTable
                      key={index}
                      title={payroll.claimType}
                    >
                      <StyledCellTable
                        width={15}
                        title={moment(payroll.reportMonth).format(FE_MONTH_FORMAT)}
                      >
                        {moment(payroll.reportMonth).format(FE_MONTH_FORMAT)}
                      </StyledCellTable>
                      <StyledCellTable
                        width={20}
                        title={payroll.employeeName}
                      >
                        {payroll.employeeName}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={payroll.claimType}
                      >
                        {payroll.claimType}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={payroll.taxibility}
                      >
                        {payroll.taxibility}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        title={
                          formatterUSD("currency", "USD").format(
                            centToUsd(payroll.paidTotal)
                          )}
                      >
                        {formatterUSD("currency", "USD").format(
                          centToUsd(payroll.paidTotal)
                        )}
                      </StyledCellTable>
                      <StyledCellTable
                        width={15}
                        className="text-right"
                        title={payroll.numberOfClaim.toString()}
                      >
                        {payroll.numberOfClaim}
                      </StyledCellTable>
                    </StyledRowBodyTable>
                  ))}
                </div>
              </StyledTable>
              <div className="text-center mt-4">
                <label>
                  <Pagination
                    pagerPagination={props.paging}
                    getPage={props.changePage}
                    onChangeRowsPerPage={props.setRowsPerPage}
                  />
                </label>
              </div>
            </StyledHideForMobile>
          </div>
          <div className="col-12 mt-2">
            <StyledHideForDesktop>
              {payrollList.map((payroll, index) => (
                <ExpansionPanel
                  square
                  defaultExpanded={false}
                  className="mt-2"
                  key={index}
                >
                  <StyledExpansionPanelSummary
                    className="m-0"
                    expandIcon={<ArrowDropUp />}
                  >
                    <div className="w-100 ">
                      <StyledTitleExpansion>Approved Period</StyledTitleExpansion>
                      <div className="mt-2">{moment(payroll.reportMonth).format(FE_MONTH_FORMAT)}</div>
                    </div>
                  </StyledExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Employee Name
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {payroll.employeeName}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Claim Type
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {payroll.claimType}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Type
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {payroll.taxibility}
                      </div>
                    </div>
                    <div className="w-100 mt-3">
                      <StyledTitleExpansion>
                        Total Claim
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {formatterUSD("currency", "USD").format(
                          centToUsd(payroll.paidTotal)
                        )}
                      </div>
                    </div>
                    <div className="w-100">
                      <StyledTitleExpansion>
                        Number Of Claim
                      </StyledTitleExpansion>
                      <div className="mt-1">
                        {payroll.numberOfClaim}
                      </div>
                    </div>
                  </StyledExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                      pagerPagination={props.paging}
                      getPage={props.changePage}
                      onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </StyledHideForDesktop>
          </div>
        </div>)
      }
    </div>
  );
})

export default PayrollReport;