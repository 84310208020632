import React from "react";
import CompanyInfoEmployerSetting from "./company-information/company-information";
import Funding from "../funding/funding";
import {
  StyledMainContent,
  StyledBigTitle,
} from "../../../shared/employer-styled";
import { RouteChildrenProps } from "react-router";
import { EmployerConfiguration } from "../../../../models/employer-configuration/employer-configuration-model"


export default function EmployerSetting(props: RouteChildrenProps & EmployerConfiguration) {
  return (
    <StyledMainContent>
      <StyledBigTitle>Setting</StyledBigTitle>
      <div className="container-fluid p-0">
        <div className="row">
          <div className={props.enablePresetBankingInformation ? "" : "col-lg-6 mt-4"}>
            <CompanyInfoEmployerSetting {...props} />
          </div>
          {!props.enablePresetBankingInformation
          &&
          <div className="col-lg-6 mt-4">
            <Funding isContinue={false} />
          </div>
          }
        </div>
      </div>
    </StyledMainContent>
  );
}
