import { AxiosResponse } from "axios";
import { getAsync, getFileAsync, postAsync } from "../cores/utils/http-client";
import { Credit } from "../components/pages/employer/wallet/wallet-type";
import {TableSearchParamType} from "../components/shared/BNPTable/BNPTableType";

const URL_Employer = "/manage-wallet-partner/employer/wallet";
const URL_Employee = "/manage-wallet-partner/employee/wallet";

/**
 * available amount
 * get physical balance
 * @param employerId
 */
export const getBalance = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/balance/${employerId}`;

  return getAsync(url);
};

/**
 * pending amount
 * logical balance
 * @param employerId
 */
export const getAllBalances = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/balances/${employerId}`;

  return getAsync(url);
};

export const getTransactionsWithCriteria = (
    employerId: number,
    criteria: TableSearchParamType
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/transactions/${employerId}`;
  let returnParams = {
    page: criteria.page,
    perPage: criteria.perPage,
    from: criteria.from,
    to: criteria.to,
    filter: criteria.filter,
    searchName: criteria.searchKey,
    columnName: criteria.columnName,
    sortType: criteria.sortType,
  };
  return getAsync(url, returnParams);
};

export const getExportTransactionsWithCriteria = (
    criteria: TableSearchParamType
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/transactions/export`;

  let returnParams = {
    page: 0,
    perPage: criteria.perPage,
    from: criteria.from,
    to: criteria.to,
    filter: criteria.filter,
    searchName: criteria.searchKey,
    columnName: criteria.columnName,
    sortType: criteria.sortType,
  };
  return getFileAsync(url, returnParams);
};

/**
 * get total amount claims has status == Requested
 * @param employerId
 */
export const getAllSeperatePendingAmount = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `manage-wallet-partner/employer/wallet/balances/pending/${employerId}`;

  return getAsync(url);
};

export const getWalletEmployee = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/balances/${employeeId}`;

  return getAsync(url, {}, false);
};

/**
 * Cash credit
 * @param model
 */
export const postCashCreditPartner = (
  model: Credit
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/employer/bank/eft/deposit/`;

  return postAsync(url, model);
};
