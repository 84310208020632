// Rating.js
import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components
type StarProps = {
  filled: boolean;
  disabled?: boolean;
};

const Star = styled.span<StarProps>`
    font-size: 20px;
    color: ${props => (props.filled ? '#fbc02d' : '#DDDDDD')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    transition: color 0.2s;

    &:hover {
        color: #fbc02d;
    }
`;

const RatingWrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
`;

type Props = {
  totalStars?: number;
  rating: number;
  setRating: (rating: number) => void;
  disabled?: boolean;
};

// Rating Component
const Rating = ({totalStars = 5, rating, setRating, disabled = false}: Props) => {
  const [hovered, setHovered] = useState(-1);

  const handleClick = (index: number) => {
    if (disabled) return;
    setRating(index + 1);
  };

  const handleMouseEnter = (index: number) => {
    if (disabled) return;
    setHovered(index);
  };

  const handleMouseLeave = () => {
    if (disabled) return;
    setHovered(-1);
  };

  return (
    <RatingWrapper>
      {[...Array(totalStars)].map((_, index) => (
        <Star
          key={index}
          filled={index <= (hovered !== -1 ? hovered : rating - 1)}
          onClick={() => handleClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          aria-disabled={disabled}
        >
          ★
        </Star>
      ))}
    </RatingWrapper>
  );
};

export default Rating;
