import React from "react";
import {
  StyledMainContent,
  StyledContentBox,
  StyledSpanBigTitle,
} from "../../../shared/employer-styled";
import styled from "styled-components";
import Stepper from "./stepper/stepper";
import {getIsSetupDone} from "../../../../cores/utils/helpers";
import {defaultLandingPageURL} from "../../../../cores/constants/url-config";

const StyledSpanMessage = styled.span`
  position: relative;
  top: 6px;
`;
const StyledPage = styled.div`
  max-width: 900px;
  margin: 3rem auto;
`;

type SetUpWizardState = {
  activeStep: number;
};

export default class SetUpWizardView extends React.Component<
  any,
  SetUpWizardState
> {
  state = {
    activeStep: 0,
  };

  componentDidMount() {
    getIsSetupDone().then((isSetupDone: boolean) => {
      if (isSetupDone) {
        window.location.href = defaultLandingPageURL;
      } else {
        let page = window.location.pathname.split("/");
        let step = this.getStep(page[page.length - 2]);
        this.setState({ activeStep: step });
      }
    });
  }

  getStep = (stepId: string) => {
    let step = 0;

    if (stepId === "company") {
      step = 0;
    } else if (stepId === "plan") {
      step = 1;
    } else if (stepId === "classes") {
      step = 2;
    } else if (stepId === "funding") {
      step = this.props.enablePresetBankingInformation ? 0 : 3; // Do not allow access funding set up
    } else {
      step = this.props.enablePresetBankingInformation ? 3 : 4;
    }

    return step;
  };

  handleChangeStep = (stepId: string) => {
    let step = this.getStep(stepId);

    this.setState({ activeStep: step });
  };

  render() {
    return (
      <StyledMainContent>
        <StyledContentBox isBorder={false}>
          <div className="row">
            <div className="col-md-3">
              <StyledSpanBigTitle>Setup Wizard</StyledSpanBigTitle>
            </div>
            <div className="col-md-9">
              <StyledSpanMessage>
                Please complete these step before you can use your program.
              </StyledSpanMessage>
            </div>
          </div>

          <div className="mt-3">
            <Stepper
              stepper={this.props.enablePresetBankingInformation ? whenEnablePresetBankingInformationStepper : stepper}
              activeStep={this.state.activeStep}
            />
          </div>
          <hr />

          <StyledPage>{this.props.children}</StyledPage>
        </StyledContentBox>
      </StyledMainContent>
    );
  }
}

const stepper = [
  { id: "company/", name: "Company Information" },
  { id: "plan/", name: "Standard Plan" },
  { id: "classes/", name: "Classes" },
  { id: "funding/", name: "Funding" },
  { id: "services-agreement/", name: "Services Agreement" },
];

const whenEnablePresetBankingInformationStepper = [
  { id: "company/", name: "Company Information" },
  { id: "plan/", name: "Standard Plan" },
  { id: "classes/", name: "Classes" },
  { id: "services-agreement/", name: "Services Agreement" },
];
