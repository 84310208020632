import { postAsync, getAsync, putAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { LoginReqModel } from "../models/login/login-req-model";
import { SignUpReqModel } from "../models/login/sign-up-req-model";
const URL_USER = "auth/api/user";
const URL_Employer = "/manage-employer/api";

export type ChangePasswordType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const login = (model: LoginReqModel): Promise<AxiosResponse> => {
  let url = `${URL_USER}/login?portal=employer`;
  return postAsync(url, model);
};

export const signUp = (model: SignUpReqModel): Promise<AxiosResponse> => {
  return postAsync("manage-register-employer/api/employer/sign-up", model);
};

export const getEmployerInfo = (param: any): Promise<AxiosResponse> => {
  let url = "/manage-register-employer/api/employer/info";

  return getAsync(url, param);
};

export const getVerificationEmail = (param: {
  token: string;
}): Promise<AxiosResponse> => {
  let url = `${URL_USER}/verify-email`;

  return getAsync(url, param);
};

export const getRole = (): Promise<AxiosResponse> => {
  let url = `auth/api/role`;

  return getAsync(url);
};

export const getAllRole = (): Promise<AxiosResponse> => {
  let url = `auth/api/role/all-role`;

  return getAsync(url);
};

export const postForgotPassword = (model: {
  email: string;
}): Promise<AxiosResponse> => {
  let url = `${URL_USER}/forgot-password?portal=employer`;

  return postAsync(url, model);
};

export const putUpdatePassword = (
  token: string,
  param: { password: string }
): Promise<AxiosResponse> => {
  let url = `${URL_USER}/forgot-password?token=${token}`;

  return putAsync(url, param);
};

export const putChangePassword = (
  model: ChangePasswordType
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/update-password`;

  return putAsync(url, model);
};
