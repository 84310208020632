import moment from "moment";

export const FE_DATE_FORMAT = "DD/MM/YYYY";
export const shortDateFormat = "DD/MM/YY";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const FE_MONTH_FORMAT = "MM/YYYY";
export const FE_DETAIL_DATE_FORMAT = "DD MMM YYYY";

export const formatInputDateTime = (date: string): string => {
    return date.split("/").map(item => item.length < 2 ? `0${item}` : item).join("/");
}

export const dateStringToDate = (date: Date | string): Date => {
    if (date instanceof Date) return date;
    return moment(date, "DD/MM/YYYY").toDate()
}

export const offsetToFrontEndTimezone = (date: Date | string): Date => {
    return moment(date).zone(new Date().getTimezoneOffset()).toDate()
}