import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EmployerHeader from "./employer-header";
import EmployerSideBar from "./employer-side-bar";
import { getToken } from "../../../cores/utils/helpers";
import { Redirect } from "react-router";
import styled from "styled-components";
import ChangePasswordModal, {
  closeChangePasswordModal,
} from "./change-password";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  ChangePasswordType,
  putChangePassword,
} from "../../../services/auth-service";
import { publishMessage } from "../../../cores/utils/message";
import { signInURL } from "../../../cores/constants/url-config";
import { Footer } from "./footer";
import { localStorageRedirectKey } from "../../../cores/config/config";
import { InfoDialog, showInfoModal } from "../../pages/employer/setup-wizard/agreement/info-dialog";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    minHeight: "calc(100vh - 156px)",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    // backgroundColor: "#fff",
  },
}));

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    z-index: 300 !important;
    background-color: ${(props) => props.theme.sideBarColor[500]} !important;
    color: #ffffff !important;
    padding: 0;
    height: 100vh !important;
  }
`;

const EmployerLayout = ({ children }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [isValid, setIsValid] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [reNewPassword, setReNewPassword] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.search === "?default") {
      showInfoModal();
    }
  }, [children]);

  // custom rule will have name 'isPasswordMatch'
  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== newPassword) {
      return false;
    }
    return true;
  });
  ValidatorForm.addValidationRule("isOldPasswordMatchNewPassword", (value) => {
    if (currentPassword && value === currentPassword) {
      return false;
    }
    return true;
  });

  const onChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPassword(event.target.value);
  };
  const onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };
  const onChangeReNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReNewPassword(event.target.value);
  };

  const handleChangePassword = () => {
    let param: ChangePasswordType = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: reNewPassword,
    };

    putChangePassword(param).then(() => {
      closeChangePasswordModal();

      publishMessage({
        message: "The password has been updated successfully.",
        variant: "success",
      });

      setIsValid(false);
      setCurrentPassword("");
      setNewPassword("");
      setReNewPassword("");
    });
  };

  const validate = (event: boolean) => {
    if (event && currentPassword && newPassword && reNewPassword) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (!getToken()) {
    localStorage.setItem(localStorageRedirectKey, children.props.location.pathname);
    // redirect to login page if don't have token
    return <Redirect to={signInURL} />;
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <EmployerHeader />
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <StyledDrawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <div>
                <div className={classes.toolbar} />
                <Divider />
                <EmployerSideBar handleDrawerToggle={handleDrawerToggle} />
              </div>
            </StyledDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <StyledDrawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <div>
                <div className={classes.toolbar} />
                <Divider />
                <EmployerSideBar />
              </div>
            </StyledDrawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>{children}</div>
        </main>

        <ChangePasswordModal
          isValid={isValid}
          currentPassword={currentPassword}
          newPassword={newPassword}
          reNewPassword={reNewPassword}
          onChangeCurrentPassword={onChangeCurrentPassword}
          handleChangePassword={handleChangePassword}
          onChangeNewPassword={onChangeNewPassword}
          onChangeReNewPassword={onChangeReNewPassword}
          validate={validate}
        />
      </div>
      <Footer />
      <InfoDialog />
    </>
  );
};
export default EmployerLayout;
