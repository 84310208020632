import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddOutlined from "@material-ui/icons/AddOutlined";
import {
  Class,
  ClassBenefit
} from "../../../components/pages/request-a-quote/request-a-quote-type";
import {
  StyledButtonSubmit,
  LandingStyledTextValidator
} from "../../../components/shared/employer-styled";
import { BNPSelect } from "../select/select";

type Props = {
  classBenefit: ClassBenefit;
  onSubmit: () => void;
  getRemainingClasses: () => Class[];
  findClassById: (classId: number) => { label: string; value: number };
  handleChangeSelectClassBenefits?: (event: any) => void;
  handleChangeInputDefineClassBenefits?: (event: any) => void;
  isValid: boolean;
  isOtherClass: boolean;
  showSelectPlan: boolean;
};

export const AddClassBenefits = (props: Props) => {
  let remainingClasses = props.getRemainingClasses();
  return (
    <div>
      {remainingClasses.length || props.isOtherClass ? (
        <ValidatorForm className="w-100" onSubmit={props.onSubmit}>
          <div className="row">
            <div className="col-lg-5 mt-3">
              {!props.isOtherClass ? (
                <BNPSelect
                  value={props.classBenefit.classId || remainingClasses[0].id}
                  options={remainingClasses}
                  name="classId"
                  onChange={props.handleChangeSelectClassBenefits}
                />
              ) : (
                <LandingStyledTextValidator
                  onChange={props.handleChangeInputDefineClassBenefits}
                  name="className"
                  type="text"
                  title="Class Name"
                  value={props.classBenefit.className || ""}
                  placeholder="Class Name"
                  tabIndex={-1}
                  role="application"
                  aria-hidden="true"
                />
              )}
            </div>
            {props.showSelectPlan ? (
              <div className="col-lg-2 mt-3">
                <BNPSelect
                  value={1}
                  options={[{ id: 1, name: "Standard Plan" }]}
                  name="planId"
                  onChange={props.handleChangeSelectClassBenefits}
                  tabIndex={-1}
                />
              </div>
            ) : (
              ""
            )}
            <div className={(props.showSelectPlan ? "col-lg-4" : "col-lg-5") + " mt-3"}>
              <LandingStyledTextValidator
                onChange={props.handleChangeInputDefineClassBenefits}
                name="coverageAmount"
                type="number"
                title="Coverage Amount"
                placeholder="Coverage Amount"
                value={props.classBenefit.coverageAmount || ""}
                validators={["required", "matchRegexp:^[0-9]"]}
                errorMessages={[
                  "Please enter the number",
                  "Please do not enter a negative number"
                ]}
                tabIndex={-1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div
              className={
                "mt-3 " + (props.showSelectPlan ? "col-lg-1" : "col-lg-2")
              }
            >
              <StyledButtonSubmit
                submit={props.isValid}
                disabled={!props.isValid}
                name="btn-add"
                type="submit"
                title="Add class"
              >
                <AddOutlined className="icon-add"></AddOutlined>
              </StyledButtonSubmit>
            </div>
          </div>
        </ValidatorForm>
      ) : (
        ""
      )}
    </div>
  );
};
