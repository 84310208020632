import {SortTable} from "../employer/employees/employee-model";

export const columnsDepositReceipt: SortTable[] = [
  {
    columnId: "companyName",
    columnName: "Deposited By",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
  {
    columnId: "email",
    columnName: "Email",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
  {
    columnId: "depositedAmount",
    columnName: "Deposit Amount",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "depositedDate",
    columnName: "Deposited Date",
    sortType: 'ASC',
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "url",
    columnName: "Receipt",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
];

export const columnsClaimReceipt: SortTable[] = [
  {
    columnId: "claimedBy",
    columnName: "Claimed By",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "email",
    columnName: "Email",
    sortType: null,
    width: 25,
    sortColumn: true,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "claimAmount",
    columnName: "Claim Amount",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "claimedDate",
    columnName: "Claimed Date",
    sortType: 'ASC',
    width: 12,
    sortColumn: true,
  },
  {
    columnId: "url",
    columnName: "Receipt",
    sortType: null,
    width: 13,
    sortColumn: false,
  },
];