import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
} from "../employer-styled";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ShowPasswordView } from "../../../cores/helpers/input/input";

const StyledModalDialog = styled.div`
  max-width: 600px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
  text-align: left;
`;

type Props = {
  isValid: boolean;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;

  validate: (event: boolean) => void;
  onChangeCurrentPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNewPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeReNewPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;

  handleChangePassword: () => void;
};

export default function ChangePasswordModal(props: Props) {
  return (
    <div className="modal" id="changePasswordModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Change password</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={closeChangePasswordModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <ValidatorForm
              className="w-100"
              onSubmit={props.handleChangePassword}
            >
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div>Current password</div>
                  <ShowPasswordView
                    onChange={props.onChangeCurrentPassword}
                    placeholder="Current password"
                    value={props.currentPassword}
                    validators={["required"]}
                    errorMessages={["Please enter password"]}
                    validatorListener={props.validate}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <div>New password</div>
                  <ShowPasswordView
                    onChange={props.onChangeNewPassword}
                    name="password"
                    placeholder="New password"
                    value={props.newPassword}
                    validators={["required", "isOldPasswordMatchNewPassword"]}
                    errorMessages={[
                      "Please enter password",
                      "New password shouldn't match old password.",
                    ]}
                    validatorListener={props.validate}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <div>Confirm new password</div>
                  <ShowPasswordView
                    onChange={props.onChangeReNewPassword}
                    name="rePassword"
                    placeholder="Confirm new password"
                    value={props.reNewPassword}
                    validators={["isPasswordMatch", "required"]}
                    errorMessages={[
                      "Password and confirm password does not match",
                      "Please enter confirm password",
                    ]}
                    validatorListener={props.validate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="col-lg-3 p-0 float-right mt-3 ml-2">
                    <StyledButtonSubmit
                      type="submit"
                      submit={props.isValid}
                      disabled={!props.isValid}
                    >
                      Save
                    </StyledButtonSubmit>
                  </div>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showChangePasswordModal() {
  ($("#changePasswordModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeChangePasswordModal() {
  ($("#changePasswordModal") as any).modal("hide");
}
