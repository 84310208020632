import React, { Component } from "react";

import { ValidatorForm } from "react-material-ui-form-validator";
import { SignUpReqModel } from "../../../models/login/sign-up-req-model";
import { signUp, getEmployerInfo, login } from "../../../services/auth-service";
import {
  StyledBigTitle,
  StyledPError,
  LandingStyledTextValidator,
  StyledButtonSubmit,
} from "../../shared/employer-styled";
import { handleError } from "../../../cores/utils/http-client";
import { validateEmail } from "../../../cores/config/config";
import { publishMessage } from "../../../cores/utils/message";
import { LoginReqModel } from "../../../models/login/login-req-model";
import { RouteChildrenProps } from "react-router";
import { getAllBalances } from "../../../services/wallet-service";
import {
  getInfoByToken,
  setToken,
  getToken,
  getIsSetupDone,
} from "../../../cores/utils/helpers";
import {
  defaultLandingPageURL,
  setupCompanyURL,
} from "../../../cores/constants/url-config";
import { ShowPasswordView } from "../../../cores/helpers/input/input";
import styled from "styled-components";
import {validFullNameLength} from "../../../cores/utils/validation/validate-employee";
import jwt from "jsonwebtoken";

const StyledSpanErrorMessage = styled.span`
  color: ${props => props.theme.errorColor};
  font-size: 14px;
`;

type State = {
  signUp: SignUpReqModel;
  isEmailRegistered: boolean;
  isValid: boolean;
  errorMessage: string;
};
type Props = RouteChildrenProps;

export default class SignUp extends Component<Props, State> {
  state: State = {
    signUp: {
      firstName: "",
      lastName: "",
      companyEmail: "",
      companyName: "",
      password: "",
      rePassword: "",
      token: "",
    },
    isEmailRegistered: false,
    isValid: false,
    errorMessage: "",
  };
  constructor(props: any) {
    super(props);
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.signUp.password) {
        return false;
      }
      return true;
    });

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const isSwitchFromEmployeePortal = urlParams.get("switchFromEmployee");
    
    if (isSwitchFromEmployeePortal != 'true' && token) {
      this.getAccount(token);
    } else if (isSwitchFromEmployeePortal == 'true' && token) {
      this.setTokenFromEmployeePortal(token);
    }
  }

  setTokenFromEmployeePortal(token: string) {
    const jwtToken = jwt.decode(token);
    if (jwtToken && jwtToken instanceof Object) {
      const expriedAt = jwtToken["exp"] as number;
      // remove before set new
      setToken("");
      setToken(token, new Date(expriedAt * 1000));
    }
  }

  UNSAFE_componentWillMount() {
    if (getToken()) {
      if (getIsSetupDone()) {
        this.props.history.push(defaultLandingPageURL);
      } else {
        this.props.history.push(setupCompanyURL);
      }
    }
  }

  async getAccount(token: string) {
    //get account
    let param = {
      token: token,
    };
    await getEmployerInfo(param).then((result) => {
      //set account on state
      let signUp: SignUpReqModel = Object.assign(this.state.signUp);
      signUp.firstName = result.data.firstName;
      signUp.lastName = result.data.lastName;
      signUp.companyEmail = result.data.companyEmail;
      signUp.companyName = result.data.companyName;
      signUp.token = token;
      this.setState({ signUp: signUp });
    });
  }

  handleChangeInput = (event: any) => {
    let signUp: SignUpReqModel = Object.assign(this.state.signUp);

    signUp[event.target.name] = event.target.value;
    this.setState({ signUp: signUp });
  };

  onSubmit = () => {
    this.setState({ isEmailRegistered: false });

    let signUpData: SignUpReqModel = Object.assign(this.state.signUp);
    signUpData.companyEmail = signUpData.companyEmail.toLowerCase().trim();

    if (this.state.signUp.password === this.state.signUp.rePassword) {
      // request api
      signUp(signUpData)
        .then(() => {
          publishMessage({
            variant: "success",
            message: "Thank you for signing up.",
          });
          this.login();
        })
        .catch((error) => {
          if (error.response && Number(error.response.data.code) === 11000) {
            this.setState({ isEmailRegistered: true });
          } else {
            handleError(error);
          }
        });
    }
  };

  login() {
    let loginReqModel: LoginReqModel = {
      username: this.state.signUp.companyEmail.toLowerCase().trim(),
      password: this.state.signUp.password,
    };

    login(loginReqModel)
      .then((value: any) => {
        setToken(value.data.access_token, new Date(value.data.expired_at));
        // init wallet
        getAllBalances(getInfoByToken().employerId);
        return getIsSetupDone();
      })
      .then(() => {
        this.props.history.push(setupCompanyURL);
      })
      .catch((error) => {
        // if err
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.status === 500) {
            publishMessage({
              message: "We’ve run into a problem. Please try again later",
              variant: "error",
            });
          }
        } else {
          publishMessage({
            message:
              "System error. We apologize for the inconvenience. Please try again.",
            variant: "error",
          });
        }
      });
  }

  validatorListener = (event: boolean) => {
    if (event) {
      let isValid = this.validate();
      this.setState({ isValid: isValid });
    } else {
      this.setState({ isValid: event });
    }
  };

  validate = (): boolean => {
    const { firstName, lastName } = this.state.signUp;

    if (!validFullNameLength(firstName, lastName)) {
      this.setState({ errorMessage: "Full name length could not be larger than 30 letters." })
    } else {
      this.setState({ errorMessage: "" })
    }

    return !!(this.state.signUp.firstName &&
        this.state.signUp.lastName &&
        this.state.signUp.password &&
        this.state.signUp.rePassword &&
        this.state.signUp.companyName &&
        this.state.signUp.companyEmail.match(validateEmail) &&
        validFullNameLength(firstName, lastName));
  };

  render() {
    return (
      <div>
        <StyledBigTitle className="mt-5">Create an new account</StyledBigTitle>
        <p className="mt-3">
          Please enter the following required information to create a new
          account.
        </p>
        <ValidatorForm className="col-lg-12 row" onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <LandingStyledTextValidator
                onChange={this.handleChangeInput}
                type="text"
                name="firstName"
                placeholder="First name"
                value={this.state.signUp.firstName}
                validators={["required"]}
                errorMessages={["Please enter first name."]}
                validatorListener={this.validatorListener}
                tabIndex={-1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-6 mb-3">
              <LandingStyledTextValidator
                onChange={this.handleChangeInput}
                type="text"
                name="lastName"
                placeholder="Last name"
                value={this.state.signUp.lastName}
                validators={["required"]}
                errorMessages={["Please enter last name."]}
                validatorListener={this.validatorListener}
                tabIndex={-1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-6 mb-3">
              <LandingStyledTextValidator
                onChange={this.handleChangeInput}
                type="text"
                name="companyEmail"
                placeholder="Email"
                value={this.state.signUp.companyEmail}
                validators={["required", `matchRegexp:${validateEmail}`]}
                errorMessages={["Please enter email", "Email is not valid"]}
                validatorListener={this.validatorListener}
                tabIndex={-1}
                role="application"
                aria-hidden="true"
              />
              {this.state.isEmailRegistered && (
                <StyledPError className="mb-0">
                  Email already registered.
                </StyledPError>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <LandingStyledTextValidator
                  onChange={this.handleChangeInput}
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  value={this.state.signUp.companyName}
                  validators={["required"]}
                  errorMessages={["Please enter company name."]}
                  validatorListener={this.validatorListener}
                  tabIndex={-1}
                  role="application"
                  aria-hidden="true"
              />
              {this.state.isEmailRegistered && (
                  <StyledPError className="mb-0">
                    Email already registered.
                  </StyledPError>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <ShowPasswordView
                onChange={this.handleChangeInput}
                name="password"
                placeholder="Password"
                value={this.state.signUp.password}
                validators={["required"]}
                errorMessages={["Please enter password"]}
                tabIndex={-1}
                validatorListener={this.validatorListener}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <ShowPasswordView
                onChange={this.handleChangeInput}
                name="rePassword"
                placeholder="Confirm the password"
                value={this.state.signUp.rePassword}
                validators={["isPasswordMatch", "required"]}
                errorMessages={[
                  "Password and confirm password does not match",
                  "Please enter confirm password",
                ]}
                tabIndex={-1}
                validatorListener={this.validatorListener}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <div className="row">
                <div className="col-lg-7 p-0 d-flex align-items-center">
                  <StyledSpanErrorMessage>
                    {this.state.errorMessage}
                  </StyledSpanErrorMessage>
                </div>
                <div className="col-lg-5 float-right p-0">
                  <StyledButtonSubmit type="submit" submit={this.state.isValid}>
                    Create a new account
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
