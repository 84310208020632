import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
} from "../../shared/employer-styled";

type Props = {};
type State = {};

export default class Support extends React.Component<Props, State> {
  render() {
    return (
      <StyledMainContent>
        <StyledBigTitle>Support</StyledBigTitle>
      </StyledMainContent>
    );
  }
}
