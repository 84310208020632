import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal
} from "../../../../shared/employer-styled";
import employee from "../../../../../cores/config/component/images/employee.png";

const StyledModalDialog = styled.div`
  text-align: left;
  margin: 2.5rem auto;
  max-width: 600px;
  width: 100%;
  pointer-events: none;
`;

const StyledImg = styled.img`
  width: 228px;
`

type Props = {
  handleOnOk: () => void;
};

export function InfoDialog() {
  return (
    <div className="modal" id="infoModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal className="text-left">
              Info
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={closeInfoModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body text-left">
            <div>
              <div>Thank you, your account has been set up!</div>
              <div>
                To add an employee, you can "Click" to "Employee" in the menu on
                the left of the screen.
              </div>
              <div className="text-center m-2">
                <label>
                  <StyledImg alt="employee" src={employee} />
                </label>
              </div>
              <div>You can contact us for detailed support on the usage.</div>
            </div>
          </div>
          {/* end body */}
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showInfoModal() {
  ($("#infoModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static"
  });
}
export function closeInfoModal() {
  ($("#infoModal") as any).modal("hide");
}
