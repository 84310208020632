import React, { Fragment, useEffect, useMemo, useState } from "react";
import { BNPInput } from "../../../../../../cores/helpers/input/input";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  StyledInputMask,
  StyledLargerTitle,
  StyledButtonSubmit,
  LandingStyledTextValidator,
  StyledPTitle,
  StyledFormControlLabel,
} from "../../../../../shared/employer-styled";
import { MenuItem, Radio, RadioGroup } from "@material-ui/core";
import styled from "styled-components";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Class } from "../../../../request-a-quote/request-a-quote-type";
import { Status } from "../../employee-model";
import { validateEmail } from "../../../../../../cores/config/config";
import { Employee, Role } from "../../employee-type";
import { capFirst } from "../../../../../../cores/helpers/cap-first";
import {
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
  BNPSelect,
} from "../../../../../../cores/helpers/select/select";
import { Provinces } from "../../../employer-setting/company-information/company-information-type";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../../../cores/helpers/google-map-services/google-location";
import { convertTimeZone } from "../../../../../../cores/utils/helpers";
import { EmployerPlan } from "../../../plan/plan-preferences/plan-preferences-type";
import moment from "moment";
import { offsetToFrontEndTimezone } from "../../../../../../cores/utils/format/date-time-format";
import { validFullNameLength } from "../../../../../../cores/utils/validation/validate-employee";

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

const StyledSpanErrorMessage = styled.span`
  color: ${(props) => props.theme.errorColor};
  font-size: 14px;
`;

const StyledDescription = styled(RadioGroup)`
  flex-direction: row !important;
  width: 100%;
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

type Props = {
  employees: Employee;
  statuses: Status[];
  roles: Role[];
  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangeSelect: (event: any) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSubmit: () => void;
  isValid: boolean;
  isEdit: boolean;
  editEmployee: () => void;
  setEmployerBenefitClass: () => Class[];
  provinces: Provinces[];
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  employerPlan: EmployerPlan;
  isT4Employee: boolean;
  setIsT4Employee: (val: boolean) => void;
};

export function ManualAdd(props: Props) {
  let classes = props.setEmployerBenefitClass();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isEligibilityDateDisabled = !!props.employees.isSetUpDone;

  useEffect(() => {
    const { firstName, lastName } = props.employees;
    if (!validFullNameLength(firstName, lastName))
      setErrorMessage("Full name length could not be larger than 30 letters");
    else {
      setErrorMessage("");
    }
  }, [props.employees.firstName, props.employees.lastName]);

  const eligibilityMinDate: Date | string | undefined = useMemo(() => {
    if (
      props.employerPlan &&
      props.employerPlan.planStartDate &&
      props.employees.hiringDate
    )
      return moment(props.employerPlan.planStartDate).isAfter(
        props.employees.hiringDate
      )
        ? offsetToFrontEndTimezone(props.employerPlan.planStartDate)
        : offsetToFrontEndTimezone(props.employees.hiringDate);
    if (
      props.employerPlan &&
      props.employerPlan.planStartDate &&
      !props.employees.hiringDate
    )
      return offsetToFrontEndTimezone(props.employerPlan.planStartDate);
    if (props.employees.hiringDate && !props.employerPlan)
      return offsetToFrontEndTimezone(props.employees.hiringDate);
    return undefined;
  }, [props.employees.hiringDate, props.employerPlan]);

  const displayT4EmployeeRadio = useMemo(() => {
    props.setIsT4Employee(true);
    return (props.employees.roleIds.length > 0 && !props.employees.roleIds.includes(3))
  }, [props.employees.roleIds])

  const displayT4EmployeeForm = useMemo(() => {
    return (
      props.employees.roleIds.length > 0 &&
      (props.employees.roleIds.includes(3) || props.isT4Employee)
    );
  }, [props.employees.roleIds, props.isT4Employee]);

  return (
    <ValidatorForm
      className="w-100"
      onSubmit={props.isEdit ? props.editEmployee : props.handleChangeSubmit}
    >
      <StyledLargerTitle>Profile Information</StyledLargerTitle>
      <div className="row">
        <div className="col-lg-6 mt-3">
          <StyledPTitle className="mb-1">First name *</StyledPTitle>
          <BNPInput
            placeholder="First name"
            name="firstName"
            value={props.employees.firstName || ""}
            onChange={props.handleChangeInput}
            tabIndex={1}
          />
        </div>
        <div className="col-lg-6 mt-3">
          <StyledPTitle className="mb-1">Last name *</StyledPTitle>
          <BNPInput
            placeholder="Last name"
            name="lastName"
            value={props.employees.lastName || ""}
            onChange={props.handleChangeInput}
            tabIndex={2}
          />
        </div>
        <div className="col-lg-6 mt-3">
          <StyledPTitle className="mb-1">Email *</StyledPTitle>
          <LandingStyledTextValidator
            onChange={props.handleChangeInput}
            type="text"
            name="email"
            placeholder="Email"
            value={props.employees.email || ""}
            validators={["required", `matchRegexp:${validateEmail}`]}
            errorMessages={["Please enter email", "Email is not valid"]}
            tabIndex={5}
          />
        </div>
        <div className="col-lg-6 mt-3">
          <StyledPTitle className="mb-1">Role *</StyledPTitle>
          <StyledSelect
            value={
              props.employees.roleIds.length ? props.employees.roleIds[0] : -1
            }
            onChange={props.handleChangeSelect}
            name="roleIds"
            inputProps={{ tabIndex: 14 }}
          >
            <MenuItem value={-1} key={-1}>
              <StyledDefaultOption className="p-2">
                Role (Required)
              </StyledDefaultOption>
            </MenuItem>
            {props.roles.map((role) => (
              <MenuItem value={role.id} key={role.id}>
                <StyledOption className="p-2">
                  {capFirst(role.name)}
                </StyledOption>
              </MenuItem>
            ))}
          </StyledSelect>
        </div>
        {displayT4EmployeeRadio && <StyledDescription
          name="Is this an employee?"
          className='w-100'
          value={props.isT4Employee}
          onChange={(e) =>
            !props.isEdit && props.setIsT4Employee(e.target.value == "true")
          }
        >
          <div className="d-flex align-items-center mr-3">
            <StyledPTitle className="mb-0">Is this an employee?</StyledPTitle>
          </div>
          <span key="isT4Employee_yes">
            <StyledFormControlLabel
              disabled={props.isEdit}
              value={true}
              control={<Radio color="primary" />}
              label="Yes"
            />
          </span>
          <span key="isT4Employee_no">
            <StyledFormControlLabel
              disabled={props.isEdit}
              value={false}
              control={<Radio color="primary" />}
              label="No"
            />
          </span>
        </StyledDescription>}

        {displayT4EmployeeForm && (
          <Fragment>
            {props.isEdit &&
            <>
            <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Gender at Birth</StyledPTitle>
              <StyledSelect
                value={props.employees.gender || -1}
                onChange={props.handleChangeSelect}
                name="gender"
                inputProps={{ tabIndex: 3 }}
              >
                <MenuItem value={-1}>
                  <StyledDefaultOption className="p-2">
                    Gender at Birth
                  </StyledDefaultOption>
                </MenuItem>
                <MenuItem value="MALE">
                  <StyledOption className="p-2">Male</StyledOption>
                </MenuItem>
                <MenuItem value="FEMALE">
                  <StyledOption className="p-2">Female</StyledOption>
                </MenuItem>
              </StyledSelect>
            </div>
            <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Date of birth</StyledPTitle>
              <StyledDivDatePicker>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    InputAdornmentProps={{ position: "start" }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline1"
                    placeholder="Date of birth"
                    value={props.employees.dateOfBirth}
                    onChange={(date) =>
                      props.handleChangeDate(date, "dateOfBirth")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    tabIndex={4}
                  />
                </MuiPickersUtilsProvider>
              </StyledDivDatePicker>
            </div>
            <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Phone number</StyledPTitle>
              <StyledInputMask
                mask="999-999-9999"
                value={props.employees.phoneNumber || ""}
                onChange={props.handleChangeInput}
                name="phoneNumber"
                placeholder="Phone number"
                tabIndex={6}
              />
            </div>

            <div className="col-lg-12 mt-3">
              <StyledPTitle className="mb-2">Street</StyledPTitle>
              <GoogleLocationInput
                id="street"
                address={props.employees.street || ""}
                setAddress={props.setAddress}
                setLocation={props.setLocation}
                placeholder="Street address"
                tabIndex={7}
              />
            </div>

            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">Province</StyledPTitle>
              <BNPSelect
                options={props.provinces}
                placeholder="Province"
                name="provinceId"
                value={props.employees.provinceId || "-1"}
                onChange={props.handleChangeSelect}
                tabIndex={8}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">City</StyledPTitle>
              <BNPInput
                value={props.employees.cityName || ""}
                onChange={props.handleChangeInput}
                name="cityName"
                placeholder="City"
                tabIndex={9}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">Post code</StyledPTitle>
              <StyledInputMask
                mask="*** ***"
                value={props.employees.postCode || ""}
                onChange={props.handleChangeInput}
                name="postCode"
                placeholder="Post code"
                tabIndex={10}
              />
            </div>
            </>
            }
            <StyledLargerTitle className="mt-3">
              Employment Information
            </StyledLargerTitle>
            <div className="row">
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">ID *</StyledPTitle>
                <BNPInput
                  placeholder="ID"
                  name="code"
                  value={props.employees.code || ""}
                  onChange={props.handleChangeInput}
                  tabIndex={11}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">Hiring Date *</StyledPTitle>
                <StyledDivDatePicker>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      InputAdornmentProps={{ position: "start" }}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline2"
                      placeholder="Hiring Date"
                      value={props.employees.hiringDate}
                      onChange={(date) =>
                        props.handleChangeDate(date, "hiringDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      tabIndex={12}
                    />
                  </MuiPickersUtilsProvider>
                </StyledDivDatePicker>
              </div>

              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">Salary</StyledPTitle>
                <BNPInput
                  placeholder="Salary (Optional)"
                  name="salary"
                  type="number"
                  value={props.employees.salary || ""}
                  onChange={props.handleChangeInput}
                  tabIndex={13}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">Position</StyledPTitle>
                <BNPInput
                  placeholder="Position (Optional)"
                  name="position"
                  value={props.employees.position || ""}
                  onChange={props.handleChangeInput}
                  tabIndex={15}
                />
              </div>

              {props.isEdit &&
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">
                  Social insurance number
                </StyledPTitle>
                <BNPInput
                  placeholder="Social insurance number (Optional)"
                  name="socialInsuranceNumber"
                  type="number"
                  value={props.employees.socialInsuranceNumber || ""}
                  onChange={props.handleChangeInput}
                  tabIndex={16}
                />
              </div>
              }
            </div>

            <StyledLargerTitle className="w-100 mt-3">
              Employment Setup
            </StyledLargerTitle>
            <div className="row w-100">
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">Class *</StyledPTitle>
                <StyledSelect
                  value={props.employees.classId || -1}
                  onChange={props.handleChangeSelect}
                  name="classId"
                  inputProps={{ tabIndex: 17 }}
                >
                  <MenuItem value={-1} key={-1}>
                    <StyledDefaultOption className="p-2">
                      Class (Required)
                    </StyledDefaultOption>
                  </MenuItem>
                  {classes.map((classItem) => (
                    <MenuItem value={classItem.id} key={classItem.id}>
                      <StyledOption className="p-2">
                        {classItem.name}
                      </StyledOption>
                    </MenuItem>
                  ))}
                </StyledSelect>
              </div>
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">Eligibility Date *</StyledPTitle>
                <StyledDivDatePicker>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      InputAdornmentProps={{ position: "start" }}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline3"
                      placeholder="Eligibility Date"
                      value={props.employees.eligibilityDate}
                      onChange={(date) =>
                        props.handleChangeDate(date, "eligibilityDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={eligibilityMinDate}
                      tabIndex={18}
                      disabled={isEligibilityDateDisabled}
                    />
                  </MuiPickersUtilsProvider>
                </StyledDivDatePicker>
              </div>
              <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">
                  Benefit Cancellation Date
                </StyledPTitle>
                <StyledDivDatePicker>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      InputAdornmentProps={{ position: "start" }}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline4"
                      placeholder="Benefit Cancellation Date"
                      value={props.employees.benefitCancellationDate}
                      onChange={(date) =>
                        props.handleChangeDate(date, "benefitCancellationDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      tabIndex={19}
                      minDate={new Date()}
                      disabled={props.employees.status === 'TERMINATED'}
                    />
                  </MuiPickersUtilsProvider>
                </StyledDivDatePicker>
              </div>
            </div>
          </Fragment>
        )}

        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-8 mt-3 p-0 d-flex align-items-center">
              <StyledSpanErrorMessage>{errorMessage}</StyledSpanErrorMessage>
            </div>
            <div className="col-lg-4 mt-3 p-0 float-right">
              <StyledButtonSubmit
                submit={props.isValid}
                disabled={!props.isValid}
                type="submit"
                tabIndex={20}
              >
                {props.isEdit ? "Save" : "Add new employee"}
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </div>
    </ValidatorForm>
  );
}
