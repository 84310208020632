import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";

export const statusTransaction = [
  {
    id: "DEBIT",
    name: "Debit",
  },
  {
    id: "CREDIT",
    name: "Credit",
  },
];
export const columnClaims: TableSortType[] = [
  {
    columnId: "employeeName",
    columnName: "Claimer",
    sortColumn: true,
    sortType: null,
    width: 15,
  },
  {
    columnId: "companyName",
    columnName: "Company name",
    sortColumn: true,
    sortType: null,
    width: 15,
  },
  {
    columnId: "totalAmount",
    columnName: "Total amount",
    sortColumn: true,
    sortType: null,
    width: 12,
  },
  {
    columnId: "submittedDate",
    columnName: "Submitted Date",
    sortColumn: true,
    sortType: null,
    width: 12,
  },
  {
    columnId: "reviewDate",
    columnName: "Review Date",
    sortColumn: true,
    sortType: null,
    width: 12,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortColumn: true,
    sortType: null,
    width: 26,
  },
  {
    columnId: "claimStatus",
    columnName: "Status",
    sortColumn: true,
    sortType: null,
    width: 8,
  },
];

export const columnTransactions: TableSortType[] = [
  {
    columnId: "txnTitle",
    columnName: "Title",
    sortColumn: false,
    sortType: null,
    width: 25,
  },
  {
    columnId: "txnDescription",
    columnName: "Description",
    sortColumn: false,
    sortType: null,
    width: 25,
  },
  {
    columnId: "clearedAmount",
    columnName: "Cleared Amount",
    sortColumn: false,
    sortType: null,
    width: 12,
  },
  {
    columnId: "pendingAmount",
    columnName: "Pending Amount",
    sortColumn: false,
    sortType: null,
    width: 12,
  },
  {
    columnId: "transactionType",
    columnName: "Transaction Type",
    sortColumn: false,
    sortType: null,
    width: 13,
  },
  {
    columnId: "createdDate",
    columnName: "Transaction Date",
    sortColumn: false,
    sortType: null,
    width: 13,
  },
]

export interface Class {
  id: number;
  name: string;
}

export type PlanPreferencesFill = {
  id: number;
  value: number;
};

export const planPreferences: PlanPreferencesFill[] = [
  {
    id: 3,
    value: 0
  },
  {
    id: 4,
    value: 30
  },
  {
    id: 5,
    value: 60
  },
  {
    id: 6,
    value: 90
  }
];
