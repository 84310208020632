import React from 'react';
import {SummarizeType} from "../ReportType";
import ReportSummarizedItem from "./ReportSummarizedItem";

type Props = {
  reportType: string;
  summarizedList: SummarizeType[];
}

const ReportSummarizedGroup = React.memo(function ReportSummarizedGroup(props: Props) {
  return (
    <div className="row">
      {props.summarizedList.map((item: SummarizeType) => (
        <ReportSummarizedItem key={`${props.reportType}_${item.title}`} title={item.title} content={item.balance} />
      ))}
    </div>
  );
})

export default ReportSummarizedGroup;