import React from 'react';
import {StyledButtonSubmit, StyledTextarea} from "../../../shared/employer-styled";
import {BNPModal} from "../../../shared/BNPModal/BNPModal";
import styled from "styled-components";
import {Avatar, FormControl} from "@material-ui/core";
import Rating from "./rating";
import {CreateReviewRequest} from "../marketplace-type";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  background-color: #F8FBFF;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled(Avatar)`
    width: 50px;
    height: 50px;
    margin-right: 16px;
`;

const Username = styled.div`
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 8px;
`;

const StyledRatingArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`;

const StyledTextArea = styled(StyledTextarea)`
  white-space: pre-wrap;
  overflow-y: scroll;
`

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: (model: CreateReviewRequest) => void;
  isEdit: boolean;
  editInfo: {
    username: string;
    avatarUrl: string;
    rating: number;
    comment: string;
  };
};

const AddReviewModal = (props: Props) => {
  const {username, avatarUrl} = props.editInfo;
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<CreateReviewRequest>({
    ratingPoint: 0,
    comment: ''
  });

  React.useEffect(() => {
    setModel({
      ratingPoint: props.editInfo.rating,
      comment: props.editInfo.comment
    });
    setIsValid(!!props.editInfo.rating);
  }, [props.editInfo]);

  const handleChangeRating = (value: number) => {
    setModel({...model, ratingPoint: value || 0});
    setIsValid(!!value);
  }

  const handleChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setModel({...model, comment: event.target.value});
  }

  const handleSubmit = () => {
    props.handleSubmit(model);
    setModel({ratingPoint: 0, comment: ''});
    setIsValid(false);
  }

  const body = () => (
    <StyledBox>
      <UserInfo>
        <StyledAvatar src={avatarUrl} alt={username} />
        <Username>{username}</Username>
      </UserInfo>
      <StyledRatingArea>
        <Rating
          totalStars={5}
          rating={model.ratingPoint}
          setRating={handleChangeRating}
        />
      </StyledRatingArea>
      <div className={"mt-4"}>
        <FormControl fullWidth>
          <StyledTextArea
            rows={5}
            placeholder={'Write your review here...'}
            value={model.comment}
            onChange={handleChangeTextarea}
          />
        </FormControl>
      </div>
    </StyledBox>
  )

  return (
    <BNPModal
      modalSize={"md"}
      open={props.open}
      title={props.title}
      handleClose={props.handleClose}
      body={body()}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!isValid}
            onClick={handleSubmit}
            submit={isValid}
            type="submit"
          >
            {props.isEdit ? 'Update' : 'Post'} Review
          </StyledButtonSubmit>
        </div>
      }
    />
  );
}

export default AddReviewModal;