import React, {Fragment, useEffect, useMemo, useState} from "react";
import {capFirst} from "../../../../../cores/helpers/cap-first";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import { PremiumInsuranceTableAdminColumns, PremiumInsuranceTableType, PremiumInsuranceType } from "./group-insurance-coverage-type";
import { StyledContentBox, StyledLargerTitle, StyledSpanLargerTitle, StyledSpanStatus } from "../../../../shared/employer-styled";
import MedicalAndTravelCoverage from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage";
import { GroupInsuranceDefault, MedicalAndTravel } from "../../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { MedicalTravelData } from "../../../request-a-quote/request-a-quote-type";
import { getBriefPremiumPaymentSubscriptions } from "../../../../../services/employer-benefits-service";
import styled from "styled-components";



const StyledContent = styled(StyledContentBox)`
  height: auto;
`


type GroupInsuranceCoverageProps = {
  employerId: number;
  isQuoteChecked: boolean;
  medicalTravels: MedicalAndTravel[];
  medicalTravelData: MedicalTravelData[];
  groupInsuranceDefault: GroupInsuranceDefault[];
  groupInsuranceQuoteRequest: number[];
  onSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAllowRenewal: boolean;
};

export const GroupInsuranceCoverage = React.memo(function PremiumInsurance(
  props: GroupInsuranceCoverageProps
) {
  const [premiumInsuranceData, setPremiumInsuranceData] = useState<
    PremiumInsuranceType[]
  >([]);

  useEffect(() => {
    if (!props.isAllowRenewal) {
        loadPremiumPaymentData().then();
    }
  }, [props.employerId, props.groupInsuranceDefault]);

  const loadPremiumPaymentData = async () => {
    if (props.employerId) {
      const result = await getBriefPremiumPaymentSubscriptions(props.employerId);
      setPremiumInsuranceData(result.data);
    }
  };

  const processPremiumInsuranceData = (
    premiumInsuranceData: PremiumInsuranceType[]
  ): PremiumInsuranceTableType[] => {
    return premiumInsuranceData.map((item) => {
      return {
        ...item,
        status: (
          <StyledSpanStatus title={item.status}>
            {capFirst(item.status)}
          </StyledSpanStatus>
        )
      };
    });
  };

  const premiumInsuranceTableData = useMemo(
    () => processPremiumInsuranceData(premiumInsuranceData),
    [premiumInsuranceData]
  );


  if (props.isAllowRenewal) {
  return (
    <Fragment>
      <StyledLargerTitle style={{paddingLeft: "20px"}}>
        Group Insurance Coverage
      </StyledLargerTitle>
      <MedicalAndTravelCoverage
          isChecked={props.isQuoteChecked}
          medicalTravelData={props.medicalTravelData}
          onCheckboxChange={props.onCheckboxChange}
          onSwitchChange={props.onSwitchChange}
          medicalTravels={props.medicalTravels}
          groupInsuranceDefault={props.groupInsuranceDefault}
          groupInsuranceQuoteRequest={props.groupInsuranceQuoteRequest}
          isSetup={false}
          isRenewal={true}
      />
    </Fragment>
  )}

  if (premiumInsuranceData && premiumInsuranceData.length > 0) {
    return (
      <Fragment>
        <StyledContent isBorder={false}>
          <StyledLargerTitle>
            Group Insurance Coverage
          </StyledLargerTitle>
          <BNPRawTable
            columns={PremiumInsuranceTableAdminColumns}
            tableWidth="100%"
            tableData={premiumInsuranceTableData}
          />
        </StyledContent>
      </Fragment>
    )
  }

  return <></>

});

export default GroupInsuranceCoverage;


