import React from "react";
import styled from "styled-components";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { getVerificationEmail } from "../../../services/auth-service";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StyledVerifyPage = styled.div`
  height: calc(100vh - 75px);
`;
const StyledVerifiedUserIcon = styled(VerifiedUserIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${props => props.theme.primaryColor};
`;
const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${props => props.theme.primaryColor};
`;
// const StyledImage = styled.img`
//   position: relative;
//   height: 132px;
//   width: 150px;
// `;
const StyledDivGroupVerify = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: 320px) {
    top: 50px;
  }
  @media (min-width: 767px) {
    top: 30%;
  }
`;

const StyledRequestMessage = styled.div`
  margin-top: 1rem;
`;

const StyledTitleMessage = styled.div`
  color: ${props => props.theme.primaryColor};
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledDescriptionMessage = styled.div`
  color: #2d2d2d;
  margin-top: 18px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
type State = {
  message: {
    title: string;
    message: string;
    isVerify: boolean;
  };
};
type Props = {};

export default class Verification extends React.Component<Props, State> {
  state: State = {
    message: {
      title: "",
      message: "",
      isVerify: false
    }
  };

  async componentDidMount() {
    let token = window.location.search.split("=")[1];
    if (token) {
      let param = {
        token: token
      };
      let message = {
        title: "",
        message: "",
        isVerify: false
      };

      await getVerificationEmail(param)
        .then(result => {
          message.title = "Verify Success";
          message.message =
            "Thank you, your email address is now verified on our system.";
          message.isVerify = result.data;
        })
        .catch(() => {
          message.title = "Failed to verify";
          message.message =
            "Failed to verify email, please check the verification link.";
          message.isVerify = false;
        });

      this.setState({ message: message });
    }
  }

  render() {
    return (
      <StyledVerifyPage>
        <StyledDivGroupVerify>
          {this.state.message.isVerify ? (
            <StyledVerifiedUserIcon />
          ) : (
            <StyledErrorOutlineIcon />
          )}
          <StyledRequestMessage>
            <StyledTitleMessage>{this.state.message.title}</StyledTitleMessage>
            <StyledDescriptionMessage>
              {this.state.message.message}
            </StyledDescriptionMessage>
          </StyledRequestMessage>
        </StyledDivGroupVerify>
      </StyledVerifyPage>
    );
  }
}
