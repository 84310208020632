import React, { Component } from "react";
import IconAddUser from "../../../../../cores/config/component/images/icon-add-user.svg";
import IconList from "../../../../../cores/config/component/images/icon-list.svg";

import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
} from "../../../../shared/employer-styled";
import styled from "styled-components";
import UploadInBulk from "./upload-in-bulk/upload-in-bulk";
import { Employee, Role } from "../employee-type";
import { Class } from "../../../request-a-quote/request-a-quote-type";
import { Status } from "../employee-model";
import { ManualAdd } from "./manual-add/manual-add";
import { Provinces } from "../../employer-setting/company-information/company-information-type";
import { ResponseLocation } from "../../../../../cores/helpers/google-map-services/google-location";
import {EmployerPlan} from "../../plan/plan-preferences/plan-preferences-type";

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  position: absolute;
  width: 100%;
  pointer-events: none;
  right: 0;
`;
const StyledContentBox = styled.div`
  padding: 9px 5px;
  width: 100%;
`;
const StyledDivBoxSwitchAddUser = styled.div`
  display: flex;
`;
const StyledButtonAddUser = styled.button`
  width: max-content;
  align-items: center;
  position: relative;
  color: #2d2d2d;
  background-color: #ffffff;
  border: 1px solid #e9eaed;
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
  display: flex;
  vertical-align: middle;
  -webkit-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 16px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;

  :hover,
  :focus {
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.8;
    outline: none;
  }
`;

const StyledModalContent = styled.div`
  min-height: 100vh;
`;

type State = {
  isUploadInBulk: boolean;
};
type Props = {
  employees: Employee;
  statuses: Status[];
  roles: Role[];
  handleChangeDate: (date: Date | null, name: string) => void;

  handleChangeSelect: (event: any) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSubmit: () => void;
  isValid: boolean;
  isEdit: boolean;
  editEmployee: () => void;
  setEmployerBenefitClass: () => Class[];
  provinces: Provinces[];
  //
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  findValueByPlanPreferencesByEmployer: () => number | null;
  classes: Class[];
  createInBulkError: String[];
  isUploadInBulk: boolean;
  handleAddEmployeeInBulk: (employees: Employee[]) => void;
  handleResetCreateInBulkError: () => void;
  employerPlan: EmployerPlan;
  isT4Employee: boolean;
  setIsT4Employee: (val: boolean) => void;
};

export default class AddEmployeeModal extends Component<Props, State> {

  render() {
    return (
      <div className="modal" id="addEmployeeModal">
        <StyledModalDialog role="document">
          <StyledModalContent className="modal-content">
            <StyledHeaderModal className="modal-header">
              <StyledHeaderTitleModal>
                {this.props.isEdit ? "Edit employees" : "Add employees"}
              </StyledHeaderTitleModal>
              <StyledButtonCloseModal
                type="button"
                onClick={closeAddEmployeeModal}
              >
                <StyledIconCloseModal>&times;</StyledIconCloseModal>
              </StyledButtonCloseModal>
            </StyledHeaderModal>
            <div className="modal-body">
              <div className="row">
                <StyledContentBox>
                  {this.props.isUploadInBulk ? (
                    <UploadInBulk
                      findValueByPlanPreferencesByEmployer={
                        this.props.findValueByPlanPreferencesByEmployer
                      }
                      classes={this.props.classes}
                      roles={this.props.roles}
                      provinces={this.props.provinces}
                      createInBulkError={this.props.createInBulkError}
                      handleAddEmployeeInBulk={this.props.handleAddEmployeeInBulk}
                      handleResetCreateInBulkError={this.props.handleResetCreateInBulkError}
                      employerPlan={this.props.employerPlan}
                    />
                  ) : (
                    <ManualAdd
                      employees={this.props.employees}
                      statuses={this.props.statuses}
                      roles={this.props.roles}
                      handleChangeInput={this.props.handleChangeInput}
                      handleChangeSelect={this.props.handleChangeSelect}
                      handleChangeSubmit={this.props.handleChangeSubmit}
                      handleChangeDate={this.props.handleChangeDate}
                      isValid={this.props.isValid}
                      isEdit={this.props.isEdit}
                      editEmployee={this.props.editEmployee}
                      setEmployerBenefitClass={
                        this.props.setEmployerBenefitClass
                      }
                      provinces={this.props.provinces}
                      //
                      setAddress={this.props.setAddress}
                      setLocation={this.props.setLocation}
                      employerPlan={this.props.employerPlan}
                      isT4Employee={this.props.isT4Employee}
                      setIsT4Employee={this.props.setIsT4Employee}
                    />
                  )}
                </StyledContentBox>
              </div>
            </div>
          </StyledModalContent>
        </StyledModalDialog>
      </div>
    );
  }
}

export function showAddEmployeeModal() {
  ($("#addEmployeeModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeAddEmployeeModal() {
  ($("#addEmployeeModal") as any).modal("hide");
}
