import React, { Component } from "react";
import { getUserRole, putCompanyInfo, terminatedEmployeeOfAdmin, updateUserRole } from "../../../../../services/employer-setting-service";
import { formatValueInputMask } from "../../../../../cores/helpers/format-phone-number";
import {
  CompanyInfo,
  Provinces,
} from "../../employer-setting/company-information/company-information-type";
import CompanyInfoView from "../../employer-setting/company-information/company-information-view";
import SetUpWizardView from "../setup";
import { RouteChildrenProps } from "react-router";
import {
  addLoading,
  removeLoading,
} from "./../../../../../cores/utils/loading";
import { ResponseLocation } from "../../../../../cores/helpers/google-map-services/google-location";
import {
  findProvinceIdByName,
  getDataCompanyInfo,
} from "../../employer-setting/company-information/company-information";
import {
  showConfirmOptionModal,
  ConfirmOptionDialog,
} from "../../../../../cores/helpers/confirm-dialog/confirm-option-dialog";
import { signOut, setToken, NA_OPTION_VALUE, getRemakedReferralPartnerUsers, getDefaultSelectedPartnerUserId, BENIPLUS_DEFAULT_OPTION } from "../../../../../cores/utils/helpers";
import ErrorDialog, {
  showErrorModal,
} from "../../../../../cores/helpers/error-dialog/error-dialog";
import {
  setupPlanURL,
  signInURL,
} from "../../../../../cores/constants/url-config";
import { EmployerConfiguration } from "../../../../../models/employer-configuration/employer-configuration-model"
import { Option } from "../../../../../cores/helpers/select/select";
import {getPartnerOptions, getReferralPartnerUserOptions} from "../../../../../services/partner-service";

type Props = RouteChildrenProps & EmployerConfiguration;
type State = {
  companyInfo: CompanyInfo;
  provinces: Provinces[];
  isValid: boolean;
  isAdditionalStreet: boolean;
  employerSetupUserRole: string;
  referralPartners: Option[];
  referralPartnerUsers: Option[];
};

export default class CompanyInfoSetup extends Component<Props, State> {
  state: State = {
    provinces: [],
    companyInfo: {
      referralPartnerId: null,
      referralPartnerName: "",
      referralPartnerUserId: null,
      referralPartnerUserName: "",
      companyName: "",
      noOfEmployees: 0,
      streetAddress: "",
      cityName: "",
      provinceId: -1,
      postcode: "",
      streetAddress2: "",
      cityName2: "",
      provinceId2: -1,
      postcode2: "",
      companyPhoneNumber: "",
      companyPhoneNumber2: "",
      soleProprietor: false,
      isHave2EmployeeIncluding1ArmLength: false,
      isCreatedByManager: false,
    },
    isValid: false,
    isAdditionalStreet: false,
    employerSetupUserRole: employerRoles.owner.name,
    referralPartners: [],
    referralPartnerUsers: [],
  };

  async componentDidMount() {
    //add loading
    addLoading();
    let result = await getDataCompanyInfo();
    const referralPartners = await getPartnerOptions();
    let referralPartnerData: any = [BENIPLUS_DEFAULT_OPTION, ...referralPartners.data] || [];
    let referralPartnerUsers: any = [];

    if (!result.companyInfo.referralPartnerId) {

      // set defalt partner
      let defaultReferralPartner = referralPartnerData.findLast((i: any) => i.isDefaultReferral);
      const defaultPartner = defaultReferralPartner ? defaultReferralPartner : BENIPLUS_DEFAULT_OPTION;
      const defaultPartnerId: number = defaultPartner.id;

      result.companyInfo.referralPartnerId = defaultPartnerId;
      result.companyInfo.referralPartnerName = defaultPartner.name;
      
      // set default partner user
      const referralPartnerUsersRes = await getReferralPartnerUserOptions(result.companyInfo.referralPartnerId);
      referralPartnerUsers = getRemakedReferralPartnerUsers(defaultPartner, (referralPartnerUsersRes.data || []));

      const defaultReferralPartnerUserId = getDefaultSelectedPartnerUserId(defaultPartner, referralPartnerUsers);
      const defaultReferralPartnerUser = referralPartnerUsers.findLast((i: any) => i.id == defaultReferralPartnerUserId);

      result.companyInfo.referralPartnerUserId = defaultReferralPartnerUserId;
      result.companyInfo.referralPartnerUserName = defaultReferralPartnerUser.name;
    }
  
    await this.getEmployerSetupUserRole();


    let isValid = this.validate(
      result.companyInfo,
      this.state.isAdditionalStreet
    );

    let isAdditionalStreet = false;
    if (
      result.companyInfo.postcode &&
      result.companyInfo.postcode2 &&
      result.companyInfo.postcode === result.companyInfo.postcode2
    ) {
      isAdditionalStreet = true;
    }

    this.setState({
      companyInfo: result.companyInfo,
      provinces: result.provinces,
      isValid: isValid,
      isAdditionalStreet: isAdditionalStreet,
    });

    // remove loading
    removeLoading();
  }

  getSelectedPartner = (referralPartnerData: any, selectedReferralPartnerId: number) => {
    let selectedPartner = null;

    let selectedPartnerArr = referralPartnerData && referralPartnerData.length > 0
      ? referralPartnerData.filter((p: any) => p.id == selectedReferralPartnerId) : [];
    if (selectedPartnerArr.length > 0) {
      selectedPartner = selectedPartnerArr[0];
    }

    return selectedPartner;
  }

  async getEmployerSetupUserRole() {
    const roleLocalStorage = localStorage.getItem(EMPLOYER_SETUP_USER_ROLE_LOCAL_STORAGE);
    const employerSetupUserRole = await getUserRole();

    this.setState({
      employerSetupUserRole: roleLocalStorage || employerSetupUserRole.data,
    })
  }

  handleChangInput = (event: any) => {
    let companyInformation = Object.assign(this.state.companyInfo);
    if (
      event.target.name === "companyPhoneNumber" ||
      event.target.name === "companyPhoneNumber2"
    ) {
      let phoneNumber = formatValueInputMask(event.target.value);
      companyInformation[event.target.name] = phoneNumber;
    } else if (event.target.name === "noOfEmployees") {
      companyInformation.noOfEmployees = Number(event.target.value);
    } else {
      companyInformation[event.target.name] = event.target.value;
    }

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  handleChangSelect = (event: any) => {
    const { name, value } = event.target;

    let companyInformation = Object.assign(this.state.companyInfo);

    companyInformation[name] = value;
    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );
    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  handleChangeEmployerRole = (event: any) => {
    const { value } = event.target;

    this.setState({ employerSetupUserRole: value})
  }
  // save update company info
  handleSubmit = () => {
    if (this.state.companyInfo.soleProprietor) {
      showConfirmOptionModal();
    } else {
      let companyInfo = this.checkIsSameTheBillingAddress();

      this.updateCompanyInfo(companyInfo, false);
    }
  };

  // check the same as the billing address
  checkIsSameTheBillingAddress = () => {
    return !this.state.isAdditionalStreet
      ? Object.assign({}, this.state.companyInfo)
      : Object.assign({}, this.state.companyInfo, {
          streetAddress2: this.state.companyInfo.streetAddress,
          cityName2: this.state.companyInfo.cityName,
          provinceId2: this.state.companyInfo.provinceId,
          postcode2: this.state.companyInfo.postcode,
          companyPhoneNumber2: this.state.companyInfo.companyPhoneNumber,
        });
  };

  // if choose yes
  handleYes = () => {
    let companyInfo = this.checkIsSameTheBillingAddress();

    companyInfo.isHave2EmployeeIncluding1ArmLength = true;

    this.updateCompanyInfo(companyInfo, false);
  };
  // if choose no
  handleNo = () => {
    let companyInfo = this.checkIsSameTheBillingAddress();

    companyInfo.isHave2EmployeeIncluding1ArmLength = false;

    this.updateCompanyInfo(companyInfo, true);
  };

  updateCompanyInfo = async (companyInfo: CompanyInfo, isDisabled: boolean) => {

    let updateCompanyInfoRequest = { ...companyInfo };
    updateCompanyInfoRequest.referralPartnerUserId =
      updateCompanyInfoRequest.referralPartnerUserId === NA_OPTION_VALUE ? null : updateCompanyInfoRequest.referralPartnerUserId

    await putCompanyInfo(updateCompanyInfoRequest);
    if (isDisabled) {
      showErrorModal();
    }

    const updateRoleRes = await updateUserRole(this.state.employerSetupUserRole);
    setToken(updateRoleRes.data.access_token, updateRoleRes.data.expired_at)

    localStorage.setItem(EMPLOYER_SETUP_USER_ROLE_LOCAL_STORAGE, this.state.employerSetupUserRole)

    this.props.history.push(setupPlanURL);
  };

  signOut = () => {
    signOut();
    this.props.history.push(signInURL);
  };

  validate = (
    companyInfo: CompanyInfo,
    isAdditionalStreet: boolean
  ): boolean => {
    if (
      companyInfo.companyName &&
      companyInfo.noOfEmployees &&
      companyInfo.provinceId > -1 &&
      companyInfo.cityName &&
      companyInfo.postcode &&
      companyInfo.companyPhoneNumber &&
      companyInfo.soleProprietor !== -1 &&
      (isAdditionalStreet ||
        (!isAdditionalStreet &&
          companyInfo.provinceId2 > -1 &&
          companyInfo.cityName2 &&
          companyInfo.postcode2 &&
          companyInfo.companyPhoneNumber2))
    ) {
      return true;
    }

    return false;
  };

  handleAdditionalStreet = (event: any): void => {
    let isValid = this.validate(this.state.companyInfo, event.target.checked);
    this.setState({
      isAdditionalStreet: event.target.checked,
      isValid: isValid,
    });
  };

  setAddress = (address: string) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);
    companyInformation.streetAddress = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setLocation = (location: ResponseLocation) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);

    companyInformation.cityName = location.city;
    companyInformation.postcode = location.postcode ? location.postcode : "";
    companyInformation.provinceId = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setAddress2 = (address: string) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);
    companyInformation.streetAddress2 = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  setLocation2 = (location: ResponseLocation) => {
    let companyInformation: CompanyInfo = Object.assign(this.state.companyInfo);

    companyInformation.cityName2 = location.city;
    companyInformation.postcode2 = location.postcode ? location.postcode : "";
    companyInformation.provinceId2 = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfo: companyInformation, isValid: isValid });
  };

  render() {
    return (
      <SetUpWizardView
        enablePresetBankingInformation={this.props.enablePresetBankingInformation}
        children={
          <>
            <CompanyInfoView
              provinces={this.state.provinces}
              companyInformation={this.state.companyInfo}
              handleSubmit={this.handleSubmit}
              handleChangInput={this.handleChangInput}
              handleChangSelect={this.handleChangSelect}
              isValid={this.state.isValid}
              isAdditionalStreet={this.state.isAdditionalStreet}
              handleAdditionalStreet={this.handleAdditionalStreet}
              isSetup={true}
              isPadding={false}
              //
              setAddress={this.setAddress}
              setLocation={this.setLocation}
              setAddress2={this.setAddress2}
              setLocation2={this.setLocation2}
              employerRoles={employerRoles}
              handleChangeEmployerRole={this.handleChangeEmployerRole}
              employerSetupUserRole={this.state.employerSetupUserRole}
            />
            <ConfirmOptionDialog
              title="Warning"
              content="Do you have at least 2 employees including 1 arm's length employee?"
              handleNo={this.handleNo}
              handleYes={this.handleYes}
            />
            <ErrorDialog
              title="Sorry!"
              description="Sorry! Our products are not offered for sole ownership company at the moment."
              handleClose={this.signOut}
            />
          </>
        }
      />
    );
  }
}

export const employerRoles = {
  owner: {
    name: "owner",
    description: "I am the owner",
  },
  admin: {
    name: "admin",
    description: "I am the administrator"
  },
  adminNotEmployee: {
    name: "admin_not_employee",
    description: "I am the administrator (not employee)"
  }
}

export const EMPLOYER_SETUP_USER_ROLE_LOCAL_STORAGE = "EmployerSetupUserRole";
