import React from "react";
import { PaymentModel } from "./funding-type";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import EmailIcon from "@material-ui/icons/Email";
// import PublicIcon from "@material-ui/icons/Public";

export const payments: PaymentModel[] = [
  {
    id: -1,
    name: <div className="p-2">-Pick an option-</div>,
  },
  // {
  //   id: 0,
  //   name: (
  //     <div className="p-2">
  //       <EmailIcon className="mr-2" /> Email Transfer
  //     </div>
  //   )
  // },
  // {
  //   id: 1,
  //   name: (
  //     <div className="p-2">
  //       <PublicIcon className="mr-2" /> Online Bill Pay
  //     </div>
  //   )
  // },
  {
    id: 2,
    name: (
      <div className="p-2">
        <AccountBalanceIcon className="mr-2" />
        Cheque (PAD) agreement
      </div>
    ),
  },
];
