import React, { Component } from "react";
import Profile from "../../../cores/config/component/images/profile.jpg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  getInfoByToken,
  signOut,
  getIsSetupDone,
  getToken,
} from "../../../cores/utils/helpers";
import { showChangePasswordModal } from "./change-password";
import { signInURL } from "../../../cores/constants/url-config";

const StyledHeaderRight = styled.span`
  position: absolute;
  color: #2d2d2d;
  right: 15px;
  top: 9px;
  font-family: ${(props) => props.theme.textFont} !important;
`;

const StyledText = styled.span`
  margin-right: 0.5em;
  font-size: 14px;
`;

const StyledImgProfile = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  position: relative;
  top: -2px;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
    text-decoration: none;
  }
  position: relative;
  top: 5px;
`;
const StyledDivChangePassword = styled.div`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
  }
`;

const StyledUl = styled.ul`
  padding: 8px 20px !important;
`;
const StyledLi = styled.li`
  height: 30px;
`;

type State = {
  name: string;
  isSetupDone: boolean;
};

export default class EmployerHeader extends Component<any, State> {
  state: State = {
    name: "",
    isSetupDone: false,
  };

  componentDidMount() {
    let decoded = getInfoByToken();
    getIsSetupDone().then((res: boolean) => {
      this.setState({ name: decoded.user ? decoded.user.name : "Employer", isSetupDone: res });
    })
  }

  switchToEmployeePortal = () => {
    let href = "http://localhost:3001";
    let token = getToken();
    // dev
    if (window.location.href.match("beniplus-dev.futurify.io")) {
      href = "https://beniplus-employee-dev.futurify.io";
    }

    // staging
    if (window.location.href.match("employer-staging.beniplus.ca")) {
      href = "https://employee-staging.beniplus.ca";
    }

    // prod
    if (window.location.href.match("employer.beniplus.ca")) {
      href = "https://employee.beniplus.ca";
    }


    // GTD staging
    if (window.location.href.match("gotodoctor-company.beniplus.ca")) {
      href = "https://gotodoctor-employee.beniplus.ca";
    }

    // GTD prod
    if (window.location.href.match("company.gotohealthwallet.ca")) {
      href = "https://employee.gotohealthwallet.ca";
    }

    window.location.href = href + "?token=" + token;
  };

  render() {
    return (
      <div>
        <StyledHeaderRight>
          <div className="dropdown mt-2 cur">
            <span className="dropdown-toggle" data-toggle="dropdown">
              <StyledText>{this.state.name}</StyledText>
              <StyledImgProfile src={Profile} alt="profile" />
            </span>
            <StyledUl className="dropdown-menu">
              {this.state.isSetupDone ? (
                <StyledLi className="dropdown">
                  <StyledDivChangePassword
                    onClick={this.switchToEmployeePortal}
                  >
                    Switch to employee portal
                  </StyledDivChangePassword>
                </StyledLi>
              ) : (
                ""
              )}
              <StyledLi className="dropdown">
                <StyledDivChangePassword onClick={showChangePasswordModal}>
                  Change password
                </StyledDivChangePassword>
              </StyledLi>
              <StyledLi className="dropdown">
                <StyledLink
                  onClick={() => {
                    signOut();
                  }}
                  to={signInURL}
                >
                  Sign out
                </StyledLink>
              </StyledLi>
            </StyledUl>
          </div>
        </StyledHeaderRight>
      </div>
    );
  }
}
