import React from 'react';
import moment from "moment";
import styled from "styled-components";
import {ExpansionPanel} from "@material-ui/core";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {TableSearchParamType, TableSelectType, TableSortType} from "./BNPTableType";
import {
  StyledChangeDetail, StyledErrorMessage,
  StyledExpansionLabel,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledSpanExpansionEdit,
  StyledTitleExpansion
} from "../employer-styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon, StyledBodyTable,
  StyledCellTable,
  StyledHeaderTable, StyledImportExportIcon,
  StyledTable
} from "../table-styled";
import Pagination, {PagingInfo} from "../../../cores/helpers/pagingation/pagination";
import {BNPTableActions} from "./BNPTableActions";
import {message} from "../../../cores/helpers/message/message";

const StyledOverflowTableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
`;

const StyledOverflowTable = styled.div`
  width: 100%;
`;

export const StyledDivExport = styled.div`
  font-weight: bold;
  line-height: 16px;
  color: ${props => props.theme.infoColor};
  width: fit-content;
  border: 1px solid ${props => props.theme.infoColor};
  border-radius: 32px;
  padding: 15px 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    padding-right: 4px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
  cursor: pointer;
`;

type BNPTableProps = {
  tableName: string;
  searchParam: TableSearchParamType;
  setSearchParam: (searchParam: TableSearchParamType) => void;
  columns: TableSortType[];
  tableWidth: string;
  tableData: any[];
  pagingInfo: PagingInfo;
  mobileDetailUrl?: string;
  handleDownload?: () => void;
  haveFilterByDate: boolean;
  haveSearch: boolean;
  searchPlaceHolder?: string;
  filterPlaceHolder?: string;
  filterType?: TableSelectType[];
};

function BnpTable(props: BNPTableProps) {

  const setRowsPerPage = (event: any) => {
    const {value} = event.target;

    const searchParam = Object.assign({}, props.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    props.setSearchParam(searchParam);
  };

  const handleChangePage = (page: number) => {
    const searchParam: TableSearchParamType = Object.assign({}, props.searchParam);
    searchParam.page = page;

    props.setSearchParam(searchParam);
  };

  const sortTable = (columnId: string) => {
    const columnsHeaderTable: TableSortType[] = [...props.columns];
    const searchParam = Object.assign({}, props.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    props.setSearchParam(searchParam);
  };

  const handleChangeDate = (date: Date | null, name: string) => {
    const searchParam: TableSearchParamType = Object.assign({}, props.searchParam);

    if (name === "from") {
      if (searchParam.to && date) {
        let currentFrom = new Date(date),
          from = new Date(new Date(currentFrom).setHours(0, 0, 0, 0)),
          to = new Date(searchParam.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date && moment(date.toString()).format("YYYY-MM-DD");
    } else {
      if (searchParam.from && date) {
        let from = new Date(searchParam.from),
        to = new Date(new Date(date).setHours(23, 59, 59, 999));
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date && moment(date.toString()).format("YYYY-MM-DD");
    }
    searchParam.page = 1;
    props.setSearchParam(searchParam);
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchParam: TableSearchParamType = Object.assign({}, props.searchParam);

    if ((event.target.name = "filter")) {
      if (Number(value) !== -1) searchParam.filter = value;
      else searchParam.filter = null;
    }
    searchParam.page = 1;

    props.setSearchParam(searchParam);
  };

  const handleSearch = (searchKey: string) => {
    props.setSearchParam({...props.searchParam, searchKey: searchKey.trim()});
  }

  return (
    <>
      {/* Always render the search and filter row */}
      {(props.haveFilterByDate || props.haveSearch || props.filterType || props.handleDownload) && (
        <div className="row pt-4 mb-4">
          <div className="col-lg-8">
            <BNPTableActions
              tableType={props.tableName}
              searchParam={props.searchParam}
              handleChangeDate={props.haveFilterByDate ? handleChangeDate : undefined}
              handleSearch={props.haveSearch ? handleSearch : undefined}
              filterTypes={props.filterType ? props.filterType : undefined}
              searchPlaceHolder={props.searchPlaceHolder ? props.searchPlaceHolder : undefined}
              filterPlaceHolder={props.filterPlaceHolder ? props.filterPlaceHolder : undefined}
              handleChangeFilter={props.filterType ? handleChangeSelect : undefined}
            />
          </div>
          <div className="col-lg-2" />
          {!!props.handleDownload && (
            <div className="col-lg-2 pt-2">
              <StyledDivExport className="w-100" onClick={props.handleDownload}>
                <SystemUpdateAltOutlinedIcon className="icon mr-2" />
                Export
              </StyledDivExport>
            </div>
          )}
        </div>
      )}
      {props.tableData.length !== 0 ? (
      <div className="row">
        <div className="col-lg-12 mt-4">
          <StyledHideForMobile>
            <StyledTable>
              <StyledOverflowTableWrapper>
                <StyledOverflowTable style={{minWidth: props.tableWidth}}>
                  <StyledHeaderTable>
                    {props.columns.map((column, index) => (
                        <StyledCellTable
                            width={column.width}
                            key={index}
                            className="cur"
                            onClick={() => column.sortColumn && sortTable(column.columnId)}
                        >
                          {column.columnName}
                          {column.sortColumn && <span>
                          {column.sortType ? (
                              column.sortType === "ASC" ? (
                                  <StyledArrowDropDownIcon/>
                              ) : (
                                  <StyledArrowDropUpIcon/>
                              )
                          ) : (
                              <StyledImportExportIcon/>
                          )}
                        </span>}
                        </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {props.tableData.map((item, i) => (
                        <StyledBodyTable key={`bnp_table_row_${i}`}>
                          {props.columns.map(col => (
                              <StyledCellTable width={col.width}>
                                {item[col.columnId]}
                              </StyledCellTable>
                          ))}
                        </StyledBodyTable>
                    ))}
                  </div>
                </StyledOverflowTable>
              </StyledOverflowTableWrapper>
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={handleChangePage}
                        onChangeRowsPerPage={setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </StyledTable>
          </StyledHideForMobile>

          <StyledHideForDesktop>
            {props.tableData.map((item, i) => (
                <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={`bnp_mobile_table_row_${i}`}
                >
                  <StyledExpansionPanelSummary
                      className="m-0"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowDropUp/>}
                  >
                    <div className="w-100">
                      <StyledTitleExpansion>{props.columns[1].columnName}</StyledTitleExpansion>
                      <div className="mt-2">{item[props.columns[1].columnId]}</div>
                    </div>
                  </StyledExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    {props.columns.map(col => (
                        <div className="w-100 mt-2">
                          <StyledTitleExpansion>{col.columnName}</StyledTitleExpansion>
                          <div className="mt-1">{item[col.columnId]}</div>
                        </div>))}
                    <hr/>
                    {!!props.mobileDetailUrl && <div className="text-center">
                      <StyledSpanExpansionEdit>
                        <StyledExpansionLabel>
                          <StyledChangeDetail
                              to={props.mobileDetailUrl}
                          >
                            Detail
                          </StyledChangeDetail>
                        </StyledExpansionLabel>
                      </StyledSpanExpansionEdit>
                    </div>}
                  </StyledExpansionPanelDetails>
                </ExpansionPanel>
            ))}
            <div className="row mt-4">
              <div className="col-12 text-center">
                <label>
                  <Pagination
                      pagerPagination={props.pagingInfo}
                      getPage={handleChangePage}
                      onChangeRowsPerPage={setRowsPerPage}
                  />
                </label>
              </div>
            </div>
          </StyledHideForDesktop>
        </div>
      </div>
    ) : (
        <div className="d-flex justify-content-center w-100">
          <StyledErrorMessage>No data to show</StyledErrorMessage>
        </div>
    )}
    </>
  )
}

export default BnpTable;