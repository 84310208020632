import React from "react";
import styled from "styled-components";


interface MinibarProps {
    color: string;
    value: number;
    total: number;
    progressStart: number;
}

const MinibarProgress = styled.div<MinibarProps>`
  height: 100%;
  position: absolute;
  top: 0rem;
  left: ${props => `${props.progressStart/props.total*100}%`};
  background-color: ${props => {
    if (props.color === "primary") return props.theme.primaryColor;
    if (props.color === "secondary") return props.theme.secondaryColor;
    return props.color
  }};
  width: ${props => `${(props.progressStart+props.value > props.total) ? (props.total - props.progressStart)/props.total*100 : props.value/props.total*100}%`};
`;

const Minibar = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 6px;
  border-radius: 20%;
  background-color: #fff
`

type Props = {
  values: number[];
  total: number;
  colors: string[];
};

/**
 *
 * @param props value from 0.00 to 100
 */
export default function BNPProgressBar(props: Props /*value from 0 to 100*/) {
    return (
      <Minibar>
          {props.values.map((v, idx) =>
              <MinibarProgress
                                key={`progress_bar_item_${idx}`}
                                value={v || 0}
                               total={props.total}
                               progressStart={props.values.reduce((total, value, currentIndex) => {
                                   if (currentIndex < idx) return total + value
                                   return total
                               }, 0)}
                               color={props.colors[idx] || "primary"} />)}
      </Minibar>
  );
}
