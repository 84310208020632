import React, { useEffect, useState } from "react";

import {StyledBigTitle, StyledMainContent} from "../../shared/employer-styled";

import styled from "styled-components";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";

export const ContentBoxCustom = styled.div`
  background-color: #f4f5f9;
  margin-top: 1.5rem;
  padding: 21px 25px;
  border-radius: 5px;
  height: 100%;
`;

export const StyledTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`;

export const StyledListItemNoneStyle = styled.li`
  list-style-type: none;
  padding-top: 16px;
`;

export default function TermServiceView() {

  const [companyName, setCompanyName] = useState<string>("BeniPlus");
  const [walletName, setWalletName] = useState<string>("BeniPlus Wallet");
  
  useEffect(() => {

    if (!isBeniplusUI) {
      setCompanyName("BeniPlus/Gotodoctor.ca")
      setWalletName("Benefit Wallet")
    }

  }, []);

  return (
    <StyledMainContent>
      <StyledBigTitle>Terms and Conditions of Use</StyledBigTitle>

      <ContentBoxCustom>
        <div className="mt-3">
          <StyledTitle>Flexible Spending Account Terms and Conditions</StyledTitle>
        </div>
        <div className="mt-3">
          {!isBeniplusUI
            &&
            <div>
              GotoHealthWallet is a “{walletName}” licensed exclusively to Gotodoctor.ca that allows employers to 
              give benefits to employees and the employees to claim those benefits. 
              BeniPlus Inc. is the plan administrator for this Private Health Service Plan.
              <br/>
              <br/>
            </div>
          }
          <div>
            The following is a summary of the criteria related to the design of an eligible {walletName}, based on
            the interpretations published by the Canada Revenue Agency (“CRA”) and the provisions based on the Income
            Tax Act of Canada (“ITA”) regarding Flexible Spending Accounts (FSA):<br/>
            <br/>
            CRA Interpretations applicable to Flexible Spending Accounts
          </div>
          <ul className="mt-2 pl-3">
            <li>The {walletName} includes a Healthcare Spending Account (HSA), which is used for the purpose of
              covering eligible medical expenses, subject to various limitations. Eligibility of expenses is as defined
              in www.cra-arc.gc.ca/medical.
            </li>
            <li>As interpreted by the CRA incorporated businesses, including employees who are shareholders and all
              other corporate employees, are eligible to participate in an FSA. All participants in this FSA must
              receive these benefits by virtue of their employment at the company and not as a shareholder benefit and
              these benefits are provided as part of an employment contract. Corporations with as few as one employee
              can be eligible so long as the benefits are provided to a shareholder in their capacity as an employee,
              not as a shareholder. One important factor in this regard is that the shareholder is earning T4 income. In
              the case of unincorporated businesses or sole proprietors, the owner and their employees are also eligible
              if the owner has at least one arm’s-length employee.
            </li>
            <li>While there are no limits specified by the ITA for an FSA the CRA has interpreted that benefits must
              be reasonable and be consistent with what would be offered to a non-arm’s length employee providing
              similar services. Different limits may be set for different employee groups. Reasonability is not defined
              in the ITA and is dependent on many factors. Consult a tax advisor to determine what level of contribution
              would be deemed reasonable in your circumstances.
            </li>
            <li>Additional details on the CRA’s interpretations are outlined in IT-529.</li>
          </ul>
          <div className="mt-2">
            <strong>{companyName + " Plan Design"}</strong>
          </div>
          <ul className="mt-2 pl-3">
            <li>The amount to be allocated to a {walletName} for an employee shall be determined at the start of a
              plan year and cannot be altered unless you are promoted to another class or there has been a life event
              such as a change in the number of dependants through birth, death, marriage or change in eligibility
              circumstances.
            </li>
            <li>Claims related to eligible medical expenses incurred during the plan year are to be submitted within
              14 days of the end of the plan year. Claims submitted beyond this period will be declined and not
              reimbursed.
            </li>
            <li>{walletName} claims are subject to an administration fee plus government taxes.
            </li>
            <li>Any unused balance in the employee’s {walletName} at the end of a plan year may be used by the
              employee for a maximum of 12 months following the plan year, if their employer allows for benefits
              rollover. After this 12-month period, any remaining benefits dollars from the previous year will be
              forfeited.
            </li>
            <li>Upon employee termination from an Employer, the employee has 14 days to submit claims after which time
              their account will be closed.
            </li>
          </ul>
          <div className="mt-2">
            {companyName} makes best efforts to ensure its {walletName} plans adhere to all ITA rules and to comply with
            the CRA interpretation and application of those rules. {companyName} does not provide tax advice to clients and
            recommends they seek their own independent advice related to the tax deductibility of healthcare 
            spending. {companyName} relies on the representations of the client as provided in the application and acknowledgement
            forms that they meet the eligibility criteria for an HSA and that the amounts contributed are reasonable in
            their particular circumstances.
          </div>
        </div>

        <div className="mt-3">
          <StyledTitle>Confidentiality and Privacy</StyledTitle>
        </div>
        <div>
          <div className="mt-3">
            Despite {companyName}’s commitment to protect your privacy and the confidential nature of your information, we
            wish to inform you that communication over the internet can entail a level of risk. {companyName} will not be
            responsible for any damages you or any third party may suffer as a result of the transmission of
            confidential information that you make to {companyName} or any third party through the Internet, or that you
            expressly or implicitly authorize {companyName} to make, or for any errors or any changes made to any transmitted
            information.
          </div>
          <div className="mt-3">
            While {companyName} will make an effort to help ensure that all information provided does not contain computer
            viruses, you should take reasonable and appropriate precautions to scan for computer viruses and should
            ensure that you have and current backup of applicable items of information contained on your computer.
          </div>
          <div className="mt-3">
            For more information on how {companyName} is working to protect your information from cyber threats visit our
            Privacy Policy.
          </div>
          <div className="mt-3">
            By accepting the Terms and Condition, you acknowledge that you have read, understand, and agree to the
            conditions in the Privacy Policy.
          </div>
        </div>

        <div className="mt-3">
          <StyledTitle>Account Security</StyledTitle>
        </div>
        <div>
          <div className="mt-3">
            In order to register your account, you must complete the registration process by providing current, complete
            and accurate information as prompted by the onboarding process. By registering your wallet, you are
            consenting to receive administrative and marketing messages from us from time to time. If at any time you do
            not wish to receive the marketing messages, simply send an email to marketing@beniplus.ca or click the
            unsubscribe link on the email.
          </div>
          <div className="mt-3">
            When you register, you will be required to choose a password. You are entirely responsible for maintaining
            the confidentiality of your password and account. {companyName} will rely completely on authorizations and
            information provided by you or by persons acting on your behalf, and assess all data provided as accurate.
            Furthermore, you are entirely responsible for any and all activities that occur under your account or the
            accounts of those you are responsible for in your organization. You agree to notify {companyName} immediately of
            any unauthorized use or any other breach of security of your account or the aforementioned 
            accounts. {companyName} will not be liable for any loss that you may incur as a result of someone else using your password
            or account, either with or without your knowledge. Similarly, you may not use anyone else's account at any
            time, without permission of the account holder.
          </div>
          <div className="mt-3">
            {companyName} may, in its sole discretion and without notice to you, de-activate your username and/or password
            and terminate access to the Site if {companyName} believes you have violated or acted inconsistently with the
            letter or spirit of these Terms of Use or you have submitted inaccurate, incomplete, false, misleading or
            fraudulent information on this Site.
          </div>
          <div className="mt-3">
            You agree that {companyName} will not be held liable for any losses or damages you may suffer as a result of a
            breach of these Terms and Conditions.
          </div>
        </div>

        <div className="mt-3">
          <StyledTitle>Support</StyledTitle>
        </div>
        <div className="mt-3">
          You acknowledge and agree that you use the Site and the {companyName} information services at your own 
          risk. {companyName} will make reasonable efforts to ensure that this site is virus free but does not guarantee that this
          is the case. Support regarding the {companyName} information services is only provided subject to the terms of a
          separate agreement between you and {companyName}.
        </div>

        <div className="mt-3">
          <StyledTitle>Reservation of Rights</StyledTitle>
        </div>
        <div className="mt-3">
          We reserve the right to change, modify, add to, or remove sections of these Terms and Conditions at any time.
        </div>

        <div className="mt-3">
          <StyledTitle>No Additional Rights</StyledTitle>
        </div>
        <div className="mt-3">
          You acknowledge and agree that except for the rights expressly granted above, all other right, title and
          interest in and to the Site and the {companyName} information services, including any and all patent, trademark,
          copyright, trade secret, intellectual and industrial property rights remain the sole and exclusive property 
          of {companyName}, its affiliates, suppliers and/or its licensors. Except as specifically set out in the Terms and
          Conditions, you are not authorized to use the {companyName} name or any of its trademarks or those of its suppliers
          and/or its licensors in any manner whatsoever, absent prior written approval from {companyName}.
        </div>

      </ContentBoxCustom>
    </StyledMainContent>
  );
}
