import React from "react";
import {
  StyledMainContent,
  StyledBigTitle, StyledCell, StyledContentBox, StyledLargerTitle, StyledButtonSubmit,
} from "../../../shared/employer-styled";
import {
  PlanPreferencesModel,
  PlanPreferences,
  RequestPlanPreferences, EmployerPlan,
} from "./plan-preferences/plan-preferences-type";
import {
  Enrollments,
  EmployerEnrollment,
} from "../employer-classes/employer-classes-type";
import { putEmployersPlanPreferences } from "../../../../services/employer-setting-service";
import {
  postRequestGroupInsuranceQuote,
  putEmployerEnrollments,
  putMedicalAndTravelCoverage,
  putRequestGroupInsuranceQuote,
} from "../../../../services/employer-benefits-service";
import EnrollmentCoverage from "./enrollment-coverage/enrollment-coverage";
import EmployerPlanPreferences from "./plan-preferences/plan-preferences";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { getDataPlan, getGroupInsuranceData } from "./plan-function";
import styled, {css} from "styled-components";
import moment from "moment";
import {FE_DETAIL_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import theme from "../../../../cores/helpers/theme";
import GroupInsuranceCoverage from "./group-insurance-coverage/group-insurance-coverage";
import { getInfoByToken } from "../../../../cores/utils/helpers";
import { GroupInsuranceDefault, MedicalAndTravel } from "../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { MedicalTravelData } from "../../request-a-quote/request-a-quote-type";
import { isBeniplusUI } from "../../../../cores/config/component/ui-config";
import GroupInsuranceOptIn from "./group-insurance-opt-in/group-insurance-opt-in";
import GroupInsuranceOptInModal, { closeGroupInsuranceOptInModal, showGroupInsuranceOptInModal } from "./group-insurance-opt-in/group-insurance-opt-in-modal";
import { ConfirmDialog, showConfirmModal } from "../../../../cores/helpers/confirm-dialog/confirm-dialog";

const StyledLine = styled.div`
  padding: 5px;
  display: flex;
`;

const StyledPlanSubmitButton = styled(StyledButtonSubmit)`
  ${(props: { disabled: boolean }) =>
  props.disabled &&
  css`
      background-color: ${theme.primaryColor};
      color: #ffffff;
      opacity: 0.8;
    `}
`;

type State = {
  planPreferencesByEmployer: RequestPlanPreferences[];
  planPreferences: PlanPreferencesModel[];
  enrollments: Enrollments[];
  employerEnrollments: EmployerEnrollment[];
  employerPlan: EmployerPlan;
  updatingEmployerEnrollments: EmployerEnrollment[];
  updatingPlanPreferences: RequestPlanPreferences[];
  isValid: boolean;

  isQuoteChecked: boolean;
  medicalTravels: MedicalAndTravel[];
  medicalTravelData: MedicalTravelData[];
  isValidMedical: boolean;
  groupInsuranceDefault: GroupInsuranceDefault[];
  groupInsuranceQuoteRequest: number[];
  groupInsuranceOptInConfirmMessage: string;
};

const AUTO_APPROVE_GROUP_INSURANCE_CONFRIM_MESSAGE = "Activating {0} insurance will start your monthly billing. Would you like to proceed?";

export default class PlanSetting extends React.Component<any, State> {
  state: State = {
    planPreferences: [],
    planPreferencesByEmployer: [],
    enrollments: [],
    employerEnrollments: [],
    employerPlan: {} as EmployerPlan,
    updatingEmployerEnrollments: [],
    updatingPlanPreferences: [],
    isValid: false,
    isQuoteChecked: false,
    medicalTravels: [],
    medicalTravelData: [],
    isValidMedical: false,
    groupInsuranceDefault: [],
    groupInsuranceQuoteRequest: [],
    groupInsuranceOptInConfirmMessage: "",
  };

  async componentDidMount() {
    addLoading();
    await this.loadData();
    removeLoading();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
  ) {
    if (prevState.updatingPlanPreferences !== this.state.updatingPlanPreferences ||
      prevState.updatingEmployerEnrollments !== this.state.updatingEmployerEnrollments ||
      (isBeniplusUI && prevState.groupInsuranceQuoteRequest != this.state.groupInsuranceQuoteRequest)) {
      const isValid: boolean =
        this.validUpdateDate &&
        this.state.updatingEmployerEnrollments.length > 0 &&
        this.state.updatingPlanPreferences.length > 0 &&
        (this.isUpdatedEmployerEnrollments() || this.isUpdatedPlanPreferences() 
        || this.isUpdateGroupInsurance());

      this.setState({ isValid })
    }
  }
  
  validUpdateDate = Number(moment().format("MMDD")) <= 115;

  loadData = async () => {
    let result = await getDataPlan();

    this.setState({
      planPreferences: result.planPreferences,
      planPreferencesByEmployer: result.planPreferencesByEmployer,
      enrollments: result.enrollments,
      employerEnrollments: result.employerEnrollments,
      employerPlan: result.employerPlan,
      updatingEmployerEnrollments: result.employerEnrollments,
      updatingPlanPreferences: result.planPreferencesByEmployer,
    });

    if (isBeniplusUI) {
      const groupInsuranceResult = await getGroupInsuranceData(); 

      const checkedGroupInsurances = this.initCheckedGroupInsurance(groupInsuranceResult.groupInsuranceDefault || [])
      const isValid = this.validateMedicalTravel(
        groupInsuranceResult.medicalAndTravel ? groupInsuranceResult.medicalAndTravel : []
      );

      this.setState({
        medicalTravelData: groupInsuranceResult.medicalAndTravel || [],
        medicalTravels: groupInsuranceResult.medicalAndTravelDefault || [],
        groupInsuranceDefault: groupInsuranceResult.groupInsuranceDefault || [],
        groupInsuranceQuoteRequest: checkedGroupInsurances,
        isQuoteChecked: this.getIsCheckedManualApproveGroupInsurance(groupInsuranceResult.groupInsuranceDefault),
        isValidMedical: isValid,
      });
    }

  }

  initCheckedGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]) => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.checked)
        .map((item: GroupInsuranceDefault) => item.productId)
  }

  getIsCheckedManualApproveGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]): boolean => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.checked && item.approveType.toLowerCase() === "manual")
        .length > 0
  }

  getManualApproveGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]): GroupInsuranceDefault[] => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.approveType.toLowerCase() === "manual");
  }

  getAutoApproveGroupInsurance = (groupInsuranceDefault: GroupInsuranceDefault[]): GroupInsuranceDefault[] => {
    return groupInsuranceDefault
        .filter((item: GroupInsuranceDefault) => item.approveType.toLowerCase() === "auto");
  }

  validateMedicalTravel = (medicalTravelData: MedicalTravelData[]) => {
    let isValid = true;

    medicalTravelData.forEach((medicalTravel) => {
      if (!medicalTravel.noOfEmployees) {
        isValid = false;
      }
    });

    return isValid;
  };

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name.startsWith("groupInsurance")) {
      const groupInsuranceQuoteRequestTemp = [...this.state.groupInsuranceQuoteRequest]
      const groupInsuranceId = Number(name.split("-")[1]);
      const idx = groupInsuranceQuoteRequestTemp.findIndex(i => i === groupInsuranceId);
      if (checked && idx === -1) {
        groupInsuranceQuoteRequestTemp.push(groupInsuranceId)
      } else {
        groupInsuranceQuoteRequestTemp.splice(idx, 1)
      }
      this.setState({groupInsuranceQuoteRequest: groupInsuranceQuoteRequestTemp})
    } else {
      let medicalTravelData: MedicalTravelData[] = Object.assign(
          this.state.medicalTravelData
      );

      if (checked) {
        // set default medical travel
        let medicalTravel = {
          category: name,
          noOfEmployees: 0,
          amount: 0,
        };
        // push
        medicalTravelData.push(medicalTravel);
      } else {
        // find index medical travel
        let index = medicalTravelData.findIndex((medicalTravel) => {
          return medicalTravel.category === name;
        });

        // if have medical travel then remove
        if (index > -1) {
          medicalTravelData.splice(index, 1);
        }
      }
      let isValid = this.validateMedicalTravel(medicalTravelData);
      this.setState({
        medicalTravelData: medicalTravelData,
        isValidMedical: isValid,
      });
    }
  };

  //#region add Medical Travels
  //change switch medical and travel
  onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let medicalTravelData: MedicalTravelData[] = Object.assign(
      this.state.medicalTravelData
    );
    let groupInsuranceQuoteRequestTemp = Object.assign(this.state.groupInsuranceQuoteRequest);

    // if checked= false then remove all medicalTravel
    if (!checked) {
      medicalTravelData = [];

      // remove all manual approve products
      const manualApproveGroupInsuranceIds = 
        this.getManualApproveGroupInsurance(this.state.groupInsuranceDefault).map(i => i.productId);
      groupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
        .filter(item => !manualApproveGroupInsuranceIds.includes(item));

    }
    let isValid = this.validateMedicalTravel(medicalTravelData);
    this.setState({
      isQuoteChecked: checked,
      medicalTravelData: medicalTravelData,
      isValidMedical: isValid,
      groupInsuranceQuoteRequest: groupInsuranceQuoteRequestTemp,
    });
  };

  ///////////////////// EnrollmentCoverage
  handleChangeEmployerEnrollment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;

    const itemIndex = this.state.updatingEmployerEnrollments.findIndex(
      (item) => item.enrollmentId === Number(value)
    );
    const updatingEmployerEnrollmentClone = [
      ...this.state.updatingEmployerEnrollments,
    ];
    if (checked) {
      if (itemIndex === -1) {
        updatingEmployerEnrollmentClone.push({
          enrollmentId: Number(value),
          id: 0,
        });
      }
    } else {
      if (itemIndex !== -1) {
        updatingEmployerEnrollmentClone.splice(itemIndex, 1);
      }
    }
    this.setState({
      updatingEmployerEnrollments: updatingEmployerEnrollmentClone,
    });
  };

  checkedCheckbox = (enrollmentId: number): boolean => {
    return this.state.updatingEmployerEnrollments.some(
      (employerEnrollment) => employerEnrollment.enrollmentId === enrollmentId
    );
  };
  ////////////////////////// EmployerPlanPreferences
  handleChangePlanPreference = (
    event: any,
    planPreferences: PlanPreferences[]
  ) => {
    const currentIdChecked = this.findIdCheckedPlanPreferences(planPreferences);

    const updatingPlanPreferenceClone = [...this.state.updatingPlanPreferences];

    const index = updatingPlanPreferenceClone.findIndex(
      (planPreferences) => planPreferences.planPreferenceId === currentIdChecked
    );

    if (index !== -1) updatingPlanPreferenceClone.splice(index, 1);

    updatingPlanPreferenceClone.push({
      planPreferenceId: Number(event.target.value),
      otherValue: "",
      allow: true,
    } as RequestPlanPreferences);

    this.setState({ updatingPlanPreferences: updatingPlanPreferenceClone });
  };

  handleChangeInput = (event: any, id: number) => {
    let { value } = event.target;

    const updatingPlanPreferencesClone: RequestPlanPreferences[] = [
      ...this.state.updatingPlanPreferences,
    ];

    let index = updatingPlanPreferencesClone.findIndex((planPreferences) => {
      return planPreferences.planPreferenceId === id;
    });

    if (isNaN(value) || value < 0 || value % 1 !== 0) value = 0;

    updatingPlanPreferencesClone[index].otherValue = value;

    this.setState({ updatingPlanPreferences: updatingPlanPreferencesClone });
  };

  findIdCheckedPlanPreferences = (planPreferences: PlanPreferences[]) => {
    if (this.state.planPreferencesByEmployer.length) {
      let planPreference = planPreferences.find((planPreference) =>
        this.state.updatingPlanPreferences.find((plan) => {
          return plan.planPreferenceId === planPreference.id;
        })
      );

      if (planPreference) {
        return planPreference.id;
      } else {
        return -1;
      }
    }
    return -1;
  };

  handleSubmitPlanSetting = async () => {
    addLoading();

    if (this.isUpdatedEmployerEnrollments()) {
      await this.handleSubmitEmployerEnrollments();
    }
    if (this.isUpdatedPlanPreferences()) {
      await this.handleSubmitPlanPreferences();
    }
    if (this.isUpdateGroupInsurance()) {
      await this.updateRequestGroupInsuranceQuote();
      // await this.setMedicalAndTravelCoverage();
    }

    await this.loadData();

    removeLoading();
  };

  updateRequestGroupInsuranceQuote = async () => {
    await putRequestGroupInsuranceQuote({
      productIds: this.state.groupInsuranceQuoteRequest, 
      isUnsubscribeAll: this.state.groupInsuranceQuoteRequest.length <= 0
    });
  }
  

  // what is medical and travel?
  setMedicalAndTravelCoverage = async () => {
    let medicalAndTravel: MedicalTravelData[] = Object.assign(this.state.medicalTravelData);
    if (medicalAndTravel.length > 0) {
      await putMedicalAndTravelCoverage(medicalAndTravel);
    }
  };

  handleSubmitEmployerEnrollments = async () => {
    const enrollmentIds = this.state.updatingEmployerEnrollments.map(
      (item) => item.enrollmentId
    );
    await putEmployerEnrollments(enrollmentIds);
  };

  handleSubmitPlanPreferences = async () => {
    await putEmployersPlanPreferences(this.state.updatingPlanPreferences);
  };

  isUpdatedEmployerEnrollments = () => {
    const currentEmployerEnrollmentIds: number[] =
      this.state.employerEnrollments.map((item) => item.enrollmentId).sort();
    const updatedEmployerEnrollmentIds: number[] =
      this.state.updatingEmployerEnrollments
        .map((item) => item.enrollmentId)
        .sort();

    return !(
      currentEmployerEnrollmentIds.length ===
        updatedEmployerEnrollmentIds.length &&
      currentEmployerEnrollmentIds.every(
        (item, index) => item === updatedEmployerEnrollmentIds[index]
      )
    );
  };

  isUpdatedPlanPreferences = () => {
    const currentPlanPreferenceIds: number[] =
      this.state.planPreferencesByEmployer
        .map((item) => item.planPreferenceId)
        .sort();
    const updatedPlanPreferenceIds: number[] =
      this.state.updatingPlanPreferences
        .map((item) => item.planPreferenceId)
        .sort();

    return !(
      currentPlanPreferenceIds.length === updatedPlanPreferenceIds.length &&
      currentPlanPreferenceIds.every(
        (item, index) => item === updatedPlanPreferenceIds[index]
      )
    );
  };

  isUpdateGroupInsurance = () => {

    if (!isBeniplusUI) return false;

    const existedGroupInsuranceQuote = this.initCheckedGroupInsurance(this.state.groupInsuranceDefault);

    if (this.state.groupInsuranceQuoteRequest 
      && this.state.groupInsuranceQuoteRequest.length <= 0
      && existedGroupInsuranceQuote 
      && existedGroupInsuranceQuote.length > 0) {
        return true;
    }
    const oldGroupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
    .filter(item => existedGroupInsuranceQuote.includes(item));

    const newGroupInsuranceQuoteRequestTemp: number[] = this.state.groupInsuranceQuoteRequest
    .filter(item => !existedGroupInsuranceQuote.includes(item));

    return (newGroupInsuranceQuoteRequestTemp && newGroupInsuranceQuoteRequestTemp.length > 0
          || oldGroupInsuranceQuoteRequestTemp && oldGroupInsuranceQuoteRequestTemp.length != existedGroupInsuranceQuote.length);

  }

  onGroupInsuranceOptInCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name.startsWith("groupInsurance")) {
      const groupInsuranceQuoteRequestTemp = [...this.state.groupInsuranceQuoteRequest]
      const groupInsuranceId = Number(name.split("-")[1]);
      const idx = groupInsuranceQuoteRequestTemp.findIndex(i => i === groupInsuranceId);
      if (checked && idx === -1) {
        groupInsuranceQuoteRequestTemp.push(groupInsuranceId)
      } else {
        groupInsuranceQuoteRequestTemp.splice(idx, 1)
      }
      this.setState({
        groupInsuranceQuoteRequest: groupInsuranceQuoteRequestTemp,
      })
    }
  };

  onGroupInsuranceOptInSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let medicalTravelData: MedicalTravelData[] = Object.assign(
      this.state.medicalTravelData
    );
    let groupInsuranceQuoteRequestTemp = Object.assign(this.state.groupInsuranceQuoteRequest);

    // if checked= false then remove all medicalTravel
    if (!checked) {
      medicalTravelData = [];

      // remove all manual approve products
      const manualApproveGroupInsuranceIds = 
        this.getManualApproveGroupInsurance(this.state.groupInsuranceDefault).map(i => i.productId);
      groupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
        .filter(item => !manualApproveGroupInsuranceIds.includes(item));

    }
    let isValid = this.validateMedicalTravel(medicalTravelData);
    this.setState({
      isQuoteChecked: checked,
      medicalTravelData: medicalTravelData,
      isValidMedical: isValid,
      groupInsuranceQuoteRequest: groupInsuranceQuoteRequestTemp,
    });
  };

  submitGroupInsuranceOptIn = () => {

    if (!isBeniplusUI) return;
    if (!this.isUpdateGroupInsurance()) closeGroupInsuranceOptInModal();

    const existedGroupInsuranceQuote = this.initCheckedGroupInsurance(this.state.groupInsuranceDefault);

    const groupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
    .filter(item => !existedGroupInsuranceQuote.includes(item));

    const autoGroupInsurances = this.getAutoApproveGroupInsurance(this.state.groupInsuranceDefault);
    
    if (autoGroupInsurances && autoGroupInsurances.length > 0) {
      const selectedAutoGroupInsurances = autoGroupInsurances
        .filter(item => groupInsuranceQuoteRequestTemp.includes(item.productId));
      if (selectedAutoGroupInsurances && selectedAutoGroupInsurances.length > 0) {
        const productNames = selectedAutoGroupInsurances.map(item => item.productName).join(", ");
        const confirmMessage = AUTO_APPROVE_GROUP_INSURANCE_CONFRIM_MESSAGE.replace("{0}", productNames);
        this.setState({groupInsuranceOptInConfirmMessage: confirmMessage});
        showConfirmModal();
        return;
      }
    }
    this.updateGroupInsuranceOptIn();
  }

  openGroupInsuranceOptInModal = () => {
    showGroupInsuranceOptInModal();
  }

  handleCloseOptInWithoutSubmit = () => {
    this.resetGroupInsuranceOptInData();
    closeGroupInsuranceOptInModal();
  }

  resetGroupInsuranceOptInData = () => {
    const checkedGroupInsurances = this.initCheckedGroupInsurance(this.state.groupInsuranceDefault || [])
    this.setState({
      groupInsuranceQuoteRequest: checkedGroupInsurances,
      isQuoteChecked: this.getIsCheckedManualApproveGroupInsurance(this.state.groupInsuranceDefault),
    });
  }

  updateGroupInsuranceOptIn = async () => {

    addLoading();

    const existedGroupInsuranceQuote = this.initCheckedGroupInsurance(this.state.groupInsuranceDefault);
    const groupInsuranceQuoteRequestTemp = this.state.groupInsuranceQuoteRequest
        .filter(item => !existedGroupInsuranceQuote.includes(item));
    if (groupInsuranceQuoteRequestTemp.length > 0) {
      await postRequestGroupInsuranceQuote({productIds: groupInsuranceQuoteRequestTemp, isOptIn: true});
      await this.loadData();
    } else {
      this.resetGroupInsuranceOptInData();
    }

    removeLoading();

    closeGroupInsuranceOptInModal();

  }

  render() {
    return (
      <StyledMainContent>
        <StyledBigTitle>Plan</StyledBigTitle>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-5 mt-4">
              <StyledContentBox isBorder={false} style={{ height: "auto" }}>
                <StyledLargerTitle>Plan information</StyledLargerTitle>
                <div className="mt-2">
                  <StyledLine className="justify-content-between">
                    <StyledCell className="p-0" width="50%">
                      Plan type
                    </StyledCell>
                    <StyledCell className="p-0" width="50%">
                      {this.state.employerPlan
                        ? this.state.employerPlan.planName
                        : "-"}
                    </StyledCell>
                  </StyledLine>
                  <StyledLine>
                    <StyledCell className="p-0" width="50%">
                      Plan start date
                    </StyledCell>
                    <StyledCell className="p-0" width="50%">
                      {moment(
                        this.state.employerPlan
                          ? this.state.employerPlan.planStartDate
                          : undefined
                      ).format(FE_DETAIL_DATE_FORMAT)}
                    </StyledCell>
                  </StyledLine>
                </div>
              </StyledContentBox>
              <EnrollmentCoverage
                enrollments={this.state.enrollments}
                employerEnrollments={this.state.employerEnrollments}
                handleChangeEmployerEnrollment={
                  this.handleChangeEmployerEnrollment
                }
                checkedCheckbox={this.checkedCheckbox}
                isSetup={false}
              />
              {isBeniplusUI && !this.validUpdateDate &&
                <GroupInsuranceOptIn 
                  onOptInClick={this.openGroupInsuranceOptInModal}
                />
              }
              {isBeniplusUI &&
                <GroupInsuranceCoverage
                  employerId={getInfoByToken().employerId}
                  isQuoteChecked={this.state.isQuoteChecked}
                  medicalTravels={this.state.medicalTravels}
                  medicalTravelData={this.state.medicalTravelData}
                  groupInsuranceDefault={this.state.groupInsuranceDefault}
                  groupInsuranceQuoteRequest={this.state.groupInsuranceQuoteRequest}
                  onSwitchChange={this.onSwitchChange}
                  onCheckboxChange={this.onCheckboxChange}
                  isAllowRenewal={this.validUpdateDate}
                />
              }
            </div>
            <div className="col-lg-7 mt-4">
              <EmployerPlanPreferences
                planPreferences={this.state.planPreferences}
                planPreferencesByEmployer={this.state.planPreferencesByEmployer}
                findIdCheckedPlanPreferences={this.findIdCheckedPlanPreferences}
                handleChangePlanPreference={this.handleChangePlanPreference}
                handleChangeInput={this.handleChangeInput}
                isSetup={false}
              />
            </div>
            {this.validUpdateDate &&
              <>
                <div className="col-lg-7"></div>
                <div className="col-lg-3">
                  <StyledPlanSubmitButton
                    submit={this.state.isValid}
                    disabled={!this.state.isValid}
                    onClick={this.handleSubmitPlanSetting}
                    type="submit"
                  >
                    Save & Continue
                  </StyledPlanSubmitButton>
                </div>
              </>
            }
            {isBeniplusUI && !this.validUpdateDate && 
              <div>
                <GroupInsuranceOptInModal 
                  employerId={getInfoByToken().employerId}
                  isQuoteChecked={this.state.isQuoteChecked}
                  medicalTravels={this.state.medicalTravels}
                  medicalTravelData={this.state.medicalTravelData}
                  groupInsuranceDefault={this.state.groupInsuranceDefault}
                  groupInsuranceQuoteRequest={this.state.groupInsuranceQuoteRequest}
                  onSwitchChange={this.onGroupInsuranceOptInSwitchChange}
                  onCheckboxChange={this.onGroupInsuranceOptInCheckboxChange}
                  isAllowRenewal={this.validUpdateDate}
                  isDisableSubmit={false}
                  handleSubmit={this.submitGroupInsuranceOptIn}
                  handleCloseWithoutSubmit={this.handleCloseOptInWithoutSubmit}
                />
                <ConfirmDialog
                    content={this.state.groupInsuranceOptInConfirmMessage}
                    title={"Confirmation"}
                    handleOnOk={this.updateGroupInsuranceOptIn}
                />
              </div>  
            }
          </div>
        </div>
      </StyledMainContent>
    );
  }
}
