import React, {useEffect} from 'react';
import {Option} from "../../../../cores/helpers/select/select";
import LocationOnIcon from '@material-ui/icons/PinDropOutlined';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import {Location} from "../marketplace";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {countrySearchAPI} from "../../../../cores/config/config";
import {ValidatorForm} from "react-material-ui-form-validator";
import {message} from "../../../../cores/helpers/message/message";
import {BNPMultiSelect} from "../../../../cores/helpers/input/BNPMultiSelect";

const StyledBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fafafa;
  padding: 1px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid ${props => props.theme.grey[200]};
  
  @media (min-width: 1200px) {
    flex-direction: row;
    width: 80%;

    .item {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        flex-basis: calc(29% - 1px);
        width: calc(29% - 1px);
      }
      &:nth-child(4) {
        flex-basis: calc(13% - 1px);
        width: calc(13% - 1px);
      }

      & + .item {
        border-left: 1px solid ${props => props.theme.grey[200]}; /* Vertical divider */
      }

      &:first-child {
        border-left: none; /* Remove border for the first item */
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    max-width: 450px;
    width: 90%;

    .item {
      flex-basis: 100%;
      width: 100%;
      border-top: 1px solid ${props => props.theme.grey[200]}; /* Horizontal divider */
    }

    .item:first-child {
      border-top: none; /* Remove border for the first item */
    }
  }
`;

const StyledItem = styled.div`
    padding: 1px;
    text-align: center;
`;

const StyledTextField = styled(TextField)`
    width: 100%;
    background-color: #ffffff;

    .MuiInputBase-input {
        padding: 6px 0;
        padding-left: 8px;
        height: 32px;
        font-size: 14px;
        line-height: 21px;
    }

    input {
        background-color: #ffffff !important;
    }

    .MuiInputAdornment-positionStart {
        //margin-right: 4px;

        .MuiIconButton-root {
            padding: 0;
        }
    }
`;

const StyledTextFieldLocation = styled(StyledTextField)`
    width: 100%;
    .MuiInputBase-input {
        padding: 6px 0;
        padding-left: -8px;
        height: 32px;
        font-size: 14px;
        line-height: 21px;
    }
`;

const StyledLocationBox = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex-basis: 35%;
    width: 100%;
    background-color: #ffffff;
`;

const StyledButtonApprove = styled.button`
    height: 44px;
    background-color: ${(props) => props.theme.primaryColor};
    color: #ffffff;
    border: 1px solid #ecedf0;
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    :hover {
        opacity: 0.8;
    }
    width: 100px;
    
    @media (min-width: 1200px) {
        width: 100%;
    }
`;

const StyledMultiSelect = styled(BNPMultiSelect)`
    background-color: white;
    text-align: left;
    .MuiSelect-selectMenu {
        border: none !important;
    }
`;

type Props = {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
  services: Option[];
  serviceIds: number[];
  location: Location;
  setServiceIds: (serviceIds: number[]) => void;
  setLocation: (location: Location) => void;
  handleSearch: () => void;
}

function MarketplaceSearchComponent(props: Props) {
  useEffect(() => {
    if (!props.location.lat && !props.location.lng) {
      handleUseMyLocation();
    }
  }, []);

  const handleUseMyLocation = () => {
    if (navigator.geolocation) {
      addLoading();
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          // addLoading();
          // getLocationFromLatLong(latitude, longitude)
          //   .then((response: any) => {
          //     if (response.data.results && response.data.results.length > 0) {
          //       props.setLocation({ address: response.data.results[0].formatted_address, lat: latitude, lng: longitude });
          //     }
          //     removeLoading();
          //   })
          //   .catch((error: any) => {
          //     console.error('Error fetching location', error);
          //     removeLoading();
          //   });
          props.setLocation({ address: "Your Location", lat: latitude, lng: longitude });
          removeLoading();
        },
        error => {
          message('Please enable location service to use "Your Location" service.', 'warning')
          removeLoading();
        }
      );
    } else {
      message('Location service is not available on this browser', 'warning')
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleManualLocationChange = (result: any) => {
    geocodeByPlaceId(result.place_id).then((results: any) => {
      const result = results[0];
      const latLng = result.geometry.location;
      props.setLocation(
        { address: result.formatted_address, lat: latLng.lat(), lng: latLng.lng() });
    });
  };

  const handleServiceChange = (event: any) => {
    if (event.target.value.includes('clear')) {
      props.setServiceIds([]);
      return;
    }
    props.setServiceIds(event.target.value);
  }

  const handleChangeSearchKey = (event: any) => {
    props.setSearchKey(event.target.value);
  }

  return (
    <ValidatorForm onSubmit={props.handleSearch}>
    <StyledBox>
      <StyledItem className={"item"}>
        <StyledTextField
          name={"searchKey"}
          value={props.searchKey}
          onChange={handleChangeSearchKey}
          placeholder={"Search name"}
        />
      </StyledItem>
      <StyledItem className={"item"}>
        <StyledMultiSelect
          value={props.serviceIds}
          name={"service"}
          onChange={handleServiceChange}
          options={props.services}
          placeholder={"Service"}
          allowAll={false}
          clearAll={true}
        />
      </StyledItem>
      <StyledItem className={"item"}>
      <StyledLocationBox>
        <InputAdornment position="start">
          <IconButton onClick={handleUseMyLocation}>
            <LocationOnIcon />
          </IconButton>
        </InputAdornment>
        <GooglePlacesAutocomplete
          onSelect={handleManualLocationChange}
          autocompletionRequest={{
            componentRestrictions: {
              country: countrySearchAPI
            }
          }}
          loader={null}
          async={true}
          placeholder={"Enter address or use your location"}
          renderInput={(propsInput: any) => (
            <div className="custom-wrapper">
              <StyledTextFieldLocation
                {...propsInput}
                onChange={event => {
                  propsInput.onChange(event);
                  props.setLocation({...props.location, address: event.target.value});
                }}
                value={props.location.address || ""}
              />
            </div>
          )}
          debounce={1000}
        />
      </StyledLocationBox>
      </StyledItem>
      <StyledItem className={"item"}>
        <StyledButtonApprove type={'submit'} onClick={props.handleSearch}>
          Search
        </StyledButtonApprove>
      </StyledItem>
    </StyledBox>
    </ValidatorForm>
  );
}

export default MarketplaceSearchComponent;
