import React from "react";
import styled from "styled-components";
import {
  StyledBigTitle,
  StyledButtonSubmit,
  StyledBtnLink,
} from "../../../../shared/employer-styled";
import {
  postFinishSetup,
  getAgreement,
  FinishSetup,
} from "../../../../../services/employer-dashboard-services";
import {
  publishMessage,
  MessageData,
} from "../../../../../cores/utils/message";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import theme from "../../../../../cores/helpers/theme";
import SetUpWizardView from "../setup";
import { Agreement } from "./agreement-type";
import {
  getInfoByToken,
  getIsSetupDone,
} from "../../../../../cores/utils/helpers";
import { RouteChildrenProps } from "react-router";
import {
  defaultLandingPageURL,
  setupBankingURL,
  setupBenefitURL
} from "../../../../../cores/constants/url-config";
import {EMPLOYER_SETUP_USER_ROLE_LOCAL_STORAGE, employerRoles} from "../company-information/company-information";
import {terminatedEmployeeOfAdmin} from "../../../../../services/employer-setting-service";
import { EmployerConfiguration } from "../../../../../models/employer-configuration/employer-configuration-model"

type State = {
  agreementData: Agreement[];
  isValid: boolean;
};
type Props = RouteChildrenProps & EmployerConfiguration;

const StyledDivHiddenForMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledDivForm = styled.div``;

export default class EmployerAgreementSetup extends React.Component<
  Props,
  State
> {
  state: State = {
    agreementData: [],
    isValid: false,
  };

  async componentDidMount() {
    let result = await getAgreement();

    let agreement = result.data || [];

    let data = agreement.map((agreement: any) => {
      return {
        id: agreement.id,
        title: agreement.title,
        description: agreement.description,
        checked: false,
      };
    }) as Agreement[];

    this.setState({ agreementData: data });

    console.log(data);
  }

  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let agreementData = Object.assign(this.state.agreementData);

    let index = agreementData.findIndex((agreement: any) => {
      return agreement.id === Number(name);
    });
    agreementData[index].checked = checked;
    this.setState({ agreementData: agreementData });
    this.validate();
  };

  handleSubmit = () => {
    $("#finishSetup").attr("disabled", "true");

    let agreementIds = this.state.agreementData.map((agreement) => {
      return agreement.id;
    });

    let decoded = getInfoByToken();

    let finishSetup: FinishSetup = {
      employerId: decoded.employerId,
      listAgreement: agreementIds,
    };

    const employerSetupUserRole = localStorage.getItem(EMPLOYER_SETUP_USER_ROLE_LOCAL_STORAGE)
    if (employerSetupUserRole && employerSetupUserRole === employerRoles.adminNotEmployee.name) {
      terminatedEmployeeOfAdmin();
    }

    postFinishSetup(finishSetup)
      .then(() => getIsSetupDone())
      .then((isSetupDone) => {
        publishMessage({
          variant: "success",
          message: "Thank you, your account has been set up!",
        });
        if (isSetupDone) {
          window.location.href = defaultLandingPageURL + "?default";
        }
      })
      .catch((error) => {
        let data = {} as MessageData;
        if (error.response && error.response.data) {
          if (error.response.status === 500) {
            publishMessage({
              message: error.response.data.message,
              variant: "error",
            });
            return;
          }

          data.message = "You have to set up the following information:";
          if (!error.response.data.hasBankingInfo) {
            data.message += " banking info";
            if (
              !error.response.data.hasCompanyInfo ||
              !error.response.data.hasClasses
            ) {
              data.message += ",";
            }
          }
          if (!error.response.data.hasCompanyInfo) {
            data.message += " company info";
            if (!error.response.data.hasClasses) {
              data.message += ",";
            }
          }
          if (!error.response.data.hasClasses) {
            data.message += " class benefits";
          }
          data.message += ".";
        } else {
          data.message = error + "";
        }
        data.variant = "error";
        publishMessage(data);
      });
  };

  validate = () => {
    let isValid = true;

    this.state.agreementData.forEach((agreement) => {
      if (agreement.checked === false) {
        isValid = false;
      }
    });

    this.setState({ isValid: isValid });
  };

  render() {
    return (
      <SetUpWizardView
        enablePresetBankingInformation={this.props.enablePresetBankingInformation}
        children={
          <StyledDivForm>
            <StyledBigTitle>Service Agreement</StyledBigTitle>
            <hr />
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 mt-3">
                  <div>
                    Before you can activate your company benefit plan and your
                    employee can start claiming benefits, you have to agree to
                    our service agreement.
                  </div>
                  <div className="mt-4">
                    {this.state.agreementData.map((agreement, index) => (
                      <CheckBox
                        key={index}
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: agreement.title,
                            }}
                          />
                        }
                        checked={agreement.checked ? agreement.checked : false}
                        name={agreement.id.toString()}
                        onChange={this.handleChangeCheckbox}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <StyledDivHiddenForMobile className="col-lg-2">
                  <StyledBtnLink
                    to={this.props.enablePresetBankingInformation ? setupBenefitURL : setupBankingURL}
                    background={theme.secondaryColor}
                  >
                    Back
                  </StyledBtnLink>
                </StyledDivHiddenForMobile>

                <div className="col-lg-7"></div>
                <div className="col-lg-3">
                  <StyledButtonSubmit
                    submit={this.state.isValid}
                    disabled={!this.state.isValid}
                    onClick={this.handleSubmit}
                    id="finishSetup"
                  >
                    Finish Setup your account
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>
          </StyledDivForm>
        }
      />
    );
  }
}
