import {
  getPlan,
  getPlanPreferences,
  getPlanPreferencesByEmployerId,
} from "../../../../services/employer-setting-service";
import {
  getEnrollments,
  getEmployerEnrollments,
  getMedicalAndTravelCoverage,
  getMedicalAndTravelCoverageDefault,
  getGroupInsuranceDefault,
} from "../../../../services/employer-benefits-service";
import {
  EmployerPlan,
  PlanPreferencesModel,
  RequestPlanPreferences,
} from "./plan-preferences/plan-preferences-type";
import {
  Enrollments,
  EmployerEnrollment,
} from "../employer-classes/employer-classes-type";
import { MedicalTravelData } from "../../request-a-quote/request-a-quote-type";
import { MedicalAndTravel } from "../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";

export async function getDataPlan() {
  const [resultAllPlanReferences, 
    resultAllPlanPreferencesByEmployer, 
    resultEnrollments, 
    resultEmployerEnrollments, 
    resultEmployerPlan] = await Promise.all([
    getPlanPreferences(),
    getPlanPreferencesByEmployerId(),
    getEnrollments(),
    getEmployerEnrollments(),
    getPlan()
  ])

  let planPreferences: PlanPreferencesModel[] =
    resultAllPlanReferences.data || [];
  let planPreferencesByEmployer: RequestPlanPreferences[] =
    resultAllPlanPreferencesByEmployer.data || [];

  let enrollments: Enrollments[] = resultEnrollments.data || [];
  let employerEnrollments: EmployerEnrollment[] =
    resultEmployerEnrollments.data || [];
  const employerPlan: EmployerPlan = resultEmployerPlan.data || {} as EmployerPlan;

  return {
    planPreferences,
    planPreferencesByEmployer: planPreferencesByEmployer || [],
    enrollments,
    employerEnrollments,
    employerPlan,
  };
}

export async function getGroupInsuranceData() {
  const [
    resultMedicalAndTravel,
    resultMedicalAndTravelDefault,
    resultGroupInsuranceDefault] = await Promise.all([
    getMedicalAndTravelCoverage(),
    getMedicalAndTravelCoverageDefault(),
    getGroupInsuranceDefault()
  ])

  return {
    medicalAndTravel: resultMedicalAndTravel.data || [] as MedicalTravelData[],
    medicalAndTravelDefault: resultMedicalAndTravelDefault.data || [] as MedicalAndTravel[],
    groupInsuranceDefault: resultGroupInsuranceDefault.data || [],
  };
}
