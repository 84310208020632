import {SearchParamType} from "../../pages/report/ReportType";
import React, {useEffect, useState} from "react";
import {ReportType} from "../../pages/report/ReportModel";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import {StyledBNPInput, StyledIconSearch} from "../../pages/claim-management/claim-management-view";
import styled from "styled-components";
import {BNPSelect} from "../../../cores/helpers/select/select";

const StyledBNPSelect = styled(BNPSelect)`
  .MuiInputBase-input {
    padding: 4px 0;
  }
`;

type TableFilterAndSearchProps = {
  tableType: string;
  searchParam: SearchParamType;
  filterTypes?: any[];
  handleChangeFilter?: (e:any) => void;
  handleSearch?: (searchKey: string) => void;
  handleChangeDate?: (date: Date | null, name: string | null) => void;
  searchPlaceholder?: string;
}

export function TableFilterAndSearch(props: TableFilterAndSearchProps ) {
  const [inputSearch, setInputSearch] = useState<string>("");

  useEffect(() => {
    setInputSearch("");
  }, [props.tableType])

  function handleChangeSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setInputSearch(value);
  }

  function onKeyUp(event: any) {
    if (event.keyCode === 13 && props.handleSearch) {
      props.handleSearch(inputSearch);
    }
  }

  return (
    <div className="row mt-4 mb-4">
      {props.filterTypes && props.handleChangeFilter && <div className="col-lg-3">
          <StyledBNPSelect
              options={props.filterTypes}
              value={props.searchParam.filter || -1}
              name="filterType"
              placeholder={
                props.tableType === ReportType.GROUP_INSURANCE.type 
                ? "Type of report" 
                : (props.tableType !== ReportType.TRANSACTION.type ? "Claim Type" : "Transaction Type")}
              onChange={props.handleChangeFilter}
              tabIndex={-1}
          />
      </div>}
      {props.handleChangeDate ? <>
          <div className="col-md-3">
            <BNPDatePicker onChange={props.handleChangeDate}
                           name="from"
                           placeholder="From Date"
                           value={props.searchParam.from} />
          </div>
          <div className="col-md-3">
            <BNPDatePicker
              onChange={props.handleChangeDate}
              name="to"
              placeholder="To Date"
              value={props.searchParam.to}
              minDate={props.searchParam.from} />
          </div>
        </> :
        <div className="col-lg-6" />}
      {props.handleSearch && <div className="col-lg-3">
          <StyledBNPInput
              placeholder={props.searchPlaceholder ? props.searchPlaceholder : "Search name or email"}
              onChange={handleChangeSearchInput}
              value={inputSearch || ""}
              onKeyUp={onKeyUp}
              tabIndex={-1}
          />
          <StyledIconSearch onClick={() => props.handleSearch && props.handleSearch(inputSearch)}/>
      </div>}
    </div>)
}