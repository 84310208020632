import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";

export type PremiumInsuranceType = {
  id: number,
  productId: number,
  providerId: number,
  providerName: string,
  productName: string,
  noOfEmployee: number,
  monthlyAmount: number,
  status: PremiumPaymentStatus
}

export type PremiumInsuranceTableType = {

  id: number,
  productName: string,
  status: any,
}

export enum PremiumPaymentStatus {
  REQUESTED = "REQUESTED",
  QUOTED = "QUOTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED"
}

export const PremiumInsuranceTableAdminColumns: TableSortType[] =[
  {
    columnId: "productName",
    columnName: "Product",
    sortType: null,
    width: 70,
    sortColumn: false
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
]