import React from "react";
import {Option} from "../../../cores/helpers/select/select";
import styled from "styled-components";

const StyledStar = styled.span`
  font-size: 1rem;
  color: #FFD700;
`;

export const THUMBNAIL_SIZE = 300;

export const workingHoursOptions: Option[] = [
  {id: 'ALL', name: 'All'},
  {id: 'WEEKDAY', name: 'Weekday'},
  {id: 'WEEKEND', name: 'Weekend'},
]

export const ratingOptions: Option[] = [
  {id: 4, name: <span><StyledStar>{'★'.repeat(4) + '☆'.repeat(1)}</StyledStar> & Up</span>},
  {id: 3, name: <span><StyledStar>{'★'.repeat(3) + '☆'.repeat(2)}</StyledStar> & Up</span>},
  {id: 2, name: <span><StyledStar>{'★'.repeat(2) + '☆'.repeat(3)}</StyledStar> & Up</span>},
  {id: 1, name: <span><StyledStar>{'★'.repeat(1) + '☆'.repeat(4)}</StyledStar> & Up</span>},
]

export const distanceOptions: (Option & {
  distanceFrom: number,
  distanceTo: number | null
})[] = [
  {id: 'ALL', name: 'All Distance', distanceFrom: 0, distanceTo: null},
  {id: '0-10', name: '0-10 km', distanceFrom: 0, distanceTo: 10000},
  {id: '10-25', name: '10-25 km', distanceFrom: 10000, distanceTo: 25000},
  {id: '25-50', name: '25-50 km', distanceFrom: 25000, distanceTo: 50000},
  {id: '50-100', name: '50-100 km', distanceFrom: 50000, distanceTo: 100000},
  {id: '100+', name: '>100 km', distanceFrom: 100000, distanceTo: null},
]