import React from "react";
import IconDelete from "../../../assets/images/RAQ-Step2-fill/bin.png";
import { EmployerBenefitClass } from "./employer-classes-type";
import styled from "styled-components";
import {
  StyledTable,
  StyledHeaderTable,
  StyledRowBodyTable,
  StyledImgIconClose,
} from "../../../shared/table-styled";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {
  ConfirmDialog,
  showConfirmModal,
  closeConfirmModal,
} from "../../../../cores/helpers/confirm-dialog/confirm-dialog";
type Props = {
  defineClassBenefits: EmployerBenefitClass[];
  delete: (idClass: number) => void;
  findClassById: (idClass: number) => { label: string; value: number };
};

const StyledColumn20Table = styled.div`
  width: 20%;
  padding: 1rem 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledSpanIconClose = styled.span`
  position: relative;
  top: 12px;
  left: 1%;
  cursor: pointer;
  width: 0;
`;

const StyledWidthTable = styled.div`
  width: 95%;
`;
export default function EmployerBenefitsTable(props: Props) {
  const [id, setId] = React.useState<number>(0);

  const showModalDelete = (classId: number) => {
    setId(classId);
    showConfirmModal();
  };

  const handleDelete = () => {
    props.delete(id);
    closeConfirmModal();
  };

  return (
    <div>
      <StyledWidthTable>
        <StyledTable>
          <StyledHeaderTable>
            <StyledColumn20Table>Class</StyledColumn20Table>
            <StyledColumn20Table>No of Employee</StyledColumn20Table>
            <StyledColumn20Table>Coverage Amount</StyledColumn20Table>
            <StyledColumn20Table>Plan</StyledColumn20Table>
            <StyledColumn20Table>Total</StyledColumn20Table>
          </StyledHeaderTable>
          <div>
            {props.defineClassBenefits.map(
              (defineClassBenefit: EmployerBenefitClass) => (
                <StyledRowBodyTable key={defineClassBenefit.id}>
                  <StyledColumn20Table>
                    {defineClassBenefit.classId === 0
                      ? defineClassBenefit.className
                      : props.findClassById(defineClassBenefit.classId).label}
                  </StyledColumn20Table>
                  <StyledColumn20Table>
                    {`${defineClassBenefit.noOfEmployees} person${
                      defineClassBenefit.noOfEmployees > 1 ? "s" : ""
                    }`}
                  </StyledColumn20Table>
                  <StyledColumn20Table>
                    {"$" + centToUsd(defineClassBenefit.coverageAmount)}
                  </StyledColumn20Table>
                  <StyledColumn20Table>Standard Plan</StyledColumn20Table>
                  <StyledColumn20Table className="font-weight-bold">
                    {"$" +
                      centToUsd(defineClassBenefit.coverageAmount) *
                        defineClassBenefit.noOfEmployees}
                  </StyledColumn20Table>
                  <StyledSpanIconClose
                    onClick={() => showModalDelete(defineClassBenefit.id)}
                  >
                    <StyledImgIconClose
                      title="Delete"
                      alt="icon-delete"
                      src={IconDelete}
                    />
                  </StyledSpanIconClose>
                </StyledRowBodyTable>
              )
            )}
          </div>
        </StyledTable>
      </StyledWidthTable>
      <ConfirmDialog
        title="Delete"
        content="Are you sure you want to delete this class?"
        handleOnOk={handleDelete}
      />
    </div>
  );
}
