import React, { Component } from "react";

import { ValidatorForm } from "react-material-ui-form-validator";
import { login } from "../../../services/auth-service";
import { LoginReqModel } from "./../../../models/login/login-req-model";
import {
  StyledBigTitle,
  StyledPError,
  LandingStyledTextValidator,
  StyledButtonSubmit,
} from "../../shared/employer-styled";
import { publishMessage } from "../../../cores/utils/message";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getAllBalances } from "../../../services/wallet-service";
import {
  getInfoByToken,
  setToken,
  getToken,
  getIsSetupDone,
} from "../../../cores/utils/helpers";
import { removeLoading } from "../../../cores/utils/loading";
import {
  forgotPasswordURL,
  enrollmentCoverageURL,
  setupCompanyURL,
} from "../../../cores/constants/url-config";
import { ShowPasswordView } from "../../../cores/helpers/input/input";
import { localStorageRedirectKey } from "../../../cores/config/config";

type State = {
  userName: string;
  password: string;
  isIncorrectAccount: boolean;
  isValid: boolean;
  message: string;
};

const StyledTitle = styled.div`
  margin-top: 3rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const StyledDivForgotPassword = styled(Link)`
  color: ${(theme) => theme.theme.primaryColor};
  font-size: 14px;
  line-height: 22px;
  float: right;
`;

export default class SignIn extends Component<any, State> {
  state: State = {
    userName: "",
    password: "",
    isIncorrectAccount: false,
    isValid: false,
    message: "",
  };

  UNSAFE_componentWillMount() {
    if (getToken()) {
      getIsSetupDone().then((isSetupDone: boolean) => {
        this.props.history.push(isSetupDone ? enrollmentCoverageURL : setupCompanyURL);
      })
    }
  }

  async checkFinishSetup() {
    const isSetupDone = await getIsSetupDone();
    const redirect = !localStorage.getItem(localStorageRedirectKey) ? enrollmentCoverageURL : localStorage.getItem(localStorageRedirectKey)
    localStorage.removeItem(localStorageRedirectKey);
    this.props.history.push(isSetupDone ? redirect : setupCompanyURL);
  }

  handleChangeInput = (event: any) => {
    let state = Object.assign(this.state);
    state[event.target.name] = event.target.value;
    this.setState(this.state);
  };

  onSubmit = () => {
    this.setState({ isIncorrectAccount: false });
    let loginReqModel: LoginReqModel = {
      username: this.state.userName.toLowerCase().trim(),
      password: this.state.password,
    };

    this.login(loginReqModel);
  };

  login(loginReqModel: LoginReqModel) {
    login(loginReqModel)
      .then((value: any) => {
        removeLoading();
        setToken(value.data.access_token, new Date(value.data.expired_at));

        getAllBalances(getInfoByToken().employerId);
        this.checkFinishSetup();
      })
      .catch((error) => {
        removeLoading();
        // if err
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.status === 500) {
            publishMessage({
              message: "We’ve run into a problem. Please try again later",
              variant: "error",
            });
          } else {
            this.setState({
              isIncorrectAccount: true,
              message: error.response.data.message,
            });
          }
        } else {
          publishMessage({
            message:
              "System error. We apologize for the inconvenience. Please try again.",
            variant: "error",
          });
        }
      });
  }

  validatorListener = () => {
    let isValid = this.validate();
    this.setState({ isValid: isValid });
  };

  validate = (): boolean => {
    if (this.state.userName && this.state.password) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <div>
          <StyledTitle>
            <StyledBigTitle>Sign In</StyledBigTitle>
          </StyledTitle>

          <ValidatorForm className="w-100" onSubmit={this.onSubmit}>
            <div className="row pt-3">
              <div className="col-lg-6 mb-2">
                <LandingStyledTextValidator
                  onChange={this.handleChangeInput}
                  type="text"
                  name="userName"
                  placeholder="Username/Email"
                  value={this.state.userName}
                  validators={["required"]}
                  errorMessages={["Please enter user name or email."]}
                  validatorListener={this.validatorListener}
                  tabIndex={-1}
                  role="application"
                  aria-hidden="true"
                />
              </div>
              <div className="col-lg-6 mb-2">
                <ShowPasswordView
                  onChange={this.handleChangeInput}
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  validators={["required"]}
                  errorMessages={["Please enter password."]}
                  tabIndex={-1}
                  validatorListener={this.validatorListener}
                />
              </div>
              {this.state.isIncorrectAccount && (
                <StyledPError className="col-lg-12 mb-0">
                  {this.state.message}
                </StyledPError>
              )}

              <div className="col-lg-12 mb-2">
                <StyledDivForgotPassword to={forgotPasswordURL}>
                  Forgot the password
                </StyledDivForgotPassword>
              </div>

              <div className="mt-3 col-lg-12">
                <div className="col-lg-4 p-0 float-right">
                  <StyledButtonSubmit type="submit" submit={this.state.isValid}>
                    Sign in
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
