import React from "react";
import CompanyManagementEmployeeDetailView from "./detail-employee-view";
import {
  ClaimData,
  StatusClaim,
  SearchParamClaim,
} from "../../../claim-management/claim-management-type";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../../../cores/helpers/pagingation/pagination";
import {
  EmployeeInfo,
  Balance,
  Adjustment,
  Transactions,
  WalletSearchParams,
  EmployeeModal,
  PendingAmountByCategory
} from "./detail-employee-type";
import {
  Status,
  employeeStatus,
  signUpStatus,
} from "../employee-model";
import {
  statusClaim,
} from "../../../claim-management/claim-management-model";
import {columnClaims, Class, columnTransactions} from "./detail-employee-model";
import { RouteChildrenProps } from "react-router";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import { message } from "../../../../../cores/helpers/message/message";
import { getRole } from "../../../../../services/auth-service";
import {
  getWalletEmployee,
  getAllTransactionsForEmployee,
  getPendingClaimAmountByCategory,
  getWalletOnboardingDate
} from "../../../../../services/employee-wallet-service";
import {
  getEmployeeClaimsById
} from "../../../../../services/employer-claim-services"
import {
  getEmployeeInfoById, putEmployeesEmployer, SortType,
} from "../../../../../services/employer-employees-service"
import {
  getClasses,
  getEnrollments
} from "../../../../../services/employer-benefits-service"
import moment from "moment";
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import {Employee} from "../employee-type";
import EditEmployeeModal from "../add-employee-modal/edit-employee-modal";
import {filterData} from "../../../../../cores/helpers/filter/filter";
import {updateEmployeeByAdmin} from "../../../../../../AdminPortal/src/services/company-service";
import {setToken} from "../../../../../cores/utils/helpers";
import {result} from "lodash";
import {publishMessage} from "../../../../../cores/utils/message";

type State = {
  // claims data
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimsData: ClaimData[];
  claimsColumn: TableSortType[];
  transactionColumn: TableSortType[],
  // employee info
  employee: EmployeeInfo;
  classes: Class[];
  statuses: Status[];
  roles: Status[];
  signUpStatuses: Status[];
  enrollments: Class[];
  onboardingDate: Date | string;
  // wallet
  availableAmount: number;
  balances: Balance[];
  pendingAmount: number;
  pendingClaimAmountByCategory: PendingAmountByCategory;
  adjustment: Adjustment;

  searchParamWallet: WalletSearchParams;
  transactionsFilter: Transactions[];
  transactions: Transactions[];
  pagingInfoWallet: PagingInfo;

  editEmployee: any;
  isAdjustment: boolean;
  openEditEmployeeModal: boolean;
};
type Props = RouteChildrenProps;

export default class DetailEmployee extends React.Component<
  Props,
  State
> {
  state: State = {
    claimsData: [],
    statusClaim: statusClaim,
    pagingInfo: {
      currentPage: 1,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    searchParam: {
      page: 1,
      from: null,
      to: null,
      status: null,
      searchName: null,
      columnName: null,
      sortType: null,
      perPage: 10,
    },
    claimsColumn: columnClaims,
    transactionColumn: columnTransactions,
    // employee info
    employee: {} as EmployeeInfo,
    classes: [],
    roles: [],
    statuses: employeeStatus,
    signUpStatuses: signUpStatus,
    enrollments: [],
    onboardingDate: "",
    // wallet
    availableAmount: 0,
    balances: [],
    pendingAmount: 0,
    pendingClaimAmountByCategory: {},
    // credit
    adjustment: {
      credit: 0,
      debit: 0,
      id: 0,
      note: "",
    },
    searchParamWallet: {
      page: 1,
      perPage: 10,
      transactionType: "",
    },
    transactionsFilter: [],
    transactions: [],
    pagingInfoWallet: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },

    editEmployee: {},
    isAdjustment: false,
    openEditEmployeeModal: false
  };
  employeeId: number = 0;

  async componentDidMount() {
    addLoading();
    let param = this.getSearchByUrl();
    await this.getData();

    await this.getSearchClaimData(param.searchParam);

    await this.getAllTransactions(this.employeeId);

    this.setState({
      searchParam: param.searchParam,
      searchParamWallet: param.searchParamWallet
    });

    removeLoading();
  }

  componentDidUpdate(p: Props, prevState: State) {
    if (
      this.state.searchParamWallet.page !== prevState.searchParamWallet.page ||
      this.state.searchParamWallet.perPage !== prevState.searchParamWallet.perPage ||
      this.state.searchParamWallet.transactionType !== prevState.searchParamWallet.transactionType ||
      this.state.transactions.length !== prevState.transactions.length
    ) {
      const { transactions } = this.state;
      let paramSearch = this.getSearchByUrl();
      let response = filterData(
        Object.assign([], transactions),
        paramSearch.searchParamWallet.page,
        paramSearch.searchParamWallet.perPage,
        paramSearch.searchParamWallet.transactionType
          ? [{ 
              name: "transactionType",
              value: paramSearch.searchParamWallet.transactionType,
            }]
          : null
      );

      let pagingInfo = setPagingInfo(
        paramSearch.searchParamWallet.page,
        response.pageCount,
        paramSearch.searchParamWallet.perPage
      );

      this.setState({
        pagingInfoWallet: pagingInfo,
        transactionsFilter: response.records,
      });
    }
  }

  async getOnboardingDate(employeeId: number) {
      const onboardingDateResponse = await getWalletOnboardingDate(employeeId);
      this.setState({
        onboardingDate: onboardingDateResponse.data
      })
  }

  async getData() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const [resultEmployee, resultRole, resultEnrollment] =
        await Promise.all([
          getEmployeeInfoById(Number(id)),
          getRole(),
          getEnrollments(),
          this.getAllBalances(Number(id)),
          this.getOnboardingDate(Number(id))
        ])
    const employee: EmployeeInfo = resultEmployee.data || ({} as EmployeeInfo);
    const classesResponse = await getClasses();
    const classes: Class[] = classesResponse.data || [];
    const roles: Status[] = resultRole.data || [];
    const enrollments: Class[] = resultEnrollment.data || [];

    this.setState({
      classes: classes,
      employee: employee,
      roles: roles,
      enrollments: enrollments,
    });
  }

  async getAllBalances(employeeId: number) {
    const [resultWalletBalance, resultPendingClaimAmountByCategory] = await Promise.all([
      getWalletEmployee(employeeId),
      getPendingClaimAmountByCategory(employeeId)
    ])
    const pendingClaimAmountByCategory: PendingAmountByCategory = resultPendingClaimAmountByCategory.data;

    let walletBalance: Balance[] = resultWalletBalance.data
      ? resultWalletBalance.data.balances
      : [];

    let available = walletBalance.find((m) => {
      return m.walletType === "physical";
    });

    const pendingAmount = Object.values(pendingClaimAmountByCategory).reduce((previousValue, currentValue) => {
      return (previousValue + currentValue.pendingApproveClaimAmount + currentValue.pendingISSClaimAmount)
    }, 0);
    this.setState({
      availableAmount: available ? available.balance : 0,
      balances: walletBalance,
      pendingAmount: pendingAmount,
      pendingClaimAmountByCategory: pendingClaimAmountByCategory,
    });
  }

  async UNSAFE_componentWillReceiveProps() {
    let param = this.getSearchByUrl();
    addLoading();
    await this.getSearchClaimData(param.searchParam);

    this.setState({
      searchParam: param.searchParam,
      // searchParamWallet: param.searchParamWallet
    });
    removeLoading();
  }

  async getSearchClaimData(searchParam: SearchParamClaim) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    await getEmployeeClaimsById(Number(id), searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      let claimData: ClaimData[] = result.data.records || [];

      this.setState({
        claimsData: claimData,
        pagingInfo: pagingInfo,
      });
    });
  }

  handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if ((event.target.name = "claimStatus")) {
      if (Number(value) !== -1) searchParam.status = value;
      else searchParam.status = null;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    searchParam.searchName = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail();
    }
  };

  handleChangeDate = (date: Date | null, name: string) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if (name === "from") {
      if (searchParam.to && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchParam.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date;
    } else {
      if (searchParam.from && date) {
        let from = new Date(searchParam.from),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  handleChangePage = (page: number) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
  };

  findStatusNameById = (statusId: string) => {
    let status = this.state.statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });
    return status ? status.name : "";
  };

  findEnrollmentNameById = (id: number) => {
    let enrollment = this.state.enrollments.find((enrollment) => {
      return enrollment.id === id;
    });
    return enrollment ? enrollment.name : "";
  };

  sortTable = (columnId: string) => {
    const columnsHeaderTable: TableSortType[] = [...this.state.claimsColumn];
    const searchParam = Object.assign({}, this.state.searchParam);

    searchParam.columnName = columnId;
    const index = columnsHeaderTable.findIndex(
        (column) => column.columnId === searchParam.columnName);

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      id = urlParams.get("id"),
      page = urlParams.get("page"),
      from = urlParams.get("from"),
      to = urlParams.get("to"),
      status = urlParams.get("status"),
      searchName = urlParams.get("searchName"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage"),
      pageWallet = urlParams.get("pageWallet"),
      rowsPerPageWallet = urlParams.get("rowsPerPageWallet"),
      transactionType = urlParams.get("transactionType");
    this.employeeId = Number(id);
    let searchParam = Object.assign({}, this.state.searchParam);

    if (page) {
      searchParam.page = Number(page);
    } else {
      searchParam.page = 1;
    }
    if (from) {
      searchParam.from = from;
    } else {
      searchParam.from = null;
    }
    if (to) {
      searchParam.to = to;
    } else {
      searchParam.to = null;
    }
    if (status) {
      searchParam.status = status;
    } else {
      searchParam.status = null;
    }
    if (searchName) {
      searchParam.searchName = searchName;
    } else {
      searchParam.searchName = null;
    }
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    const columns: TableSortType[] = [...columnClaims];
    const index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);
    if(pageWallet) {
      searchParamWallet.page = Number(pageWallet);
    }
    if(rowsPerPageWallet) {
      searchParamWallet.perPage = Number(rowsPerPageWallet);
    }
    if(transactionType) {
      searchParamWallet.transactionType = transactionType;
    }

    return {
      searchParam,
      columns,
      searchParamWallet
    };
  };

  setSearchByParam = (searchParam: SearchParamClaim) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.from) {
      url.searchParams.set("from", moment(searchParam.from).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("from");
    }
    if (searchParam.to) {
      url.searchParams.set("to", moment(searchParam.to).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("to");
    }
    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }
    if (searchParam.searchName) {
      url.searchParams.set("searchName", searchParam.searchName);
    } else {
      url.searchParams.delete("searchName");
    }
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setSearchByParamWallet = (searchParam: WalletSearchParams) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("pageWallet", searchParam.page.toString());
    } else {
      url.searchParams.delete("pageWallet");
    }
    if (searchParam.perPage) {
      url.searchParams.set("rowsPerPageWallet", searchParam.perPage.toString());
    } else {
      url.searchParams.delete("rowsPerPageWallet");
    }
    if (searchParam.transactionType) {
      url.searchParams.set("transactionType", searchParam.transactionType.toString());
    } else {
      url.searchParams.delete("transactionType");
    }

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  findClassById = (classId: number): string => {
    let classItem = this.state.classes.find((classBenefit: Class) => {
      return classBenefit.id === classId;
    });
    return classItem ? classItem.name : "";
  };

  findSignUpStatusById = (signUpStatusId: string): string => {
    let signUpStatusItem = this.state.signUpStatuses.find(
      (signUpStatus: Status) => {
        return signUpStatus.id === signUpStatusId;
      }
    );
    return signUpStatusItem ? signUpStatusItem.name : "";
  };

  findStatusesById = (statusId: string): string => {
    let statusItem = this.state.statuses.find((status: Status) => {
      return status.id === statusId;
    });

    return statusItem ? statusItem.name : "";
  };

  findRoleById = (roleId: string): string => {
    let role = this.state.roles.find((role: Status) => {
      return role.id === roleId;
    });

    return role ? role.name : "";
  };

  changeFilterWallet = (event: any) => {
    let { name, value } = event.target,
      searchParam: any = Object.assign({}, this.state.searchParamWallet);

    if (Number(value) === -1) value = null;

    searchParam[name] = value;

    searchParam.page = 1;

    // set url search
    this.setSearchByParamWallet(searchParam);

    this.setState({ searchParamWallet: searchParam });
  };

  changePageWallet = (page: number) => {
    let searchParamWallet = Object.assign({},
      this.state.searchParamWallet
    );
    searchParamWallet.page = page;

    this.setSearchByParamWallet(searchParamWallet);
    this.setState({searchParamWallet})
  };

  async getAllTransactions(employerId: number) {
    let resultTransactions = await getAllTransactionsForEmployee(employerId);

    let transactions: Transactions[] = resultTransactions.data;

    this.setState({transactions})
  }

  setRowsPerPageWallet = (event: any) => {
    const { value } = event.target;

    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);
    searchParamWallet.page = 1;
    searchParamWallet.perPage = Number(value);

    // set url search
    this.setSearchByParamWallet(searchParamWallet);
    this.setState({searchParamWallet});
  };

  handleUpdateEmployee = async (employee: Employee) => {
    addLoading();

    const updateResult = await putEmployeesEmployer(employee);

    if (!!updateResult.data) {
      setToken(updateResult.data.access_token);
    }

    await this.getData();

    publishMessage({
      message: "Update employee info successfully",
      variant: "success",
    });

    this.handleCloseEditEmployeeModal();
    removeLoading();
  }

  modelFactory = () => {
    let temp: EmployeeModal = { ...this.state.employee } as any
    this.setState({ editEmployee: temp})
  }

  handleOpenEditEmployeeModal = () => {
    this.setState({ openEditEmployeeModal: true })
  }

  handleCloseEditEmployeeModal = () => {
    this.setState({ openEditEmployeeModal: false })
  }

  render() {
    return (
      <>
        <CompanyManagementEmployeeDetailView
          searchParam={this.state.searchParam}
          pagingInfo={this.state.pagingInfo}
          statusClaim={this.state.statusClaim}
          claimsData={this.state.claimsData}
          claimsColumn={this.state.claimsColumn}
          transactionsColumn={this.state.transactionColumn}
          handleChangeDate={this.handleChangeDate}
          handleChangSelect={this.handleChangSelect}
          handleChangeInput={this.handleChangeInput}
          onKeyUp={this.onKeyUp}
          searchNameOrEmail={this.searchNameOrEmail}
          findStatusNameById={this.findStatusNameById}
          handleChangePage={this.handleChangePage}
          sortTable={this.sortTable}
          // employee info
          employee={this.state.employee}
          classes={this.state.classes}
          statuses={this.state.statuses}
          roles={this.state.roles}
          signUpStatuses={this.state.signUpStatuses}
          findClassById={this.findClassById}
          findSignUpStatusById={this.findSignUpStatusById}
          findStatusesById={this.findStatusesById}
          findRoleById={this.findRoleById}
          findEnrollmentNameById={this.findEnrollmentNameById}
          setRowsPerPage={this.setRowsPerPage}
          onboardingDate={this.state.onboardingDate}
          // wallet
          availableAmount={this.state.availableAmount}
          balance={this.state.balances}
          pendingClaimAmountByCategory={this.state.pendingClaimAmountByCategory}
          pendingAmount={this.state.pendingAmount}

          pagingInfoWallet={this.state.pagingInfoWallet}
          changePageWallet={this.changePageWallet}
          setRowsPerPageWallet={this.setRowsPerPageWallet}

          transactions={this.state.transactionsFilter}
          searchParamWallet={this.state.searchParamWallet}
          changeFilterWallet={this.changeFilterWallet}
          modelFactory={this.modelFactory}

          handleOpenEditEmployeeModal={this.handleOpenEditEmployeeModal}
        />
        {this.state.employee.t4Employee && <EditEmployeeModal
          open={this.state.openEditEmployeeModal}
          handleClose={this.handleCloseEditEmployeeModal}
          employee={this.state.employee as Employee}
          handleSubmit={this.handleUpdateEmployee}
          {...this.props}
        />}

      </>
    );
  }
}

export const getLimitBalance = (id: number, balances: Balance[]) => {
  switch (id) {
    case 1: {
      let hsaLimit = balances.find((m) => {
        return m.accountName === "hsa_limit";
      });

      return hsaLimit ? hsaLimit.balance : 0;
    }
    case 2: {
      let wellnessLimit = balances.find((m) => {
        return m.accountName === "wellness_limit";
      });
      return wellnessLimit ? wellnessLimit.balance : 0;
    }
    case 3: {
      let insuranceLimit = balances.find((m) => {
        return m.accountName === "insurance_limit";
      });
      return insuranceLimit ? insuranceLimit.balance : 0;
    }
    case 4: {
      let charityLimit = balances.find((m) => {
        return m.accountName === "charity_limit";
      });
      return charityLimit ? charityLimit.balance : 0;
    }
    case 5: {
      let rrspLimit = balances.find((m) => {
        return m.accountName === "rrsp_limit";
      });
      return rrspLimit ? rrspLimit.balance : 0;
    }
    case 6: {
      let craLimit = balances.find((m) => {
        return m.accountName === "cra_limit";
      });
      return craLimit ? craLimit.balance : 0;
    }
    default:
      return 0;
  }
};
