export const calculateWalletAmount = (pendingAmount: any, availableAmount: number) => {
    const {
      processing = 0,
      pendingAmount: pendingTopUp = 0,
      claimRequested = 0
    } = pendingAmount;
    // Perform calculations
    const claimPending = processing + claimRequested;
    const requiredTopUp = claimPending - (availableAmount + pendingTopUp);
    return {claimPending, requiredTopUp};
}