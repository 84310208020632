import React, {useEffect} from 'react';
import {BNPModal} from "../../../shared/BNPModal/BNPModal";
import ReviewComponent from "./review-component";
import {Review} from "../marketplace-type";
import InfiniteScroll from "react-infinite-scroll-component";
import {Box} from "@material-ui/core";
import styled from "styled-components";
import ProfileImage from "../../../../cores/config/component/images/profile.jpg";

const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e0e0e0;
  border-radius: 5px;
`;

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  reviews: Review;
  handleLoadMore: () => void;
}

function AllReviewModal({open, handleClose, title, reviews, handleLoadMore}: Props) {

  const hasMore = reviews.total > reviews.records.length;

  const body =
    <div className={"w-100"}>
      <InfiniteScroll
        dataLength={reviews.records.length || 0}
        next={handleLoadMore}
        hasMore={hasMore}
        loader={""}
      >
        {reviews.records.map((review, index) =>
          <ReviewComponent
            key={`all-review-${index}`}
            index={`all-review-${index}`}
            avatarUrl={review.employeePhotoUrl || ProfileImage}
            username={`${review.reviewByEmployeeFirstName} ${review.reviewByEmployeeLastName}`}
            rating={review.ratingPoint}
            content={review.comment}
            date={new Date(review.createdDate)}
          />
        )}
      </InfiniteScroll>
      {hasMore && (
        <Box width="100%" marginTop={1} textAlign="center">
          <StyledActionButton title="load more" onClick={handleLoadMore}>
            Load more...
          </StyledActionButton>
        </Box>
      )}
    </div>

  return (
    <BNPModal
      open={open}
      title={title || "All Reviews"}
      handleClose={handleClose}
      body={body}
      footer={<></>}
    />
  );
}

export default AllReviewModal;