import {AxiosResponse} from "axios";
import {getAsync, getFileAsync} from "../cores/utils/http-client";
import {SearchParamType} from "../components/pages/report/ReportType";

const URL_files = "/manage-employer/api/document";

export const getDepositReceipt = ( params: SearchParamType): Promise<AxiosResponse> => {
    let url = `${URL_files}/deposit-receipt`;

    return getAsync(url, params);
};

export const getAllDepositReceiptAsZip = async (criteria: SearchParamType): Promise<AxiosResponse> => {

    let url = `${URL_files}/deposit-receipt/download`;

    return await getFileAsync(url, criteria);
};

export const getClaimReceipt = ( params: SearchParamType): Promise<AxiosResponse> => {
    let url = `${URL_files}/claim-receipt`;

    return getAsync(url, params);
};

export const getAllClaimReceiptAsZip = async (criteria: SearchParamType): Promise<AxiosResponse> => {

    let url = `${URL_files}/claim-receipt/download`;

    return await getFileAsync(url, criteria);
};