import React, {useEffect, useState} from 'react';
import {StyledContentBox, StyledFormControlLabel, StyledSpanLargerTitle} from "../../../../shared/employer-styled";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import styled from "styled-components";
import RadioGroup from "@material-ui/core/RadioGroup";
import {EmployerPlan} from "../../plan/plan-preferences/plan-preferences-type";
import moment from "moment";
import BNPDatePicker from "../../../../../cores/helpers/date-picker/date-picker";
import ClearIcon from "@material-ui/icons/Clear";

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
`;
const StyledDescription = styled(RadioGroup)`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 0;
  }
`;
const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding-left: 8px;
`;

const StyledIconClear = styled(ClearIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.primaryColor};
`;

const PlanStartDateOption = [
    {
        id: 1,
        description: "Current Date",
    },
    {
        id: 2,
        description: "First date of this month",
    },
    {
        id: 3,
        description: "Other"
    }
]

type Props = {
    employerPlan: EmployerPlan;
    updatedEmployerPlan: EmployerPlan;
    handleChangeEmployerPlan: (employerPlan: EmployerPlan) => void;
}

function PlanInformation(props: Props) {
    const [planStartDateOption, setPlanStartDateOption] = useState<number>(-1);

    useEffect(() => {
        if (props.employerPlan && props.employerPlan.planStartDate) {
            props.handleChangeEmployerPlan(props.employerPlan)
        }
    }, [props.employerPlan])

    const handleChangePlanStartDateOption = (e: any) => {
        const {value} = e.target;
        const employerPlanClone = Object.assign({}, props.employerPlan)

        setPlanStartDateOption(Number(value));
        if (Number(value) === 1 || Number(value) === 3) {
            employerPlanClone.planStartDate = moment().toDate();
        }
        if (Number(value) === 2) {
            employerPlanClone.planStartDate = moment().startOf('month').toDate();
        }
        props.handleChangeEmployerPlan(employerPlanClone);
    }

    const handleChangeOtherPlanStartDate = (date: Date | null, name: string) => {
        if (name === "planStartDate" && date) {
            const employerPlanClone = Object.assign({}, props.employerPlan)
            employerPlanClone.planStartDate = date;
            props.handleChangeEmployerPlan(employerPlanClone);
        }
    }

    const handleClearEdit = () => {
        setPlanStartDateOption(-1);
        props.handleChangeEmployerPlan(props.employerPlan);
    }

    return (
        <StyledContentBox isBorder={true} style={{height: "auto", marginBottom: "10px"}}>
            <div>
                <StyledSpanLargerTitle>Plan Information</StyledSpanLargerTitle>
            </div>
            <div className="mt-3">
                <div key={"Standard Plan"}>
                    <StyledTitle>
                        Standard Plan
                    </StyledTitle>
                    <FormControl component="fieldset">
                        <StyledDescription
                            aria-label={"Standard Plan"}
                            name={"Standard Plan"}
                            value={planStartDateOption}
                            onChange={handleChangePlanStartDateOption}
                        >
                            {PlanStartDateOption.map(item => (
                                <span key={item.id}>
                                  <StyledFormControlLabel
                                      value={item.id}
                                      control={<Radio color="primary" />}
                                      label={item.description}
                                  />
                                  {item.description && (
                                    <Tooltip
                                        title={item.description}
                                        placement="right"
                                    >
                                        <InfoIcon className="icon ml-2" />
                                    </Tooltip>
                                  )}
                                </span>
                            ))}
                        </StyledDescription>
                        {
                            <div className="row">
                                <div className="col-9">
                                    <BNPDatePicker
                                        value={props.updatedEmployerPlan.planStartDate}
                                        onChange={handleChangeOtherPlanStartDate}
                                        name="planStartDate"
                                        disabled={planStartDateOption !== 3}
                                        minDate={planStartDateOption === 3 && new Date() || undefined}
                                    />
                                </div>
                                <div className="col-3">
                                    {props.employerPlan !== props.updatedEmployerPlan &&
                                        <StyledActionButton title="Clear" onClick={handleClearEdit}>
                                            <StyledIconClear/>
                                        </StyledActionButton>
                                    }
                                </div>
                            </div>
                        }
                    </FormControl>
                </div>
            </div>
        </StyledContentBox>
    );
}

export default PlanInformation;