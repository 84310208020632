import React, {useEffect} from 'react';
import styled from "styled-components";
import {StyledBigTitle} from "../../shared/employer-styled";
import VendorCard from "./component/vendor-card";
import {getSearchVendors} from "../../../services/marketplace-service";
import {getAvgRating} from "../../../cores/utils/helpers";
import {RouteChildrenProps} from "react-router";
import {SearchVendorParams} from "./marketplace-type";

const StyledBackgroundImage = styled.div`
  height: 550px;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Beniplus-marketplace-background-image.png");
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

const StyledInfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;
  font-family: 'Roboto',sans-serif;
  text-align: center;
  h1 {
    color: white;
    font-weight: 700;
  }

  h2 {
    color: white;
    font-weight: 500;
  }

  @media (min-width: 320px) {
    h1 {
      font-size: 18px;
      line-height: 22px;
    }
    h2 {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (min-width: 1366px) {
    h1 {
      font-size: 36px;
      line-height: 39px;
    }
    h2 {
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (min-width: 1920px) {
    h1 {
      font-size: 54px;
      line-height: 65px;
    }
    h2 {
      font-size: 22px;
      line-height: 25px;
    }
  }
`;

const StyledSearchContainer = styled.div`
    width: 100%;
`;

type Props = {
  searchComponent: JSX.Element;
  searchParams: SearchVendorParams;
} & RouteChildrenProps;

function MarketplaceLandingPage(props: Props) {
  const [featuredVendors, setFeaturedVendors] = React.useState<any>([]);

  useEffect(() => {
    // Fetch Featured Vendors
    getSearchVendors({
      page: 1,
      perPage: 4,
      sortBy: ['created_date;desc'],
    }).then((response: any) => {
      const result = response.data.records;

      const convertedVendors = result.map((vendor: any) => ({
        ...vendor,
        stars: !!vendor.ratingPointTotal ? getAvgRating(vendor.ratingPointTotal, vendor.reviewTotal) : 0,
      }));

      return setFeaturedVendors(convertedVendors);
    });
  }, []);

  return (
    <div>
      <StyledBackgroundImage>
        <StyledInfoText>
          <h1>
            Make A Positive Change In Your Life.
          </h1>
          <h1 className="pb-2">
            Start Your Health Journey Now.
          </h1>
          <h2>Embark On Your Personalized Journey To Optimal Health And Well-being.</h2>
          <h2>We Provide The Tools, Resources, And Support You Need To Archive Your Goals.</h2>
        </StyledInfoText>
        <StyledSearchContainer>
          {props.searchComponent}
        </StyledSearchContainer>
      </StyledBackgroundImage>

      <StyledBigTitle>Featured Vendor</StyledBigTitle>
      <div className="row">
        {featuredVendors.map((vendor: any) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3"
               key={`featured-vendor-${vendor.id}`}
          >
            <VendorCard
              id={vendor.id}
              image={vendor.coverImageUrl}
              name={vendor.name}
              stars={vendor.stars}
              reviews={vendor.reviewTotal || 0}
              postcode={vendor.postcode}
              discount={vendor.discount || ''}
              searchParams={props.searchParams}
              {...props}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default MarketplaceLandingPage;