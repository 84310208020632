import React, {useEffect} from 'react';
import {ManualAdd} from "./manual-add/manual-add";
import {RouteChildrenProps} from "react-router";
import {Employee, Role} from "../employee-type";
import {getClassBenefits, getClasses} from "../../../../../services/employer-benefits-service";
import {getPlan, getPlanPreferencesByEmployerId, getProvinces} from "../../../../../services/employer-setting-service";
import {getAllRole} from "../../../../../services/auth-service";
import {Class} from "../../../request-a-quote/request-a-quote-type";
import {EmployerBenefitClass} from "../../employer-classes/employer-classes-type";
import {Provinces} from "../../employer-setting/company-information/company-information-type";
import {EmployerPlan} from "../../plan/plan-preferences/plan-preferences-type";
import {employeeStatus, planPreferences} from "../employee-model";
import {ResponseLocation} from "../../../../../cores/helpers/google-map-services/google-location";
import {BNPModal} from "../../../../shared/BNPModal/BNPModal";
import {addLoading, removeLoading} from "../../../../../cores/utils/loading";
import {formatValueInputMask} from "../../../../../cores/helpers/format-phone-number";
import {validateForm} from "../../../../../cores/utils/validation/validate-upload-in-bulk";
import {findProvinceIdByName} from "../../employer-setting/company-information/company-information";
import {toUTCTime} from "../../../../../cores/helpers/to-utc-time";
import {validateEmail} from "../../../../../cores/config/config";
import {validFullNameLength} from "../../../../../cores/utils/validation/validate-employee";
import {usdToCent} from "../../../../../cores/helpers/cent-to-usd";
import styled from "styled-components";

const StyledContentBox = styled.div`
  padding: 9px 5px;
  width: 100%;
`;

type Props = RouteChildrenProps & {
  employee: Employee;
  handleSubmit: (employee: Employee) => void;
  open: boolean;
  handleClose: () => void;
}

const EditEmployeeModal = (props: Props) => {
  const [editEmployee, setEditEmployee] = React.useState<Employee>({...props.employee});
  const [classes, setClasses] = React.useState<Class[]>([]);
  const [classBenefits, setClassBenefits] = React.useState<EmployerBenefitClass[]>([]);
  const [planPreferencesByEmployers, setPlanPreferencesByEmployers] = React.useState<number[]>([]);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [provinces, setProvinces] = React.useState<Provinces[]>([]);
  const [employerPlan, setEmployerPlan] = React.useState<EmployerPlan>({} as EmployerPlan);
  const [isValid, setIsValid] = React.useState<boolean>(false);

  useEffect(() => {
    addLoading();
    getData()
      .then(() => removeLoading());
  }, [props.employee.id])

  const getData = async () => {
    const [classesResponse,
      resultAllPlanPreferencesByEmployer,
      resultClassBenefits,
      resultRole,
      resultProvinces,
      resultEmployerPlan
    ] = await Promise.all([
      getClasses(),
      getPlanPreferencesByEmployerId(),
      getClassBenefits(),
      getAllRole(),
      getProvinces(),
      getPlan()
    ])

    const planPreferencesByEmployer: number[] =
      resultAllPlanPreferencesByEmployer.data.map(
        (f: any) => f.planPreferenceId
      ) || [];

    setClasses(classesResponse.data || []);
    setClassBenefits(resultClassBenefits.data || []);
    setPlanPreferencesByEmployers(planPreferencesByEmployer);
    setRoles(resultRole.data || []);
    setProvinces(resultProvinces.data || []);
    setEmployerPlan(resultEmployerPlan.data || {} as EmployerPlan);
  }

  const handleChangeDate = (date: Date | null, name: string) => {
    const employee = Object.assign({}, editEmployee);

    if (name === "hiringDate") {
      const value = findValueByPlanPreferencesByEmployer();

      if (!!date && value !== null && !employee.isSetUpDone) {
        if (value > -1) {
          let eligibilityDate = new Date(date);
          eligibilityDate.setDate(date.getDate() + value);
          const planStartDate = employerPlan.planStartDate ? new Date(employerPlan.planStartDate) : null;
          if (planStartDate && planStartDate > eligibilityDate) {
            eligibilityDate = planStartDate;
          }
          employee.eligibilityDate = eligibilityDate;
        }
      }
    }
    employee[name] = date && toUTCTime(new Date(date));

    setIsValid(validateForm(employee))
    setEditEmployee(employee);
  };
  const findValueByPlanPreferencesByEmployer = (): number | null => {
    let value = null;

    planPreferencesByEmployers.forEach(
      (planPreferencesByEmployer) => {
        let planPreference = planPreferences.find((planPreference) => {
          return planPreference.id === planPreferencesByEmployer;
        });
        if (planPreference) {
          value = planPreference.value;
        }
      }
    );

    return value;
  };
  const handleChangeSelect = (event: any) => {
    const employee = Object.assign({}, editEmployee);
    let {name, value} = event.target;

    if (name === "roleIds") {
      employee.roleIds = [value];
    } else {
      if (Number(value) === -1) value = null;

      employee[name] = value;
    }

    setIsValid(validateForm(employee))
    setEditEmployee(employee);
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const employee = Object.assign({}, editEmployee);

    if (event.target.name === "phoneNumber") {
      employee.phoneNumber = formatValueInputMask(event.target.value);
    } else {
      employee[event.target.name] = event.target.value;
    }

    setIsValid(validateForm(employee))
    setEditEmployee(employee);
  };
  const setAddress = (address: string) => {
    const employee = Object.assign({}, editEmployee);
    employee.street = address;

    setIsValid(validateForm(employee))
    setEditEmployee(employee);
  };
  const setLocation = (location: ResponseLocation) => {
    const employee = Object.assign({}, editEmployee);

    employee.cityName = location.city;
    employee.postCode = location.postcode ? location.postcode : "";
    employee.provinceId = findProvinceIdByName(location.province, provinces);

    setIsValid(validateForm(employee))
    setEditEmployee(employee);
  };
  const handleSubmit = async () => {
    const employee = Object.assign({}, editEmployee, {
      salary: usdToCent(editEmployee.salary),
      homeAddress: editEmployee.street
    });

    props.handleSubmit(employee);
  };

  //TODO: Review this function
  const setEmployerBenefitClass = (): Class[] => {
    let currentClasses: Class[] = [];

    classBenefits.forEach((classBenefit) => {
      classes.forEach((classItem) => {
        if (classBenefit.classId === classItem.id) {
          currentClasses.push(classItem);
        }
      });
    });
    return currentClasses;
  };

  const validateForm = (employee: Employee) => {
    return !!(employee.email.match(validateEmail) &&
      employee.firstName &&
      employee.lastName &&
      employee.roleIds.length > 0 &&
      employee.roleIds[0] > 0 &&
      employee.code &&
      employee.gender &&
      employee.dateOfBirth &&
      employee.classId &&
      employee.eligibilityDate &&
      employee.hiringDate &&
      employee.provinceId &&
      employee.provinceId > 0 &&
      validFullNameLength(employee.firstName, employee.lastName)
    );
  };

  const modalBody = <div className='row'>
    <StyledContentBox>
      <ManualAdd
        employees={editEmployee}
        statuses={employeeStatus}
        roles={roles}
        handleChangeDate={handleChangeDate}
        handleChangeSelect={handleChangeSelect}
        handleChangeInput={handleChangeInput}
        handleChangeSubmit={handleSubmit}
        isValid={isValid}
        isEdit={true}
        editEmployee={handleSubmit}
        setEmployerBenefitClass={setEmployerBenefitClass}
        provinces={provinces}
        setAddress={setAddress}
        setLocation={setLocation}
        employerPlan={employerPlan}
        isT4Employee={props.employee.t4Employee || true}
        setIsT4Employee={() => {
        }}
      />
    </StyledContentBox>
  </div>

  return <BNPModal
    open={props.open}
    title={'Edit Employee'}
    handleClose={props.handleClose}
    body={modalBody}
  />
};

export default EditEmployeeModal;