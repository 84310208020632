import {
  getClasses,
  getEmployerEnrollments,
  getClassBenefits,
} from "../../../../services/employer-benefits-service";
import {
  Class,
} from "../../request-a-quote/request-a-quote-type";
import {
  EmployerEnrollment,
  EmployerBenefitClass
} from "./employer-classes-type";

export async function getDataEmployerBenefits() {
  const [
    resultClasses,
    resultClassBenefits] = await Promise.all([
    getClasses(),
    getClassBenefits()
  ])

  return {
    classes: resultClasses.data || [] as Class[],
    classBenefits: resultClassBenefits.data || [] as EmployerBenefitClass[],
  };
}

export async function getEmployerEnrollmentsData() {
  let resultEmployerEnrollments = await getEmployerEnrollments();

  let employerEnrollments: EmployerEnrollment[] =
    resultEmployerEnrollments.data || [];

  return employerEnrollments;
}
