import {AxiosResponse} from "axios";
import {getAsync} from "../cores/utils/http-client";

const URL_Employee = "/manage-wallet-partner/employee/wallet";

export const getWalletEmployee = (
    employeeId: number
): Promise<AxiosResponse> => {
    let url = `${URL_Employee}/balances/${employeeId}`;

    return getAsync(url, {}, false);
};

export const getWalletOnboardingDate = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/wallet/${employeeId}/onboardingDate`;

  return getAsync(url, {}, false);
};

export const getAllTransactionsForEmployee = (
    employeeId: number
): Promise<AxiosResponse> => {
    let url = `${URL_Employee}/transactions/all/${employeeId}`;

    return getAsync(url);
};

export const getPendingClaimAmountByCategory = (
    employeeId: number
): Promise<AxiosResponse> => {
    let url = `${URL_Employee}/balances/pending-claim-by-category/${employeeId}`;

    return getAsync(url);
};