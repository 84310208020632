import styled from "styled-components";
import Background from "../../assets/images/left-background/background.png";

export const StyledWrapper = styled.section`
  padding: 0px;
  margin: 0px;
  height: 100%;
  position: relative;
  background-color: #fff;
`;
export const StyledLeftPage = styled.div`
  background-color: #ecedf0;
  height: 100vh;
  background-image: url(${Background});
  background-size: cover;

  @media (max-width: 426px) {
    height: 471px;
  }
  @media (min-width: 320px) {
    padding: 1rem !important;
    margin-top: 4vh;
  }
  @media (min-width: 768px) {
    padding: 2em 3em !important;
    margin-top: 0;
  }
`;

export const StyledBackgroundImage = styled.img`
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
`;
export const StyledLogo = styled.img`
  height: 70px;
`;

export const StyledInfoText = styled.div`
  position: relative;
  top: 24%;
  left: 51%;
  width: max-content;
  font-family: ${props => props.theme.headlineFont};
  h1 {
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.subheadFont};
    font-weight: 900;
    text-align: left;
  }

  h2 {
    color: ${props => props.theme.secondaryColor};
    font-family: ${props => props.theme.subheadFont};
    font-weight: 700;
    position: relative;
  }

  @media (min-width: 320px) {
    h1 {
      font-size: 22px;
      line-height: 27px;
    }
    h2 {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (min-width: 1366px) {
    h1 {
      font-size: 36px;
      line-height: 39px;
    }
    h2 {
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (min-width: 1920px) {
    h1 {
      font-size: 54px;
      line-height: 65px;
    }
    h2 {
      font-size: 22px;
      line-height: 25px;
    }
  }
`;

export const StyledRightPage = styled.div`
  @media (min-width: 320px) {
    padding: 1rem !important;
  }
  @media (min-width: 768px) {
    padding: 2em 3em !important;
  }
`;

export const StyledHeaderRightPage = styled.div`
  color: ${props => props.theme.primaryColor};

  > a {
    color: ${props => props.theme.primaryColor};
  }
  > span {
    position: relative;
    width: 100%;
  }
  > span > a {
    color: ${props => props.theme.primaryColor};
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
  > a > svg {
    margin-top: 1px;
  }
  > a > span {
    position: relative;
    padding-left: 5px;
  }

  @media (min-width: 320px) {
    > a {
      display: none;
    }
    > span {
      margin-left: 0;
      text-align: center;
      top: -512px;
    }
  }
  @media (min-width: 768px) {
    > a {
      display: inherit;
    }
    > span {
      text-align: right;
      width: 100%;
      top: 0;
    }
  }
`;
