import React from "react";

import { BNPSelect } from "../../../cores/helpers/select/select";
import { BNPInput } from "../../../cores/helpers/input/input";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
  StyledRowBodyTable,
} from "../../shared/table-styled";
import { SearchParamClaim, ClaimData } from "./claim-management-type";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";
import { ExpansionPanel } from "@material-ui/core";
//import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
//import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";

import moment from "moment";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagingation/pagination";
import { SortTable } from "../employer/employees/employee-model";
import {
  StyledBigTitle,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionEdit,
  StyledExpansionLabel,
  StyledChangeDetail,
  StyledMainContent,
} from "../../shared/employer-styled";
import { Enrollments } from "../employer/employer-classes/employer-classes-type";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;
export const StyledDivExportExcel = styled.div`
  color: #007540;
`;
export const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;
export const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

type Props = {
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  enrollmentTypes: Enrollments[];
  claimsData: ClaimData[];
  columns: SortTable[];

  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  findStatusNameById: (statusId: string) => string;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
};

export default function ClaimManagementView(props: Props) {
/*  return (
    <StyledMainContent>
      <StyledBigTitle>Claim Management</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox isBorder={false}>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={props.enrollmentTypes}
                    value={props.searchParam.enrollmentType}
                    name="enrollmentType"
                    placeholder="Filter by claim type"
                    onChange={props.handleChangSelect}
                    tabIndex={3}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <StyledBNPInput
                    placeholder="Search name or email"
                    onChange={props.handleChangeInput}
                    value={props.searchParam.searchName || ""}
                    onKeyUp={props.onKeyUp}
                    tabIndex={4}
                  />
                  <StyledIconSearch onClick={props.searchNameOrEmail} />
                </div>
                <div className="col-lg-2">
                  {/!* <BNPDatePicker
                    onChange={props.handleChangeDate}
                    value={props.searchParam.from}
                    placeholder="From date"
                    name="from"
                    tabIndex={1}
                  /> *!/}
                </div>
                <div className="col-lg-2">
                  {/!* <BNPDatePicker
                    value={props.searchParam.to}
                    onChange={props.handleChangeDate}
                    placeholder="To date"
                    name="to"
                    tabIndex={2}
                  /> *!/}
                </div>
                <div className="col-lg-2"></div>
                {/!* <div className="col-lg-2 mt-4">
                  <StyledSelect
                    value={-1}
                    tabIndex={5}
                    //onChange={props.onChange}
                    //name={props.name}
                    //disabled={props.disabled ? true : false}
                  >
                    <MenuItem value={-1}>
                      <StyledDefaultOption className="p-2">
                        <PrintOutlinedIcon className="icon" /> Export data
                      </StyledDefaultOption>
                    </MenuItem>
                    <MenuItem value="1">
                      <StyledOption
                        className="p-2"
                        // onClick={() => {
                        //   getExportListSearchClaim(props.searchParam).then(
                        //     (result) => {
                        //       exportExcel(result.data);
                        //     }
                        //   );
                        // }}
                      >
                        <StyledDivExportExcel>
                          <SystemUpdateAltOutlinedIcon className="icon" />{" "}
                          Export via Excel
                        </StyledDivExportExcel>
                      </StyledOption>
                    </MenuItem>
                  </StyledSelect>
                </div> *!/}
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <StyledHideForMobile>
                <StyledTable id="tableClaim">
                  <StyledHeaderTable>
                    {props.columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => props.sortTable(column.columnId)}
                      >
                        {column.columnName}
                        <span>
                          {column.sortType ? (
                            column.sortType === "ASC" ? (
                              <StyledArrowDropDownIcon />
                            ) : (
                              <StyledArrowDropUpIcon />
                            )
                          ) : (
                            <StyledImportExportIcon />
                          )}
                        </span>
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {props.claimsData.map((claimData, i) => (
                      <StyledRowBodyTable key={i}>
                        <StyledCellTable width={25}>
                          {claimData.employeeName}
                        </StyledCellTable>
                        <StyledCellTable width={25}>
                          {claimData.type}
                        </StyledCellTable>
                        <StyledCellTable width={16}>
                          {claimData.totalAmount
                            ? formatterUSD("currency", "USD").format(
                                claimData.totalAmount / 100
                              )
                            : 0}
                        </StyledCellTable>
                        <StyledCellTable width={17}>
                          {claimData.claimedDate
                            ? moment(claimData.claimedDate).format(FE_DATE_FORMAT)
                            : ""}
                        </StyledCellTable>
                        <StyledCellTable width={17}>
                          {props.findStatusNameById(claimData.claimStatus)}
                        </StyledCellTable>
                      </StyledRowBodyTable>
                    ))}
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.handleChangePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledTable>
              </StyledHideForMobile>
            </div>

            <div className="col-lg-12 mt-4">
              <StyledHideForDesktop>
                {props.claimsData.map((claimData, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Claimer</StyledTitleExpansion>
                        <div className="mt-2">{claimData.employeeName}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100">
                        <StyledTitleExpansion>
                          Company name
                        </StyledTitleExpansion>
                        <div className="mt-1">{claimData.type}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Total amount
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {claimData.totalAmount
                            ? formatterUSD("currency", "USD").format(
                                claimData.totalAmount / 100
                              )
                            : 0}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Submitted Date
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {claimData.claimedDate
                            ? moment(claimData.claimedDate).format(FE_DATE_FORMAT)
                            : ""}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Status</StyledTitleExpansion>
                        <div className="mt-1">
                          {props.findStatusNameById(claimData.claimStatus)}
                        </div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <StyledSpanExpansionEdit>
                          <StyledExpansionLabel>
                            <StyledChangeDetail
                              to={`/claim/detail?id=${claimData.id}`}
                            >
                              Detail
                            </StyledChangeDetail>
                          </StyledExpansionLabel>
                        </StyledSpanExpansionEdit>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );*/
}
