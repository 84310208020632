import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledButtonSubmit, StyledLargerTitle, StyledTextSubmit,
} from "../../../shared/employer-styled";
import styled, { css } from "styled-components";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import theme from "../../../../cores/helpers/theme";
import { WalletTransactionTable } from "./WalletTransactionTable";
import {WalletClaimPendingTable} from "./WalletClaimPendingTable";
import { EmployerConfiguration } from "../../../../models/employer-configuration/employer-configuration-model";
import SettingIcon from "../../../assets/images/settings.png";
import { calculateWalletAmount } from "./wallet-util";

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
`;
const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  ${(props: { color: string }) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTextSubmitCustom = styled(StyledTextSubmit)`
  font-family: Graphik Semibold;
  width: auto;
  margin-left: 4px;
`

const StyledTopUpSettingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSettingIcon = styled.img`
  width: 16px;
  height: 16px;
`

type Props = {
  availableAmount: number;
  pendingAmount: {
    processing: number;
    pendingAmount: number;
    processingMonthlyContribution: number;
    claimRequested: number;
  };
  openModalCredit: () => void;
  openSettingAutoTopUpModal: () => void;
} & EmployerConfiguration;

export default function MyWalletView(props: Props) {
  const { pendingAmount: pendingTopUp, processingMonthlyContribution } = props.pendingAmount;
  const { claimPending, requiredTopUp } = calculateWalletAmount(props.pendingAmount, props.availableAmount);
  return (
    <StyledMainContent>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-2 mt-2">
            <StyledBigTitle>My wallet</StyledBigTitle>
          </div>
          <div className="col-lg-8 mt-2">
            <div className="row">
              <BalanceItem title="Balance in Account" color={theme.primaryColor} balance={props.availableAmount} />
              <BalanceItem title="Top Up Pending" color={theme.primaryColor} balance={pendingTopUp} />
              <BalanceItem title="Claim Pending" color={theme.primaryColor} balance={claimPending} />
              <BalanceItem title="Required Top Up" color={theme.secondaryColor} balance={requiredTopUp > 0 ? requiredTopUp : 0} />
              <BalanceItem title="Auto Topup Pending" color={theme.primaryColor} balance={processingMonthlyContribution} />
            </div>
          </div>
          <div className="col-lg-2 mt-2">
            {!props.enablePresetBankingInformation &&
            <div>
              <StyledButtonSubmit submit={true} onClick={props.openModalCredit}>
                Top Up Account
              </StyledButtonSubmit>
            </div>
            }
            <StyledTopUpSettingButton>
              <StyledSettingIcon alt="setting-icon" src={SettingIcon} />
              <StyledTextSubmitCustom onClick={props.openSettingAutoTopUpModal}>
                Auto Top Up Settings
              </StyledTextSubmitCustom>
            </StyledTopUpSettingButton>
          </div>
        </div>
        <div className="pt-5">
          <StyledLargerTitle>Pending Claims</StyledLargerTitle>
          <WalletClaimPendingTable />
        </div>
        <div className="pt-5">
          <StyledLargerTitle>Transactions</StyledLargerTitle>
          <WalletTransactionTable />
        </div>
      </div>
    </StyledMainContent>
  );
}

type BalanceItemProps = {
  title: string,
  color: string,
  balance: number
}

function BalanceItem(props: BalanceItemProps) {
  return (
    <div className="w-20">
      <StyledTitleBalance>{props.title}</StyledTitleBalance>
      <StyledBalance
          color={props.color}
          title={formatterUSD("currency", "USD").format(
              centToUsd(props.balance)
          )}
      >
        {formatterUSD("currency", "USD").format(
            centToUsd(props.balance)
        )}
      </StyledBalance>
    </div>
  )
}
