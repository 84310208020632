import { postAsync, getAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
const URL_Employer = "/manage-employer/api";

export type FinishSetup = {
  employerId: number;
  listAgreement: number[];
};

export const postFinishSetup = (
  finishSetup: FinishSetup
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/finish-setup`;
  return postAsync(url, finishSetup, false);
};

export const getCheckFinishSetup = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/is-setup-done`;

  return getAsync(url);
};

export const getEmployerConfiguration = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/configuration`;

  return getAsync(url);
};

export const getAgreement = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/agreement?type=1`;

  return getAsync(url);
};

export const getAgreementBankingInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/agreement?type=2`;

  return getAsync(url);
};
